import { useContext } from 'react';

import MainContext from '../../../context/MainContext';
import useRegistrtionForm from '../../../hooks/useRegistrtionForm';
import { capitalize } from '../../../utils/functions';

const GamRegistrationForm = (props) => {
    const context = useContext(MainContext)

    const {
        output,
        getValue,
        getLegals,
    } = useRegistrtionForm()

    let fields = 1
        ? [
            {
                elementType: "hidden",
                id: "episodes",
                name: "episodes",
                value: window._.map(props.openEpisodes, (item => item.id))//[window._.map(props.openEpisodes, (item => item.id))],
            },
        ]
        : [];


    const languagesList = window._.get(context, "event.languages") || []
    const languageField = languagesList.length > 1 ? {
        elementType: "select",
        id: "lang_id",
        name: "lang_id",
        required: true,
        // invalidText: "Invalid error message.",
        label: window._.get(context, "contents.language") || "Language",
        placeholder: window._.get(context, "contents.language") || "Language",
        value: getValue("lang_id") || context.language,
        options: window._.get(context, "event.languages") || []
    } : {
        elementType: "hidden",
        id: "lang_id",
        name: "episodes",
        value: window._.get(languagesList[0], "value"),
    }

    fields.push(languageField)

    fields = fields.concat([
        {
            elementType: "hidden",
            id: "country_id",
            name: "country_id",
            value: "it",
        },
        {
            elementType: "text",
            id: "firstname",
            name: "firstname",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.firstname") || "firstname",
            placeholder: window._.get(context, "contents.firstname") || "firstname",
            value: getValue("firstname"),
        },
        {
            elementType: "text",
            id: "lastname",
            name: "lastname",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.lastname") || "lastname",
            placeholder: window._.get(context, "contents.lastname") || "lastname",
            value: getValue("lastname"),
        },
        {
            elementType: "email",
            id: "email",
            name: "email",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.email") || "email",
            placeholder: window._.get(context, "contents.email") || "email",
            value: getValue("email"),
        },
        {
            elementType: "select",
            id: "company_type",
            name: "company_type",
            // required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.company_type") || "Azienda/Istituto",
            value: getValue("company_type") || 0,
            options: [{
                value: 0,//internal_false_value_label
                label: window._.get(context, "contents.affiliation") || "Istituto",
            }, {
                value: 1,//internal_false_value_label
                label: window._.get(context, "contents.istitution") || "Azienda",
            }]
        }
    ])


    if (parseInt(getValue("company_type"))) {
        fields = fields.concat([
            {
                elementType: "text",
                id: "istitution",
                name: "istitution",
                required: true,
                label: window._.get(context, "contents.company_name") || "Nome Azienda",
                placeholder: capitalize(window._.get(context, "contents.istitution")) || "Istitution",
                value: getValue("istitution"),
            }]
        )
    }
    else {
        fields = fields.concat([
            {
                elementType: "text",
                id: "department",
                name: "department",
                required: true,
                label: window._.get(context, "contents.department") || "Codice Meccanografico",
                placeholder: capitalize(window._.get(context, "contents.department")) || "Istitution",
                value: getValue("department"),
            }]
        )
    }

    fields.push(
        {
            elementType: "text",
            id: "phone",
            name: "phone",
            label: window._.get(context, "contents.phone") || "Telephone No./Work No",
            placeholder: window._.get(context, "contents.phone") || "Telephone No./Work No",
            value: getValue("phone"),
        }
    )

    if (context.legals) {
        fields = fields.concat(getLegals())
    }

    return output(fields)
}

export default GamRegistrationForm