import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { LIVE, VOD } from "../../config/channelStatus";
import "./TheoPlayer.scss"

const TheoPlayer = ({ defaultLang, forceLiveUi, hideControls, hidePip, muted = true, ...props }) => {

    const {
        onPlay = () => { },
        onTimeUpdate = () => { },
        onPause = () => { },
        onSeeking = () => { },
        onSeeked = () => { },
        onPlaying = () => { },
        onReadyStateChange = () => { },
        onAudioTrackChange = () => { },
        onCcTrackChange = () => { }
    } = props;

    const [player, setPlayer] = useState()
    const [source, setSource] = useState(props.source)
    const _el = React.useRef();
    const _wrapper = React.useRef();

    const checkControlsVisible = () => {
        if (!_el.current) {
            return false
        }
        return (_el.current.classList.contains("vjs-user-active") || _el.current.classList.contains("vjs-paused")) && _el.current.classList.contains("vjs-has-started");
    }

    const updateTextTracksPosition = () => {
        const htmlCollection = document.getElementsByClassName("theoplayer-texttracks")
        if (checkControlsVisible()) {
            for (const element of htmlCollection) {
                element.classList.remove("bottom-0");
            }
        }
        else {
            for (const element of htmlCollection) {
                element.classList.add("bottom-0");
            }
        }
    }

    useEffect(() => {
        let _player = null
        if (!player) {
            _player = librariesLoaded()
        }
        else {
            _player = player
        }
        if (props.source && source !== props.source) {
            setSource(props.source)
        }
        _player.source = props.source
        // _player.play()
        window.setTimeout(() => {
            _player.play()
            const observer = new MutationObserver(function (event) {
                updateTextTracksPosition()
            })
            observer.observe(_el.current, {
                attributes: true,
                attributeFilter: ['class'],
                childList: false,
                characterData: false
            })
        }, 1)


        _wrapper.current.addEventListener("contextmenu", (e) => {
            e.preventDefault()
            return false
        })
    }, [props.source])

    // useEffect(() => {
    //     if (props.status !== status) {
    //         setStatus(props.status)
    //     }
    // }, [props.status])

    const handleInsightChange = async (_player, t) => {
        // console.log("handleInsightChange", t)
        if (!_player.paused) {
            await onPause(null, _player)
            window.setTimeout(async () => {
                // await onPause(null, _player)
                await onPlaying(null, _player)
            }, 2000)
        }
    }

    const handleReadyStateChange = (e, _player) => {
        // console.log(" _player.audioTracks", _player.audioTracks)
        if (defaultLang) {
            let audioIndex = null
            _player.audioTracks.forEach(function (track, index) {
                if ((track.language === defaultLang)) {
                    audioIndex = index
                }
            });
            if (audioIndex !== null) {
                _player.audioTracks.forEach(function (track, index) {
                    track.enabled = false
                });
                _player.audioTracks[audioIndex].enabled = true
            }
        }
        onReadyStateChange(e, _player)
    }

    const handlePlayerPlaying = (e, _player) => {
        // if (_player.readyState === 4) {
        onPlaying(e, _player)
        // }
    }

    const handlePlayerPause = (e, _player) => {
        // if (_player.readyState === 4) {
        onPause(e, _player)
        // }
    }
    const isSubtitle = (kind) => {
        return ["captions", "subtitles"].indexOf(kind) >= 0
    }

    const librariesLoaded = () => {

        if (_el.current) {

            let theoplayerOptions = {
                libraryLocation: "https://cdn.myth.theoplayer.com/" + process.env.REACT_APP_THEO_API_KEY,
                // libraryLocation: "/node_modules/theoplayer/",
                license: process.env.REACT_APP_THEO_API_LICENSE,
                muted: true,
                fluid: true
            }

            if (!hidePip && process.env.REACT_APP_PIP_ENABLED) {
                theoplayerOptions = {
                    ...theoplayerOptions,
                    // Picture-in-picture initialisation
                    pip: {
                        "visibility": 0.7
                    }
                }
            }

            const _player = new window.THEOplayer.Player(_el.current, theoplayerOptions);

            _player.preload = 'auto';
            _player.muted = muted;
            _player.source = props.source
            _player.autoplay = true;

            if (![LIVE, VOD].includes(props.status)) {
                _player.loop = true;
            }

            if (!props.demo) {
                _player.addEventListener('play', (e) => onPlay(e, _player))
                _player.addEventListener('playing', (e) => handlePlayerPlaying(e, _player))
                _player.addEventListener('pause', (e) => handlePlayerPause(e, _player))
                _player.addEventListener('seeking', (e) => onSeeking(e, _player))
                _player.addEventListener('seeked', (e) => onSeeked(e, _player))
                _player.addEventListener('readystatechange', (e) => handleReadyStateChange(e, _player))
                _player.addEventListener('timeupdate', (e) => onTimeUpdate(e, _player))

                // _player.network.addResponseInterceptor((response) => {
                //     if (response.status >= 400 && response.status <= 599) {
                //         var errorDetails = {
                //             status: response.status,
                //             fileType: response.request.type,
                //             url: response.request.url
                //         }
                //         var networkErrorEvent = new CustomEvent('THEOnetworkerror', { detail: errorDetails });
                //         document.dispatchEvent(networkErrorEvent);
                //     };
                // })

                _player.audioTracks.addEventListener('change', function (event) {
                    const track = event.track;
                    // console.log("defaultLang6", track, track.label, track.language, track.enabled);
                    if (track.enabled) {
                        onAudioTrackChange(track.language)
                        handleInsightChange(_player, "audioTracks")
                    }
                });
                _player.textTracks.addEventListener('change', function (event) {
                    if (!isSubtitle(event.track.kind)) {
                        return false
                    }
                    // const track = event.track;
                    // const isEnabled = (track.mode === "showing");
                    // const isSubtitle = (track.kind === "subtitles");
                    // console.log(track, track.label, track.kind, track.language, track.type, isEnabled);
                    // if (isSubtitle) {
                    //     onCcTrackChange(isEnabled ? track.language : null)
                    //     handleInsightChange(_player)
                    // }
                    let currentTextTrack;
                    for (var t in _player.textTracks) {
                        const track = _player.textTracks[t]
                        if (
                            track.mode === 'showing' &&
                            isSubtitle(track.kind)
                        ) {
                            currentTextTrack = track.language;
                        }
                    }
                    onCcTrackChange(currentTextTrack)
                    handleInsightChange(_player, "textTracks")
                });
            }

            setPlayer(_player)
            if (props.onPLayerReady) {
                props.onPLayerReady(_player)
            }
            return _player
        }
    }

    const playerBox = useMemo(() => {
        const wrapperClassName = classNames("player-wrapper", {
            "hide-controls": hideControls,
            "force-live": forceLiveUi
        })
        return (
            <div
                ref={_wrapper}
                className={wrapperClassName}>
                <div
                    // vjs-16-9 THEOplayer are not necessary, but just added for demo purposes
                    className={"theoplayer-container video-js theoplayer-skin vjs-16-9 hide-casts"}
                    // The ref prop here is key it returns the actual dom element and not the virtual react dom elements
                    // Which is need by the player
                    ref={_el}
                />
            </div>
        );
    }, [props.source, forceLiveUi, hideControls])

    return playerBox

}

export default TheoPlayer;