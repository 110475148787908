import classNames from "classnames"
import "./DefaultPage.scss"
const DefaultPage = (props) => {

    return (
        <div className={classNames("container", {
            "full-height": props.fullHeight
        })}>
            {
                props.message ? (
                    <div className="message"
                        dangerouslySetInnerHTML={{
                            __html: props.message
                        }}>
                    </div>
                ) : null
            }
            {
                props.content ? (
                    <div className="message">
                        {props.content}
                    </div>
                ) : null

            }
        </div>
    )
}

//

export default DefaultPage