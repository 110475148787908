import classNames from "classnames"
import "./AgendaAccordion.scss"

const AgendaAccordion = (props) => {
    return (
        <div className={classNames("agenda-accordion", {
            open: props.open,
            "m-0": props.removeMargin,
            "no-shadow": props.removeShadow
        })}>
            {props.children}
        </div>
    )
}

export default AgendaAccordion