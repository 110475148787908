import React, { useEffect, useState, useContext } from "react"
import { ThemeContext } from "styled-components";
import MainContext from "../../context/MainContext";
import Container from "../../ui/Container/Container";
import Row from "../../ui/Row/Row";
import AdamOverlay from "../../ui/AdamOverlay/AdamOverlay";
import SelectEpisodeCard from "../SelectEpisodeCard/SelectEpisodeCard";

const SelectEpisodeOverlay = ({ onEpisodeSelected, title, hideCloseButton, onLogoutClicked, ...props }) => {
    const [modalOpen, setModalOpen] = useState(false)
    const context = useContext(MainContext)
    const themeContext = useContext(ThemeContext);

    useEffect(() => {
        setModalOpen(props.open)
    }, [props.open])

    // useEffect(() => {
    //     if (modalOpen) {
    //         disableScroll(window)
    //     }
    // }, [modalOpen])

    // const episodes = props.episodes?.filter(item => item.has_future_events)
    const episodes = props.episodes

    const onOverlayClose = () => {
        context.setSelectEpisodeModalOpen(false)
        // enableScroll(window)
    }

    const _onLogoutClicked = () => {
        onLogoutClicked()
    }

    const grid = (
        <Container fluid={true}>
            <Row>
                {
                    episodes ? episodes.map(item => {
                        return (
                            <SelectEpisodeCard
                                data={item}
                                count={episodes.length}
                                key={`select-episode-card-${item.id}`}
                                borderColor={themeContext.primaryColor}
                                onEpisodeSelected={onEpisodeSelected}
                            />

                        )
                    }) : "No episodes available"
                }
            </Row>
        </Container>
    )

    const modal = modalOpen ? (
        <AdamOverlay
            title={title || "Please select room"}
            open={modalOpen}
            onClose={onOverlayClose}
            shouldCloseOnEsc={false}
            hideCloseButton={hideCloseButton}
            onLogoutClicked={_onLogoutClicked}
        >
            {grid}
        </AdamOverlay>
    ) : null

    return modal
}

export default SelectEpisodeOverlay