import React, { useEffect, useState, useContext } from "react"
import AdamModal from "../AdamModal/AdamModal";
import { ThemeContext } from "styled-components";
import MainContext from "../../context/MainContext";
import Container from "../../ui/Container/Container";
import Row from "../../ui/Row/Row";
import classNames from "classnames";

const FaqItem = (props) => {
    const [open, setOpen] = useState(false)

    const toggle = () => {
        setOpen(open => !open)
    }

    return (
        <Row
            className={classNames("my-2 py-4 faq", {
                open: open
            })}>
            <div className="col-12">
                <dt className="faq__question"
                    onClick={toggle}>
                    {props.question}
                    <div className="faq__toggle">+</div>
                </dt>
                <dd className="faq__answer mt-4"
                    dangerouslySetInnerHTML={{
                        __html: props.answer
                    }}
                />
            </div>
        </Row>
    )
}

const FaqsModal = (props) => {
    // const [modalOpen, setModalOpen] = useState(false)
    const context = useContext(MainContext)
    // const themeContext = useContext(ThemeContext);

    // useEffect(() => {
    //     setModalOpen(props.open)
    // }, [props.open])

    const grid = (
        <Container fluid={true} className="px-4">
            {
                context.faqs ? context.faqs.map((item, index) => {
                    return (
                        <FaqItem {...item} key={`faq-${index}`} />
                    )
                }) : null

            }
        </Container >
    )

    const modal = (
        <AdamModal
            content={grid}
            open={props.open}
            onClose={() => props.setFaqsModalOpen(false)}
        />
    )

    return modal
}

export default FaqsModal