const Spin = (props) => {

    const spin = (
        <i className="fas fa-circle-notch fa-spin"></i>
    )
    return (
        spin
    )
}

export default Spin