import React, { useEffect, useState, useContext } from "react"
import AdamModal from "../AdamModal/AdamModal";
import { ThemeContext } from "styled-components";
import MainContext from "../../context/MainContext";
import Container from "../../ui/Container/Container";
import Row from "../../ui/Row/Row";

const FormErrorsModal = (props) => {
    const [modalOpen, setModalOpen] = useState(false)
    const context = useContext(MainContext)
    const themeContext = useContext(ThemeContext);

    useEffect(() => {
        setModalOpen(props.open)
    }, [props.open])

    let contents = []
    if (props.content) {
        Object.keys(props.content).forEach(item => {
            contents.push(
                <Row
                    className="my-2 py-4 px-2">
                    {
                        props.content[item] ? props.content[item].map(row => <div>{row}</div>) : null
                    }
                </Row>
            )
        })
    }

    const grid = (
        <Container fluid={true}>
            {contents}
        </Container >
    )

    const modal = (
        <AdamModal
            content={grid}
            open={modalOpen}
            // fluidHeader={true}
            onClose={() => context.closeFormErrorsModal()}
        />
    )

    return modal
}

export default FormErrorsModal