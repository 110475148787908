import React, { useContext, useEffect, useMemo, useState } from 'react';
import { getPlayerSources, playerWhebHook, playerInsight, setSubscriberChannelPresence, resetSubscriberChannelPresence } from '../../api/functions';
import { UNKNOWN, LIVE, VOD, BUFFER } from '../../config/channelStatus';
import MainContext from '../../context/MainContext';
import wrapSection from '../../hoc/wrapSection';
import { consoleLog, getAuthToken, getLocalStorageOffsetFieldName, translateChannelStatus, getLocalStorageLastPauseFieldName, getLocalStorageDurationFieldName, getCloseCaptionsVttFileUrl, utc } from '../../utils/functions';
import TheoPlayer from '../TheoPlayer/TheoPlayer';
import "./PlayerBox.scss"
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import FakePlayer from '../FakePlayer/FakePlayer';
import Input from '../Input/Input';
import classNames from 'classnames';
import PusherManager from '../../utils/PusherManager';

let buffer = 0
let playerStatus = "pause"
let lastAction = moment()
let lastActionPlayerTime = 0
let liveLocked = true // DO not edit offset until readyStateChanged === 4
let vodLocked = true // DO not edit offset until readyStateChanged === 4
// let pusherChannelState = null
let playerObject = null
let connectionStatus = null
let channelStatusCode = UNKNOWN
let timeOut = null
let logId = null
let playerStarterTime = 0
let playerCurrentTime = 0
let initialized = false
// let hidden, visibilityChange;
const PlayerBox = (props) => {

    const history = useHistory()
    const [status, setStatus] = useState(UNKNOWN)
    const [playList, setPlayList] = useState([])
    const [alternativeSources, setAleternativeSources] = useState([])
    const [ccSources, setCcSources] = useState([])
    const [formattedAlternativeSources, setFormattedAleternativeSources] = useState([])
    const [selectedAlternativeSource, setSelectedAlternativeSource] = useState()
    const [episodeLiveStartedAt, setEpisodeLiveStartedAt] = useState()
    // const [textTracks, setTextTracks] = useState()
    const [episode, setEpisodeId] = useState()
    const [channelId, setChannelId] = useState()
    const [channel, setChannel] = useState()
    const [loading, setLoading] = useState(true)
    const [audioTrackLangId, setAudioTrackLangId] = useState()
    const [ccTrackLangId, setCcTrackLangId] = useState()
    const [playerSource, setPlayerSource] = useState()
    const [playerReadyState, setPlayerReadyState] = useState()
    const [insights, setInsights] = useState()
    const [isFakeLive, setIsFakeLive] = useState(false)
    // const [liveLocked, setLiveLocked] = useState(true)
    // const [vodLocked, setVodLocked] = useState(true)
    const [pusherChannelState, setPusherChannelState] = useState() // DO NOT REMOVE!!!
    const [hidePlayer, setHidePlayer] = useState(true) // DO NOT REMOVE!!!
    // const [lastActionTime, setLastActionTime] = useState(0)
    const context = useContext(MainContext)

    const channelsStatus = translateChannelStatus(status)

    let lastEvent = null

    const handleChannelSubscription = ({ action, channelId }) => {
        try {
            switch (action) {
                case "subscribe":
                    setSubscriberChannelPresence({ channelId })
                    break
                case "unsubscribe":
                    resetSubscriberChannelPresence()
                    break
                default:
            }
        }
        catch (e) { }
    }

    useEffect(() => {
        if (!getAuthToken()) {
            history.push("/")
        }

        liveLocked = true
        vodLocked = true

        // window.setInterval(() => {
        //     setLastActionTime(lastActionTime => ++lastActionTime)
        // }, 1000)

        // let lastTime = (new Date()).getTime();

        // setInterval(function () {
        //     let currentTime = (new Date()).getTime();
        //     if (currentTime > (lastTime + 2000 * 2)) {  // ignore small delays
        //         console.log("AAA woke up " + moment().format())

        //     }
        //     lastTime = currentTime;
        // }, 2000);

        // if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
        //     hidden = "hidden";
        //     visibilityChange = "visibilitychange";
        // } else if (typeof document.msHidden !== "undefined") {
        //     hidden = "msHidden";
        //     visibilityChange = "msvisibilitychange";
        // } else if (typeof document.webkitHidden !== "undefined") {
        //     hidden = "webkitHidden";
        //     visibilityChange = "webkitvisibilitychange";
        // }
        // document.addEventListener(visibilityChange, handleVisibilityChange, false);
        window.addEventListener('online', handleConnectionChange);
        window.addEventListener('offline', handleConnectionChange);
        return function cleanup() {
            window.removeEventListener("online", handleConnectionChange)
            window.removeEventListener("offline", handleConnectionChange)
        };

        // window.ononline = (event) => {
        //     // alert("You are now connected to the network.");
        //     setOnline(true)
        //   };
        //   // Detect when user's connection is broken
        //   window.onoffline = (event) => {
        //     // alert("The network connection has been lost.");
        //     setOnline(false)
        //   };        
    }, [])

    useEffect(() => {

        if (!channelId) {
            return false
        }
        // window.Pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
        //     // key: process.env.REACT_APP_PUSHER_APP_KEY,
        //     cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
        //     forceTLS: true,
        //     authEndpoint: process.env.REACT_APP_API_BASEURL + 'broadcasting/auth',
        //     pongTimeout: 6000,
        //     activityTimeout: 30000,
        //     auth: {
        //         headers: {
        //             'Authorization': "Bearer " + getAuthToken()
        //         }
        //     }
        // });
        window.Pusher.connection.bind('connected', function () {
            // consoleLog("pusher:connected")
            // if (pusherChannelState === 'disconnected') {
            //     consoleLog(`pusher:connected status : ${playerStatus}`)
            // }
            // setPusherChannelState('connected')
        })
        window.Pusher.connection.bind('disconnected', function () {
            // consoleLog("pusher:disconnected")
            playerObject.pause()
            // if (pusherChannelState === 'connected') {
            //     consoleLog(`pusher:disconnected status : ${playerStatus}`)
            // }
            // setPusherChannelState('disconnected')
        })
        // window.Pusher.connection.bind("error", function (err) {
        //     // if (err.error.data.code === 4004) {
        //     consoleLog(">>> detected  error " + err.error.data.code);
        //     // }
        // });
        if (channelId && !window._.isEmpty(context.user)) {

            let pusherChannel = PusherManager.pusherchannel

            pusherChannel.bind("App\\Events\\ChannelStatusChanged", (data) => {
                consoleLog(`ChannelStatusChanged was triggered with data ${JSON.stringify(data)}`);

                const action = data?.actions?.find(action => parseInt(action.id) === parseInt(channelId))
                consoleLog(action);
                if (action) {
                    const newChannelStatus = parseInt(action.status)
                    switch (newChannelStatus) {
                        case LIVE:
                            liveLocked = true
                            changeChannelStatus({ newValue: LIVE, newSource: action.source, newAlternativeSources: action.channel_sources, newEpisodeLiveStartedAt: action.episode_live_started_at })
                            break
                        // case "App\\Events\\EventStatusChanged":
                        //     if (data.keep_current_time) {
                        //         vodLocked = true
                        //     }
                        //     changeChannelStatus({ newValue: action.status, newSource: action.source, newAlternativeSources: action.channel_sources, newCCSources: action.closed_captions })
                        //     break
                        // case "App\\Events\\ChangeQaaStatus":
                        //     changeQaaStatus(data.status)
                        //     break
                        default:
                            if (data.keep_current_time) {
                                vodLocked = true
                            }
                            changeChannelStatus({ newValue: newChannelStatus, newSource: action.source, newAlternativeSources: action.channel_sources, newCCSources: action.closed_captions })
                            break
                    }
                }
            });

            // subscribeToChannel
            const channel = subscribeToChannel(props.eventId, channelId)
            // setSubscriberChannelPresence({ channelId })
            handleChannelSubscription({ action: "subscribe", channelId })
            // const channel = window.Pusher.subscribe(`presence-${props.eventId}.${channelId}`)
            // .here((e) => {
            //     consoleLog("LiveStarted join", e);
            //     // setStatus(LIVE)
            // })
            // .listen('.App\\Events\\LiveStarted', (e) => {
            //     consoleLog("LiveStarted", e);
            //     setStatus(LIVE)
            // });
            // consoleLog("channel", channel)
            // channel.bind('pusher:subscription_succeeded', function (members) {
            //     consoleLog("pusher:subscription_succeeded")
            // })
            // channel.bind('pusher:member_added', function (member) {
            //     consoleLog("pusher:member_added")
            // });
            // channel.bind("pusher:member_removed", (member) => {
            //     consoleLog("member_removed at " + moment().format())
            //     // remove_member(member.id, member.info);
            // });
            // channel.bind('.App\\Events\\LiveStarted', function (e) {
            //     consoleLog("lpusher:isten", e)
            // });
            // channel.bind('.App\\Events\\LiveStopped', function (e) {
            //     consoleLog("pusher:listen", e)
            // });
            // channel.bind('pusher:ping', function (e) {
            //     consoleLog("pusher:ping", e)
            // });
            // channel.bind('pusher:pong', function (e) {
            //     consoleLog("pusher:pong", e)
            // });
            channel.bind_global(globalCallback);

        }
        return function cleanup() {
            if (window.Pusher) {
                unsubscribeFromChannel(props.eventId, channelId)
                // resetSubscriberChannelPresence()
                handleChannelSubscription({ action: "unsubscribe" })
                // window.Pusher.unsubscribe(`presence-${props.eventId}.${channelId}`)
            }
        };
    }, [channelId])

    useEffect(() => {
        const _episodeId = window._.get(context, "episodeId")
        consoleLog("onEpisodeSelected playerbox", _episodeId, props.episodeId)
        if (props.episodeId && episode !== props.episodeId) {
            loadPlayList(_episodeId)
            // setEpisodeId(_episodeId)
        }
    }, [props.episodeId])

    // useEffect(() => {
    //     if (context.channelId && context.channelId !== channelId) {
    //         setChannelId(context.channelId)
    //     }
    // }, [context.channelId])

    useEffect(() => {
        channelStatusCode = status
        handleLastPauseLog()
    }, [status])

    useEffect(() => {
        setFormattedAleternativeSources(alternativeSources?.map(item => {
            return {
                id: item.id,
                src: item.src,
                type: item.type
            }
        })
        )
    }, [alternativeSources])

    useEffect(() => {
        if (!channelId) {
            return
        }
        let params = {
            channel_id: channelId,
        }
        if (audioTrackLangId) {
            params = {
                ...params,
                audio_lang_id: audioTrackLangId,
            }
        }
        if (ccTrackLangId) {
            params = {
                ...params,
                cc_lang_id: ccTrackLangId,
            }
        }
        if (selectedAlternativeSource) {
            params = {
                ...params,
                channel_source_id: selectedAlternativeSource?.id
            }
        }
        onInsightChange(params)
    }, [audioTrackLangId, ccTrackLangId, selectedAlternativeSource, playerReadyState])

    useEffect(() => {
        window.setTimeout(async () => {
            updateSource()
        }, 500)
    }, [playList, status, selectedAlternativeSource, ccSources])

    const updateSource = async () => {
        // const playerSource = (alternativeSources?.length > 1 && selectedAlternativeSource) ? {
        //     sources: selectedAlternativeSource
        // } : {
        //     sources: playList ? [playList[status]] : null,
        //     textTracks: getTextTrack(playList, status)
        // };
        let source = {}
        if (alternativeSources?.length > 1 && selectedAlternativeSource) {
            source = {
                sources: selectedAlternativeSource
            }
        }
        else {
            const tracks = await getTextTrack(playList, status)
            source = {
                sources: playList ? [playList[status]] : null,
                textTracks: tracks
            }
        }
        setPlayerSource(source);
    }

    // useEffect(() => {
    //     let newTextTracks = null
    //         if (newValue === PRE_LIVE) {
    //             newTextTracks = [{
    //                 label: 'Chapters',
    //                 kind: 'chapters',
    //                 src: 'https://cdn.theoplayer.com/video/sintel/chapters.vtt',
    //                 srclang: 'en',
    //                 default: true
    //             }]
    //         }
    //         setTextTracks(newTextTracks)
    // }, [playList])

    // // If the page is hidden, pause the video;
    // // if the page is shown, play the video
    // function handleVisibilityChange() {
    //     if (document[hidden]) {
    //         console.log("AAA hidden " + moment().format())
    //     } else {
    //         console.log("AAA visible " + moment().format())
    //     }
    // }

    const setLastPauseLog = (playerObject) => {
        localStorage.setItem(getLocalStorageLastPauseFieldName(context.episodeId), playerObject.currentTime)
        localStorage.setItem(getLocalStorageDurationFieldName(context.episodeId), playerObject.duration)
    }

    const handleLastPauseLog = () => {
        const timeInt = localStorage.getItem(getLocalStorageLastPauseFieldName(context.episodeId))
        if (timeInt) {
            onPause(timeInt, true)
            localStorage.setItem(getLocalStorageLastPauseFieldName(context.episodeId), null)
            localStorage.setItem(getLocalStorageDurationFieldName(context.episodeId), null)
        }
    }

    const handleConnectionChange = (e) => {
        if (e.type !== connectionStatus) {
            if (e.type === "offline") {
                playerObject.pause()
                setLastPauseLog(playerObject.currentTime)
                alert("You are offline")
            }
            else {
                handleLastPauseLog()
                alert("You back online")
            }
            connectionStatus = e.type
        }
    }

    const subscribeToChannel = (eventId, channelId) => {
        return window.Pusher.subscribe(`presence-${eventId}.${channelId}`)
    }
    const unsubscribeFromChannel = (eventId, channelId) => {
        window.Pusher.unsubscribe(`presence-${eventId}.${channelId}`)
    }

    const globalCallback = (eventName, data) => {
        consoleLog(`bind global: The event ${eventName} was triggered with data ${JSON.stringify(data)}`);
        switch (eventName) {
            case "App\\Events\\LiveStarted":
                liveLocked = true
                changeChannelStatus({ newValue: LIVE, newSource: data.source, newAlternativeSources: data.channel_sources, newEpisodeLiveStartedAt: data.episode_live_started_at })
                break
            case "App\\Events\\LiveStopped":
                changeChannelStatus({ newValue: BUFFER, newSource: data.source, newAlternativeSources: data.channel_sources })
                break
            case "App\\Events\\EventStatusChanged":
                if (data.keep_current_time) {
                    vodLocked = true
                }
                changeChannelStatus({ newValue: data.status, newSource: data.source, newAlternativeSources: data.channel_sources, newCCSources: data.closed_captions })
                break
            // case "App\\Events\\ChangeQaaStatus":
            //     changeQaaStatus(data.status)
            //     break
            default:
        }
    }

    // const changeChannelStatus = (newValue, newSource, newAlternativeSources = [], newCCSources = []) => {
    const changeChannelStatus = ({ newValue, newSource, newAlternativeSources = [], newCCSources = [], newEpisodeLiveStartedAt }) => {

        setEpisodeLiveStartedAt(newEpisodeLiveStartedAt)
        if (newSource) {
            setPlayList(playList => {
                return {
                    ...playList,
                    [newValue]: newSource
                }
            })
        }
        setSelectedAlternativeSource()
        setAleternativeSources(newAlternativeSources)
        setCcSources(newCCSources)
        setStatus(newValue)
        context.setChannelStatus(newValue)
    }

    // const changeQaaStatus = (newValue) => {
    //     consoleLog("changeQaaStatus", newValue)
    //     context.changeQaaStatus(newValue)
    // }

    const loadPlayList = async (episodeId) => {
        if (!getAuthToken()) {
            return false
        }
        const res = await getPlayerSources({
            episode_id: episodeId,
            lang_id: context.language
        })
        setPlayList(window._.get(res, "playlist"))
        setChannel(window._.get(res, "channel"))
        setChannelId(window._.get(res, "id"))
        changeChannelStatus({ newValue: window._.get(res, "status") })
        setAleternativeSources(window._.get(res, "sources"))
        setCcSources(window._.get(res, "cc_sources"))
        setLoading(false)
        // setEligible(window._.get(res, "id") ||)
    }

    // INACTIVE
    // const onPlay = (currentTime) => {
    //     // consoleLog(currentTime, "The player event play.")
    //     // playerStatus = "playing"
    //     // lastAction = moment()
    //     // // sendWebHook(currentTime, "play")
    // }

    // ACTIVE
    const onPlaying = async (currentTime) => {
        consoleLog(currentTime, "The player event onPlaying.")
        playerStarterTime = currentTime
        if (playerStatus !== "playing") {
            playerStatus = "playing"
            lastAction = moment()
            return await sendWebHook(currentTime, "play")
        }
    }

    // ACTIVE
    const onPause = async (currentTime, force) => {
        if (isNaN(currentTime)) {
            return Promise.resolve(false)
        }
        else {
            consoleLog(currentTime, force, "The player event onPause.")
            if (playerStatus === "playing" || force) {
                playerStatus = "pause"
                lastAction = moment()
                return await sendWebHook(currentTime, "pause", force)
            }
        }
    }

    // ACTIVE
    const onInsightChange = async (params) => {
        params = {
            ...params,
            status
        }
        if (ccSources.length && !params.cc_lang_id && !initialized) {
            const active = ccSources.find(item => item.is_active)
            // params = {
            //     ...params,
            //     cc_lang_id: active?.lang_id
            // }
            setCcTrackLangId(active?.lang_id)
            initialized = true
            return
        }
        if (params === insights) {
            return
        }
        if (playerReadyState !== 4) {
            return
        }
        if (!params.cc_lang_id) {
            params = {
                ...params,
                cc_lang_id: null
            }
        }
        setInsights(params)
        window.clearTimeout(timeOut)
        timeOut = window.setTimeout(() => {
            consoleLog(params, "The player event onPlaying.")
            playerInsight(params)
        }, 100)
    }

    const onAudioTrackChange = (e) => {
        // console.log("onAudioTrackChange", e)
        setAudioTrackLangId(e)
    }

    const onCcTrackChange = (e) => {
        // console.log("onCcTrackChange", e)
        setCcTrackLangId(e)
    }
    // // INACTIVE
    // const onSeeking = (currentTime) => {
    //     // consoleLog(currentTime, "The player event onSeeking.")
    //     // playerStatus = "seeking"
    //     // sendWebHook(currentTime, "pause")
    // }

    // // INACTIVE
    // const onSeeked = (currentTime) => {
    //     // consoleLog(currentTime, "The player event onSeeked.")
    //     // playerStatus = "seeked"
    //     // sendWebHook(currentTime, "pause")
    // }

    // INACTIVE
    const onTimeUpdate = (currentTime) => {

        playerCurrentTime = currentTime
        if ([VOD].includes(status) && !vodLocked) {
            const timeInt = parseInt(currentTime)
            localStorage.setItem(getLocalStorageOffsetFieldName(context.episodeId), timeInt)
        }
    }

    // INACTIVE
    const onReadyStateChange = async (player) => {
        setPlayerReadyState(player.readyState)
        setIsFakeLive(false)
        consoleLog("onReadyStateChange", player.readyState)
        if (player.readyState === 4) {
            setHidePlayer(false)
        }

        if (![LIVE].includes(status)) {
            liveLocked = true
        }
        if (![VOD].includes(status)) {
            vodLocked = true
        }

        if (channel?.is_fake_live && [LIVE].includes(status)) {
            setIsFakeLive(true)
        }
        if (
            player.readyState === 4 &&
            !buffer &&
            liveLocked &&
            [LIVE].includes(status)
        ) {
            if (channel?.is_fake_live) {

                const liveStartedAt = context.episode?.live_started_at || episodeLiveStartedAt
                // console.log("liveStartedAt", liveStartedAt, episodeLiveStartedAt)
                if (liveStartedAt) { // episode_live_started_at
                    const diff = moment().diff(utc(liveStartedAt), "seconds")
                    consoleLog("player.currentTime: LIVE starts from", diff)
                    player.currentTime = diff;
                    liveLocked = false
                }
            }

            if (playerStatus === "playing") {
                // const now = moment()
                // const resPause = await sendWebHook(lastActionPlayerTime + (parseInt(now.format("X")) - parseInt(lastAction.format("X"))), "pause")
                // const resPlay = await sendWebHook(player.currentTime, "play")
                // consoleLog("resPause", player.currentTime, parseInt(now.format("X")) - parseInt(lastAction.format("X")))
                // lastAction = now
            }
        }
        if (
            player.readyState === 4 &&
            [VOD].includes(status) &&
            vodLocked
        ) {
            if (startFrom()) {
                player.currentTime += startFrom()
                localStorage.setItem(getLocalStorageOffsetFieldName(context.episodeId), null)
            }
            vodLocked = false
        }
        // HAVE_ENOUGH_DATA
    }

    const sendWebHook = async (currentTime, eventName, force) => {
        let res = null
        // consoleLog("The player event xxxx " + eventName, parseInt(currentTime), parseInt(playerStarterTime))
        if (
            (
                [LIVE, VOD].includes(status) &&
                lastEvent !== eventName
            ) ||
            force
        ) {
            lastEvent = eventName
            let calculatedPlayerTime = 0
            if ([VOD].includes(status)) {
                calculatedPlayerTime = parseInt(currentTime)
            }
            else {
                calculatedPlayerTime = parseInt(currentTime) - parseInt(playerStarterTime)
                if (calculatedPlayerTime < 0) {
                    calculatedPlayerTime = 0
                }
            }

            res = await playerWhebHook({
                time: calculatedPlayerTime,
                name: eventName,
                episodeId: context.episodeId,
                channelId: channelId,
                channelStatus: channelStatusCode,
                duration: force ? localStorage.getItem(getLocalStorageDurationFieldName(context.episodeId)) : playerObject.duration,
                buffer: buffer,
                async: force,
                log_id: logId
            })
            logId = res.log
            lastActionPlayerTime = parseInt(currentTime)
        }
        return res
    }

    const startFrom = () => {
        if ([VOD].includes(status)) {
            return parseInt(localStorage.getItem(getLocalStorageOffsetFieldName(context.episodeId)))
        }
    }

    const getTextTrack = async (playList, status) => {

        let textTracks = []
        const srcM3u8 = window._.get(playList, status + ".src")

        if (srcM3u8) {

            const defaultCcConfig = {
                default: true, //optional
                kind: 'subtitles', //optional - find other values at https://support.theoplayer.com/hc/en-us/articles/214350425#TextTrackDescription
                // label: 'English subs', //optional - this will appear in your UI
                srclang: 'en'
            }

            if (status === LIVE) {
                textTracks.push(defaultCcConfig)
            }

            // else if (status === VOD) {
            // Subtitles / CC / Captions
            // let liveConfiguration = {
            //     default: true, //optional
            //     kind: 'subtitles', //optional - find other values at https://support.theoplayer.com/hc/en-us/articles/214350425#TextTrackDescription
            //     // label: 'English subs', //optional - this will appear in your UI
            //     srclang: 'en'
            // }
            // if (status === VOD) {
            //     if (ccSources.length === 0) {
            //         const ccVttUrl = getCloseCaptionsVttFileUrl(srcM3u8)
            //         // const ccVttUrl = 'https://deu4xpzslem1x.cloudfront.net/index.m3u8'
            //         try {
            //             await get(ccVttUrl)
            //             liveConfiguration = {
            //                 ...liveConfiguration,
            //                 src: ccVttUrl
            //             }
            //         }
            //         catch (e) {
            //         }
            //         liveConfiguration = {
            //             ...liveConfiguration,
            //             src: ccVttUrl
            //         }
            //     }
            //     else {}

            // }

            // textTracks.push(liveConfiguration)
            // }
            if (status === VOD) {
                if (ccSources.length === 0) {
                    // BE AWARE!!! ASYNC makes troubles BUT DO NOT DELETE!!!
                    // const ccVttUrl = getCloseCaptionsVttFileUrl(srcM3u8)
                    // // const ccVttUrl = 'https://deu4xpzslem1x.cloudfront.net/index.m3u8'
                    // try {
                    //     await get(ccVttUrl)
                    //     textTracks.push({
                    //         ...defaultCcConfig,
                    //         src: ccVttUrl
                    //     })
                    // }
                    // catch (e) {
                    // }
                }
                else {
                    // "default" Not Working with iOS!!!
                    // ccSources.forEach(item => {
                    //     textTracks.push({
                    //         default: item.is_active, //!index, //optional
                    //         kind: 'subtitles', //optional - find other values at https://support.theoplayer.com/hc/en-us/articles/214350425#TextTrackDescription
                    //         label: item.default_title, //optional - this will appear in your UI
                    //         srclang: item.lang_id,
                    //         src: getCloseCaptionsVttFileUrl(srcM3u8, item.lang_id)
                    //     })
                    // })
                }
                // console.log("textTracks", status, textTracks, ccSources)
                // Chapters
                // textTracks.push({
                //     label: 'Chapters',
                //     kind: 'chapters',
                //     src: getChaptersVttFileUrl(srcM3u8),
                //     // srclang: 'en',
                //     default: true
                // })
                // // Thumbnails
                // textTracks.push({
                //     default: true,
                //     src: getThumbnailsVttFileUrl(srcM3u8),
                //     label: 'thumbnails',
                //     kind: 'metadata'
                // })
            }
        }
        return Promise.resolve(textTracks)
    }

    const alterantiveSourceChanged = (e) => {
        onPause(playerCurrentTime, true)
        setSelectedAlternativeSource(formattedAlternativeSources.find(item => item.src === e.target.value))
        setAudioTrackLangId()
        setCcTrackLangId()
    }

    const alternativeSourcesSelector = alternativeSources?.length > 1 ? (
        <Input
            elementType="select"
            value={selectedAlternativeSource?.src}
            options={alternativeSources.map(item => {
                return {
                    value: item.src,
                    label: item.title
                }
            })}
            onChange={(e) => alterantiveSourceChanged(e)}
        />
    ) : null

    const getPlayerDefaultLang = () => {
        return (context.user.lang_id !== context.event.default_language) ? context.user.lang_id : null
    }

    const playerBox = useMemo(() => {

        const playerBoxClassNames = classNames("playerbox-wrapper", {
            "playerbox-overlay": hidePlayer
        })
        // console.log("event changed", channelsStatus, status, playerSource)
        return loading ? (
            <div>Loading video...</div>
        ) : (channelsStatus && status !== UNKNOWN) ? (
            <div className={playerBoxClassNames}>
                {process.env.NODE_ENV !== "production" ? (
                    <div>
                        <div>channelsStatus {channelsStatus}</div>
                        <div>status {status}</div>
                        <div>source {playerSource?.sources[0]?.src}</div>
                        <div>User Lang ID {context.user.lang_id}</div>
                        <div>Event Lang ID {context.language}</div>
                        <div>Event Default Lang ID {context.event.default_language}</div>
                        <div>Episode ID {context.episodeId}</div>
                    </div>
                ) : null}
                {
                    hidePlayer && <FakePlayer />
                }
                <TheoPlayer
                    key={`player-key-${status}`}
                    source={playerSource}
                    // textTracks={textTracks}
                    defaultLang={getPlayerDefaultLang()}
                    status={status}
                    startFrom={startFrom()}
                    forceLiveUi={isFakeLive}
                    hidePlayer={hidePlayer}
                    // onPlay={(e, player) => { onPlay(player.currentTime) }}
                    onPause={(e, player) => { onPause(player.currentTime) }}
                    // onSeeking={(e, player) => { onSeeking(player.currentTime) }}
                    // onSeeked={(e, player) => { onSeeked(player.currentTime) }}
                    onTimeUpdate={(e, player) => { onTimeUpdate(player.currentTime) }}
                    onPlaying={(e, player) => { onPlaying(player.currentTime) }}
                    onReadyStateChange={(e, player) => { onReadyStateChange(player) }}
                    onAudioTrackChange={onAudioTrackChange}
                    onCcTrackChange={onCcTrackChange}
                    onPLayerReady={(player) => { playerObject = player }}
                    muted={true}
                />
            </div>
        ) : (
            <FakePlayer>
                {window._.get(context, "contents.notEligible")}
            </FakePlayer>
        )
    }, [status, playerSource, isFakeLive, hidePlayer])
    return (window._.isEmpty(context.user)) ? null : (
        <>
            {alternativeSourcesSelector}
            {playerBox}
            {process.env.NODE_ENV !== "production" ? channelsStatus : null}
        </>
    )
}

PlayerBox.className = "play"
export default wrapSection(PlayerBox)