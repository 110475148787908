import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import MainContext from '../../context/MainContext';
import classNames from 'classnames';

const Logo = React.forwardRef(({ sectionName }, ref) => {

    const context = useContext(MainContext)
    const themeContext = useContext(ThemeContext);

    const className = classNames("d-inline-block align-center navbar-logo", ` navbar-logo__${sectionName}`)

    const logo = themeContext.mediaHeaderLogoDesktop ? (
        <img
            src={themeContext.mediaHeaderLogoDesktop.url}
            className={className}
            alt={context.siteTitle} />
    ) : context.siteTitle
    return logo
})

export default Logo