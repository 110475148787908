import React, { useContext, useEffect, useState, } from 'react';

import Form from '../components/Form/Form';
import MainContext from '../context/MainContext';

import { getContents } from '../utils/functions';


const EditQaAForm = (props) => {
    const [defaultValues, setDefaultValues] = useState({});

    const context = useContext(MainContext)

    useEffect(() => {
        setDefaultValues({
            "edited_content": props.edited_content || props.original_content
        })
    }, [props.edited_content, props.original_content])

    const getEndpoint = () => {
        return [
            "questions"
        ].join("/");
    }

    const getValue = (name, type) => {
        const value = !window._.isEmpty(defaultValues) ? defaultValues[name] : null;
        switch (type) {
            case "bool":
                return value !== 0;
            default:
                return value || "";
        }
    };

    const onChange = (key, value) => {
        setDefaultValues({
            ...defaultValues,
            [key]: value
        });
    }

    const onSubmit = (e) => {
        props.onSubmit(e)
    }

    let fields = 1
        ? [
            {
                elementType: "hidden",
                id: "episode_id",
                name: "episode_id",
                value: window._.get(context, "episode.id"),
            },
        ]
        : [];

    if (props.chairMan) {
        fields.push({
            elementType: "hidden",
            id: "confirmed",
            name: "confirmed",
            value: 1,
        })
    }

    fields.push({
        elementType: "textarea",
        id: "edited_content",
        name: "edited_content",
        required: true,
        placeholder: window._.get(context, "contents.question") || "Ask a question",
        value: getValue("edited_content"),
    })

    const episodeQaaCategories = window._.get(context, "episode.qaa_categories")

    if (episodeQaaCategories) {
        const categorySelectOptions = episodeQaaCategories.map(item => {
            return {
                value: item.toLowerCase(),//internal_false_value_label
                label: item,
            }
        })
        fields.push({
            elementType: "select",
            id: "category",
            name: "category",
            placeholder: window._.get(context, "contents.qaa_category") || "Assign a category",
            value: getValue("category"),
            options: categorySelectOptions
        })
    }

    return (
        <div className="regstration-form regstration-from__wrapper">
            <Form
                fields={fields}
                endpoint={getEndpoint()}
                method={"post"}
                itemId={props.id}
                onSubmit={(e) => onSubmit(e)}
                onChange={(key, val) => onChange(key, val)}
                submitButtonLabel={getContents(context, "sendQuestionToSpeaker") || "Send question to the chairman"}
                disableSubmit={false}
                disableRequiredHelper={true}
                abortButton={true}
                onAbortClicked={props.onAbort}
                deleteButton={true}
                onDeleteClicked={props.onDelete}
                updateButton={true}
                onUpdateClicked={props.onUpdate}
            />
        </div>
    );
}

export default EditQaAForm