import { useState } from "react"
import DefaultPage from "../DefaultPage/DefaultPage"
const ErrorPageWithReload = ({ message, reloadAfter = 10 }) => {

    const [countDown, setCountDown] = useState(reloadAfter)

    useState(() => {
        window.setInterval(() => {
            console.log(countDown, 0)
            setCountDown(countDown => {
                if (countDown <= 1) {
                    window.location.reload()
                }
                else {
                    return --countDown
                }

            })
        }, 1000)
    }, [reloadAfter])

    message = (
        <>
            <div>
                {message}
            </div>
            {
                countDown ? (
                    <div>
                        This page will be automatically reloaded in {countDown} seconds. If the problem persists, please contact support
                    </div>
                ) : null
            }

        </>
    )

    return (
        <DefaultPage content={message} fullHeight />
    )
}

export default ErrorPageWithReload