import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import MainContext from '../../context/MainContext';
import wrapSection from '../../hoc/wrapSection';
import Button from '../../ui/Button/Button';
import Spin from '../../ui/Spin/Spin';
import { getThemeConfig } from '../../utils/functions';
import Input from '../Input/Input';
import "./Header.scss"
const Header = (props) => {

    const [email, setEmail] = useState("")
    const history = useHistory()
    const context = useContext(MainContext)
    const themeContext = useContext(ThemeContext);

    const HeaderTitle = styled.div`
        // color: ${themeContext.headerIntroColor || themeContext.secondaryColor}
    `
    const HeaderSubtitle = styled.div`
        // color: ${themeContext.headerIntroColor || themeContext.secondaryColor}
    `
    // Redirect to player page when user data are loaded 
    // and user has subscribed at least a channel
    // useEffect(() => {

    //     console.log("loadUserData", window._.get(context, "user.channels"))
    //     if (window._.get(context, "user.channels")) {
    //         alert("loadUserData")
    //         history.push("/play");
    //     }
    // }, [context.user])

    const config = getThemeConfig(context, "header")
    const rfConfig = getThemeConfig(context, "registration-form")

    const loginForm = (window._.get(config, "login-form")) ? (
        <>
            <div>
                <Input
                    id="login_id"
                    name="login_id"
                    placeholder={"email"}
                    elementType={"email"}
                    onChange={(e) => setEmail(e.target.value)}
                    nowrap={true}
                    role="button"
                    value={email}
                    placeholder={window._.get(context, "contents.emailBoxLoginPlaceholder")}
                    style={{
                        display: "inline-block",
                        width: "50%"
                    }}
                />
                <Button
                    className="btn-login btn-inverted"
                    onClick={() => props.onLoginClicked({
                        email: email
                    })}>
                    {
                        props.loginWaiting ? (
                            <Spin />
                            // <i className="fas fa-circle-notch fa-spin"></i>
                        ) : window._.get(context, "contents.login")
                    }
                </Button>
            </div>
            {
                props.loginErrors ? (
                    <div>
                        {props.loginErrors.join(", ")}
                    </div>
                ) : null
            }
        </>
    ) : null
    //(window._.get(rfConfig, "modal"))
    const registerButton = (
        <Button
            className="btn-register btn-primary">
            {window._.get(context, "contents.register")}
        </Button>
    )

    const intro = props.intro ? (
        <div className="header-intro">
            <HeaderTitle
                className="header-intro__title"
                dangerouslySetInnerHTML={{ __html: props.intro }}
            />
            <HeaderSubtitle
                className="header-intro__subtitle"
                dangerouslySetInnerHTML={{ __html: props.emailBoxLogin }}
            />
            {loginForm}
            {registerButton}
        </div>
    ) : null

    return intro
}

Header.className = "header"
Header.fluid = true
export default wrapSection(Header)