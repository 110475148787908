import React, { useContext, useEffect, useState } from "react"
import AdamModal from "../AdamModal/AdamModal";
import Container from "../../ui/Container/Container";
import Row from "../../ui/Row/Row";
import Button from "../../ui/Button/Button";
import { sendEvaluationFormAnswers } from "../../api/functions"
import classNames from "classnames";
import "./EvaluationFormModal.scss"
import Multiselect from "multiselect-react-dropdown";
import { consoleLog } from "../../utils/functions";
import MainContext from "../../context/MainContext";

const EvaluationFormModal = (props) => {

    const context = useContext(MainContext)
    const [modalOpen, setModalOpen] = useState(false)
    const [episode, setEpisode] = useState()
    const [loading, setLoading] = useState(false)
    const [answers, setAnswers] = useState({})
    const [requiredAnswers, setRequiredAnswers] = useState({})
    const [formFeedback, setFormFeedback] = useState()
    const [formErrors, setFormErrors] = useState([])
    const [answered, setAnswered] = useState([])

    const { user } = props
    const userOpenQuestions = window._.get(user, `questions`, [])
    const userEpisodes = window._.get(user, `episodes`, []).filter(item => item.is_evaluation_open && userOpenQuestions.includes(item.id))
    // const questionsCategories = window._.get(episode, "evaluation_questions")
    const questionsCategories = props.questionsCategories

    useEffect(() => {
        if (userEpisodes.length === 1) {
            setEpisode(userEpisodes[0])
        }
    }, [user])

    useEffect(() => {
        setModalOpen(props.open)
    }, [props.open])

    useEffect(() => {
        setEpisode(context.episode)
    }, [context.episode])

    const onChange = (key, e, isRequired) => {
        let value = e
        if (e.target) {
            value = e.target?.value
        }
        setAnswers({
            ...answers,
            [key]: value
        })
        if (isRequired) {
            setRequiredAnswers({
                ...requiredAnswers,
                [key]: value
            })
        }
    }

    const buttonIsDisabled = () => {
        // if (!requiredAnswers) {
        //     return false
        // }

        let answersResults = []
        Object.keys(requiredAnswers).forEach(k => {
            answersResults.push(requiredAnswers[k])
        })
        let questionsCOunt = 0
        if (questionsCategories) {
            questionsCategories.forEach(cat => {
                questionsCOunt += cat.evaluationquestions?.filter(item => item.is_required && item.lang_id === context.language)?.length
            })
        }
        return (
            !questionsCategories ||
            loading ||
            answersResults.filter(item => !!item).length !== questionsCOunt
        )
    }

    const sendAnswers = async () => {
        setFormFeedback(formFeedback => {
            return {
                ...formFeedback,
                [episode.id]: null
            }
        })
        setFormErrors()
        setLoading(true)
        try {
            const res = await sendEvaluationFormAnswers({
                episode_id: episode.id,
                answers: answers
            })
            setFormFeedback(formFeedback => {
                let ff = formFeedback
                ff[episode.id] = "Thank you for your time!"
                return ff
            })
            // setFormFeedback("Thank you for your time!")
            setAnswered(answered => answered.concat(episode.id))
            setAnswers()
            setRequiredAnswers({})
            props.setEvaluationFormAnswered(true)
            // window.setTimeout(() => {
            //     close()
            // }, 2000)
        }
        catch (e) {
            const newErrors = window._.get(e, "response.data.message")
            setFormErrors(newErrors)
        }
        finally {
            setLoading(false)
        }
    }

    const close = () => {
        setFormErrors()
        setRequiredAnswers({})
        props.closeEvaluartionFormModal()
    }

    const grid = episode ? window._.get(props.answered, episode.id) ? ( //window._.get(props.answered, episode.id)
        <Container fluid={true}>
            <Row className="my-2 py-4 px-2">
                <div className="col">
                    {props.alreadyAnsweredMessage}
                </div>
            </Row>
        </Container>
    ) : (
        <Container fluid={true}>
            <Row>
                <div
                    className="col mt-3 mx-3 px-0 pb-3 form-category__title"
                >
                    {
                        userEpisodes.length > 1 ? (
                            <i
                                class="fas fa-chevron-left  mr-3"
                                style={{ cursor: "pointer" }}
                                onClick={() => setEpisode()}
                            ></i>
                        ) : null
                    }
                    <span className="h4">{episode.evaluation_form_title || episode.title}</span>
                </div>
            </Row>
            {
                window._.get(formFeedback, episode.id) ? (
                    <Row>
                        <div className="col py-3">
                            {window._.get(formFeedback, episode.id)}
                        </div>
                    </Row>
                ) : questionsCategories ? questionsCategories.map((category, index) => {

                    const questions = category.evaluationquestions?.filter(q => q.lang_id === context.language)
                    return (
                        <div key={`question-category-${index}`}>
                            {
                                questions?.length ? (
                                    <Row>
                                        <div
                                            className="col h5 mt-3 mx-3 px-0 pb-3 form-category__title"
                                        >
                                            {category.title}
                                        </div>
                                    </Row>
                                ) : null
                            }

                            <div>
                                {
                                    questions.map(item => {
                                        consoleLog("item.options", item.options)
                                        const answer = (!item.question_type) ? (
                                            <textarea
                                                onChange={(e) => onChange(item.id, e, item.is_required)}
                                                className="form-control form-control-lg"
                                            ></textarea>
                                        ) : item.is_radio ? (
                                            <div>
                                                {
                                                    item.options ? item.options.map(option => {
                                                        return (
                                                            <div
                                                                key={`option-${index}-${option}`}
                                                                className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name={`option-${index}`}
                                                                    value={option}
                                                                    onChange={(e) => onChange(item.id, e, item.is_required)}
                                                                />
                                                                <label className="form-check-label" htmlFor="exampleRadios3">
                                                                    {option}
                                                                </label>
                                                            </div>
                                                        )
                                                    }) : null
                                                }
                                            </div>
                                        ) : item.max_allowed_choices > 1 ? (
                                            <Multiselect
                                                displayValue={"name"}
                                                onSelect={(selectedList, selectedItem) => { onChange(item.id, selectedList.map(sel => sel.id), item.is_required) }}
                                                showCheckbox={true}
                                                closeOnSelect={false}
                                                selectionLimit={item.max_allowed_choices}
                                                onRemove={(selectedList, removedItem) => { onChange(item.id, selectedList.map(sel => sel.id), item.is_required) }}
                                                options={item.options?.map(option => {
                                                    return {
                                                        id: option,
                                                        key: option,
                                                        name: option
                                                    }
                                                })}
                                            />
                                        ) : (
                                            <select
                                                onChange={(e) => onChange(item.id, e, item.is_required)}
                                                className="form-control"
                                            >
                                                <option
                                                    key={`option-${index}}`}
                                                >
                                                </option>
                                                {
                                                    (item.options && !window._.isEmpty(item.options)) ? Object.keys(item.options).map(k => {
                                                        return (
                                                            <option
                                                                key={`option-${index}-${item.options[k]}`}
                                                                value={item.options[k]}>
                                                                {item.options[k]}
                                                            </option>
                                                        )
                                                    }) : null
                                                }
                                            </select>
                                        )

                                        const legend = item.legend ? (
                                            <div
                                                className="question__legend mt-2"
                                                dangerouslySetInnerHTML={{
                                                    __html: item.legend
                                                }} />
                                        ) : null

                                        return (
                                            <Row
                                                key={`question-${index}`}
                                                className="question my-2 py-4 px-2">
                                                <div className="col-12 question__sentence">
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: `${item.content}${item.is_required ? " *" : ""}`
                                                        }} />
                                                    {legend}
                                                </div>
                                                <div className="col-12 question__answer">
                                                    {answer}
                                                </div>
                                            </Row>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                }) : "No questions"
            }
            <Row>
                <div
                    className="col"
                >
                    {
                        formErrors ? (
                            <div
                                className="py-3 text-danger">
                                {formErrors}
                            </div>
                        ) : null
                    }
                    {
                        window._.get(formFeedback, episode.id) ? null : (
                            <div
                                className="pb-3"
                            >
                                <Button
                                    disabled={buttonIsDisabled()}
                                    onClick={sendAnswers}
                                    size="sm"
                                >
                                    {
                                        loading ? "Please wait" : "Confirm"
                                    }
                                </Button>
                            </div>
                        )
                    }

                </div>
            </Row>
        </Container >

    ) : (
        <Container fluid={true}>
            <Row className="mb-3 py-4">
                {userEpisodes.map(_episode => {
                    const _answered = window._.get(user, `answers.${_episode.id}.answered`) || answered.includes(_episode.id)
                    return (
                        <div
                            className={classNames("col-12 mt-3 evaluation-form__question", {
                                answered: _answered
                            })}
                            onClick={() => {
                                if (_answered) {
                                    return false
                                }
                                else {
                                    setEpisode(_episode)
                                }
                            }}
                        >
                            <div className="evaluation-form__question--title">
                                {_episode.evaluation_form_title || _episode.title}
                            </div>
                            {
                                _answered ? (
                                    <div>
                                        <em>
                                            {props.alreadyAnsweredMessage}
                                        </em>
                                    </div>
                                ) : null
                            }
                        </div>
                    )
                })}
                <div
                    className="text-center mt-4 py-0 px-2"
                >
                    <Button
                        onClick={close}
                        size="sm"
                        className="mx-2 btn-email-confirmation"
                    >
                        Close
                    </Button>
                </div>
            </Row>
        </Container>
    )

    const modal = (
        <AdamModal
            content={grid}
            open={modalOpen}
            // fluidHeader={true}
            onClose={() => close()}
        />
    )

    return modal
}

export default EvaluationFormModal