import React, { useEffect, useState, useContext } from "react"
import ModalCloseButton from "../../ui/ModalCloseButton/ModalCloseButton";
import Modal from 'react-modal';
import MainContext from "../../context/MainContext";
import { ThemeContext } from 'styled-components';
import classNames from "classnames";
import "./AdamModal.scss"
import { getContents } from "../../utils/functions";

const AdamModal = (props) => {
    const context = useContext(MainContext)
    const themeContext = useContext(ThemeContext);
    const [modalOpen, setModalOpen] = useState(false)
    // const [originalContent, setOriginalContent] = useState()
    const [currentContent, setCurrentContent] = useState("")

    useEffect(() => {
        setCurrentContent(props.content)
    }, [props.content])


    useEffect(() => {
        setModalOpen(props.open)
    }, [props.open])

    const openModal = (content) => {
        // setOriginalContent(oc)
        setCurrentContent(content)
        setModalOpen(true)
    }

    const onModalRequestClose = () => {
        // setOriginalContent()
        setCurrentContent("")
        props.onClose()
        // context.setLoginModalOpen(false)
    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            width: props.small ? '30%' : '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: "900000",
            padding: 0,
            position: "relative",
            overflow: "visible",
            border: `0px solid ${themeContext.shadowColor || themeContext.secondaryColor}`
            // boxShadow: `0 0.25rem 1rem -0.25rem lighter(${themeContext.shadowColor}, 20%)`
        }
    };
    const contentStyles = {
        maxHeight: "75vh",
        overflow: "auto"
    };

    const logoUrl = window._.get(themeContext, "mediaPopupLogoDesktop.url") || window._.get(themeContext, "mediaHeaderLogoDesktop.url")
    const logo = logoUrl ? (
        <img
            src={logoUrl}
            className="d-inline-block align-center navbar-logo navbar-logo__modal"
            alt={context.siteTitle} />
    ) : context.siteTitle

    let footerContent = getContents(context, `defaultModalFooter`)

    if (props.footerContent) {
        footerContent = props.footerContent
    }

    const modal = (
        <Modal
            isOpen={modalOpen}
            //   onAfterOpen={afterOpenModal}
            onRequestClose={onModalRequestClose}
            style={customStyles}
            contentLabel="Modal"
            className={props.className}
            shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
            shouldCloseOnEsc={props.shouldCloseOnEsc}
        >
            {
                !props.hideHeader ? (
                    <div
                        className={classNames("modal__header",
                            "py-2 px-2",
                            props.headerClass,
                            {
                                fluid: props.fluidHeader
                            },
                            {
                                transparent: props.transparentHeader
                            }
                        )}>
                        {logo}
                    </div>
                ) : null
            }
            {
                !props.hideCloseButton ? (
                    <ModalCloseButton onClick={onModalRequestClose} />
                ) : null
            }
            <div
                className="modal__content"
                style={contentStyles}
            >
                {props.content}
            </div>
            {
                footerContent ? (
                    <div className="modal__footer">
                        <div className="modal__footer--content pt-2"
                            dangerouslySetInnerHTML={{
                                __html: footerContent
                            }} />
                    </div>
                ) : null
            }
        </Modal >

    )

    return modal
}

export default AdamModal