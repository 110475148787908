import { useContext } from 'react';
import { MSD_CTIO } from '../config/registrationFlows';
import MainContext from '../context/MainContext';

function useRegistrtionFlow() {

    const context = useContext(MainContext)

    const isMsdCtio2022 = () => {
        return [MSD_CTIO].includes(window._.get(context, "event.registration_flow"))
    }

    return {
        _isMsdCtio2022: () => isMsdCtio2022(),
    };
}

export default useRegistrtionFlow