import classNames from 'classnames';
import React from 'react';
import wrapSection from '../../hoc/wrapSection';
import PageBody from '../../ui/PageBody/PageBody';
import TheoPlayer from '../TheoPlayer/TheoPlayer';

const DemoPlayer = (props) => {

    const playerSource = {
        sources: props.source,
    };
    return (
        <PageBody>
            <div className={
                classNames("col col-xs-12 col-md-12 col-lg-10 offset-lg-1")
            }>
                <TheoPlayer
                    source={playerSource}
                    onPlay={() => { console.log("The player has started playing.") }}
                    demo={true}
                />
            </div>
        </PageBody>
    )
}

DemoPlayer.className = "play"
export default wrapSection(DemoPlayer)