import React, { useContext, useState, } from 'react';
import { buildQuery } from '../../api/functions';
import { getThemeConfig, getContents, formatRegistrationFooter, capitalize, consoleLog } from '../../utils/functions';
import Form from '../Form/Form';
import MainContext from '../../context/MainContext';
import { ThemeContext } from 'styled-components';
import LightText from '../../ui/LightText/LightText';

const Eurogin2021RegistrationForm = (props) => {
    const [defaultValues, setDefaultValues] = useState({});

    const themeContext = useContext(ThemeContext);
    const context = useContext(MainContext)

    const getEndpoint = () => {
        const query = buildQuery({});
        // const endpoint = `events/${event}/${CONTENTS}?${query}`;
        return [
            "register"
        ].join("/") + `?${query}`;
    }

    const getValue = (name, type) => {
        const value = !window._.isEmpty(defaultValues) ? defaultValues[name] : null;
        switch (type) {
            case "bool":
                return value !== 0;
            default:
                return value || "";
        }
    };

    const onChange = (key, value) => {
        switch (key) {
            case "lang_id":
                context.setLanguage(value)
                break
            case "country_id":
                context.setCountry(value)
                break
            case "is_internal":
                context.setInternal(value)
                break
        }
        setDefaultValues({
            ...defaultValues,
            [key]: value
        });
    }

    const onSubmit = (params) => {
        // context.setUser(params.me)
        // context.setToken(params.token, params.me)
        context.onRegistred(true)
    }

    const countries = window._.get(context, "event.countries")

    const allCountries = window._.get(context, "event.all_countries")

    let newRequiredTos = []
    let fields = 1
        ? [
            {
                elementType: "hidden",
                id: "episodes",
                name: "episodes",
                value: window._.map(props.openEpisodes, (item => item.id))//[window._.map(props.openEpisodes, (item => item.id))],
            },
        ]
        : [];

    if (context.event.has_internal) {
        // fields.push({
        //     elementType: "checkbox",
        //     id: "is_internal",
        //     name: "is_internal",
        //     // required: true,
        //     // invalidText: "Invalid error message.",
        //     label: window._.get(context, "contents.internal"),
        //     value: context.internal
        // })
        fields.push({
            elementType: "select",
            id: "is_internal",
            name: "is_internal",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.internal") || "Internal",
            value: getValue("is_internal") || "0",
            options: [{
                value: 0,//internal_false_value_label
                label: window._.get(context, "contents.internalFalseValueLabel") || "External",
            }, {
                value: 1,//internal_false_value_label
                label: window._.get(context, "contents.internalTrueValueLabel") || "Internal",
            }]
        })
        fields.push({
            elementType: "select",
            id: "country_id",
            name: "country_id",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.country") || "Country",
            placeholder: window._.get(context, "contents.country") || "Country",
            value: getValue("country_id") || context.country,
            // disabled: parseInt(context.internal),
            options: (parseInt(context.internal)) ? allCountries : countries || []
        })
    }

    // if (!parseInt(context.internal)) {
    //     fields.push({
    //         elementType: "select",
    //         id: "country_id",
    //         name: "country_id",
    //         required: true,
    //         // invalidText: "Invalid error message.",
    //         label: window._.get(context, "contents.country") || "Country",
    //         placeholder: window._.get(context, "contents.country") || "Country",
    //         value: getValue("country_id") || context.country,
    //         disabled: parseInt(context.internal),
    //         options: countries || []
    //     })
    // }

    const languagesList = window._.get(context, "event.languages") || []
    const languageField = languagesList.length > 1 ? {
        elementType: "select",
        id: "lang_id",
        name: "lang_id",
        required: true,
        // invalidText: "Invalid error message.",
        label: window._.get(context, "contents.language") || "Language",
        placeholder: window._.get(context, "contents.language") || "Language",
        value: getValue("lang_id") || context.language,
        options: window._.get(context, "event.languages") || []
    } : {
        elementType: "hidden",
        id: "lang_id",
        name: "lang_id",
        value: window._.get(languagesList[0], "value"),
    }

    fields.push(languageField)

    if (context.country || context.internal) {

        fields = fields.concat([
            {
                elementType: "text",
                id: "firstname",
                name: "firstname",
                required: true,
                // invalidText: "Invalid error message.",
                label: window._.get(context, "contents.firstname") || "firstname",
                placeholder: window._.get(context, "contents.firstname") || "firstname",
                value: getValue("firstname"),
            },
            {
                elementType: "text",
                id: "lastname",
                name: "lastname",
                required: true,
                // invalidText: "Invalid error message.",
                label: window._.get(context, "contents.lastname") || "lastname",
                placeholder: window._.get(context, "contents.lastname") || "lastname",
                value: getValue("lastname"),
            },
            {
                elementType: "text",
                id: "specialty",
                name: "specialty",
                // invalidText: "Invalid error message.",
                label: window._.get(context, "contents.specialty") || "Specialty",
                placeholder: window._.get(context, "contents.specialty") || "Specialty",
                value: getValue("specialty"),
            },
            {
                elementType: "text",
                id: "affiliation",
                name: "affiliation",
                required: true,
                // invalidText: "Invalid error message.",
                label: window._.get(context, "contents.affiliation") || "Affiliation (Place of work)",
                placeholder: window._.get(context, "contents.affiliation") || "Affiliation (Place of work)",
                value: getValue("affiliation"),
            },
            {
                elementType: "email",
                id: "email",
                name: "email",
                required: true,
                // invalidText: "Invalid error message.",
                label: window._.get(context, "contents.email") || "email",
                placeholder: window._.get(context, "contents.email") || "Email",
                value: getValue("email"),
            },
            {
                elementType: "text",
                id: "phone",
                name: "phone",
                label: window._.get(context, "contents.phone") || "Telephone No./Work No",
                placeholder: window._.get(context, "contents.phone") || "Telephone No./Work No",
                value: getValue("phone"),
            }
        ])

        if (context.legals) {
            context.legals.forEach(item => {
                consoleLog("context.legals", item.field_name)
                if (!item.field_name) {
                    return false
                }
                fields.push({
                    elementType: "checkbox",
                    id: item.field_name,
                    name: item.field_name,
                    required: item.is_required,
                    // invalidText: "Invalid error message.",
                    // label: `${getContents(context, "iAgree")} ${getContents(context, "cookiePolicyLinkText")}`,
                    labelHtml: item.checkbox_label,
                    popupContent: item.content,
                    value: getValue(item.field_name),
                    section: "footer"
                })

                if (item.is_required) {
                    newRequiredTos.push(item.field_name)
                    // setRequiredTos([
                    //     ...requiredTos,
                    //     item.field_name
                    // ])
                }
            })
        }

    }
    // setRequiredTos(newRequiredTos)

    const logo = (getThemeConfig(context, "registration-form.logo")) ?
        !window._.isEmpty(themeContext.registrationFormLogoDesktop) ? (
            <div className="regstration-form__logo">
                <img
                    src={themeContext.registrationFormLogoDesktop.url}
                    className="d-inline-block align-center"
                    alt={context.siteTitle} />
            </div>
        ) : null : null

    const formHelp = (getThemeConfig(context, "registration-form.footer")) ? (
        <LightText
            className="registration-form__help"
            dangerouslySetInnerHTML={{ __html: formatRegistrationFooter(getContents(context, "registrationFooter"), context) }}>
        </LightText>
    ) : null

    const registrationHeader = getContents(context, "registrationHeader") ? (
        <LightText
            className="registration-form__header"
            dangerouslySetInnerHTML={{ __html: getContents(context, "registrationHeader") }}>
        </LightText>
    ) : null

    //newRequiredTos
    const disableSubmit = (reqs) => {
        let disable = false
        if (reqs) {
            reqs.forEach(item => {
                if (!getValue(item)) {
                    disable = true
                }
            })
        }
        else {
            disable = true
        }

        // context.event.episodes.forEach(item => {
        //     if (item.id === window._.get(context, "episode.id")) {
        //         disable = !item.is_subscriptions_open
        //     }
        // })
        return disable
    }

    return (
        <div className="regstration-form regstration-from__wrapper">
            {logo}
            {/* {registrationHeader} */}
            <Form
                // key={`form-website-contents-${key}`}
                fields={fields}
                endpoint={getEndpoint()}
                method={"post"}
                confirmBeforeSubmit={true}
                onSubmit={(e) => onSubmit(e)}
                onChange={(key, val) => onChange(key, val)}
                submitButtonLabel={getContents(context, "register")}
                disableSubmit={disableSubmit(newRequiredTos)}
                isRegistration={true}
            />
            {/* {formHelp} */}
        </div>
    );
}

export default Eurogin2021RegistrationForm