/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import {
    Switch,
    Route,
    useHistory,
    useLocation
} from "react-router-dom";
import MainContext from '../../context/MainContext';
import Agenda from '../Agenda/Agenda';
import Contacts from '../Contacts/Contacts';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Navbar from "../Navbar/Navbar"
import Speakers from '../Speakers/Speakers';
import { getContents, getLegals, detectEvent, me, login, logout, eventPreview, getAgenda, getSpeakers, forceLogout, getEligibleEpisodes, selectEpisodeWebHook } from "../../api/functions"
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import Registration from '../Registration/Registration';
import PlayerBox from '../PlayerBox/PlayerBox';
import ScrollToTop from '../../utils/ScrollToTop/ScrollToTop';
import { LIVE, PRE_LIVE, PRE_VOD, UNKNOWN, VOD } from '../../config/channelStatus';
import { getThemeConfig, setAuthToken, getAuthToken, initializeGTM, consoleLog, initPusher, lightenDarkenColor } from '../../utils/functions';
import 'bootstrap/dist/css/bootstrap.css';
import "./App.scss"
import DemoPlayer from '../DemoPlayer/DemoPlayer';
import PageBody from '../../ui/PageBody/PageBody';
import EventTitle from '../EventTitle/EventTitle';
import classNames from 'classnames';
import LoginModal from '../LoginModal/LoginModal';
import PlayerSidebar from '../PlayerSidebar/PlayerSidebar';
import Maintenance from '../Maintenance/Maintenance';
import MainContainer from '../../ui/MainContainer/MainContainer';
import ThankYouRegistrationModal from '../ThankYouRegistrationModal/ThankYouRegistrationModal';
import AdamCookieConsent from '../AdamCookieConsent/AdamCookieConsent';
import FormErrorsModal from '../FormErrorsModal/FormErrorsModal';
import QandA from '../QandA/QandA';
import QandAManager from '../QandAManager/QandAManager';
import QandASpeaker from '../QandASpeaker/QandASpeaker';
import DefaultPage from '../DefaultPage/DefaultPage';
import EpisodeDetails from '../EpisodeDetails/EpisodeDetails';
import InitialModal from '../InitialModal/InitialModal';
import VirtualWorldModal from '../VirtualWorldModal/VirtualWorldModal';
import IframeContent from '../IframeContent/IframeContent';
import AlreadyLoggedInModal from '../AlreadyLoggedInModal/AlreadyLoggedInModal';
import { MSD_CTIO } from '../../config/registrationFlows';
import EpisodesListEmptyModal from '../EpisodesListEmptyModal/EpisodesListEmptyModal';
import EvaluationForm from '../EvaluationForm/EvaluationForm';
import { MSD_CTIO_A, MSD_CTIO_B, MSD_CTIO_C } from '../../config/registrationModes';
import EpisodeDetailsWithNestedAgenda from '../EpisodeDetails/EpisodeDetailsWithNestedAgenda';
import WaitingRoomModal from '../WaitingRoomModal/WaitingRoomModal';
import SelectEpisodeOverlay from '../SelectEpisodeOverlay/SelectEpisodeOverlay';
import ErrorPageWithReload from '../ErrorPageWithReload/ErrorPageWithReload';
import PusherManager from '../../utils/PusherManager';

let originalEvent = {}

function Adam() {

    const history = useHistory()
    const location = useLocation();
    let query = new URLSearchParams(useLocation().search);
    const [event, setEvent] = useState()
    const version = process.env.REACT_APP_VERSION
    // const [version, setVersion] = useState(process.env.REACT_APP_VERSION)
    const [fnf, setFnf] = useState()
    const [country, setCountry] = useState()
    const [countryOfWork, setCountryOfWork] = useState()
    const [internal, setInternal] = useState(false)
    const [language, setLanguage] = useState(sessionStorage.getItem("language"))
    const [registrationMode, setRegistrationMode] = useState()
    const [allowChangeContents, setAllowChangeContents] = useState(false)
    const [episode, setEpisode] = useState()
    const [episodeId, setEpisodeId] = useState(sessionStorage.getItem("episodeId"))
    const [channelId, setChannelId] = useState()
    const [status, setStatus] = useState(UNKNOWN)
    const [isPreview, setIsPreview] = useState(false)
    const [isVirtualWorld, setIsVirtualWorld] = useState(false)
    const [forceEF, setForceEF] = useState(false)
    const [user, setUser] = useState(null)
    const [registered, setRegistered] = useState(false)
    const [legals, setLegals] = useState()
    const [maintenance, setMaintenance] = useState()
    const [loginErrors, setLoginErrors] = useState([])
    const [loginWaiting, setLoginWaiting] = useState(false)
    const [theme, setTheme] = useState()
    const [loadingContents, setLoadingContents] = useState(true)
    // const [scrollY, setScrollY] = useState(0)
    const [navHeight, setNavHeight] = useState(0)
    const [headerTitleHeight, setHeaderTitleHeight] = useState(0)
    const [loginModalOpen, setLoginModalOpen] = useState(false)
    const [initialModalOpen, setInitialModalOpen] = useState(false)
    const [initialModalInit, setInitialModalInit] = useState(false)
    const [selectEpisodeModalOpen, setSelectEpisodeModalOpen] = useState(false)
    const [tankyouRegistrationModalOpen, setTankyouRegistrationModalOpen] = useState(false)
    const [faqsModalOpen, setFaqsModalOpen] = useState(false)
    const [loggingOut, setLoggingOut] = useState(false)
    const [channelStatus, setChannelStatus] = useState()
    const [agenda, setAgenda] = useState([])
    const [speakers, setSpeakers] = useState([])
    const [faqs, setFaqs] = useState([])
    const [goToPlayer, setGoToPlayer] = useState(false)
    const [cookieConsent, setCookieConsent] = useState({})
    const [formErrors, setFormErrors] = useState([])
    const [formErrorsModalOpen, setFormErrorsModalOpen] = useState(false)
    // const [evaluationFormAnswered, setEvaluationFormAnswered] = useState(false)
    const [virtualWorldModalOpen, setVirtualWorldModalOpen] = useState(false)
    const [mode, setMode] = useState()
    // const [forceEpisodeId, setForceEpisodeId] = useState()
    const [slidoIframe, setSlidoIframe] = useState()
    const [hideQaAIframe, setHideQaAIframe] = useState()
    const [isLogout, setIsLogout] = useState(false)
    // const [forcedLanguage, setForcedLanguage] = useState(false)
    const [alreadyLoggedError, setAlreadyLoggedError] = useState()
    const [alreadyLoggedInModalOpen, setAlreadyLoggedInModalOpen] = useState(false)
    const [streamChangedModalOpen, setStreamChangedModalOpen] = useState(false)
    const [waitingRoomModalOpen, setWaitingRoomModalOpen] = useState(false)
    const [episodeCategories, setEpisodeCategories] = useState([])
    const [selectedEpisodes, setSelectedEpisodes] = useState([])
    const [selectedStreamId, setSelectedStreamId] = useState()
    const [selectedEpisodeCategories, setSelectedEpisodeCategories] = useState([])
    const [episodesListEmptyModalOpen, setEpisodesListEmptyModalOpen] = useState(false)
    const [allEpisodes, setAllEpisodes] = useState([])
    // const [retrivingUserData, setRetrivingUserData] = useState(false)
    const [openQaAManager, setOpenQaAManager] = useState(false)
    const [pusherConnection, setPusherConnection] = useState()
    const [errorWithReload, setErrorWithReload] = useState()

    const [contents, setContents] = useState()

    useEffect(() => {
        setIsLogout(query.get("logout"))
        if (!event) {
            // console.log("location", query.get("preview"))
            setIsPreview(query.get("preview"))
            setMode(query.get("mode"))

            const detectIsVirtualWorld = query.get("mode") === "vw"
            if (detectIsVirtualWorld) {
                setIsVirtualWorld(true)
            }

            forceEpisode(forcedEpisodeId())
            setForceEF(query.get("forceEF"))
            setSlidoIframe(query.get("slido"))
            setHideQaAIframe(query.get("hideqaa"))

            let loadEventParams = {
                episodeId: forcedEpisodeId() || episodeId,
                force: detectIsVirtualWorld || forcedEpisodeId()
            }

            if (query.get("ln")) {
                const ln = query.get("ln")
                // setForcedLanguage(ln)
                setLanguage(ln)
                loadEventParams = {
                    ...loadEventParams,
                    ln: ln
                }
            }
            loadEvent(loadEventParams)
        }
        // let siteTitle = document.title
        // window.addEventListener('blur', () => {
        //     document.title = 'Come back! :c';
        //     console.log("blur")
        // });
        // window.addEventListener('focus', () => {
        //     document.title = siteTitle;
        //     console.log("focus")
        // });
        // return () => {
        //     window.Pusher.unsubscribe(`presence-${event.id}.none`)
        // }
    }, [])


    useEffect(() => {
        if (event) {
            loadTranslatable(language)
        }
    }, [language])

    useEffect(() => {
        if ((country && language) || allowChangeContents) {
            loadByCountry()
        }
    }, [internal])

    useEffect(() => {
        if (allowChangeContents && !internal) {
            loadByCountry()
        }
    }, [country, countryOfWork])

    useEffect(() => {
        if (allowChangeContents && internal && isCtio2022()) {
            loadByCountry()
        }
    }, [countryOfWork])

    useEffect(() => {
        if (allowChangeContents && (registrationMode !== 102 || selectedEpisodeCategories?.length)) {
            loadByCountry()
        }
    }, [registrationMode, selectedEpisodes])

    useEffect(() => {
        if (allowChangeContents) {
            loadByCountry()
        }
    }, [selectedEpisodeCategories])

    // useEffect(() => {
    //     console.log("allowChangeContents", allowChangeContents)
    //     if (allowChangeContents) {
    //         // loadAgenda()
    //         // loadSpeakers()
    //         loadEvent(episodeId)
    //     }
    // }, [episodeId])

    const hasVisrtualWorld = (user) => {
        return (event.has_virtual_world && !isVirtualWorld && userIsAttendant(user))
    }

    const isVirtualWorldOpen = () => {
        return event.is_virtualworld_active && event.virtual_world_url
    }

    const onPusherConnected = (e) => {
        consoleLog("pusher->connecting", e)
        setPusherConnection(e)
    }

    const onPusherUnavailable = (e) => {
        consoleLog("pusher->unavailable", e)
        setErrorWithReload("The connection is temporarily unavailable.  This means that there is no internet connection or some intermediary is blocking the connection")
    }

    const onPusherFailed = (e) => {
        consoleLog("pusher->failed", e)
        setErrorWithReload("Fatal error with your connection. This implies that WebSockets are not natively available and an HTTP-based transport could not be found")
    }

    useEffect(() => {
        setUserData(user)
        if (userIsAttendant(user)) {
            return function cleanup() {
                if (window.Pusher && event && !userIsAttendant(user)) {
                    window.Pusher.unsubscribe(`presence-${event.id}.none`)
                }
            }
        }
        // else {
        //     window.Pusher.connection.bind('initialized', (e) => console.log("pusher->initialized", e))
        //     window.Pusher.connection.bind('connecting', (e) => console.log("pusher->connecting", e))
        //     window.Pusher.connection.bind('connected', (e) => onPusherConnected(e))
        //     window.Pusher.connection.bind('unavailable', (e) => onPusherUnavailable(e, pusherConnection))
        //     window.Pusher.connection.bind('failed', (e) => console.log("pusher->failed", e))
        //     window.Pusher.connection.bind('disconnected', (e) => console.log("pusher->disconnected", e))
        // }
    }, [user])

    useEffect(() => {
        openInitialModal()
    }, [event])

    useEffect(() => {
        if (episodeId && event) {
            sessionStorage.setItem("episodeId", episodeId)
            const episodesList = userIsAttendant(user) ? event.episodes : user.episodes
            const epi = window._.find(episodesList, item => parseInt(item.id) === parseInt(episodeId))
            changeEpisode(epi)
        }
        if (user) {
            subscribeToEpisodeChannel(episodeId)
        }
    }, [episodeId])

    useEffect(() => {
        if (language) {
            sessionStorage.setItem("language", language)
        }
    }, [language])

    const setUserData = async () => {
        if (user) {
            // consoleLog("forceEpisodeIdUSER", forcedEpisodeId())
            changeLanguage(window._.get(user, "lang_id"))
            const _episodeId = forcedEpisodeId() || episodeId
            if (!window._.isEmpty(window._.get(user, "channels"))) {
                const episodeChannel = window._.get(user, "channels").find(item => parseInt(item.episode_id) === parseInt(_episodeId))
                if (episodeChannel) {
                    setChannelId(episodeChannel.id)
                }
            }
            window.Pusher = initPusher()

            window.Pusher.connection.bind('initialized', (e) => consoleLog("pusher->initialized", e))
            window.Pusher.connection.bind('connecting', (e) => consoleLog("pusher->connecting", e))
            window.Pusher.connection.bind('connected', (e) => onPusherConnected(e))
            window.Pusher.connection.bind('unavailable', (e) => onPusherUnavailable(e))
            window.Pusher.connection.bind('failed', (e) => onPusherFailed(e))
            window.Pusher.connection.bind('disconnected', (e) => consoleLog("pusher->disconnected", e))


            let userEpisodes = Object.values(window._.get(user, "episodes"))

            if (forcedEpisodeId()) {
                userEpisodes = userEpisodes.filter(item => item.id === parseInt(_episodeId))
            }
            else if (!userIsAttendant(user)) {
                userEpisodes = userEpisodes.filter(item => (item.has_future_live_events || item.is_live_open) && item.is_player_active)
            }
            else {
                userEpisodes = userEpisodes.filter(item => item.is_player_active)
            }

            consoleLog("userEpisodes", userEpisodes)
            originalEvent = event

            setEvent(event => {
                return {
                    ...event,
                    episodes: userEpisodes
                }
            })
            // setEpisode(userEpisodes.find(item => item.id === parseInt(_episodeId)))
            setEpisode(userEpisodes[0])
            setEpisodeId(userEpisodes[0].id)

            if (event) {
                consoleLog("presence", `presence-${event.id}.none`)
                const presenceChannel = window.Pusher.subscribe(`presence-${event.id}.none`)
                presenceChannel.bind_global(globalCallback);
                PusherManager.setPusherChannel(presenceChannel)
            }


            if (isLogout) {
                onLogoutClicked()
            }
            // else if (forcedEpisodeId()) {
            //     setGoToPlayer(true)
            // }
            else if (userEpisodes && !goToPlayer) {
                if (hasVisrtualWorld(user)) {
                    if (isVirtualWorldOpen()) {
                        if (event.virtual_world_url && user) {
                            if (
                                (
                                    hasUnansweredQuestions() &&
                                    !isCtio2022()
                                ) ||
                                location.pathname === "/evaluation"
                            ) {
                                // alert("evaluationFormEnabled")
                                // window._.get(user, `answers.${_episode.id}.answered`) || answered.includes(_episode.id)
                            }
                            else {
                                goToVt()
                            }
                            // goToVt()
                        }
                    }
                    else {
                        // window.Pusher.subscribe(`presence-${event.id}.none`)
                        setVirtualWorldModalOpen(true)
                    }
                }
                else {
                    // const userEpisodesWithFutureEvents = userEpisodes.filter(item => item.has_future_events)
                    const userEpisodesWithFutureEvents = userEpisodes.filter(item => item.is_player_active)
                    setEpisodeId()
                    // if (episodeId && userIsAttendant(user)) {
                    //     const wrRws = await subscribeToEpisodeChannel(episodeId)
                    // }
                    //props.episodes?.filter(item => item.has_future_events)
                    setOpenQaAManager(true)
                    if (userEpisodesWithFutureEvents?.length > 1) {
                        setSelectEpisodeModalOpen(true)
                        // console.log("me.episodes", goToPlayer)
                    }
                    else if (userEpisodesWithFutureEvents[0]) {
                        consoleLog("setEpisodeIdCCC", userEpisodesWithFutureEvents[0].id)
                        setEpisodeId(userEpisodesWithFutureEvents[0].id)
                        // setOpenQaAManager(true)
                        if (!waitingRoomIsOpen(userEpisodesWithFutureEvents[0])) {
                            setGoToPlayer(true)
                        }
                        if (episodeId && userIsAttendant(user)) {
                            const wrRws = await subscribeToEpisodeChannel(episodeId)
                        }
                    }
                    else if (isCtio2022()) {
                        setGoToPlayer(true)
                    }
                }
            }
        }
    }

    const isMsdCtio2022 = () => {
        return [MSD_CTIO].includes(parseInt(window._.get(event, "registration_flow")))
    }

    const waitingRoomIsOpen = (testEpisode) => {
        testEpisode = testEpisode || episode
        return testEpisode?.waitingRoom?.is_open
    }

    const subscribeToEpisodeChannel = async ($episodeId) => {
        if (
            !$episodeId
        ) {
            return false
        }
        const episodeResponse = await selectEpisodeWebHook({ episodeId: $episodeId })
        setEpisode(episode => {
            return {
                ...episode,
                waitingRoom: episodeResponse?.waitingRoom
            }
        })
        if (episodeResponse?.waitingRoom?.is_open) {
            setWaitingRoomModalOpen(true)
            setGoToPlayer(false)
        }
        else {
            setWaitingRoomModalOpen(false)
            // if (!hasVisrtualWorld()) { // || isVirtualWorldOpen()
            setGoToPlayer(true)
            // }
        }
    }

    const openInitialModal = () => {
        if (
            // ((
            //     window._.get(event, `theme.config.initial-popup`) && (
            //         contents.initialPopupBox1Title ||
            //         contents.initialPopupBox2Title ||
            //         contents.initialPopupBox3Title ||
            //         contents.initialPopupBox4Title
            //     )
            // ) || theme?.showInitialPopup) &&
            theme?.showInitialPopup &&
            location.pathname !== "/videotest" &&
            location.pathname !== "/evaluation" &&
            !getIam() &&
            !initialModalInit
        ) {
            setInitialModalOpen(true)
            setInitialModalInit(true)
        }
    }

    const forceEpisode = (forceEpisodeId) => {
        consoleLog("forceEpisodeId", forceEpisodeId)
        setEpisodeId(forceEpisodeId)
        // setForceEpisodeId(forceEpisodeId)
    }

    const forcedEpisodeId = () => {
        return query.get("eid")
    }

    const loadFont = (fontFamily = "Poppins") => {

        const font = document.createElement('link');
        font.rel = 'stylesheet'
        font.id = 'dynamic-font'
        document.head.appendChild(font)
        // font.href = "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,300;1,400;1,700&display=swap"
        font.href = `${process.env.REACT_APP_FONT_BASE_URI}/css/${window._.kebabCase(fontFamily)}.css`
    }

    const globalCallback = (eventName, data) => {
        consoleLog(`bind global: The event ${eventName} was triggered with data ${JSON.stringify(data)}`);
        switch (eventName) {
            case "App\\Events\\ForceLogout":
                const remove = window._.get(data, "remove")
                consoleLog("ForceLogout", remove)
                if (window._.indexOf(remove, user.id) >= 0) {
                    onLogoutClicked(true)
                }
                break
            case "App\\Events\\AcceptUser":
                const accept = window._.get(data, "accept")
                if (window._.indexOf(accept, user.subscription.code) >= 0) {
                    acceptUserFromWaitingRoom()
                }
                break
        }
    }

    const getIam = () => {
        return query.get("iam")
    }

    const loadData = async (args) => {
        const { force, ln } = args

        const langId = ln || language
        if (getIam()) {
            let loginParams = {
                email: getIam(),
                auto: true
            }
            if (force) {
                loginParams = {
                    ...loginParams,
                    force: force
                }
            }
            if (langId) {
                loginParams = {
                    ...loginParams,
                    lang_id: langId
                }
            }
            if (query.get("mode") === "vw") {
                loginParams = {
                    ...loginParams,
                    iframe: 1
                }
            }
            if (query.get("eid")) {
                loginParams = {
                    ...loginParams,
                    episode_id: query.get("eid")
                }
            }

            if (!loginParams.iframe) {
                setTimeout(() => {
                    onLoginClicked(loginParams)
                }, 2000)
            }
            else {
                onLoginClicked(loginParams)
            }
        }
        else {
            // setRetrivingUserData(true)
            window.setTimeout(async () => {
                let userData = null
                let loginParams = {
                    lang_id: langId
                }
                try {
                    userData = await loadUserData(loginParams)
                }
                catch (e) {
                    // history.push("/");
                }
                finally {
                    // setRetrivingUserData(false)
                }
                setUser(userData)
            }, 2000)
            // if (userData) {
            //     setGoToPlayer(true)
            // }
        }
    }

    const loadTranslatable = async (newLang) => {
        // let newContens = null
        // let newLegals = null
        consoleLog("loadTranslatable", language, newLang)

        // if (newLang) {
        // if (!newLang) {
        //     newLang = language
        // }
        setLoadingContents(true)
        if (newLang) {


            let params = {
                lang_id: newLang,
            }
            if (isPreview) {
                params = {
                    ...params,
                    preview: isPreview
                }
            }

            const newContens = await loadContents(params)

            const newLegals = await loadLegals({
                ...params,
                country_id: country
            })

            setLegals(newLegals)
            loadAgenda()
            loadSpeakers()
            // }
            setWebsiteContents(newContens)

            if (newContens.episodes) {
                setEvent(event => {
                    return {
                        ...event,
                        episodes: newContens.episodes
                    }
                })

                const newEpisode = newContens.episodes.find(item => item.id === episodeId)

                if (newEpisode) {
                    setEpisode(newEpisode)
                }
            }
        }
        setLoadingContents(false)
        // }
    }

    const setWebsiteContents = (value) => {
        document.title = window._.get(value, "siteTitle") || "Maintenance"
        setContents(value)
    }

    const loadByCountry = async () => {
        // let newContens = null
        // let newLegals = null
        // console.log("loadByCountry", country)
        setLoadingContents(true)
        if (country || internal) {

            let params = {
                // is_internal: internal,
                lang_id: language,
                internal: internal,
                country_id: internal ? countryOfWork : country,
            }
            if (registrationMode) {
                params = {
                    ...params,
                    registration_mode: registrationMode
                }
            }
            // const newLegals = await loadLegals(params)
            // setLegals(newLegals)
            saveLegals(params)

            if (isMsdCtio2022()) {
                loadEligibleEpisodes(params)
            }
            else {
                loadAgenda()
                loadSpeakers()
            }
        }
        setLoadingContents(false)
    }

    const saveLegals = async (params) => {
        const newLegals = await loadLegals(params)
        setLegals(newLegals)
        return newLegals
    }

    const loadAgenda = async () => {

        consoleLog("loadAgenda", event)
        if (event && event.episodes.length > 1) {

            event.episodes.forEach(async epi => {
                const epiId = epi.id
                // consoleLog("epiId", epiId)
                const res = await getAgenda({ country, language, episodeId: epiId, internal })

                if (epiId === episodeId) {
                    setEpisode(episode => {
                        return {
                            ...episode,
                            agenda: res
                        }
                    })
                }
                setEvent(event => {
                    let eventEpisodes = event.episodes
                    // const selectedEpisode = eventEpisodes.find(item => item.id === episodeId)
                    const episodeIndex = window._.findIndex(eventEpisodes, (item) => { return item.id === epiId });
                    eventEpisodes[episodeIndex] = {
                        ...eventEpisodes[episodeIndex],
                        agenda: res
                    }
                    return {
                        ...event,
                        episodes: eventEpisodes
                    }
                })
            })
        }
        else {
            // deprecated
            const res = await getAgenda({ country, language, episodeId, internal })
            setAgenda(res)
        }
    }

    const loadSpeakers = async () => {
        if (event && event.episodes.length > 1) {

            event.episodes.forEach(async epi => {
                const epiId = epi.id
                consoleLog("epiId", epiId)
                const res = await getSpeakers({ country, language, episodeId: epiId, internal })
                if (epiId === episodeId) {
                    setEpisode(episode => {
                        return {
                            ...episode,
                            speakers: res
                        }
                    })
                }
                setEvent(event => {
                    let eventEpisodes = event.episodes
                    // const selectedEpisode = eventEpisodes.find(item => item.id === episodeId)
                    const episodeIndex = window._.findIndex(eventEpisodes, (item) => { return item.id === epiId });
                    consoleLog("epiId pre " + episodeIndex, eventEpisodes[episodeIndex])
                    eventEpisodes[episodeIndex] = {
                        ...eventEpisodes[episodeIndex],
                        speakers: res
                    }
                    consoleLog("epiId post " + episodeIndex, eventEpisodes[episodeIndex])
                    return {
                        ...event,
                        episodes: eventEpisodes
                    }
                })
            })
        }
        else {
            const res = await getSpeakers({ country, language, episodeId, internal })
            setSpeakers(res)
        }
    }

    const loadEligibleEpisodes = async (params) => {
        const res = await getEligibleEpisodes({
            ...params,
            selectedEpisodeCategories,
            selectedEpisodes,
            all: true
        })
        // console.log("find", res.find(item => item.id == episodeId))
        setEvent(event => {
            return {
                ...event,
                episodes: res
            }
        })
        const filteredEpisodes = res.filter(item => item.has_future_events)
        if (
            filteredEpisodes.length === 0 &&
            (
                [
                    MSD_CTIO_A
                ].includes(registrationMode) ||
                (
                    [
                        MSD_CTIO_B
                    ].includes(registrationMode) &&
                    selectedEpisodeCategories?.length > 0
                )
            )
        ) {
            setEpisodesListEmptyModalOpen(true)
        }
        else if ([
            MSD_CTIO_C
        ].includes(registrationMode)) {
            const filteredEpisodesId = filteredEpisodes.map(item => item.id)
            const diff = selectedEpisodes.filter(item => !filteredEpisodesId.includes(item))
            if (diff.length > 0) {
                setEpisodesListEmptyModalOpen(true)
            }
        }
        setEpisode(res.find(item => item.id == episodeId))
    }

    const loadUserData = async (loginParams) => {
        const token = getAuthToken()
        if (token && token !== "null") {
            const res = await me(loginParams)
            // Redirect to player page when user data are loaded 
            // and user has subscribed at least a channel
            // useEffect(() => {
            // console.log("loadUserData", window._.get(context, "user.channels"))
            // if (window._.get(res, "channels")) {
            //     history.push("/play");
            // }
            // }, [context.user])        
            return res
        }
        else {
            return null
        }

    }

    const loadEvent = async (args) => {
        const { episodeId, force, ln } = args
        let res = null
        setLoadingContents(true)

        let langId = language || window.navigator.language
        if (ln) {
            langId = ln
        }
        try {
            if (query.get("preview")) {
                // eventPreview
                let previewParams = {
                    preview: query.get("preview"),
                    lang_id: langId
                }
                if (force) {
                    previewParams = {
                        ...previewParams,
                        force: force
                    }
                }
                if (episodeId) {
                    previewParams = {
                        ...previewParams,
                        episode_id: episodeId
                    }
                }
                res = await eventPreview(previewParams)
            }
            else {
                let params = {
                    domain: window.location.hostname,
                    lang_id: langId
                }
                if (force) {
                    params = {
                        ...params,
                        force: force
                    }
                }
                if (episodeId) {
                    params = {
                        ...params,
                        episode_id: episodeId
                    }
                }
                if (country) {
                    params = {
                        ...params,
                        country_id: country
                    }
                }
                res = await detectEvent(params)
            }
        }
        catch (e) {
            // console.error("clearStatus", window._.get(e, "response.status"))
            if (window._.get(e, "response.status") === 404) {
                setFnf(true)
                return false
            }
        }
        finally {

        }
        if (window._.get(res, "id")) {
            if (!event) {
                window.axios.defaults.baseURL = `${window.axios.defaults.baseURL}${res.id}/`;
            }

            if (res.locale !== language) {
                setLanguage(res.locale)
            }
            // Use 'event_countries' if 'countries' variable is empty
            const countriesList = res.countries || res.event_countries
            // console.log("process.env.REACT_APP_SET_DEFAULT_COUNTRY", window._.get(countriesList[0], "id") !== country && !country && process.env.REACT_APP_SET_DEFAULT_COUNTRY)
            if (
                window._.get(countriesList[0], "id") !== country &&
                !country &&
                parseInt(process.env.REACT_APP_SET_DEFAULT_COUNTRY)
                // &&
                // ![3, 4].includes(parseInt(window._.get(event, "registration_form")))
            ) {
                setCountry(countriesList[0].id)
            }

            // Detect whether or not the website is published. If not shows 404 error page
            if (!window._.get(res, "published")) {
                setFnf(true)
                return false
            }

            // Website contents
            if (window._.get(res, "contents")) {
                const newContents = cleanContents(window._.get(res, "contents"))
                setWebsiteContents(newContents)
            }

            // Cookie consent popup content
            if (window._.get(res, "cookie_consent")) {
                const ccCheckboxLabel = window._.get(res, "cookie_consent.checkbox_label")
                const ccContent = window._.get(res, "cookie_consent.content")
                const ccButtonLabel = window._.get(res, "cookie_consent.cookieconsent_button_label")
                setCookieConsent({
                    ccCheckboxLabel,
                    ccContent,
                    ccButtonLabel
                })
            }
            const ctio2022 = window._.get(res, `theme.config.event`) === "ctio-2022"

            if (window._.get(res, "agenda")) {
                setAgenda(window._.get(res, "agenda"))
            }

            if (window._.get(res, "speakers")) {
                setSpeakers(window._.get(res, "speakers"))
            }

            if (window._.get(res, "faqs")) {
                setFaqs(window._.get(res, "faqs"))
            }

            setMaintenance(window._.get(res, "maintenance"))
            if (window._.get(res, "maintenance")) {
                setTheme(loadTheme(window._.get(res, "styles"), window._.get(res, "media")))
                setEvent(res)
                return false
            }
            else if (!user) (
                loadData({ force, ln })
            )
            if (window._.get(res, "tagmanager")) {
                initializeGTM(window._.get(res, "tagmanager"))
            }

            if (window._.get(res, "legals")) {
                setLegals(window._.get(res, "legals"))
            }
            if (!forcedEpisodeId()) {
                // userEpisodes.filter(item => item.is_player_active)
                let episode_categories = window._.get(res, "episodecategories") || []
                let eventEpisodes = window._.get(res, "episodes") || []
                eventEpisodes = ctio2022 ? eventEpisodes : eventEpisodes.filter(item => item.is_player_active)
                const openEpisodes = eventEpisodes.filter(item => {
                    return (
                        // item.is_player_active &&
                        item.is_subscriptions_open
                    )
                })
                setEpisodeCategories(episode_categories)
                if (eventEpisodes.length) {
                    // eventEpisodes.forEach(item => {
                    //     item.episode_categories.forEach(ec => {
                    //         if (ec.id) {
                    //             episode_categories.push(ec)
                    //         }
                    //     })
                    // })
                    const firstAvailableEpisode = ctio2022 ? openEpisodes.shift() : eventEpisodes.shift()

                    if (!forcedEpisodeId() && firstAvailableEpisode && firstAvailableEpisode.id !== episode) {
                        changeEpisode(firstAvailableEpisode)
                    }
                    setAllEpisodes(window._.get(res, "episodes"))
                }
                else {
                    const defaultEpisode = window._.get(res, "default_episode") || []
                    if (!window._.isEmpty(defaultEpisode) && !episode) {
                        if (window._.get(defaultEpisode, "id") !== episode) {
                            changeEpisode(defaultEpisode)
                        }
                    }
                }
            }
            const newTheme = loadTheme(window._.get(res, "styles"), window._.get(res, "media"))

            setTheme(newTheme)
            setLoadingContents(false)
            setEvent(res)
            loadFont(newTheme.defaultFontFamily)
        }
        return res
    }

    const loadTheme = (resStyles, resMedia) => {
        let newTheme = {}
        Object.keys(resStyles).forEach(k => {
            newTheme = {
                ...newTheme,
                [window._.camelCase(k)]: resStyles[k]
            }
        })

        Object.keys(resMedia).forEach(k => {
            newTheme = {
                ...newTheme,
                [window._.camelCase(`media_${k}`)]: resMedia[k]
            }
        })

        if (window._.get(newTheme, "mediaFavicon.url")) {
            const favicon = document.getElementById("favicon");
            favicon.href = window._.get(newTheme, "mediaFavicon.url")
        }
        return newTheme
    }

    const loadContents = async (params) => {

        // consoleLog("params", params)
        // if (!event) {
        //     return {}
        // }
        // let newContens = {
        //     ...contents
        // }
        params = {
            lang_id: language,
            ...params
        }
        const resContents = await getContents(params)
        return cleanContents(resContents)
        // Object.keys(resContents).forEach(k => {
        //     newContens = {
        //         ...newContens,
        //         [window._.camelCase(`${k}`)]: resContents[k]
        //     }
        // })
        // return newContens
    }

    const cleanContents = (resContents) => {
        let newContens = {
            ...contents
        }
        Object.keys(resContents).forEach(k => {
            newContens = {
                ...newContens,
                [window._.camelCase(`${k}`)]: resContents[k]
            }
        })
        return newContens
    }

    const loadLegals = async (params) => {

        if (internal) {
            params = {
                ...params,
                internal: internal
            }
        }
        const resLegals = await getLegals({
            country: country,
            language: language,
            ...params
        })
        return resLegals
    }

    const setToken = async (token, userData) => {
        setAuthToken(token)
        if (token) {
            window.axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            // const userData = await loadUserData()
            // setUser(userData)
            if (window._.get(userData, "channels")) {
                // history.push(`/e/${episode}/play`);
            }
        }
        else {
            window.axios.defaults.headers.common["Authorization"] = "";
            setUser(null)
        }
    }

    const onRegistred = () => {
        setRegistered(true)
        setTankyouRegistrationModalOpen(true)
    }

    const onLoginClicked = async (args) => {
        const { ln, lang_id } = args
        const langId = ln || language
        if (langId && !lang_id) {
            args = {
                ...args,
                lang_id: langId
            }
        }
        setLoginWaiting(true)
        setAlreadyLoggedError()
        setLoginErrors([])
        let res = null

        try {
            res = await login(args)
            setUser(res.me)
            setToken(res.token, res.me)
            // console.log("me.episodes", window._.get(res, "me.episodes"))
            // if (window._.get(res, "me.episodes").length > 1) {
            //     setSelectEpisodeModalOpen(true)
            //     console.log("me.episodes", goToPlayer)
            // }
            // else {
            //     setGoToPlayer(true)
            // }
            setLoginModalOpen(false)
        }
        catch (e) {
            // console.log("setLoginErrors", window._.get(e, "response.data"))
            const newErrors = window._.get(e, "response.data.errors") ? window._.get(e, "response.data.errors") : [window._.get(e, "response.data.message")]
            // consoleLog("errorAAAA", window._.get(e, "response.status"))
            setLoginErrors(newErrors)
            // setAlreadyLoggedError(parseInt(e.response.status) === 409)
            setAlreadyLoggedError(window._.get(e, "response.data.concurrent"))//concurrent
            if (!loginModalOpen) {
                setAlreadyLoggedInModalOpen(true)
            }
            // Auto: iam query param is set
            // Force: force query param is set
            if (args.auto && !args.force) {
                setFormErrors({
                    alreadyLogged: newErrors
                })
                // setFormErrorsModalOpen(true)
                setAlreadyLoggedInModalOpen(true)
                // loginModalOpen
            }
        }
        setLoginWaiting(false)
        return res
    }

    const onLogoutClicked = async (forced) => {
        setLoggingOut(true)
        try {
            if (!forced) {
                const res = await logout()
            }
            setIsLogout(false)
        }
        catch (e) {
        }
        finally {
            setToken(null)
            setUser(null)
            setGoToPlayer(false)
            if (window._.get(event, `theme.config.header.hidden`)) {
                setNavHeight(0)
            }
            let logoutUrl = "/"
            if (query.get("preview")) {
                logoutUrl += `?preview=${query.get("preview")}`
            }
            if (inIframe()) {
                setEvent()
            }
            else {
                history.push(logoutUrl);
            }
            // console.log("originalEvent", originalEvent)
            setEvent(originalEvent)
            if (episodeId) {
                setEpisode(originalEvent.episodes.find(item => item.id === parseInt(episodeId)))
            }
            else {
                setEpisode(originalEvent.episodes[0])
            }
            // setEpisode(originalEvent.episodes.find(item => item.id === parseInt(episodeId)))

            setLoggingOut(false)
        }
    }

    const onForceLogoutClicked = async () => {
        setLoggingOut(true)
        try {
            const res = await forceLogout({
                concurrent: alreadyLoggedError
            })

            if (res.status) {
                setAlreadyLoggedError()
                setAlreadyLoggedInModalOpen()
                setLoginErrors([])
                if (getIam()) {
                    const args = {
                        email: getIam(),
                        auto: true
                    }
                    onLoginClicked(args)
                }

            }
        }
        catch (e) {
        }
        finally {
            setLoggingOut(false)
        }
    }

    const changeLanguage = (value) => {
        if (value && value !== language) {
            setLanguage(value)
            if (country) {
                setAllowChangeContents(true)
            }
        }
    }

    const changeCountry = (value) => {
        if (value && value !== country) {
            setCountry(value)
            if (language) {
                setAllowChangeContents(true)
            }
        }
    }

    const changeCountryOfWork = (value) => {
        if (value && value !== countryOfWork) {
            setCountryOfWork(value)
        }
    }

    const changeRegistrationMode = (value) => {
        if (value && value !== registrationMode) {
            setRegistrationMode(value)
            if (language && country) {
                setAllowChangeContents(true)
            }
        }
    }

    const changeIntrernal = (value) => {
        setInternal(value)
        setAllowChangeContents(true)
    }

    const changeEpisode = (value) => {
        const id = window._.get(value, "id")
        if (value && (episodeId !== id || window._.get(episode, "id") !== id)) {
            setEpisode(value)
            setEpisodeId(window._.get(value, "id"))
        }
    }

    const onEpisodeChange = (e, direct) => {
        const value = direct ? e : e.target.value
        setAllowChangeContents(true)
        setEpisodeId(value)
        // console.log("onEpisodeChange", window._.get(event, `theme.config.event`))
        if (
            value !== episodeId
        ) {
            if (!isCtio2022()) {
                // loadEvent({ episodeId: value })
            }
        }
    }

    const onEpisodeSelected = (e) => {
        onEpisodeChange(e, true)
        setSelectEpisodeModalOpen(false)
        if (!waitingRoomIsOpen()) {
            setGoToPlayer(true)
        }
    }

    const onLanguageChange = (value) => {
        changeLanguage(value)
    }

    const onCountryChange = (value) => {
        changeCountry(value)
    }

    const onCountryOfWorkChange = (value) => {
        changeCountryOfWork(value)
    }

    const onInternalChange = (value) => {
        value = (value === "int" || value === 1 || value === true || value === "1")
        value = value ? 1 : 0
        changeIntrernal(value)
    }

    const onRegistrationModeChange = (value) => {
        changeRegistrationMode(parseInt(value))
    }

    const onFaqButtonClicked = () => {
        setFaqsModalOpen(true)
    }

    const openFormErrorsModal = (newErrors) => {
        setFormErrors(newErrors)
        setFormErrorsModalOpen(true)
    }

    const closeFormErrorsModal = () => {
        setFormErrors([])
        setFormErrorsModalOpen(false)
    }

    const goToVt = () => {
        let vwUrl = event.virtual_world_url + `?iam=${user.email}`
        if (isPreview) {
            vwUrl += `&preview=${isPreview}`
        }
        window.location.assign(vwUrl)
    }

    const onEvaluationFormModalClose = () => {
        if (
            event.virtual_world_url &&
            event.is_virtualworld_active &&
            user
        ) {
            goToVt()
        }
    }

    const acceptUserFromWaitingRoom = () => {
        setGoToPlayer(true)
        setWaitingRoomModalOpen(false)
    }

    // const openEvaluartionFormModal = () => {
    //     setEvaluartionFormModalOpen(true)
    // }

    // const closeEvaluartionFormModal = () => {
    //     setEvaluartionFormModalOpen(false)
    // }

    const questions = window._.get(episode, `evaluation_questions`)
    const isEfEnabled = window._.get(episode, `is_evaluation_open`)

    // const userEpisodes = window._.get(user, `episodes`)
    const userQuestions = window._.get(user, `questions`)
    let userHasOpenEvaluationForms = false

    if (userQuestions) {
        userQuestions.forEach(item => {
            if (
                item.evaluation_questions &&
                item.is_evaluation_open
            ) {
                userHasOpenEvaluationForms = true
            }
        })
    }
    if (userQuestions) {
        userQuestions.forEach(itemId => {
            const epi = event.episodes?.find(item => item.id === itemId)
            if (
                epi &&
                epi.evaluation_questions &&
                epi.is_evaluation_open
            ) {
                userHasOpenEvaluationForms = true
            }
        })
    }
    // consoleLog("userEpisodes", userEpisodes, userHasOpenEvaluationForms)

    const userIsAttendant = (_user) => {
        // console.log("userIsAttendant", _user)
        return (
            !window._.get(_user, "is_chairman") &&
            !window._.get(_user, "is_moderator")
        )
    }

    const evaluationFormEnabled = () => {
        return (
            forceEF &&
            window._.get(user, "is_staff")
        ) ||
            (
                user &&
                // episodeId &&
                // questions &&
                // isEfEnabled &&
                userHasOpenEvaluationForms &&
                // !window._.isEmpty(questions) &&
                userIsAttendant(user)
                // !window._.get(user, "is_chairman") &&
                // !window._.get(user, "is_moderator")
            )
    }

    const hasUnansweredQuestions = () => {
        let _hasUnansweredQuestions = false
        consoleLog("userQuestions", userQuestions)
        if (userQuestions) {
            userQuestions.filter(item => item.is_evaluation_open).forEach(item => {
                consoleLog("userQuestions " + item.id, window._.get(user, `answers.${item.id}.answered`))
                if (
                    window._.get(user, `answers.${item.id}.answered`) !== true
                ) {
                    _hasUnansweredQuestions = true
                }
            })
        }
        consoleLog("userEpisodes AAA", _hasUnansweredQuestions && evaluationFormEnabled())
        return _hasUnansweredQuestions && evaluationFormEnabled()
    }

    const inIframe = () => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    const allowIframe = () => {
        return inIframe() || process.env.REACT_APP_ALLOW_IFRAME_IN_TOP_WINDOW
    }

    const isCtio2022 = () => {
        return window._.get(event, `theme.config.event`) === "ctio-2022"
    }

    const isAppView = window._.get(event, `theme.config.view`) === "app"
    const is2ColsView = window._.get(event, `theme.config.view`) === "2cols"
    const rfOnLeft = window._.get(event, `theme.config.registration-form.position`) === "left"
    const hideRegistrationFormTitle = window._.get(event, `theme.config.registration-form.hide-title`)
    const hiddenHeader = window._.get(event, `theme.config.header.hidden`)
    const hideDropDown = window._.get(event, `theme.config.episodes.hideDropDown`)
    const showAllEpisodes = window._.get(event, `theme.config.episodes.showAll`)
    const narrow = window._.get(event, `theme.config.width`) === "narrow"
    // title
    const titleFontSize = window._.get(event, `theme.config.title.size`)
    const titleLineHeight = window._.get(event, `theme.config.title.line-height`)
    const titleFontWeight = window._.get(event, `theme.config.title.weight`)
    // sub-title
    const subTitleFontSize = window._.get(event, `theme.config.subtitle.size`)
    const subTitleLineHeight = window._.get(event, `theme.config.subtitle.line-height`)
    const subTitleFontWeight = window._.get(event, `theme.config.subtitle.weight`)
    // sub-sub-title
    const subSubTitleFontSize = window._.get(event, `theme.config.subsubtitle.size`)
    const subSubTitleLineHeight = window._.get(event, `theme.config.subsubtitle.line-height`)
    const subSubTitleFontWeight = window._.get(event, `theme.config.subsubtitle.weight`)

    const registrationFormHasLoginForm = window._.get(event, `theme.config.registration-form.login-form`)
    const contactsAlign = window._.get(event, `theme.config.contacts.text-align`)
    const hideContactsTitle = window._.get(event, `theme.config.contacts.hide-title`)
    const nestedAgenda = window._.get(event, `theme.config.agenda.nested`)
    const removeBorderFromEpisodeTitle = window._.get(event, `theme.config.episodes.title.remove-border`)
    const episodeTitleFontSize = window._.get(event, `theme.config.episodes.title.font-size`)
    const episodeTitleLineHeight = window._.get(event, `theme.config.episodes.title.line-height`)

    const mainContext = {
        user: user,
        event: event,
        version: version,
        country: country,
        internal: internal,
        language: language,
        episode: episode,
        episodeId: episodeId,
        contents: contents,
        legals: legals,
        channelId: channelId,
        status: status,
        loadingContents: loadingContents,
        isAppView: isAppView,
        is2ColsView: is2ColsView,
        navHeight: navHeight,
        headerTitleHeight: headerTitleHeight,
        loginModalOpen: loginModalOpen,
        loginWaiting: loginWaiting,
        faqsModalOpen: faqsModalOpen,
        channelStatus: channelStatus,
        loggingOut: loggingOut,
        loginErrors: loginErrors,
        agenda: agenda,
        faqs: faqs,
        speakers: speakers,
        registered: registered,
        isPreview: isPreview,
        alreadyLoggedError: alreadyLoggedError,
        episodeCategories: episodeCategories,
        registrationMode: registrationMode,
        allEpisodes: allEpisodes,
        selectedStreamId: selectedStreamId,
        streamChangedModalOpen: streamChangedModalOpen,
        // formErrors, formErrors,
        // formErrorsModalOpen, formErrorsModalOpen,
        setLanguage: (value) => onLanguageChange(value),
        setCountry: (value) => onCountryChange(value),
        setCountryOfWork: (value) => onCountryOfWorkChange(value),
        setInternal: (value) => onInternalChange(value),
        setRegistrationMode: (value) => onRegistrationModeChange(value),
        setToken: (token, userData) => setToken(token, userData),
        setUser: (value) => setUser(value),
        onRegistred: (value) => onRegistred(value),
        setNavHeight: (value) => setNavHeight(value),
        setHeaderTitleHeight: (value) => setHeaderTitleHeight(value),
        setLoginModalOpen: (value) => setLoginModalOpen(value),
        setSelectEpisodeModalOpen: (value) => setSelectEpisodeModalOpen(value),
        setChannelStatus: (value) => setChannelStatus(value),
        setTankyouRegistrationModalOpen: (value) => setTankyouRegistrationModalOpen(value),
        openFormErrorsModal: (value) => openFormErrorsModal(value),
        closeFormErrorsModal: () => closeFormErrorsModal(),
        closeAlreadyLoggedInModal: () => setAlreadyLoggedInModalOpen(false),
        openStreamChangedModal: () => setStreamChangedModalOpen(true),
        closeStreamChangedModal: () => setStreamChangedModalOpen(false),
        closeWaitingRoomModal: () => setWaitingRoomModalOpen(false),
        setSelectedEpisodes: (value) => setSelectedEpisodes(value),
        setSelectedStreamId: (value) => setSelectedStreamId(value),
        setSelectedEpisodeCategories: (value) => setSelectedEpisodeCategories(value)
    }

    const buttonTheme = getThemeConfig(mainContext, "buttons")
    const headerTheme = getThemeConfig(mainContext, "header")
    const speakersTheme = getThemeConfig(mainContext, "speakers")
    const sectionsTheme = getThemeConfig(mainContext, "sections")

    const headerContentAlign = window._.get(headerTheme, "content-align")

    const isGridLayout = (!window._.isEmpty(speakersTheme)) ? speakersTheme.theme === "grid" : false

    const sectionTitleUnderline = window._.get(sectionsTheme, "title.underline")
    const thumbsRounded = (!window._.isEmpty(speakersTheme)) ? speakersTheme['thumbs-shape'] === "round" : false
    const textCentered = (!window._.isEmpty(speakersTheme)) ? speakersTheme["text-align"] === "center" : false
    const thumbBordered = (!window._.isEmpty(speakersTheme)) ? speakersTheme["thumbs-border"] : false
    const speakerPictureWidth = (!window._.isEmpty(speakersTheme)) ? speakersTheme["picture-width"] : "100%"
    const speakerBoxShadow = (!window._.isEmpty(speakersTheme)) ? speakersTheme["box-shadow"] : false
    const defaultBorderRadius = getThemeConfig(mainContext, "default-border-radius") || "3px"
    const fullPageHeader = getThemeConfig(mainContext, "header.fullpage") || false
    const mainCols = getThemeConfig(mainContext, "main.cols") || {}
    const defaultTransition = "all 0.5s ease-in-out"

    const footerAlign = window._.get(event, `theme.config.footer.text-align`)
    // let mainContent = useMemo(() => {
    //     let mainContent_
    //     if (user) {

    //         if (window._.get(user, "is_chairman")) {
    //             mainContent_ = (
    //                 <PageBody>
    //                     <QandASpeaker
    //                         eventId={event.id}
    //                     />
    //                 </PageBody>
    //             )
    //         }
    //         else if (window._.get(user, "is_moderator")) {
    //             mainContent_ = (
    //                 <PageBody>
    //                     <QandAManager
    //                         eventId={event.id}
    //                     />
    //                 </PageBody>
    //             )
    //         }
    //         else {
    //             mainContent_ = (goToPlayer) ? (
    //                 <PageBody>
    //                     <div className={
    //                         classNames("col")
    //                     }>
    //                         <PlayerBox
    //                             eventId={event.id}
    //                             episodeId={episodeId}
    //                         />
    //                         {
    //                             [LIVE, PRE_LIVE, VOD].includes(channelStatus) ? (
    //                                 <QandA />
    //                             ) : null
    //                         }
    //                     </div>
    //                     <PlayerSidebar
    //                         language={language}
    //                         country={country}
    //                         episode={episode}
    //                         episodeId={episodeId}
    //                         internal={internal}
    //                         contents={contents}
    //                     />
    //                 </PageBody>
    //             ) : (
    //                 <>
    //                     {
    //                         fullPageHeader ? (
    //                             <Header
    //                                 theme={headerTheme}
    //                                 loginWaiting={loginWaiting}
    //                                 loginErrors={loginErrors}
    //                                 intro={contents.websiteIntro}
    //                                 onLoginClicked={(args) => onLoginClicked(args)}
    //                                 emailBoxLogin={contents.emailBoxLogin}
    //                             />
    //                         ) : null
    //                     }
    //                 </>
    //             )
    //         }
    //     }
    //     return mainContent_
    // }, [
    //     user,
    //     language,
    //     country,
    //     episode,
    //     episodeId,
    //     internal,
    //     contents,
    //     goToPlayer,
    // ])

    // MAIN
    let defaultTextColor = null
    let primaryColor = null
    let primaryTextColor = null
    let tertiaryTextColor = null
    let defaultFontFamily = null;

    let buttonsShape = null;
    let buttonsFontWeight = null;

    // Registration form
    let registrationFormButtonBackgroundColor = null
    let registrationFormButtonBorderColor = null
    let registrationFormButtonTextColor = null
    let registrationFormFooterTextSize = null

    // Login form
    let loginFormButtonBackgroundColor = null
    let loginFormButtonBorderColor = null
    let loginFormButtonTextColor = null

    // Login header
    let loginHeaderButtonBackgroundColor = null
    let loginHeaderButtonBorderColor = null
    let loginHeaderButtonTextColor = null

    // FAQ
    let faqHeaderButtonBackgroundColor = null
    let faqHeaderButtonBorderColor = null
    let faqHeaderButtonTextColor = null

    // Email confirmation modal
    let emailConfirmationButtonBackgroundColor = null
    let emailConfirmationButtonBorderColor = null
    let emailConfirmationButtonTextColor = null

    // Cookie consent
    let cookieConsentButtonBackgroundColor = null
    let cookieConsentButtonBorderColor = null
    let cookieConsentButtonTextColor = null

    // Agenda
    let agendaBackgroundColor = null
    let agendaTimeColor = null
    let agendaTitleColor = null
    let agendaSpeakersColor = null
    let agendaAccordionBodyBgColor = null
    let agendaRowBorderColor = null
    let agendaRowSpeakerCardColor = null
    let agendaTitleFontSize = null
    let agendaTitleLineHeight = null

    // Speakers
    let speakersCardBackgroundColor = null

    // Player page
    let playerPageBodyBackgroundImageDesktop = null
    let playerPageBodyBackgroundImageMobile = null
    let playerPageAgendaBackgroundColor = null
    let playerPageAgendaTimeColor = null
    let playerPageAgendaTitleColor = null
    let playerPageAgendaSpeakersColor = null
    let playerPageAgendaNestedBorderLeftColor = null
    let playerPageSpeakersNameColor = null
    let playerPageSpeakersDescriptionColor = null
    let playerPageSpeakersCardBackgroundColor = null
    let playerPageSpeakersRoleColor = null
    let playerPageSectionTitleColor = null
    let playerPageSpeakersCardExplodeButtonColor = null

    let speakersNameColor = null
    let modalSectionTitleColor = null
    let speakersDescriptionColor = null
    let speakersRoleColor = null

    let playerPageInfoTabTextColor = null

    let sectionTitleColor = null
    let colRightBackgroundColor = null

    let speakerThumbBorderColor = null

    let episodeTitleTextColor = null
    let agendaTitleTextColor = null
    let agendaTitleBackgroundColor = null
    let agendaTitleBackgroundColorExplandable = null

    let nestedagendaVodDetailsTextColor = null
    let nestedagendaVodDetailsBorderColor = null

    let modalHeaderColor = null
    let loginModalLeftSideBackgorundColor = null

    let contactsTextColor = null
    let contactsFontSizeDesktop = null
    let contactsFontSizeMobile = null

    let footerTextColor = null
    let footerFontSizeDesktop = null
    let footerFontSizeMobile = null

    let tosTextColor = null
    let tosLinkColor = null
    let episodeDatesFontSize = null

    let playerBorderRadius = null;
    let playerShadowColor = null;

    let navbarRegistrationDesktopLogoHeight = null;
    let navbarRegistrationMobileLogoHeight = null;
    let navbarPlayerDesktopLogoHeight = null;
    let navbarPlayerMobileLogoHeight = null;
    let navbarModalDesktopLogoHeight = null;
    let navbarModalMobileLogoHeight = null;

    let groupAgendaAndSpeakers = false;
    let hideAgendaDatetime = false;
    let hideEpisodeDatetime = false;
    let episodeAccordionTitleOrder = false;
    let episodeAccordionTitleIcon = 0;

    if (theme) {
        primaryColor = theme.primaryColor
        primaryTextColor = theme.primaryTextColor || theme.tertiaryColor
        defaultTextColor = theme.defaultTextColor || theme.tertiaryColor
        tertiaryTextColor = theme.tertiaryTextColor || primaryColor
        defaultFontFamily = theme.defaultFontFamily || "Poppins"

        registrationFormButtonBackgroundColor = theme.registrationFormButtonBackgroundColor || primaryColor
        registrationFormButtonBorderColor = theme.registrationFormButtonBorderColor || theme.registrationFormButtonBackgroundColor || primaryColor
        registrationFormButtonTextColor = theme.registrationFormButtonTextColor || theme.primaryTextColor
        registrationFormFooterTextSize = theme.registrationFormFooterTextSize

        loginFormButtonBackgroundColor = theme.loginFormButtonBackgroundColor || primaryColor
        loginFormButtonBorderColor = theme.loginFormButtonBorderColor || loginFormButtonBackgroundColor
        loginFormButtonTextColor = theme.loginFormButtonTextColor || theme.primaryTextColor

        loginHeaderButtonBackgroundColor = theme.loginHeaderButtonBackgroundColor || primaryColor
        loginHeaderButtonBorderColor = theme.loginHeaderButtonBorderColor || loginHeaderButtonBackgroundColor
        loginHeaderButtonTextColor = theme.loginHeaderButtonTextColor || theme.primaryTextColor

        faqHeaderButtonBackgroundColor = theme.faqHeaderButtonBackgroundColor || theme.tertiaryColor
        faqHeaderButtonBorderColor = theme.faqHeaderButtonBorderColor || theme.tertiaryColor
        faqHeaderButtonTextColor = theme.faqHeaderButtonTextColor || theme.secondaryBackgroundColor

        emailConfirmationButtonBackgroundColor = theme.emailConfirmationButtonBackgroundColor || primaryColor
        emailConfirmationButtonBorderColor = theme.emailConfirmationButtonBorderColor || emailConfirmationButtonBackgroundColor
        emailConfirmationButtonTextColor = theme.emailConfirmationButtonTextColor || theme.primaryTextColor

        cookieConsentButtonBackgroundColor = theme.cookieConsentButtonBackgroundColor || theme.secondaryColor
        cookieConsentButtonBorderColor = theme.cookieConsentButtonBorderColor || cookieConsentButtonBackgroundColor
        cookieConsentButtonTextColor = theme.cookieConsentButtonTextColor || primaryColor

        agendaBackgroundColor = theme.agendaBackgroundColor || "transparent"
        agendaTimeColor = theme.agendaTimeColor || theme.primaryTextColor
        agendaTitleColor = theme.agendaTitleColor || primaryColor
        agendaSpeakersColor = theme.agendaSpeakersColor || theme.primaryTextColor
        agendaRowSpeakerCardColor = theme.agendaRowSpeakerCardColor

        agendaTitleFontSize = theme.agendaTitleFontSize ? parseFloat(theme.agendaTitleFontSize) : 1
        agendaTitleLineHeight = agendaTitleFontSize * 1.4;

        groupAgendaAndSpeakers = theme.groupAgendaAndSpeakers
        hideAgendaDatetime = theme.hideAgendaDatetime
        hideEpisodeDatetime = theme.hideEpisodeDatetime
        episodeAccordionTitleOrder = parseInt(theme.episodeAccordionTitleOrder)
        episodeAccordionTitleIcon = parseInt(theme.episodeAccordionTitleIcon)

        playerPageAgendaBackgroundColor = theme.playerPageAgendaBackgroundColor || (groupAgendaAndSpeakers ? null : agendaBackgroundColor)
        playerPageAgendaTimeColor = theme.playerPageAgendaTimeColor || agendaTimeColor
        playerPageAgendaTitleColor = theme.playerPageAgendaTitleColor || agendaTitleColor
        playerPageAgendaSpeakersColor = theme.playerPageAgendaSpeakersColor || agendaSpeakersColor
        playerPageAgendaNestedBorderLeftColor = theme.playerPageAgendaNestedBorderLeftColor || "transparent"

        speakersCardBackgroundColor = theme.speakersCardBackgroundColor || theme.defaultBackgroundColor

        playerPageSpeakersCardBackgroundColor = theme.playerPageSpeakersNameColor || theme.primaryTextColor
        playerPageSpeakersNameColor = theme.playerPageSpeakersNameColor || theme.primaryTextColor
        playerPageSpeakersDescriptionColor = theme.playerPageSpeakersDescriptionColor || theme.primaryTextColor
        playerPageSpeakersRoleColor = theme.playerPageSpeakersRoleColor || theme.primaryTextColor
        playerPageSpeakersCardExplodeButtonColor = theme.playerPageSpeakersCardExplodeButtonColor || primaryColor

        modalSectionTitleColor = theme.modalSectionTitleColor || theme.primaryTextColor
        speakersNameColor = theme.speakersNameColor || theme.primaryTextColor
        speakersDescriptionColor = theme.speakersDescriptionColor || theme.primaryTextColor
        speakersRoleColor = theme.speakersRoleColor || primaryColor

        sectionTitleColor = primaryTextColor || theme.tertiaryColor
        playerPageSectionTitleColor = theme.playerPageSectionTitleColor || sectionTitleColor
        colRightBackgroundColor = theme.colRightBackgroundColor || primaryColor

        playerPageInfoTabTextColor = theme.playerPageInfoTabTextColor || primaryTextColor

        speakerThumbBorderColor = theme.speakerPictureBorderColor || primaryColor

        episodeTitleTextColor = theme.episodeTitleTextColor || primaryTextColor
        agendaTitleTextColor = theme.agendaTitleTextColor || theme.tertiaryColor
        agendaTitleBackgroundColor = theme.agendaTitleBgColor || primaryColor
        agendaTitleBackgroundColorExplandable = theme.agendaTitleExpandableBgColor || agendaTitleBackgroundColor
        agendaAccordionBodyBgColor = theme.agendaAccordionBodyBgColor;
        agendaRowBorderColor = theme.agendaRowBorderColor;

        modalHeaderColor = theme.modalHeaderColor || primaryColor
        loginModalLeftSideBackgorundColor = theme.loginModalLeftSideBackgorundColor || primaryColor

        contactsTextColor = theme.contactsTextColor
        contactsFontSizeDesktop = theme.contactsFontSizeDesktop
        contactsFontSizeMobile = theme.contactsFontSizeMobile

        footerTextColor = theme.footerTextColor
        footerFontSizeDesktop = theme.footerFontSizeDesktop
        footerFontSizeMobile = theme.footerFontSizeMobile

        tosTextColor = theme.tosTextColor
        tosLinkColor = theme.tosLinkColor || primaryColor
        episodeDatesFontSize = theme.episodeDatesFontSize

        playerBorderRadius = theme.playerBorderRadius ? `${parseInt(theme.playerBorderRadius)}px` : null;
        playerShadowColor = theme.playerShadowColor;
        buttonsShape = theme.buttonsShape || buttonTheme?.shape
        buttonsFontWeight = theme.buttonsFontWeight || "normal"

        navbarRegistrationDesktopLogoHeight = theme.navbarRegistrationDesktopLogoHeight || "2rem";
        navbarRegistrationMobileLogoHeight = theme.navbarRegistrationMobileLogoHeight || navbarRegistrationDesktopLogoHeight;
        navbarPlayerDesktopLogoHeight = theme.navbarPlayerDesktopLogoHeight || navbarRegistrationDesktopLogoHeight;
        navbarPlayerMobileLogoHeight = theme.navbarPlayerMobileLogoHeight || navbarPlayerDesktopLogoHeight;
        navbarModalDesktopLogoHeight = theme.navbarModalDesktopLogoHeight || navbarRegistrationDesktopLogoHeight;
        navbarModalMobileLogoHeight = theme.navbarModalMobileLogoHeight || navbarModalDesktopLogoHeight;
    }
    const GlobalStyle = (theme) ? createGlobalStyle`

        :root {
            --primary: ${primaryColor};
            --navbar-registration-desktop-logo-height ${navbarRegistrationDesktopLogoHeight};
            --navbar-registration-mobile-logo-height ${navbarRegistrationMobileLogoHeight};
            --navbar-player-desktop-logo-height ${navbarPlayerDesktopLogoHeight};
            --navbar-player-mobile-logo-height ${navbarPlayerMobileLogoHeight};
            --navbar-modal-desktop-logo-height ${navbarModalDesktopLogoHeight};
            --navbar-modal-mobile-logo-height ${navbarModalMobileLogoHeight};
        }            

        body, html {
            color: ${defaultTextColor};
            background-color: ${theme.defaultBackgroundColor || 'transparent'};
            font-family: ${defaultFontFamily}, Helvetica, Arial, Sans-Serif;
            // background: repeating-linear-gradient(
            //     90deg,
            //     ${theme.registrationFormBackgroundColor},
            //     ${theme.registrationFormBackgroundColor} 50%,
            //     ${primaryColor} 50%,
            //     ${primaryColor} 100%
            //   );
            overflow-x: hidden;
        }
        .playerbox {
            &-wrapper {
                overflow: hidden;
                ${playerBorderRadius ? `border-radius: ${playerBorderRadius};` : null}
                ${playerShadowColor ? `box-shadow: 1px 1px 20px 3px ${playerShadowColor};` : null}
            }
        }         
        .app-wrapper {
            background-position: center top;
            background-size: contain;
            background-repeat: no-repeat;
            ${window._.get(event, `media.body_background_image_mobile.url`) ? `background-image: url(${window._.get(event, `media.body_background_image_mobile.url`)});` : ``}
            @media (min-width: 992px) { 
                ${window._.get(event, `media.body_background_image_desktop.url`) ? `background-image: url(${window._.get(event, `media.body_background_image_desktop.url`)});` : ``}
            }
        }
        div {
            // font-size: 0.875rem;
            // line-height: 1.6rem;
        }
        a,
        a:hover {
            text-decoration: none;
            color: ${theme.defaultLinkColor || primaryColor};
        }
        // :root {
        //     --primary-color: ${primaryColor};
        // }
        ::-webkit-scrollbar {
            width: 10px;
          }
          ::-webkit-scrollbar-thumb {
            background: ${theme.tertiaryColor};
          }
          ::-webkit-scrollbar-thumb:hover {
            background: ${primaryColor};
          }
          ::-webkit-scrollbar-track {
            background: #eeeeee;
          }  
        .container,
        .main {
            @media (min-width: 992px) { 
                padding-left: 3rem;
                padding-right: 3rem;
            }
        }  
        .container-fluid {
            ${is2ColsView ? `
            .col-right,
            .registration {
                @media (min-width: 768px) { 
                    padding-left: 2rem;
                    padding-right: 2rem;
                }    
                @media (min-width: 992px) { 
                    padding-left: 5rem;
                    padding-right: 5rem;
                }    
                &.with-background,
                &.ctio-2022 {
                    min-height: 90vh;
                    padding-bottom: 5rem;
                    background-position: left top;
                    background-repeat: no-repeat;
                    background-size: 100%;
                    ${window._.get(event, `media.right_col_background.url`) ? `background-image: url(${window._.get(event, `media.right_col_background.url`)})` : ``};
                }
                &.ctio-2022 {
                    padding-top: 20%;
                }
                .embedded-login-title {
                    color: ${primaryColor};
                    ${isCtio2022() ? `font-weight: bold;` : null}
                }
            }` : ``}
        }
        .col-right {
            background-color: ${colRightBackgroundColor};
            ${is2ColsView ? `
                &::after {
                    top: 0;
                    position: absolute;
                    width: 100vw;
                    background-color: ${colRightBackgroundColor};
                    content: " ";
                    display: block;
                    height: 100%;
                    left: 100%;
                }
                &.logged {
                    min-height: auto;
                    &::before {
                        top: 0;
                        position: absolute;
                        width: 100vw;
                        background-color: ${colRightBackgroundColor};
                        content: " ";
                        display: block;
                        height: 100%;
                        right: 100%;
                    }
                }
                ` : ""
        }
            &__logo {
                img {
                    display: block;
                    max-width: 200px;
                    max-height: 80px
                }    
            }
        }
        .loading {
            opacity: .6;
        }    
        .bullet {
            padding-left: 1rem;
            &::before {
                content: "•";
                font-size: 2rem;
                display: inline-block; 
                width: 1rem;
                margin-left: -1rem;
                vertical-align: text-bottom;
            }          
        }
        label {
            &::first-letter {
                // font-weight: bold;
                // color: red;
                text-transform: capitalize;
              }
            color: ${theme.formLabelColor || primaryColor};
            font-size: 0.75rem;
            line-height: 1rem;
        }
        form,
        .form {
            font-size: 0.75rem;
            line-height: 1rem;
            &-buttons {
                padding: 0 1rem;
            }
            &-check-label {
                margin-top: 3px;
            }
        }
        .btn {
            border-radius: ${buttonsShape ? buttonsShape === "round" ? "20px !important" : defaultBorderRadius : defaultBorderRadius};
            font-weight: ${buttonsFontWeight};
            padding-right: 1rem;
            padding-left: 1rem;
            &-primary {
                background-color:  ${primaryColor};
                color: ${theme.secondaryBackgroundColor};
                border-color:   ${primaryColor};
                &:hover,
                &:active,
                &:focus,
                &:disabled {
                    box-shadow: none !important;
                    background-color: ${theme.secondaryBackgroundColor} !important;
                    color: ${primaryColor} !important;
                    border-color:  ${theme.secondaryBackgroundColor} !important;
                }
            }  
            &-inverted {
                background-color: ${theme.secondaryBackgroundColor};
                color: ${primaryColor};
                border-color:  ${theme.secondaryBackgroundColor};
                &:hover,
                &:active,
                &:focus {
                    box-shadow: none !important;
                    background-color:  ${primaryColor} !important;
                    color: ${theme.secondaryBackgroundColor} !important;
                    border-color:   ${primaryColor} !important;
                }
            }  
            &-info {
                background-color: ${faqHeaderButtonBackgroundColor};
                border-color: ${faqHeaderButtonBorderColor};
                color: ${faqHeaderButtonTextColor};
                &:disabled {faqheaderbuttonhoverbackgroundcolor
                    background-color: ${theme.faqHeaderButtonDisabledBackgroundColor || faqHeaderButtonBackgroundColor};
                    border-color: ${theme.faqHeaderButtonDisabledBorderColor || theme.faqHeaderButtonDisabledBackgroundColor || faqHeaderButtonBorderColor};
                    color: ${theme.faqHeaderButtonDisabledTextColor || faqHeaderButtonTextColor};
                }
                &:hover,
                &:active,
                &:focus {
                    background-color: ${theme.faqHeaderButtonHoverBackgroundColor || faqHeaderButtonBackgroundColor} !important;
                    border-color: ${theme.faqHeaderButtonHoverBorderColor || theme.faqHeaderButtonHoverBackgroundColor || faqHeaderButtonBorderColor} !important;
                    color: ${theme.faqHeaderButtonHoverTextColor || faqHeaderButtonTextColor} !important;
                }                    
            }   
            &-login,
            &-register  {
                display: inline-block;
                width: 25%;
                text-align: center;
                vertical-align: top;
            }
            ${loginFormButtonBackgroundColor && loginFormButtonTextColor ? `
                &-login-modal {
                    background-color: ${loginFormButtonBackgroundColor};
                    border-color: ${loginFormButtonBorderColor};
                    color: ${loginFormButtonTextColor};
                    &:disabled {
                        background-color: ${theme.loginFormButtonDisabledBackgroundColor || loginFormButtonBackgroundColor};
                        border-color: ${theme.loginFormButtonDisabledBorderColor || theme.loginFormButtonDisabledBackgroundColor || loginFormButtonBorderColor};
                        color: ${theme.loginFormButtonDisabledTextColor || loginFormButtonTextColor};
                    }
                    &:hover,
                    &:active,
                    &:focus {
                        background-color: ${theme.loginFormButtonHoverBackgroundColor || loginFormButtonBackgroundColor} !important;
                        border-color: ${theme.loginFormButtonHoverBorderColor || theme.loginFormButtonHoverBackgroundColor || loginFormButtonBorderColor} !important;
                        color: ${theme.loginFormButtonHoverTextColor || loginFormButtonTextColor} !important;
                    }                    
                }` : null
        }        
        ${loginHeaderButtonBackgroundColor && loginHeaderButtonTextColor ? `
            &-header-login {
                background-color: ${loginHeaderButtonBackgroundColor};
                border-color: ${loginHeaderButtonBorderColor};
                color: ${loginHeaderButtonTextColor};
                &:disabled {
                    background-color: ${theme.loginHeaderButtonDisabledBackgroundColor || loginHeaderButtonBackgroundColor};
                    border-color: ${theme.loginHeaderButtonDisabledBorderColor || theme.loginHeaderButtonDisabledBackgroundColor || loginHeaderButtonBorderColor};
                    color: ${theme.loginHeaderButtonDisabledTextColor || loginHeaderButtonTextColor};
                }
                &:hover,
                &:active,
                &:focus {
                    background-color: ${theme.loginHeaderButtonHoverBackgroundColor || loginHeaderButtonBackgroundColor} !important;
                    border-color: ${theme.loginHeaderButtonHoverBorderColor || theme.loginHeaderButtonHoverBackgroundColor || loginHeaderButtonBorderColor} !important;
                    color: ${theme.loginHeaderButtonHoverTextColor || loginHeaderButtonTextColor} !important;
                }                    
            }
            ` : null
        }
        ${emailConfirmationButtonBackgroundColor && emailConfirmationButtonTextColor ? `
            &-email-confirmation {
                background-color: ${emailConfirmationButtonBackgroundColor};
                border-color: ${emailConfirmationButtonBorderColor};
                color: ${emailConfirmationButtonTextColor};
                &:disabled {
                    background-color: ${theme.emailConfirmationButtonDisabledBackgroundColor || emailConfirmationButtonBackgroundColor};
                    border-color: ${theme.emailConfirmationButtonDisabledBorderColor || theme.emailConfirmationButtonDisabledBackgroundColor || emailConfirmationButtonBorderColor};
                    color: ${theme.emailConfirmationButtonDisabledTextColor || emailConfirmationButtonTextColor};
                }
                &:hover,
                &:active,
                &:focus {
                    background-color: ${theme.emailConfirmationButtonHoverBackgroundColor || emailConfirmationButtonBackgroundColor};
                    border-color: ${theme.emailConfirmationButtonHoverBorderColor || theme.emailConfirmationButtonHoverBackgroundColor || emailConfirmationButtonBorderColor};
                    color: ${theme.emailConfirmationButtonHoverTextColor || emailConfirmationButtonTextColor};
                }                    
            } 
            ` : null
        }
            &-register {
                margin-top: 1rem;
                background-color: ${registrationFormButtonBackgroundColor};
                border-color: ${registrationFormButtonBorderColor};
                color: ${registrationFormButtonTextColor};
                &:disabled {
                    background-color: ${theme.registrationFormButtonDisabledBackgroundColor || registrationFormButtonBackgroundColor} !important;
                    border-color: ${theme.registrationFormButtonDisabledBorderColor || theme.registrationFormButtonDisabledBackgroundColor || registrationFormButtonBorderColor} !important;
                    color: ${theme.registrationFormButtonDisabledTextColor || registrationFormButtonTextColor} !important;
                }
                &:hover,
                &:active,
                &:focus {
                    background-color: ${theme.registrationFormButtonHoverBackgroundColor || registrationFormButtonBackgroundColor};
                    border-color: ${theme.registrationFormButtonHoverBorderColor || theme.registrationFormButtonHoverBackgroundColor || registrationFormButtonBorderColor};
                    color: ${theme.registrationFormButtonHoverTextColor || registrationFormButtonTextColor};
                }
            }   
            
            .inline & {
                margin: 0 1rem;
            }
            
            &-form-submit,
            &-form-abort {
                margin: 1rem 0;
                width: 100%;
                font-size: 0.875rem;
                line-height: 1.125rem;
                font-weight: 700;
            }          
            &-form-abort {
                margin: 1rem 0;
                width: 100%;
                background-color: ${theme.loginFormButtonBackgroundColor || primaryColor};
                border-color: ${theme.loginFormButtonBackgroundColor || primaryColor};
                color: ${theme.loginFormButtonTextColor || theme.primaryTextColor};
                font-size: 0.875rem;
                line-height: 1.125rem;
                font-weight: 700;

                &:disabled {
                    background-color: ${theme.secondaryBackgroundColor};
                    border-color: ${theme.secondaryBackgroundColor};
                }
            }    
        } 
        .adam-overlay {
            background-color: ${theme.modalsBackgroundColor || `#ffffff`};
            &__navbar {
                box-shadow: 1px 1px 5px ${theme.shadowColor};
            }
        }  
        .tos-link {
            cursor: pointer;
            text-decoration: none;
            font-weight: 700;
            color: ${tosLinkColor};
            &:hover {
                text-decoration: underline;
                color: ${tosLinkColor};
            }
          }     
        .tos-text {
            ${tosTextColor ? `color: ${tosTextColor};` : null}
        }   
        .header{
            .container,
            .container-fluid {
                    position: relative;
                    display: flex;
                    z-index: 100;
                    align-items: center;
            }
            &-intro {
                color: ${theme.headerIntroColor || theme.secondaryColor};
                width: ${window._.get(headerTheme, "content-width") || "50%"};
                text-align: ${headerContentAlign || "center"};
                // left: ${headerContentAlign === "left" ? "0" : "50%"};
                padding-left: ${headerContentAlign === "left" ? "2rem" : "0"};
                // transform: translate(${headerContentAlign === "left" ? "0" : "-50%"}, -50%);
            }
        }
        .faqs {
            &-popup-btn {
                cursor: pointer;
                font-weight: bold;
            }
        }
        .event-header {
            &__title {
                color: ${theme.titleTextColor || primaryTextColor};
                font-size: ${titleFontSize || "1.25rem"};
                line-height: ${titleLineHeight || "2rem"};   
                font-weight: ${titleFontWeight || "700"};    
                @media (max-width: 991px) { 
                    font-size: 1.6rem;
                    line-height: 2rem;  
                }
                word-break: break-word;
                .initial-modal & {
                    color: ${theme.initialModalTitleColor || theme.primaryTextColor};
                }
                // .modal-login__wrapper & {
                //     color: ${"red"};
                // }
                .ctio-2022 & {
                    margin-top: 3rem;
                }
                &--categories {
                    color: ${theme.primaryTextColor || theme.tertiaryColor};
                }
            }
            &__subtitle {
                color: ${theme.subTitleTextColor || tertiaryTextColor};
                font-size: 1rem;
                line-height: 1.6rem;  
                .initial-modal & {
                    color: ${theme.initialModalSubtitleColor || theme.tertiaryTextColor};
                }
            }
            &__sub-subtitle {
                color: ${theme.subSubTitleTextColor || primaryTextColor};
                font-size: ${subSubTitleFontSize || "0.875rem"};
                line-height:  ${subSubTitleLineHeight || "1.4rem"};  
                ${subSubTitleFontWeight ? `font-weight: ${subSubTitleFontWeight}` : null}                
                .initial-modal & {
                    color: ${theme.initialModalSubSubtitleColor || theme.primaryTextColor};
                }
            }
            &__dates,
            &__intro {
                color: ${theme.primaryTextColor || theme.tertiaryColor};
                font-size: 0.75rem;
                line-height: 1rem;
                p{
                    display: inline;
                    margin: 0;
                }
                .ctio-2022 & {
                    font-size: 0.875rem;
                }
                ${episodeDatesFontSize ? `.episode-dates {
                        font-size: ${episodeDatesFontSize}
                    }` : null
        }
            }
            &.ctio-2022 {
                border-bottom: 1px solid ${theme.primaryTextColor || theme.tertiaryColor};
                padding-bottom: 2rem;
                option {
                    text-align: center
                }
            }
        }
        .episode-header {
            color: ${theme.primaryTextColor || theme.tertiaryColor};
            .initial-modal & {
                color: ${theme.initialModalTitleColor || theme.tertiaryColor} !important;
            }
            &__title {
                ${removeBorderFromEpisodeTitle ? null : `border-top: 1px solid ${theme.primaryTextColor || theme.tertiaryColor};`}
                ${!episodeAccordionTitleOrder ? ` font-weight: 700;               
                div {
                    ${episodeTitleTextColor ? `color: ${episodeTitleTextColor};` : null}
                    ${episodeTitleFontSize ? `font-size: ${episodeTitleFontSize};` : null}
                    ${episodeTitleLineHeight ? `line-height: ${episodeTitleLineHeight};` : null}
                }` : null}

                .initial-modal & {
                    color: ${theme.initialModalTitleColor} !important;
                    border-top: 1px solid ${theme.initialModalTitleColor || theme.tertiaryColor};
                }
            }
            &__dates {
                // font-size: 0.75rem;
                // line-height: 1rem;
                &--details {
                    padding-right: 2rem;
                }
                p{
                    display: inline;
                    margin: 0;
                }
            }
            &__open-accordion {
                color: ${theme.primaryTextColor || "white"};

                .agenda-explode-button {
                    color: ${agendaTitleTextColor};
                }
            }
        }
        .form{ 
            &-control {
                font-size: 0.875rem;
                line-height: 1.125rem;    
                color: ${theme.formInputColor || theme.primaryTextColor};
                background-color: ${theme.formInputBackgroundColor};
                border-color: ${theme.formInputBorderColor || theme.formInputBackgroundColor};
                border-radius: ${buttonsShape ? buttonsShape === "round" ? "20px !important" : defaultBorderRadius : defaultBorderRadius};
                ${theme.formInputShadow ? `box-shadow: ${theme.formInputShadow} 0px 3px 10px !important;` : ""}
                background-clip: border-box;
                &::placeholder {
                    color: ${theme.formPlaceholderColor};
                }
                :-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: ${theme.formPlaceholderColor};
                }
                ::-ms-input-placeholder { /* Microsoft Edge */
                    color: ${theme.formPlaceholderColor};
                } 
                &:focus,
                &:active{
                    border-color: ${primaryColor};
                    box-shadow: none !important;
                }
            }
            &-singleselect {
                &__control {
                    background-color: ${theme.formInputBackgroundColor};
                    padding: 3px 5px;
                }
                &__placeholder {
                    color: ${theme.formPlaceholderColor};
                }
                &__menu,
                &__single-value {
                    color: ${theme.formInputColor || theme.primaryTextColor};
                }
            }
        }
        .website-section {
            padding: 1rem;
            &__title {
                position: relative;
                font-size: 0.875rem;
                line-height: 1.125rem;
                margin-bottom: 2rem;
                font-weight: 400;
                color: ${sectionTitleColor};
                ${sectionTitleUnderline ? "display: inline-block; &:after {display:block;position:absolute;bottom:-1rem;content:' ';width:2rem;height:2px;background-color:" + primaryColor + ";}" : ""}

                .modal__content & {
                    color: ${modalSectionTitleColor}
                }

                .player-page & {
                    color: ${playerPageSectionTitleColor}
                }
            }
            &__maintitle {
                color: ${theme.defaultTextColor || theme.tertiaryColor};
                font-size: 1.25rem;
                line-height: 2rem;   
                font-weight: 700;    
            }
            &__intro {
                margin-top: 1rem;
                font-size: 0.875rem;
                line-height: 1.4rem;   
                text-align: justify;
            }
            // &.registration {
            //     @media (min-width: 992px) { 
            //         margin-top: -${headerTitleHeight + navHeight}px;
            //     }
            // }
        }
        .speaker {
            &-wrapper {
                margin-bottom: 2rem;
                position: relative;
            }
            &-card {
                transition: ${defaultTransition}; 
                height: 100%;
                padding: 1rem 1rem;
                // margin-bottom: 2rem;
                background-color: ${speakersCardBackgroundColor};
                box-shadow:${speakerBoxShadow ? `0 0.25rem 1rem -0.25rem ${theme.shadowColor}` : "none"} ;
                border-radius: ${defaultBorderRadius};         
                .player-page & {
                    &__title {
                        color: ${playerPageSpeakersNameColor};
                        font-size: 0.875rem;
                        line-height: 1.125rem;
                        font-weight: 700
                    }
                    &__description,
                    &__bio {
                        color: ${playerPageSpeakersDescriptionColor};
                        font-size: 0.75rem;
                        text-align: justify;
                        &--expandable {
                            display: none;
                            &.expanded {
                                display: block;
                            }
                        }
                    }        
                    &__role {
                        color: ${playerPageSpeakersRoleColor};
                        font-size: 0.875rem;
                    }            
                }
                &__open-accordion,
                &__open-popup {
                    color: ${theme.speakerExplodeBtnTextColor || speakersNameColor};
                    .player-page & {
                        color: ${playerPageSpeakersCardExplodeButtonColor};
                    }
                }
                &__open-popup {
                    position: absolute;
                    width: 1rem;
                    height: 1rem;
                    top: 1rem;
                    right: 1rem;
                    z-index: 201;
                    cursor: pointer;
                    font-size: 1.6rem;
                }       
                &__title {
                    color: ${speakersNameColor};
                    font-size: 0.875rem;
                    line-height: 1.125rem;
                    font-weight: 700
                }
                &__body {
                    padding-top: 1rem;
                }
                &__description,
                &__bio {
                    color: ${speakersDescriptionColor};
                    font-size: 0.75rem;
                    margin-top: 0.75rem;
                    text-align: justify;
                    &--expandable {
                        display: none;
                        &.expanded {
                            display: block;
                        }
                    }
                }
                &__description {
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                }
                &__role {
                    color: ${speakersRoleColor};
                    font-size: 0.875rem;
                }
                &:hover {
                    box-shadow:${speakerBoxShadow ? `0 0.25rem 1rem -0.25rem ${theme.shadowHoverColor}` : "none"} ;
                }
                &__picture {
                    width: ${speakerPictureWidth};
                    padding-top: ${speakerPictureWidth};
                    border-radius: ${thumbsRounded ? "100%" : 0};
                    border: ${thumbBordered ? `${thumbBordered} solid ${speakerThumbBorderColor}` : defaultBorderRadius};
                }
            } 
        }
        .agenda {
            &:not(.sidebar) {
                ${agendaBackgroundColor ? `background-color: ${agendaBackgroundColor};` : null}
                ${groupAgendaAndSpeakers ? `padding: 0 !important;` : null}
            }
            &-row {

                width: 100%;
                overflow: hidden;                
                &__time {
                    font-size: 0.875rem;
                    line-height: 1.2rem;
                    color: ${agendaTimeColor};
                }
                &__title {
                    font-size: ${agendaTitleFontSize}rem;
                    line-height: ${agendaTitleLineHeight}rem;
                    padding-top: 0.5rem;
                    color: ${agendaTitleColor};
                }
                &__speakers {
                    font-size: 0.75rem;
                    line-height: 1rem;
                    color: ${agendaSpeakersColor};

                }
                &__agenda-card {
                    padding: 1rem;
                }
                &__speaker-card {
                    ${agendaRowSpeakerCardColor ? `background-color: ${agendaRowSpeakerCardColor};` : null}
                    padding: 1rem 0;
                    .row {
                        padding: 0 1rem;
                        width: 100%;
                    }
                }

                &:not(.sidebar) {
                    ${!groupAgendaAndSpeakers ? `margin-bottom: 1rem !important;` : null}
                    ${agendaRowBorderColor ? `border-bottom: 1px solid ${agendaRowBorderColor};` : null}
                    .row {
                        ${!groupAgendaAndSpeakers ? `padding: 0.5rem 0 !important;` : null}
                    }
                }
                &.sidebar {
                    margin-bottom: 1rem !important;
                    .row {
                        padding: 0.5rem 0 !important;
                    }
                }

            }
            &-nested {
                &__container {
                    &.expanded {
                        border-left: 2px solid ${playerPageAgendaNestedBorderLeftColor};
                    }
                }
            }
            .player-page & {
                background-color: ${playerPageAgendaBackgroundColor};
                &-row {
                    margin-bottom: 1rem;
                    &__time {
                        font-size: 0.875rem;
                        line-height: 1.2rem;
                        color: ${playerPageAgendaTimeColor};
                    }
                    &__title {
                        font-size: 1rem;
                        line-height: 1.6rem;
                        padding-top: 0.5rem;
                        color: ${playerPageAgendaTitleColor};
                    }
                    &__speakers {
                        font-size: 0.75rem;
                        line-height: 1rem;
                        color: ${playerPageAgendaSpeakersColor};
                    }
                }
            }
            &-accordion {
                ${agendaAccordionBodyBgColor ? `background-color: ${agendaAccordionBodyBgColor};` : null}
                &-title {
                    margin-bottom: 0.5rem;
                    color: ${agendaTitleTextColor};
                    background-color: ${agendaTitleBackgroundColor};

                    &.expandable {
                        background-color: ${agendaTitleBackgroundColorExplandable};
                    }
                }
            }
            &-nested-vod-details {
                padding: 0.5rem 1rem;
                font-weight: 700;
                color: ${nestedagendaVodDetailsTextColor || agendaTitleBackgroundColor};
                border: 1px solid ${nestedagendaVodDetailsBorderColor || agendaTitleBackgroundColorExplandable};
            }
        }

        .registration {
            background-color: ${theme.registrationFormBackgroundColor || theme.defaultBackgroundColor};
            &-form__required {
                color: ${primaryColor};
                margin: 1rem 0;
    
                font-size: 0.75rem;
                line-height: 1rem;
            }
            &-form__gotologin {
                font-size: 0.875rem;
                line-height: 1rem;   
                cursor: pointer;
            }
            &-form__footer {
                font-size: ${registrationFormFooterTextSize};
            }
            ${is2ColsView ? `
                &::before {
                    top: 0;
                    position: absolute;
                    width: 100vw;
                    background-color: ${theme.registrationFormBackgroundColor || theme.defaultBackgroundColor};
                    content: " ";
                    display: block;
                    height: 100%;
                    right: 100%;
                }
                ` : ""
        }
        }   
        .contacts {
            ${contactsAlign ? `text-align:${contactsAlign};` : ''}
            &-content {
                ${contactsTextColor ? `color: ${contactsTextColor};` : null
        }
                ${contactsFontSizeDesktop ? `font-size: ${contactsFontSizeDesktop};` : null
        }
                @media (max-width: 767px) { 
                    ${contactsFontSizeMobile ? `font-size: ${contactsFontSizeMobile};` : null
        }
                }
            }
        }
        .footer {
            ${footerAlign ? `text-align:${footerAlign};` : ''}
            &-content {
                ${footerTextColor ? `color: ${footerTextColor};` : null
        }
                ${footerFontSizeDesktop ? `font-size: ${footerFontSizeDesktop};` : null
        }
                @media (max-width: 767px) { 
                    ${footerFontSizeMobile ? `font-size: ${footerFontSizeMobile};` : null
        }
                }
            }
        }
        .faq {
            border-top: 1px solid #eee;
            position: relative;
            &:first-child {
                border-top: 0;
            }
            &__toggle {
                position: absolute;
                right: 1rem;
                text-align: center;
                font-weight: normal;
                font-size: 1.2rem;
                top: 0;
                width: 2rem;
                height: 2rem;
                line-height: 2rem;
                .open & {
                    transform: rotate(45deg);
                }
            }
            &__question {
                color: ${primaryColor};
                font-size: 1rem;
                font-weight: bold;
                padding-right: 2rem;
                cursor: pointer;
            }
            &__answer {
                transition: ${defaultTransition};
                height: 1px;
                opacity: 0;
                overflow: hidden;
                .open & {
                    height: auto;
                    opacity: 1;
                }
            }
        }
        .light-text {
            color: ${theme.lightTextColor}
        }
        .modal {
            &__header {
                position: relative;
                background-color: ${modalHeaderColor};
                &.fluid {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    z-index: 201;
                }
                &.transparent {
                    background-color: transparent !important;
                }
            }
            &__content {
                position: relative;
                padding: 0;
                background-color: ${theme.modalsBackgroundColor || `#ffffff`};
                word-break: break-word;
                p {
                    margin: 0;
                }
            }
            &__footer {
                background-color: ${theme.modalsBackgroundColor || `#ffffff`};
                margin-top: 0;
                &--content {
                    border-top: 1px solid ${lightenDarkenColor(defaultTextColor, 98)};
                    margin: auto 1rem;
                    font-size: 0.75rem;
                }
            }
            &__event-title {
                color: ${primaryColor};
                font-size: 1rem;
                line-height: 1.6rem;
            }
        }
        .modal-login {
            &__wrapper {
                @media (min-width: 1200px) { 
                    display: flex;
                    align-items: stretch;
                    align-content: center;
                }
            }
            &__event-content {
                width: 100%;
            }
            &__event-data {
                flex-basis: 60%;
                background-color: ${loginModalLeftSideBackgorundColor};
                padding: 2.5rem 1rem;
                display: flex;
                align-items: center;
                .event-header {
                    &__title,
                    &__subtitle {
                        width: 70%;
                        margin-top: 1rem;
                        color:${theme.loginPopupTitleColor || theme.secondaryColor};
                    }
                }
            }
            &__form {
                @media (min-width: 1200px) { 
                    flex-basis: 40%;
                }
                padding: 2rem 1rem;
                background-color: ${theme.loginFormBackgroundColor || theme.secondaryColor};
                &--wrapper {
                    padding: 2rem 0;
                }
            }
        }
        .qaa {
            &-subscriber {
                ${theme.qaBackgroundColor ? `padding:1rem; background-color: ${theme.qaBackgroundColor}` : ""}
            }
            &-card {
                &__sidebar{
                    cursor: pointer;
                }
                &__sidebar,
                &__sent {
                    border-bottom: 1px solid ${theme.defaultBorderColor ? theme.defaultBorderColor : "transparent"};
                    background-color: ${theme.defaultBackgroundColor};
                    transition: ${defaultTransition}; 
                    &:hover {
                        background-color: ${theme.secondaryBackgroundColor};
                    }
                    &--preview {
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                    }
                    &--time {
                        font-size: 0.625rem;
                    }
                    &--name {
                        font-size: 0.75rem;
                    }
                }
                &__editable {
                    box-shadow:${speakerBoxShadow ? `0 0.25rem 1rem -0.25rem ${theme.shadowHoverColor}` : "none"} ;
                    transition: ${defaultTransition}; 
                    &:hover {
                        box-shadow: 0 0.25rem 3rem -0.25rem ${theme.shadowColor};
                    }
                    &--time {
                        font-size: 0.625rem;
                    }
                    &--user {
                        color:${primaryColor};
                    }
                    &--original {
                        font-size: 0.75rem;
                    }
                    &--big {
                        font-size: 1.25rem;
                        line-height: 1.8rem;
                    }
                }
            }
        }
        .question {
            &__legend {
                font-size: 0.75rem;
                opacity: 0.8;
            }
        }
        .agenda-accordion {
            box-shadow: 0 0 2rem -1.25rem ${theme.shadowColor};
        }  
        .generic-info-tab {
            color: ${playerPageInfoTabTextColor}
        } 
        .initial-modal {
            &__banner {
              background-color: ${theme.initialModalBannerBackgroundColor || primaryColor};
              color: ${theme.initialModalBannerTextColor || theme.secondaryColor};
              border-radius: ${theme.defaultBorderRadius || "6px"};
              ${theme.initialModalBannerShadowColor ? `box-shadow: 0 0.25rem 1rem -0.25rem ${theme.initialModalBannerShadowColor};` : ``
        }
            //   color: 
            }
          }          
        .ReactModal {
            &__Content {
                box-shadow: 0 0.25rem 3rem -0.25rem ${theme.shadowColor};
                @media (max-width: 1400px) { 
                    width: 50% !important;
                }

                @media (max-width: 991px) { 
                    width: 70% !important;
                }

                @media (max-width: 767px) { 
                    width: 85% !important;
                }
            }
        }
        .theo-primary-color,
        .vjs-selected {
            color: ${theme.playerPrimaryColor || primaryColor} !important;
        }

        .theo-primary-background {
            color: ${theme.playerPrimaryBackground || theme.backgroundColor} !important;
            background-color:${theme.playerPrimaryColor || primaryColor} !important;

        .theo-secondary-color {
            color: ${theme.playerSecondaryColor || theme.secondaryColor} !important;
        }

        .theo-secondary-background {
            color:${theme.playerSecondaryBackground || theme.secondaryBackgroundColor} !important;
            background-color:  ${theme.playerSecondaryColor || theme.secondaryColor} !important;
        }

        .theo-tertiary-color {
            color: ${theme.playerTertiaryColor || theme.tertiaryColor} !important;
        }

        .theo-tertiary-background {
            color: ${theme.playerTertiaryBackground || theme.tertiaryColor} !important;
            background-color: ${theme.playerTertiaryColor || theme.tertiaryColor} !important;
        }
        /* Increase button size when hovering */
        .theoplayer-skin .vjs-big-play-button:hover {
          background: none;
          -webkit-transition-duration: 0.2s;
          -moz-transition-duration: 0.2s;
          transition-duration: 0.2s;
          transform: translate(-50%, -50%) scale(1.2);
          transition: transform 0.2s;
        }
        /* Set other image */
        .theoplayer-skin .vjs-big-play-button:before {
          content: "";
          background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjcycHgiIGhlaWdodD0iNzJweCIgdmlld0JveD0iMCAwIDcyIDcyIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA0My4yICgzOTA2OSkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+dmlkZW8gaWNvbiBjb3B5IDQ8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iSG9tZXBhZ2UiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJIb21lcGFnZS1fZGVza3RvcCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ3MC4wMDAwMDAsIC0zMDEyLjAwMDAwMCkiPgogICAgICAgICAgICA8ZyBpZD0iZnd0diIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTIwLjAwMDAwMCwgMjc4OC4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxnIGlkPSJiaWctdmlkZW8iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLCA1MC4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8ZyBpZD0idmlkZW8tMnB4IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzNTEuMDAwMDAwLCAxNzUuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgICAgIDxnIGlkPSJ2aWRlby1jb3B5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzNS4wMDAwMDAsIDM1LjAwMDAwMCkgcm90YXRlKC0zNjAuMDAwMDAwKSB0cmFuc2xhdGUoLTM1LjAwMDAwMCwgLTM1LjAwMDAwMCkgIj4KICAgICAgICAgICAgICAgICAgICAgICAgICAgIDxjaXJjbGUgaWQ9Ik92YWwtMiIgc3Ryb2tlPSIjRkZGRkZGIiBzdHJva2Utd2lkdGg9IjIiIGZpbGwtb3BhY2l0eT0iMC4xNSIgZmlsbD0iIzAwMDAwMCIgY3g9IjM1IiBjeT0iMzUiIHI9IjM1Ij48L2NpcmNsZT4KICAgICAgICAgICAgICAgICAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJUcmlhbmdsZSIgZmlsbD0iI0ZGRkZGRiIgcG9pbnRzPSIyNi45ODI4NTY5IDQ5Ljk1Mzc1MiAyNi45ODI4NTY5IDE5LjM0MjEwNTMgNDguNTQzNDU4OSAzMy45OTY5NDI5Ij48L3BvbHlnb24+CiAgICAgICAgICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==")
            no-repeat 0 0;
          /* background: url('http://i.stack.imgur.com/LT3WE.png') no-repeat 0 0; */ /* Use image instead */
          background-size: 100%;
          display: block;
        }
        /* Remove default THEOplayer image */
        .theo-big-play-button-svg-container svg {
          display: none;
        }
        /* Remove default spotlight around button on focus */
        .theoplayer-skin:hover .vjs-big-play-button:after,
        .theoplayer-skin .vjs-big-play-button:focus:after {
          opacity: 0;
        }
    ` : null


    // const speakers = (
    //     <Speakers
    //         title={contents.speakersTitle}
    //         language={language}
    //         country={country}
    //         episodeId={episodeId}
    //         internal={internal}
    //     />
    // )

    // const agenda = (
    //     <Agenda
    //         title={contents.agendaTitle}
    //         language={language}
    //         country={country}
    //         episodeId={episodeId}
    //         internal={internal}
    //     />
    // )
    // switch (selectedSidebarTab) {
    //     case "agenda":
    //         selectedTab = agenda
    //         break
    //     case "speakers":
    //         selectedTab = speakers
    //         break
    // }

    // let mainContent = useMemo(() => {
    let mainContent
    if (user) {

        if (window._.get(user, "is_chairman") && openQaAManager) {
            mainContent = (
                <PageBody>
                    <QandASpeaker
                        eventId={event.id}
                    />
                </PageBody>
            )
        }
        else if (window._.get(user, "is_moderator") && openQaAManager) {
            mainContent = (
                <PageBody>
                    <QandAManager
                        eventId={event.id}
                        episodeId={episodeId}
                    />
                </PageBody>
            )
        }
        else {
            mainContent = (goToPlayer && !selectEpisodeModalOpen) ? (
                <PageBody className="player-page">
                    <div className={
                        classNames("col")
                    }>
                        <PlayerBox
                            eventId={event.id}
                            episodeId={episodeId}
                        />
                        {
                            [LIVE, PRE_LIVE, VOD, PRE_VOD].includes(channelStatus) && !episode?.is_qaa_form_hidden ? (
                                <QandA />
                            ) : null
                        }
                    </div>
                    <PlayerSidebar
                        language={language}
                        country={country}
                        episode={episode}
                        episodeId={episodeId}
                        internal={internal}
                        contents={contents}
                        hideAgendaDatetime={!!hideAgendaDatetime}
                    />
                </PageBody>
            ) : (
                <>
                    {
                        fullPageHeader ? (
                            <Header
                                theme={headerTheme}
                                loginWaiting={loginWaiting}
                                loginErrors={loginErrors}
                                intro={contents.websiteIntro}
                                onLoginClicked={(args) => onLoginClicked(args)}
                                emailBoxLogin={contents.emailBoxLogin}
                            />
                        ) : null
                    }
                </>
            )
        }
    }
    //     return mainContent_
    // }, [
    //     user,
    //     language,
    //     country,
    //     episode,
    //     episodeId,
    //     internal,
    //     contents,
    // ])

    const output = errorWithReload ? (
        <ErrorPageWithReload
            maintenanceMessage={errorWithReload}
        />
    ) : fnf === true ? (
        <DefaultPage message={"404"} fullHeight />
    ) : (
        event &&
        // theme &&
        // contents &&
        !maintenance
    ) ? (
        <div className='app-wrapper'>
            <GlobalStyle />
            <ScrollToTop />
            <ThemeProvider theme={theme}>
                <MainContext.Provider value={mainContext}>
                    {
                        ((hiddenHeader && !user) || isVirtualWorld) ? null : (
                            <Navbar
                                contents={contents}
                                isEfEnabled={isEfEnabled}
                                user={user}
                                questions={questions}
                                forceEF={forceEF}
                                episode={episode}
                                language={language}
                                isVirtualWorld={event.virtual_world_url && event.is_virtualworld_active}
                                evaluationFormEnabled={evaluationFormEnabled()}
                                hasUnansweredQuestions={hasUnansweredQuestions()}
                                onFaqButtonClicked={onFaqButtonClicked}
                                onLogoutClicked={() => onLogoutClicked()}
                                onEvaluationFormModalClose={onEvaluationFormModalClose}
                                isCtio2022={isCtio2022()}
                            />
                        )
                    }
                    <MainContainer
                        style={{ paddingTop: `${navHeight * 1.8}px` }}
                        className={classNames("main-app", {
                            "loading": loadingContents
                        })}>
                        <Switch>
                            <Route path="/" exact>
                                {mainContent}
                                {
                                    (user && goToPlayer) || !userIsAttendant(user) ? null : (
                                        <PageBody narrow={narrow}>
                                            {
                                                user || !rfOnLeft ? null : (
                                                    <Registration
                                                        // style={{
                                                        //     marginTop: `-${headerTitleHeight + navHeight}px`
                                                        // }}
                                                        hasLoginForm={registrationFormHasLoginForm}
                                                        hasLogo={true}
                                                        hideTitle={hideRegistrationFormTitle}
                                                        mainTitle={contents.registrationIntro}
                                                        intro={contents.registrationHeader}
                                                        legals={legals}
                                                        title={registered ? contents.loginFormTitle : contents.registrationFormTitle}
                                                        loginWaiting={loginWaiting}
                                                        loginErrors={loginErrors}
                                                        onLoginClicked={(args) => onLoginClicked(args)}
                                                    />
                                                )
                                            }
                                            {
                                                isCtio2022() ? (
                                                    <PageBody
                                                        isCol={true}
                                                        className={
                                                            classNames({
                                                                "col-right": is2ColsView,
                                                                "ctio-2022": true,
                                                                "logged": user,
                                                                [`col-xs-${mainCols.mobile}`]: mainCols.mobile,
                                                                [`col-md-${mainCols.tablet}`]: mainCols.tablet,
                                                                [`col-lg-${mainCols.desktop}`]: mainCols.desktop,

                                                            })
                                                        }
                                                    // className={"d-block"}
                                                    >
                                                        {
                                                            location.pathname !== "/videotest" ? (
                                                                <>
                                                                    <EventTitle
                                                                        className="website-section"
                                                                        eventTitle={is2ColsView ? contents.eventTitle : episode.title}
                                                                        eventSubTitle={is2ColsView ? null : contents.eventTitle}
                                                                        eventSubSubTitle={contents.subSubTitle}
                                                                        eventLive={window._.get(episode, "live_scheduling.formatted_start_at")}
                                                                        eventLiveTitle={window._.get(contents, "liveDatetimeTitle")}
                                                                        eventVod={window._.get(episode, "vod_scheduling.formatted_start_at")}
                                                                        eventVodTitle={window._.get(contents, "vodDatetimeTitle")}
                                                                        eventIntro={window._.get(contents, "websiteIntro")}
                                                                        eventNotes={window._.get(contents, "websiteNotes")}
                                                                        upTo={window._.get(contents, "upTo")}
                                                                        hours={window._.get(contents, "hours")}
                                                                        episodes={event.episodes}
                                                                        allEpisodes={allEpisodes}
                                                                        episode={episode}
                                                                        onEpisodeChange={(e, direct) => onEpisodeChange(e, direct)}
                                                                        hideDropDown={hideDropDown}
                                                                        event="ctio-2022"
                                                                    />

                                                                </>
                                                            ) : null
                                                        }
                                                    </PageBody>
                                                ) : (
                                                    <PageBody
                                                        isCol={true}
                                                        className={
                                                            classNames({
                                                                "col-right": is2ColsView,
                                                                "with-background": true,
                                                                "logged": user,
                                                                [`col-xs-${mainCols.mobile}`]: mainCols.mobile,
                                                                [`col-md-${mainCols.tablet}`]: mainCols.tablet,
                                                                [`col-lg-${mainCols.desktop}`]: mainCols.desktop,

                                                            })
                                                        }
                                                    // className={"d-block"}
                                                    >
                                                        {
                                                            location.pathname !== "/videotest" && episode ? (
                                                                <>
                                                                    <EventTitle
                                                                        className="website-section"
                                                                        eventTitle={is2ColsView ? contents.eventTitle : episode.title}
                                                                        eventSubTitle={is2ColsView ? contents.subTitle : contents.eventTitle}
                                                                        eventSubSubTitle={contents.subSubTitle}
                                                                        eventLive={window._.get(episode, "live_scheduling.formatted_start_at")}
                                                                        eventLiveTitle={window._.get(contents, "liveDatetimeTitle")}
                                                                        eventVod={window._.get(episode, "vod_scheduling.formatted_start_at")}
                                                                        eventVodTitle={window._.get(contents, "vodDatetimeTitle")}
                                                                        eventIntro={window._.get(contents, "websiteIntro")}
                                                                        upTo={window._.get(contents, "upTo")}
                                                                        hours={window._.get(contents, "hours")}
                                                                        episodes={event.episodes}
                                                                        episode={episode}
                                                                        onEpisodeChange={(e, direct) => onEpisodeChange(e, direct)}
                                                                        hideDropDown={hideDropDown}
                                                                    />
                                                                    {
                                                                        showAllEpisodes ? event.episodes.map((item, index) => {
                                                                            const EpisodeDetailsComponent = nestedAgenda ? EpisodeDetailsWithNestedAgenda : EpisodeDetails
                                                                            return (
                                                                                <EpisodeDetailsComponent
                                                                                    key={`episode-detail-${index}`}
                                                                                    episode={item}
                                                                                    contents={contents}
                                                                                    language={language}
                                                                                    internal={internal}
                                                                                    country={country}
                                                                                    expandable={true}
                                                                                    groupedSeakers={groupAgendaAndSpeakers}
                                                                                    hideAgendaDatetime={hideAgendaDatetime}
                                                                                    hideDates={hideEpisodeDatetime}
                                                                                    fullWidth={hideEpisodeDatetime}
                                                                                    titleLinesOrder={episodeAccordionTitleOrder}
                                                                                    accordionTitleIcon={episodeAccordionTitleIcon}
                                                                                />
                                                                            )
                                                                        }) : (
                                                                            <>
                                                                                <Agenda
                                                                                    title={contents.agendaTitle}
                                                                                    language={language}
                                                                                    country={country}
                                                                                    episodeId={episodeId}
                                                                                    episode={episode}
                                                                                    internal={internal}
                                                                                    items={episode.agenda}
                                                                                />
                                                                                <Speakers
                                                                                    title={contents.speakersTitle}
                                                                                    language={language}
                                                                                    country={country}
                                                                                    episodeId={episodeId}
                                                                                    internal={internal}
                                                                                    items={episode.speakers}
                                                                                    hiddenItems={episode.hidden_speakers_cards}
                                                                                />
                                                                            </>
                                                                        )
                                                                    }

                                                                </>
                                                            ) : null
                                                        }
                                                    </PageBody>
                                                )
                                            }
                                            {
                                                user || rfOnLeft ? null : (
                                                    <Registration
                                                        // style={{
                                                        //     marginTop: `-${headerTitleHeight + navHeight}px`
                                                        // }}
                                                        allEpisodes={allEpisodes}
                                                        mainTitle={contents.registrationIntro}
                                                        intro={contents.registrationHeader}
                                                        legals={legals}
                                                        title={registered ? contents.loginFormTitle : contents.registrationFormTitle}
                                                    />
                                                )
                                            }
                                        </PageBody>
                                    )
                                }

                            </Route>
                            {/* <Route path="/play">
                                <PageBody>
                                    <div className={
                                        classNames("col")
                                    }>
                                        <PlayerBox
                                            eventId={event.id}
                                            episodeId={episodeId}
                                        />
                                        {
                                            [LIVE, PRE_LIVE, VOD].includes(channelStatus) ? (
                                                <QandA />
                                            ) : null
                                        }
                                    </div>
                                    <PlayerSidebar
                                        language={language}
                                        country={country}
                                        episode={episode}
                                        episodeId={episodeId}
                                        internal={internal}
                                        contents={contents}
                                    />
                                </PageBody>
                            </Route> */}
                            <Route path="/iframe">
                                {goToPlayer ? allowIframe() ? (
                                    <IframeContent
                                        slido={slidoIframe}
                                        hideQaAIframe={hideQaAIframe}
                                        eventId={event.id}
                                        episode={episode}
                                        channelStatus={channelStatus}
                                        goToPlayer={goToPlayer}
                                    />
                                ) : (
                                    <div>
                                        Protected contents
                                    </div>
                                ) : null}
                            </Route>
                            <Route path="/evaluation">
                                {user ? evaluationFormEnabled() ? (
                                    <EvaluationForm
                                        user={user}
                                        // setEvaluationFormAnswered={setEvaluationFormAnswered}
                                        answered={
                                            window._.get(user, `answers`)
                                        }
                                        alreadyAnsweredMessage={contents.alreadyAnsweredMessage}
                                        questionsCategories={window._.get(episode, `evaluation_questions`)}
                                    />
                                ) : (
                                    <div>
                                        Disabled
                                    </div>
                                ) : (
                                    <div>
                                        PLease login
                                    </div>
                                )}
                            </Route>
                            <Route path="/videotest">
                                {
                                    event !== undefined && (
                                        <DemoPlayer
                                            source={
                                                [{
                                                    "src": event.video_test_url || process.env.REACT_APP_DEMO_PLAYER_SRC,
                                                    "type": "application/x-mpegurl",
                                                    "lowLatency": false
                                                }]
                                            }
                                        />
                                    )
                                }
                            </Route>
                        </Switch>

                        {mode ? null : (
                            <>
                                <Contacts
                                    hideTitle={hideContactsTitle}
                                    forceContainer={true}
                                    fluidContainer={true}
                                    title={contents.contactsTitle}
                                    content={contents.websiteContacts} />

                                <Footer
                                    forceContainer={true}
                                    fluidContainer={true}
                                    content={contents.websiteFooter} />
                            </>
                        )}
                        {
                            episode !== undefined &&
                                window._.get(user, "episodes")?.length > 1 &&
                                user ? (
                                // episode !== undefined && user ? (
                                <SelectEpisodeOverlay
                                    title={contents.overlayTitle}
                                    episodes={window._.get(user, "episodes")}
                                    open={selectEpisodeModalOpen}
                                    shouldCloseOnOverlayClick={false}
                                    hideCloseButton={!episodeId}
                                    onEpisodeSelected={(args) => onEpisodeSelected(args)}
                                    onLogoutClicked={() => onLogoutClicked()}
                                />
                                // <SelectEpisodeModal
                                //     formTitle={contents.loginFormTitle}
                                //     eventTitle={episode.title}
                                //     eventSubTitle={contents.eventTitle}
                                //     episodes={window._.get(user, "episodes")}
                                //     channels={window._.get(user, "channels")}
                                //     open={selectEpisodeModalOpen}
                                //     shouldCloseOnOverlayClick={false}
                                //     onEpisodeSelected={(args) => onEpisodeSelected(args)} />
                            ) : null
                        }
                        <InitialModal
                            event={event}
                            contents={contents}
                            open={initialModalOpen}
                            // showAllEpisodes={showAllEpisodes}
                            language={language}
                            internal={internal}
                            country={country}
                            onClose={() => setInitialModalOpen(false)} />

                        <ThankYouRegistrationModal
                            content={contents.thankyouMessage}
                            open={tankyouRegistrationModalOpen} />

                        <FormErrorsModal
                            content={formErrors}
                            open={formErrorsModalOpen} />
                        {/* 
                        <FaqsModal
                            open={faqsModalOpen} />
                        {
                            evaluationFormEnabled() ? (
                                <EvaluationFormModal
                                    answered={
                                        window._.get(user, `answers.${episodeId}.answered`) ||
                                        evaluationFormAnswered
                                    }
                                    alreadyAnsweredMessage={contents.alreadyAnsweredMessage}
                                    questionsCategories={window._.get(episode, `evaluation_questions`)}
                                    open={evaluartionFormModalOpen}
                                />
                            ) : null
                        } */}
                        {
                            episode ? (
                                <LoginModal
                                    formTitle={contents.loginFormTitle}
                                    // eventTitle={episode.title}
                                    eventTitle={contents.eventTitle}
                                    // eventSubTitle={contents.eventTitle}
                                    open={loginModalOpen}
                                    onLoginClicked={(args) => onLoginClicked(args)}
                                    onForceLogoutClicked={() => onForceLogoutClicked()}
                                    hideChairmanLoginButton={theme.loginFormHideChairmanLoginButton}
                                />
                            ) : null
                        }

                        <AlreadyLoggedInModal
                            open={alreadyLoggedInModalOpen}
                            onForceLogoutClicked={() => onForceLogoutClicked()}
                        />
                        {
                            episode && (
                                <WaitingRoomModal
                                    open={waitingRoomModalOpen}
                                    episode={episode}
                                />
                            )
                        }
                        {
                            isVirtualWorld ? null : (
                                <VirtualWorldModal
                                    virtual_world_start_at={event.virtual_world_start_at}
                                    virtual_world_url={event.virtual_world_url}
                                    open={virtualWorldModalOpen}
                                    eventTitle={contents.eventTitle}
                                    openMessage={contents.virtualWorldWillOpen}
                                    onClose={(val) => setVirtualWorldModalOpen(val)} />
                            )
                        }
                        {
                            !isVirtualWorld && cookieConsent && window._.get(cookieConsent, "ccCheckboxLabel") ? (
                                <AdamCookieConsent
                                    {...cookieConsent}
                                    cookieConsentButtonBackgroundColor={cookieConsentButtonBackgroundColor}
                                    cookieConsentButtonBorderColor={cookieConsentButtonBorderColor}
                                    cookieConsentButtonTextColor={cookieConsentButtonTextColor}
                                    theme={theme} />
                            ) : null
                        }
                        <EpisodesListEmptyModal
                            // content={context.contents.checkYourEmailMessage}
                            open={episodesListEmptyModalOpen}
                            // emailAddress={getValue("email")}
                            onConfirm={() => setEpisodesListEmptyModalOpen(false)}
                            onCancel={() => setEpisodesListEmptyModalOpen(false)}
                            onClose={() => setEpisodesListEmptyModalOpen(false)}
                        />
                    </MainContainer>
                </MainContext.Provider>
            </ThemeProvider>
        </div >
    ) : maintenance ? (
        <>
            {/* <GlobalStyle /> */}
            <Maintenance
                maintenanceMessage={contents.maintenanceMessage}
            />
        </>
    ) : null

    return output
}

export default Adam;
