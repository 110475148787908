import React, { useEffect, useState } from "react"
import AdamModal from "../AdamModal/AdamModal";
import Button from "../../ui/Button/Button";
import Container from "../../ui/Container/Container";
import Row from "../../ui/Row/Row";

const ForceEvaluationFormModal = ({ onClose, onConfirm, content, okButtonText, koButtonText, ...props }) => {
    const [modalOpen, setModalOpen] = useState(false)

    useEffect(() => {
        setModalOpen(props.open)
    }, [props.open])

    const grid = (
        <Container fluid={true} className="text-center">
            <Row className="mt-3">
                <div className="col-12">
                    {content || "Are you sure?"}
                </div>
            </Row>

            <Row className="my-3">
                <div className="col-12">
                    <Button
                        onClick={onConfirm}
                        size="sm"
                        className="mx-2 btn-email-confirmation"
                    >
                        {okButtonText || "Confirm"}
                    </Button>
                    <Button
                        onClick={onClose}
                        size="sm"
                        className="mx-2 btn-primary"
                    >
                        {koButtonText || "Cancel"}
                    </Button>
                </div>
            </Row>
        </Container >
    )

    const modal = (
        <AdamModal
            hideCloseButton={true}
            content={grid}
            open={modalOpen}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            headerClass="d-none d-sm-block"
            onClose={onClose}
        />
    )

    return modal
}

export default ForceEvaluationFormModal