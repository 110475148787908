const { getAuthToken } = require('./utils/functions');

window._ = require('lodash');
/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
} catch (e) { }

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// const token = document.head.querySelector('meta[name="csrf-token"]');
// const eventId = document.head.querySelector('meta[name="event-id"]');

// window.token = (token) ? token.content : '';
// window.eventId = (eventId) ? eventId.content : '';

window.axios.defaults.baseURL = `${process.env.REACT_APP_API_BASEURL}${process.env.REACT_APP_VERSION}/event/`;
// window.axios.defaults.baseURL = `http://localhost:8091/api/event/${window.eventId}/`;

const authToken = getAuthToken();

if (authToken) {
    // window.axios.defaults.headers.common["Authorization"] = "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5Mjc4NTkzZi0wYjI5LTQ0N2UtYjQ0Zi0wNzkxMGY2Yzk2ZmMiLCJqdGkiOiI5ZDI0OTM2ODk3NTFiOGI1OTNhMGEzMzE0MWZmMDMxMTYwMWUwZjU5ZmVmZDVlYzFiNzdiZjlhYjJjZjM5MTE3ZmY5ZjY0ZWJiNDQ1NTM0MiIsImlhdCI6IjE2MTA1NDIwNzYuNjU4NDM3IiwibmJmIjoiMTYxMDU0MjA3Ni42NTg0NDgiLCJleHAiOiIxNjQyMDc4MDc2LjU5Nzc4NiIsInN1YiI6IjEiLCJzY29wZXMiOltdfQ.ecsHmJtuq1XzFcqPKJMdnoGuzAPZp_asaTpwkirBRwbOyiPvxwcHYZKOUe_l_m-VY6VwU_nfllmpUo-IERt6fbPkpj3w9KI3DsgJgCjT9j40K5LeYaANJIpOL-GB4wb_VeMKpHHo7u1QiCdCZDbnaD-7lRuImP-J_6agxFwjjjbtN6CQRyukt3JDvIoVcXXkYSgwjE46RXh7iBfTdp0c2lxHlvSzS2AADAgC0-C0_fiFbDjDxEbg3Q5nkLEQioHKNvTDdwiZS28mJYvSEJCfcLjmg0v7ynbYjpKIBltqdT2yxZ8ELoZH-uDwymIF_sU60I6GWjuB7d2VNnwRK61w_ajDpKK5b4Zxyflp1u33Qq7vQWnW4I8-H7MJc3b90vkXUZtaSPM99Eis5F9L8VyB9VHQo5bFEewPeM1VAh_4ErHDARiVdmWlyzK-L0b85Js40Hu2sZ3sT6263barJOshC442YOwbxPWNdV4LkDzmoWTkPcmD5bUX7BZ43z9TxpB_kXxyQ95MpsSYrt-TtSQU9QcLbVOthh1G7c-rMAhY7jq6H8_J80-I2AN5SadPdLRDNOQDBPeIWzE5sUV5Jn3A8dR_BnQF_UlENvzNKUgYOWKYuvuUXkwTOyQKRoP6qW9PMYsddhvGzzC2HaVs2HRDi0MWhIM79e_jOlwQOY-GSp0";
    window.axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
}
// window.axios.defaults.headers.common["Authorization"] = "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5Mjc4NTkzZi0wYjI5LTQ0N2UtYjQ0Zi0wNzkxMGY2Yzk2ZmMiLCJqdGkiOiI5ZDI0OTM2ODk3NTFiOGI1OTNhMGEzMzE0MWZmMDMxMTYwMWUwZjU5ZmVmZDVlYzFiNzdiZjlhYjJjZjM5MTE3ZmY5ZjY0ZWJiNDQ1NTM0MiIsImlhdCI6IjE2MTA1NDIwNzYuNjU4NDM3IiwibmJmIjoiMTYxMDU0MjA3Ni42NTg0NDgiLCJleHAiOiIxNjQyMDc4MDc2LjU5Nzc4NiIsInN1YiI6IjEiLCJzY29wZXMiOltdfQ.ecsHmJtuq1XzFcqPKJMdnoGuzAPZp_asaTpwkirBRwbOyiPvxwcHYZKOUe_l_m-VY6VwU_nfllmpUo-IERt6fbPkpj3w9KI3DsgJgCjT9j40K5LeYaANJIpOL-GB4wb_VeMKpHHo7u1QiCdCZDbnaD-7lRuImP-J_6agxFwjjjbtN6CQRyukt3JDvIoVcXXkYSgwjE46RXh7iBfTdp0c2lxHlvSzS2AADAgC0-C0_fiFbDjDxEbg3Q5nkLEQioHKNvTDdwiZS28mJYvSEJCfcLjmg0v7ynbYjpKIBltqdT2yxZ8ELoZH-uDwymIF_sU60I6GWjuB7d2VNnwRK61w_ajDpKK5b4Zxyflp1u33Qq7vQWnW4I8-H7MJc3b90vkXUZtaSPM99Eis5F9L8VyB9VHQo5bFEewPeM1VAh_4ErHDARiVdmWlyzK-L0b85Js40Hu2sZ3sT6263barJOshC442YOwbxPWNdV4LkDzmoWTkPcmD5bUX7BZ43z9TxpB_kXxyQ95MpsSYrt-TtSQU9QcLbVOthh1G7c-rMAhY7jq6H8_J80-I2AN5SadPdLRDNOQDBPeIWzE5sUV5Jn3A8dR_BnQF_UlENvzNKUgYOWKYuvuUXkwTOyQKRoP6qW9PMYsddhvGzzC2HaVs2HRDi0MWhIM79e_jOlwQOY-GSp0";

window.axios.defaults.headers.common['Accept'] = 'application/json';
window.axios.defaults.headers.common['Content-Type'] = 'application/json';


// Add a response interceptor
window.axios.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if ([401, 419].includes(error.response.status)) {
            console.log("Error 4xx Unauthenticated")
        }
        return Promise.reject(error);
    }
);

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */



// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true,
//     authEndpoint: '/api/broadcasting/auth',
//     auth: {
//         headers: {
//             'Authorization': "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5Mjc4NTkzZi0wYjI5LTQ0N2UtYjQ0Zi0wNzkxMGY2Yzk2ZmMiLCJqdGkiOiI5ZDI0OTM2ODk3NTFiOGI1OTNhMGEzMzE0MWZmMDMxMTYwMWUwZjU5ZmVmZDVlYzFiNzdiZjlhYjJjZjM5MTE3ZmY5ZjY0ZWJiNDQ1NTM0MiIsImlhdCI6IjE2MTA1NDIwNzYuNjU4NDM3IiwibmJmIjoiMTYxMDU0MjA3Ni42NTg0NDgiLCJleHAiOiIxNjQyMDc4MDc2LjU5Nzc4NiIsInN1YiI6IjEiLCJzY29wZXMiOltdfQ.ecsHmJtuq1XzFcqPKJMdnoGuzAPZp_asaTpwkirBRwbOyiPvxwcHYZKOUe_l_m-VY6VwU_nfllmpUo-IERt6fbPkpj3w9KI3DsgJgCjT9j40K5LeYaANJIpOL-GB4wb_VeMKpHHo7u1QiCdCZDbnaD-7lRuImP-J_6agxFwjjjbtN6CQRyukt3JDvIoVcXXkYSgwjE46RXh7iBfTdp0c2lxHlvSzS2AADAgC0-C0_fiFbDjDxEbg3Q5nkLEQioHKNvTDdwiZS28mJYvSEJCfcLjmg0v7ynbYjpKIBltqdT2yxZ8ELoZH-uDwymIF_sU60I6GWjuB7d2VNnwRK61w_ajDpKK5b4Zxyflp1u33Qq7vQWnW4I8-H7MJc3b90vkXUZtaSPM99Eis5F9L8VyB9VHQo5bFEewPeM1VAh_4ErHDARiVdmWlyzK-L0b85Js40Hu2sZ3sT6263barJOshC442YOwbxPWNdV4LkDzmoWTkPcmD5bUX7BZ43z9TxpB_kXxyQ95MpsSYrt-TtSQU9QcLbVOthh1G7c-rMAhY7jq6H8_J80-I2AN5SadPdLRDNOQDBPeIWzE5sUV5Jn3A8dR_BnQF_UlENvzNKUgYOWKYuvuUXkwTOyQKRoP6qW9PMYsddhvGzzC2HaVs2HRDi0MWhIM79e_jOlwQOY-GSp0"
//         }
//     }
// });

// window.Pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
//     key: process.env.REACT_APP_PUSHER_APP_KEY,
//     cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
//     forceTLS: true,
//     authEndpoint: '/api/broadcasting/auth',
//     auth: {
//         headers: {
//             'Authorization': "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5Mjc4NTkzZi0wYjI5LTQ0N2UtYjQ0Zi0wNzkxMGY2Yzk2ZmMiLCJqdGkiOiI5ZDI0OTM2ODk3NTFiOGI1OTNhMGEzMzE0MWZmMDMxMTYwMWUwZjU5ZmVmZDVlYzFiNzdiZjlhYjJjZjM5MTE3ZmY5ZjY0ZWJiNDQ1NTM0MiIsImlhdCI6IjE2MTA1NDIwNzYuNjU4NDM3IiwibmJmIjoiMTYxMDU0MjA3Ni42NTg0NDgiLCJleHAiOiIxNjQyMDc4MDc2LjU5Nzc4NiIsInN1YiI6IjEiLCJzY29wZXMiOltdfQ.ecsHmJtuq1XzFcqPKJMdnoGuzAPZp_asaTpwkirBRwbOyiPvxwcHYZKOUe_l_m-VY6VwU_nfllmpUo-IERt6fbPkpj3w9KI3DsgJgCjT9j40K5LeYaANJIpOL-GB4wb_VeMKpHHo7u1QiCdCZDbnaD-7lRuImP-J_6agxFwjjjbtN6CQRyukt3JDvIoVcXXkYSgwjE46RXh7iBfTdp0c2lxHlvSzS2AADAgC0-C0_fiFbDjDxEbg3Q5nkLEQioHKNvTDdwiZS28mJYvSEJCfcLjmg0v7ynbYjpKIBltqdT2yxZ8ELoZH-uDwymIF_sU60I6GWjuB7d2VNnwRK61w_ajDpKK5b4Zxyflp1u33Qq7vQWnW4I8-H7MJc3b90vkXUZtaSPM99Eis5F9L8VyB9VHQo5bFEewPeM1VAh_4ErHDARiVdmWlyzK-L0b85Js40Hu2sZ3sT6263barJOshC442YOwbxPWNdV4LkDzmoWTkPcmD5bUX7BZ43z9TxpB_kXxyQ95MpsSYrt-TtSQU9QcLbVOthh1G7c-rMAhY7jq6H8_J80-I2AN5SadPdLRDNOQDBPeIWzE5sUV5Jn3A8dR_BnQF_UlENvzNKUgYOWKYuvuUXkwTOyQKRoP6qW9PMYsddhvGzzC2HaVs2HRDi0MWhIM79e_jOlwQOY-GSp0"
//         }
//     }
// });

// if (window.eventId) {
//     const presenceChannel = window.Echo.join(`presence.${window.eventId}`)
//         .here((users) => {
//             console.log("presenceChannel here", users)
//         })
//         .joining((user) => {
//             console.log("presenceChannel joining", user.name);
//         })
//         .leaving((user) => {
//             console.log("presenceChannel leaving", user.name);
//         });
//     console.log("presenceChannel", presenceChannel)
// }
