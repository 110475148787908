import React, { useEffect, useState, useContext } from "react"
import AdamModal from "../AdamModal/AdamModal";
import MainContext from "../../context/MainContext";
import Container from "../../ui/Container/Container";
import Row from "../../ui/Row/Row";

const ThankYouRegistrationModal = (props) => {
    const [modalOpen, setModalOpen] = useState(false)
    const context = useContext(MainContext)

    useEffect(() => {
        setModalOpen(props.open)
    }, [props.open])

    const grid = (
        <Container fluid={true}>
            {
                props.content ?
                    (
                        <Row
                            className="my-2 py-4 px-2">
                            <div className="col-12">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: props.content
                                    }} />
                            </div>
                        </Row>
                    ) : null
            }
        </Container >
    )

    const modal = (
        <AdamModal
            content={grid}
            open={modalOpen}
            // fluidHeader={true}
            onClose={() => context.setTankyouRegistrationModalOpen(false)}
        />
    )

    return modal
}

export default ThankYouRegistrationModal