import React, { useContext, useState, } from 'react';
import { buildQuery } from '../../api/functions';
import { getThemeConfig, getContents, formatRegistrationFooter } from '../../utils/functions';
import Form from '../Form/Form';
import MainContext from '../../context/MainContext';
import { ThemeContext } from 'styled-components';
import LightText from '../../ui/LightText/LightText';

const RegistrationForm = (props) => {
    const [defaultValues, setDefaultValues] = useState({});

    const themeContext = useContext(ThemeContext);
    const context = useContext(MainContext)

    const getEndpoint = () => {
        const query = buildQuery({});
        // const endpoint = `events/${event}/${CONTENTS}?${query}`;
        return [
            "register"
        ].join("/") + `?${query}`;
    }

    const getValue = (name, type) => {
        const value = !window._.isEmpty(defaultValues) ? defaultValues[name] : null;
        switch (type) {
            case "bool":
                return value !== 0;
            default:
                return value || "";
        }
    };

    const onChange = (key, value) => {
        console.log("onChange", key, value);
        switch (key) {
            case "lang_id":
                context.setLanguage(value)
                break
            case "country_id":
                context.setCountry(value)
                break
            case "is_internal":
                context.setInternal(value)
                break
        }
        setDefaultValues({
            ...defaultValues,
            [key]: value
        });
    }

    const onSubmit = (params) => {
        // context.setUser(params.me)
        // context.setToken(params.token, params.me)
        context.onRegistred(true)
    }

    const countries = [{
        id: null,
        label: "-",
        code: null
    }].concat(window._.get(context, "event.countries"))

    let fields = 1
        ? [
            {
                elementType: "hidden",
                id: "episodes",
                name: "episodes",
                value: [window._.get(context, "episode.id")],
            },
        ]
        : [];

    if (context.event.has_internal) {
        fields.push({
            elementType: "checkbox",
            id: "is_internal",
            name: "is_internal",
            // required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.internal"),
            value: context.internal
        })
    }

    if (!context.internal) {
        fields.push({
            elementType: "select",
            id: "country_id",
            name: "country_id",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.country"),
            placeholder: window._.get(context, "contents.country"),
            value: getValue("country_id") || context.country,
            disabled: context.internal,
            options: countries || []
        })
    }

    fields.push({
        elementType: "select",
        id: "lang_id",
        name: "lang_id",
        required: true,
        // invalidText: "Invalid error message.",
        label: window._.get(context, "contents.language"),
        placeholder: window._.get(context, "contents.language"),
        value: getValue("lang_id") || context.language,
        options: window._.get(context, "event.languages") || []
    })

    if (context.country) {
        fields = fields.concat([
            {
                elementType: "text",
                id: "firstname",
                name: "firstname",
                required: true,
                // invalidText: "Invalid error message.",
                label: window._.get(context, "contents.firstname"),
                placeholder: window._.get(context, "contents.firstname"),
                value: getValue("firstname"),
            },
            {
                elementType: "text",
                id: "lastname",
                name: "lastname",
                required: true,
                // invalidText: "Invalid error message.",
                label: window._.get(context, "contents.lastname"),
                placeholder: window._.get(context, "contents.lastname"),
                value: getValue("lastname"),
            },
            {
                elementType: "email",
                id: "email",
                name: "email",
                required: true,
                // invalidText: "Invalid error message.",
                label: window._.get(context, "contents.email"),
                placeholder: window._.get(context, "contents.email"),
                value: getValue("email"),
            },
        ])

        // fields.push({
        //     elementType: "checkbox",
        //     id: "privacy_policy",
        //     name: "privacy_policy",
        //     required: true,
        //     // invalidText: "Invalid error message.",
        //     label: `${getContents(context, "iAgree")} ${getContents(context, "privacyPolicyLinkText")}`,
        //     value: getValue("privacy_policy"),
        //     section: "footer"
        // })
        // fields.push({
        //     elementType: "checkbox",
        //     id: "cookie_policy",
        //     name: "cookie_policy",
        //     required: true,
        //     // invalidText: "Invalid error message.",
        //     label: `${getContents(context, "iAgree")} ${getContents(context, "cookiePolicyLinkText")}`,
        //     value: getValue("cookie_policy"),
        //     section: "footer"
        // })
        if (context.legals) {
            context.legals.forEach(item => {
                console.log("context.legals", item.field_name)
                fields.push({
                    elementType: "checkbox",
                    id: item.field_name,
                    name: item.field_name,
                    required: item.is_required,
                    // invalidText: "Invalid error message.",
                    // label: `${getContents(context, "iAgree")} ${getContents(context, "cookiePolicyLinkText")}`,
                    labelHtml: item.checkbox_label,
                    popupContent: item.content,
                    value: getValue(item.field_name),
                    section: "footer"
                })
            })
        }
    }


    const logo = (getThemeConfig(context, "registration-form.logo")) ?
        !window._.isEmpty(themeContext.registrationFormLogoDesktop) ? (
            <div className="regstration-form__logo">
                <img
                    src={themeContext.registrationFormLogoDesktop.url}
                    className="d-inline-block align-center"
                    alt={context.siteTitle} />
            </div>
        ) : null : null

    const formHelp = (getThemeConfig(context, "registration-form.footer")) ? (
        <LightText
            className="registration-form__help"
            dangerouslySetInnerHTML={{ __html: formatRegistrationFooter(getContents(context, "registrationFooter"), context) }}>
        </LightText>
    ) : null

    const registrationHeader = getContents(context, "registrationHeader") ? (
        <LightText
            className="registration-form__header"
            dangerouslySetInnerHTML={{ __html: getContents(context, "registrationHeader") }}>
        </LightText>
    ) : null

    const disableSubmit = () => {
        let disable = true
        context.event.episodes.forEach(item => {
            if (item.id === window._.get(context, "episode.id")) {
                disable = !item.is_subscriptions_open
            }
        })
        return disable
    }

    return (
        <div className="regstration-form regstration-from__wrapper">
            {logo}
            {/* {registrationHeader} */}
            <Form
                // key={`form-website-contents-${key}`}
                fields={fields}
                endpoint={getEndpoint()}
                method={"post"}
                confirmBeforeSubmit={true}
                onSubmit={(e) => onSubmit(e)}
                onChange={(key, val) => onChange(key, val)}
                submitButtonLabel={getContents(context, "register")}
                disableSubmit={disableSubmit()}
                isRegistration={true}
            />
            {/* {formHelp} */}
        </div>
    );
}

export default RegistrationForm