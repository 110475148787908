import React from "react"
import { translateChannelStatus } from "../../utils/functions"

const BlinkingIcon = () => {
    return (
        <span className="select-episode__card--blinking-icon">
            <i className="fas fa-play" />
        </span>
    )
}

const ChannelStatusIcon = ({ playerStatus }) => {
    const literalStatus = translateChannelStatus(playerStatus)

    return playerStatus ? (
        <div className={`select-episode__card--status select-episode__card--status-${literalStatus.toLowerCase()}`}>
            <BlinkingIcon /> {literalStatus}
        </div>
    ) : null
}

export default ChannelStatusIcon