import { useContext } from 'react';

import useRegistrtionForm from '../../../hooks/useRegistrtionForm';
import MainContext from '../../../context/MainContext';

const IsploraWebinarRegistrationForm = (props) => {
    const context = useContext(MainContext)

    const {
        output,
        countries,
        getValue,
        getLegals
    } = useRegistrtionForm()

    let fields = [
        {
            elementType: "hidden",
            id: "episodes",
            name: "episodes",
            value: window._.map(props.openEpisodes, (item => item.id))//[window._.map(props.openEpisodes, (item => item.id))],
        },
    ];

    const languagesList = window._.get(context, "event.languages") || []
    const countriesList = countries || []

    const languageField = languagesList.length > 1 ? {
        elementType: "select",
        id: "lang_id",
        name: "lang_id",
        required: true,
        // invalidText: "Invalid error message.",
        label: window._.get(context, "contents.language") || "Language",
        placeholder: window._.get(context, "contents.language") || "Language",
        value: getValue("lang_id") || context.language,
        options: window._.get(context, "event.languages") || []
    } : {
        elementType: "hidden",
        id: "lang_id",
        name: "lang_id",
        value: window._.get(languagesList[0], "value"),
    }

    const countryField = countriesList.length > 1 ? {
        elementType: "select",
        id: "country_id",
        name: "country_id",
        required: true,
        // invalidText: "Invalid error message.",
        label: window._.get(context, "contents.country") || "Country",
        placeholder: window._.get(context, "contents.country") || "Country",
        value: getValue("country_id"),
        options: countries || []

    } : {
        elementType: "hidden",
        id: "country_id",
        name: "country_id",
        value: window._.get(languagesList[0], "value"),
    }

    fields.push(languageField)

    // if (context.country || context.internal) {
    fields = fields.concat([
        countryField,
        {
            elementType: "text",
            id: "firstname",
            name: "firstname",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.firstname") || "firstname",
            placeholder: window._.get(context, "contents.firstname") || "firstname",
            value: getValue("firstname"),
        },
        {
            elementType: "text",
            id: "lastname",
            name: "lastname",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.lastname") || "lastname",
            placeholder: window._.get(context, "contents.lastname") || "lastname",
            value: getValue("lastname"),
        },
        {
            elementType: "email",
            id: "email",
            name: "email",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.email") || "email",
            placeholder: window._.get(context, "contents.email") || "email",
            value: getValue("email"),
        },
        {
            elementType: "text",
            id: "istitution",
            name: "istitution",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.istitution") || "istitution",
            placeholder: window._.get(context, "contents.istitution") || "istitution",
            value: getValue("istitution"),
        },
        {
            elementType: "text",
            id: "phone",
            name: "phone",
            label: window._.get(context, "contents.phone") || "Telephone No./Work No",
            placeholder: window._.get(context, "contents.phone") || "Telephone No./Work No",
            value: getValue("phone"),
        }
    ])

    if (context.legals) {
        fields = fields.concat(getLegals())
    }
    // }

    return output(fields)
}

export default IsploraWebinarRegistrationForm