import React from "react"
import classNames from "classnames"

const Button = (props) => {

    const As = props.as || "button"

    const buttonType = props.buttonType || 'primary'
    const outline = props.outline ? "outline-" : ""

    const btnClass = classNames(
        props.className, {
        btn: true,
        // "btn-lg": true,
        [`btn-${outline}${buttonType}`]: true,
        [`btn-${props.size}`]: props.size,
    });
    // const className = props.className ? [
    //     props.className,
    //     btnClass
    // ].join(" ") : btnClass

    const style = {}

    return (
        <As
            style={style}
            {...props}
            className={btnClass}
        >
            {props.children}
        </As>
    )
}

export default Button