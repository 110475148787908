import React, { useContext, useEffect, useState } from "react";
import _ from "lodash"
import classNames from "classnames"
import Input from "../Input/Input";
import Button from "../../ui/Button/Button";
import { client } from "../../api/functions";
import MainContext from "../../context/MainContext";
import { consoleLog, getContents, getThemeConfig } from "../../utils/functions";
import LightText from "../../ui/LightText/LightText";
import Spin from "../../ui/Spin/Spin";
import PreRegistrationModal from "../PreRegistrationModal/PreRegistrationModal";
import Row from "../../ui/Row/Row";
import EpisodesListEmptyModal from "../EpisodesListEmptyModal/EpisodesListEmptyModal";
// import { InlineLoading, ModalBody, ModalFooter } from "carbon-components-react";

// import Skeleton from "forms/inputs/Skeleton";
// import client from "client/client";

// Avoid rendering
// let values = {};
let rules = {};
// let loaded = false;

const Form = (props) => {

    const context = useContext(MainContext)

    /**
     * Field values
     */
    const [values, setValues] = useState({}); // Comment to avoid rendering
    // const setValues = (newValues) => {
    //   values = newValues;
    // };
    /**
     * Validation errors
     */
    const [errors, setErrors] = useState({});

    /**
     * Loaded data indicator
     */
    const [loaded, setLoaded] = useState(true); // Comment to avoid rendering
    // const setLoaded = (newLoaded) => {
    //   loaded = newLoaded;
    // };
    /**
     * Set the list of touched fields
     */
    const [touched, setTouched] = useState([]);

    /**
     * Set the list of validation rules
     */
    //   const [rules, setRules] = useState([]); // Comment to avoid rendering
    const setRules = (newRules) => {
        rules = newRules;
    };

    /**
     * Element ID
     */
    const [itemId, setItemId] = useState(null);

    /**
     * Saving indicator. Set to `true` when form is sendig data to backend
     */
    const [saving, setSaving] = useState(false);

    /**
     * Set to `true` when backend returns correct response
     */
    const [savingSuccess, setSavingSuccess] = useState(false);

    /**
     * Set to `true` when backend returns an error
     */
    const [savingErrors, setSavingErrors] = useState(false);

    /**
     * Set to `true` when backend returns an error in order to call `clearErrors` method as soon as a user touch an input field
     */
    const [sentWithErrors, setSentWithErrors] = useState(false);

    /**
     * Set to `true` when backend returns an error in order to call `clearErrors` method as soon as a user touch an input field
     */
    const [validated, setValidated] = useState(false);

    const [required, setRequired] = useState([]);

    const [preRegistrationModalOpen, setPreRegistrationModalOpen] = useState(false)
    const [episodesListEmptyModalOpen, setEpisodesListEmptyModalOpen] = useState(false)

    /**
     * Initialize values and rules arrays
     */
    useEffect(() => {
        if (!_.isEmpty(touched) && props.blockTouched) {
            return false
        }
        let fields = {};
        let rules = {};
        props.fields.forEach((element) => {
            if (!_.isEmpty(element.id)) {
                // console.log("Form", element.id, element.value)
                fields = {
                    ...fields,
                    [element.id]: element.value,
                };
                // if (element.rules) {
                //   rules = {
                //     ...rules,
                //     [element.id]: element.rules,
                //   };
                // }
            }
        });
        setValues(fields);

        setRequired(getRequired())
        // setRules(rules);
    }, [props.fields]);

    useEffect(() => {
        if (!props.blockTouched) {
            setTouched([]);
        }
    }, [props.defaultParams]);

    useEffect(() => {
        setItemId(props.itemId)
        setTouched([])
    }, [props.itemId]);

    useEffect(() => {
        if (props.onTouched) {
            props.onTouched(touched)
        }
    }, [touched]);

    const onEmailConfirmed = (e) => {
        setPreRegistrationModalOpen(false)
        if (
            props.modalOnEmptyEpisodesList &&
            context.event.episodes.filter(item => item.has_future_events).length === 0
        ) {
            setEpisodesListEmptyModalOpen(true)
        }
        else {
            onSubmit(e)
        }
    }

    const onSubmit = async (e, forceParams) => {
        e.preventDefault()

        onSavingChange(true)
        let res = null

        const params = forceParams || cleanValues()
        try {
            // if ((itemId && itemId !== "new") || props.method === "put") {
            //     res = await client.put([props.endpoint, itemId].join("/"), params);
            // } else {
            //     res = await client.post(props.endpoint, params);
            // }
            if (itemId) {
                res = await client.put(`${props.endpoint}/${itemId}`, params);
            }
            else {
                res = await client.post(props.endpoint, params);
            }
            if (res.data) {
                setSavingSuccess(true);
                if (props.checkMetas) {
                    props.checkMetas(res.data.meta)
                }
                if (props.onSubmit) {
                    props.onSubmit(res.data.data)
                }
                if (props.setAutoId) {
                    setItemId(res.data.data.id)
                }
                setTouched([])
            } else {
                setSavingErrors(true);
            }
        }
        catch (e) {
            console.error("clearStatus", window._.get(e, "response.data.errors"))
            const newErrors = window._.get(e, "response.data.errors")
            setErrors(newErrors)
            context.openFormErrorsModal(newErrors)
            clearStatus();
        }
        setValidated(true)
        // setTouched([])
        window.setTimeout(() => {
            clearStatus();
        }, 1000);
    };

    const onSavingChange = (value) => {
        setSaving(value)
        if (props.onSavingChange) {
            props.onSavingChange(value)
        }
    }

    const cleanValues = (forceValues) => {

        const params = forceValues || values
        const cleaned = (props.cleanValues) ?
            props.cleanValues(params) : params
        return {
            ...cleaned,
            ...props.defaultParams
        };
    };

    /**
     * Handle field change Event. Called when user changes manually single field value
     *
     * @param {Event} e
     * @param {String} name
     * @param {Boolean} skipTouch
     */
    const handleChange = (e, name, skipTouch) => {
        // console.log("handleChange", e, name, skipTouch)
        let value = null;
        if (e.target) {
            value = e.target.value;
            if (e.target.type === "checkbox") {
                value = e.target.checked
            }
        } else if (e.selectedItem) {
            value = e.selectedItem.id;
        } else if (e.selectedItems) {
            value = e.selectedItems.map((item) => item.id);
        } else if (e.value) {
            value = e.value;
        } else {
            value = e;
        }

        // Clear errors if is the first time user touches something after clicked `save` button
        if (sentWithErrors) {
            clearErrors();
            setSentWithErrors(false);
        }
        setValue(name, value);
        if (!skipTouch) {
            touch(name, value);
        }
        if (props.onChange) {
            props.onChange(name, value)
        }
    };

    const editValues = (name, value) => {
        const newValues = (name) ? {
            ...values,
            [name]: value,
        } : values
        return cleanValues(newValues)
    }

    // const setValue = (name, value, args) => {
    //   console.log("setValue", name, value, {
    //     ...values,
    //     [name]: value,
    //   })
    //   setValues({
    //     ...values,
    //     [name]: value,
    //   });
    // };
    const setValue = (name, value) => {
        consoleLog("touchsetvalue", name, value, {
            ...values,
            [name]: value,
        })
        setValues({
            ...values,
            [name]: value,
        });
    };

    const getValues = () => {
        return values;
    };

    const getValue = (name) => {
        return values ? values[name] : "";
    };

    const setError = (name, value, args) => {
        setErrors({
            ...errors,
            [name]: value,
        });
    };

    const clearErrors = () => {
        setErrors({});
    };

    /**
     * Reset all status
     */
    const clearStatus = () => {
        setSavingSuccess(false);
        setSavingErrors(false);
        onSavingChange(false);
    };

    /**
     * Updated touched fields list
     *
     * @param {String} name
     * @param value
     */
    const touch = (name, value) => {
        consoleLog("touch", name, value)
        setTouched({
            ...touched,
            [name]: value,
        });
    };

    const openConfirmationPopup = (e) => {
        e.preventDefault()
        // Blur button
        e.target.blur()
        setPreRegistrationModalOpen(true)
    }
    const getRequired = () => {
        return props.fields.filter((attributes) => {
            return attributes.required
        }).map(attributes => attributes.name)
    }

    const getFields = (section) => {

        return props.fields.map((attributes) => {
            if (section && section !== attributes.section) {
                return null
            }
            if (!section && attributes.section) {
                return null
            }
            let { value, id, ...item } = attributes;
            const keyPostfix = (itemId) ? [itemId, id].join("-") : id
            // if (loaded) {
            let itemProps = {
                ...item,
                id: keyPostfix,
                name: keyPostfix,
                onChange: (e, skipTouch) => {
                    if (attributes.onChange) {
                        attributes.onChange(e, skipTouch)
                    }
                    handleChange(e, id, skipTouch)
                },
                // compareValues: props.compareValues,
                original: attributes.original,
                invalid: errors[id],
                invalidText: errors[id] ? createErrorMessage(errors[id]) : null,
                defaultValue: attributes.value,
                labelHtml: attributes.labelHtml,
                popupContent: attributes.popupContent,
                value: getValue(id)
            };

            return <Input {...itemProps} key={"form-element" + keyPostfix} />;
            // } else {
            //     return null
            //     // return <Skeleton {...item} key={"form-element" + keyPostfix} />;
            // }
        }).filter(item => item !== null);
    };

    const onFormSubmit = (e) => {
        if (submitDisabled()) {
            props.onSubmitDisabled()
        }
        else if (props.confirmBeforeSubmit) {
            openConfirmationPopup(e)
        }
        else {
            onSubmit(e)
        }
    }

    const submitDisabled = (isButton) => {
        if (props.forceEnableLoginButton && isButton) {
            return false
        }
        return (required.map(item => {
            return item
        }).filter(item => (
            getValue(item) === 0 ||
            !getValue(item) ||
            getValue(item) === [] ||
            getValue(item) === null)).length > 0 ||
            props.disableSubmit
        )
    }

    let buttonContent = (
        <Button
            className={classNames("btn-form-submit col", {
                "btn-register": props.isRegistration
            })}
            role="button"
            type="button"
            onClick={onFormSubmit}
            disabled={saving || submitDisabled(true)}
        >
            {
                saving ? (
                    // <i className="fas fa-circle-notch fa-spin"></i>
                    <Spin />
                ) :
                    props.submitButtonLabel || "Save"
            }
        </Button>
    );

    if (saving) {
        // if (savingSuccess) {
        //   buttonContent = <InlineLoading description="Saved" status="finished" />;
        // } else if (savingErrors) {
        //   buttonContent = <InlineLoading description="Error" status="error" />;
        // } else {
        //   buttonContent = <InlineLoading description="Saving..." status="active" />;
        // }
    }

    const createErrorMessage = (messages) => {
        return (messages) ? messages.join(", ") : ""
    }

    const onUpdateClicked = (e) => {
        onSubmit(e, {
            ...cleanValues(),
            confirmed: 0
        })
        props.onUpdateClicked(e)
    }

    const requiredFields = (props.disableRequiredHelper) ? null : (
        <div
            className="registration-form__required"
        >
            * {getContents(context, "requiredFields")}
        </div>
    )

    const footer = (getThemeConfig(context, "registration-form.footer") && props.isRegistration) ? (
        <LightText
            className="registration-form__footer"
            dangerouslySetInnerHTML={{ __html: getContents(context, "registrationFooter") }}>
        </LightText>
    ) : null

    const footerHelp = (getThemeConfig(context, "registration-form.footer") && props.isRegistration) ? (
        <LightText
            className="registration-form__footer"
            dangerouslySetInnerHTML={{ __html: getContents(context, "registrationFooterHelpContact") }}>
        </LightText>
    ) : null

    const goToLoginForm = (props.isRegistration) ? (getContents(context, "goToLoginForm")) ? (
        <div
            onClick={() => context.setLoginModalOpen(true)}
            className="registration-form__gotologin"
            dangerouslySetInnerHTML={{ __html: getContents(context, "goToLoginForm") }}>
        </div>
    ) : null : null

    if (!props.modal) {
        const abortButton = (props.abortButton && props.onAbortClicked) ? (
            <Button
                className="btn-form-abort col"
                role="button"
                type="button"
                onClick={props.onAbortClicked}
            >
                Close
            </Button>
        ) : null

        const deleteButton = (props.deleteButton && props.onDeleteClicked) ? (
            <Button
                className="btn-form-abort col"
                role="button"
                type="button"
                onClick={props.onDeleteClicked}
            >
                Delete permanently
            </Button>
        ) : null

        const updateButton = (props.updateButton && props.onUpdateClicked) ? (
            <Button
                className="btn-form-abort col"
                role="button"
                type="button"
                onClick={onUpdateClicked}
            >
                Save for later
            </Button>
        ) : null

        const buttons = (!props.hideSubmitButton) ? (
            <div className="form-buttons">
                <div className={
                    classNames("row", {
                        inline: abortButton
                    })
                }>
                    {buttonContent}{updateButton}{abortButton}{deleteButton}
                </div>
            </div>
        ) : null

        const preRegsitrationForm = (props.isRegistration) ? (
            <PreRegistrationModal
                content={context.contents.checkYourEmailMessage}
                open={preRegistrationModalOpen}
                emailAddress={getValue("email")}
                onConfirm={(e) => onEmailConfirmed(e)}
                onCancel={() => setPreRegistrationModalOpen(false)}
                onClose={() => setPreRegistrationModalOpen(false)}
            />
        ) : null

        const episodesListEmptyModal = (props.modalOnEmptyEpisodesList) ? (
            <EpisodesListEmptyModal
                // content={context.contents.checkYourEmailMessage}
                open={episodesListEmptyModalOpen}
                // emailAddress={getValue("email")}
                onConfirm={(e) => onEmailConfirmed(e)}
                onCancel={() => setEpisodesListEmptyModalOpen(false)}
                onClose={() => setEpisodesListEmptyModalOpen(false)}
            />
        ) : null

        const form = (
            <form onSubmit={onFormSubmit}
                className={
                    classNames({
                        "was-validated": validated && !props.disableValidation
                    })
                }>
                {getFields()}
                {footerHelp}
                {getFields("footer")}
                {requiredFields}
                {
                    props.inline ? null : buttons
                }
                {goToLoginForm}
                {footer}
            </form>
        )

        const output = props.inline ? (
            <Row>
                <div className="col col-8">
                    {form}
                </div>
                <div className="col col-4 mt-2">
                    <label></label>
                    {buttons}
                </div>
            </Row>
        ) : form

        return (
            <>
                {output}
                {preRegsitrationForm}
                {episodesListEmptyModal}
            </>
        );
    }
    else {
        return null
    }
};

Form.timeouts = []

export default Form
