import { useContext } from "react";
import styled, { ThemeContext } from "styled-components"

const ModalCloseButton = (props) => {

    const themeContext = useContext(ThemeContext);
    const posotion = props.position || "absolute"

    const Btn = styled.div`
        cursor: pointer;
        position: ${posotion};
        right: 0;
        top: 0;
        line-height: 2rem;
        font-size: 1.4rem;
        width: 2rem;
        height: 2rem;
        text-align: center;
        transform: translate(50%, -50%) rotate(45deg);
        background-color: #ffffff;
        color: ${themeContext.modalsCloseButtonColor || "#000000"};
        border-radius: 50%;
        box-shadow: 0px 0px 5px 1px  #aaaaaa;
        z-index: 101;

    `
    return (
        <Btn onClick={props.onClick}>
            +
        </Btn>
    )
}

export default ModalCloseButton