import classNames from "classnames"
import React from "react"

const Row = React.forwardRef((props, ref) => {

    const style = props.relative ? {
        position: "relative"
    } : {}
    const { className, ...cleanProps } = props
    return (
        <div
            ref={ref}
            style={style}
            className={classNames("row", props.className)}
            {...cleanProps}>
            {props.children}
        </div>
    )
})

export default Row