import React from "react"
import classnames from "classnames"

const Container = (props) => {

    const { className, ...cleanProps } = props

    const containerClassName = classnames(
        props.className, {
        "container-fluid": props.fluid,
        "container": !props.fluid,
    })

    return (
        <div className={containerClassName} {...cleanProps}>
            {props.children}
        </div>
    )
}

export default Container