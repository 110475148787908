import classNames from "classnames"
import { useContext, useEffect, useState } from "react"
import { consoleLog } from "../../utils/functions"
import EpisodeTitleWithNestedAgenda from "../EpisodeTitle/EpisodeTitleWithNestedAgenda"
import "./EpisodeDetails.scss"
import NestedAgendaDetails from "./NestedAgendaDetails"
import { LIVE, PRE_LIVE } from "../../config/channelStatus"
import { ThemeContext } from "styled-components"

const EpisodeDetailsWithNestedAgenda = (props) => {

    const themeContext = useContext(ThemeContext);
    const [open, setOpen] = useState(false)
    const [liveMode, setLiveMode] = useState(false)
    const {
        episode,
        language,
        country,
        contents,
        className,
        groupedSeakers
    } = props

    useEffect(() => {
        let newLiveMode = false
        episode?.channels.forEach(element => {
            if ([LIVE, PRE_LIVE].includes(element.status)) {
                newLiveMode = true
            }
        });
        setLiveMode(newLiveMode)
    }, [episode])

    const toggle = () => {
        consoleLog("toggle")
        setOpen(open => {
            return !open
        })
    }

    const getEventVod = () => {
        return window._.get(episode, "vod_scheduling.formatted_start_at")
    }

    const getEventVodTitle = () => {
        return window._.get(contents, "vodDatetimeTitle")
    }

    const getChannelsDuration = () => {
        const maxChannelDurationInHours = window._.get(props, "episode.max_channel_duration")
        return themeContext.showVodDurationInDays ? parseInt(maxChannelDurationInHours / 24) : maxChannelDurationInHours
    }

    return (
        <div
            style={{ width: "100%", position: "relative" }}
            className={classNames(className)}
        >
            {/* {openAccordionButton} */}
            <EpisodeTitleWithNestedAgenda
                className={classNames(
                    props.detailsClassname,
                    "website-section"
                )}
                eventTitle={episode.title}
                eventSubTitle={episode.subtitle}
                onToggleAccordionClicked={toggle}
                eventLive={window._.get(episode, "live_scheduling.formatted_start_at")}
                eventLiveTitle={window._.get(contents, "liveDatetimeTitle")}
                eventVod={window._.get(episode, "vod_scheduling.formatted_start_at")}
                eventVodTitle={window._.get(contents, "vodDatetimeTitle")}
                // eventIntro={window._.get(contents, "websiteIntro")}
                upTo={window._.get(contents, "upTo")}
                hours={window._.get(contents, "hours")}
                open={open}
                episode={episode}
                fullWidth={props.fullWidth}
                hideDates={props.hideDates}
            // hideSubtitle={props.hideDates}
            />
            {
                episode.agenda.map(item => {
                    return <NestedAgendaDetails
                        language={language}
                        country={country}
                        episode={episode}
                        groupedSeakers={groupedSeakers}
                        agenda={item} />
                })
            }
            {getEventVod() && liveMode ? (
                <div className="agenda-nested-vod-details">
                    {window._.get(contents, "vodDatetimeTitle") ? (
                        <em
                            dangerouslySetInnerHTML={{ __html: getEventVodTitle() }} />
                    ) : null}
                    <br />
                    <em>
                        {/* {localTime(props.eventVod, context.event.timezone).format("HH:mm")} */}
                        {getEventVod()}
                        {
                            getChannelsDuration() ? (
                                <span> {props.upTo || "up to"} {getChannelsDuration()} {contents.hours || "hours"}</span>
                                // <span> {window._.get(props, "episode.vod_channels_duration").join("/")} hours</span>
                            ) : null
                        }
                    </em>
                </div>
            ) : null}
        </div>
    )
}

export default EpisodeDetailsWithNestedAgenda