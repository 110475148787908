import { useContext, useState, } from 'react';

import useRegistrtionForm from '../../../hooks/useRegistrtionForm';
import MainContext from '../../../context/MainContext';
import { capitalize } from '../../../utils/functions';
import useThreeStatesInternal from '../../../hooks/useThreeStatesInternal';

const MSDAPSmartMeetingRegistrationForm = (props) => {
    const context = useContext(MainContext)

    const {
        output,
        countries,
        allCountries,
        getValue,
        getLegals
    } = useRegistrtionForm()

    const {
        initial,
        onTouched,
        isInternalField
    } = useThreeStatesInternal()

    let fields = 1
        ? [
            {
                elementType: "hidden",
                id: "episodes",
                name: "episodes",
                value: window._.map(props.openEpisodes, (item => item.id))//[window._.map(props.openEpisodes, (item => item.id))],
            },
        ]
        : [];


    const languagesList = window._.get(context, "event.languages") || []
    const languageField = languagesList.length > 1 ? {
        elementType: "select",
        id: "lang_id",
        name: "lang_id",
        required: true,
        // invalidText: "Invalid error message.",
        label: window._.get(context, "contents.language") || "Language",
        placeholder: window._.get(context, "contents.language") || "Language",
        value: getValue("lang_id") || context.language,
        options: window._.get(context, "event.languages") || []
    } : {
        elementType: "hidden",
        id: "lang_id",
        name: "episodes",
        value: window._.get(languagesList[0], "value"),
    }

    fields.push(languageField)

    const getSpecialtyOptions = () => {
        const values = [
            "Microbiologist",
            "Infectious Diseases Specialist",
            "Laboratory Technician",
            "Other"
        ]

        const specialtiesTranslations = {
            "en": {
                "Microbiologist": "Microbiologist",
                "Infectious Diseases Specialist": "Infectious Diseases Specialist",
                "Laboratory Technician": "Laboratory Technician",
                "Other": "Other (please specify)"
            }
        }

        return values.map(item => {
            return {
                value: item,
                label: window._.get(specialtiesTranslations, `${getValue("lang_id")}.${item}`) || window._.get(specialtiesTranslations, `en.${item}`)
            }
        })
    }

    if (context.event.has_internal) {
        fields.push(isInternalField({
            context: context,
            getValue: (e) => getValue(e)
        }))
    }


    // if (context.country || context.internal) {
    fields = fields.concat([
        {
            elementType: "text",
            id: "firstname",
            name: "firstname",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.firstname") || "firstname",
            placeholder: window._.get(context, "contents.firstname") || "firstname",
            value: getValue("firstname"),
        },
        {
            elementType: "text",
            id: "lastname",
            name: "lastname",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.lastname") || "lastname",
            placeholder: window._.get(context, "contents.lastname") || "lastname",
            value: getValue("lastname"),
        },
    ])

    if (!initial) {
        if (!parseInt(context.internal)) {
            fields = fields.concat([
                {
                    elementType: "select",
                    id: "country_id",
                    name: "country_id",
                    required: true,
                    // invalidText: "Invalid error message.",
                    label: window._.get(context, "contents.country") || "Country",
                    placeholder: window._.get(context, "contents.country") || "Country",
                    value: getValue("country_id") || context.country,
                    disabled: parseInt(context.internal),
                    options: countries || []
                },
                {
                    elementType: "text",
                    id: "istitution",
                    name: "istitution",
                    required: true,
                    label: window._.get(context, "contents.istitution") || "Istitution",
                    placeholder: capitalize(window._.get(context, "contents.istitution")) || "Istitution",
                    value: getValue("istitution"),
                },
                {
                    elementType: "select",
                    id: "specialty",
                    name: "specialty",
                    required: true,
                    // invalidText: "Invalid error message.",
                    label: window._.get(context, "contents.specialty") || "Specialty",
                    placeholder: window._.get(context, "contents.specialty") || "Specialty",
                    value: getValue("specialty"),
                    // disabled: parseInt(context.internal),
                    options: getSpecialtyOptions()
                }]
            )
            if (getValue("specialty") === "Other") {
                fields.push({
                    elementType: "text",
                    id: "other_specialty",
                    name: "other_specialty",
                    required: true,
                    // invalidText: "Invalid error message.",
                    label: window._.get(context, "contents.otherSpecialty") || "Other specialty",
                    placeholder: window._.get(context, "contents.otherSpecialty") || "Other specialty",
                    value: getValue("other_specialty"),
                })
            }
        }
        else {
            fields = fields.concat([
                {
                    elementType: "select",
                    id: "country_id_of_work",
                    name: "country_id_of_work",
                    required: true,
                    // invalidText: "Invalid error message.",
                    label: window._.get(context, "contents.countryOfWork") || "Country of work",
                    placeholder: window._.get(context, "contents.countryOfWork") || "Country of work",
                    value: getValue("country_id_of_work") || context.country,
                    disabled: !parseInt(context.internal),
                    options: allCountries || []
                }]
            )
            if (getValue("department") === "Other") {
                fields.push({
                    elementType: "text",
                    id: "other_department",
                    name: "other_department",
                    required: true,
                    label: window._.get(context, "contents.otherDepartment") || "Other department",
                    placeholder: window._.get(context, "contents.otherDepartment") || "Other department",
                    value: getValue("other_department"),
                })
            }
        }
    }

    fields.push(
        {
            elementType: "email",
            id: "email",
            name: "email",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.email") || "email",
            placeholder: window._.get(context, "contents.email") || "email",
            value: getValue("email"),
        }
    )

    if (context.legals) {
        fields = fields.concat(getLegals())
    }
    // }

    return output(fields, {
        onTouched: (e) => onTouched(e)
    })
}

export default MSDAPSmartMeetingRegistrationForm