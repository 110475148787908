import React, { useState, useContext, useEffect } from 'react';
import classNames from "classnames"
import MainContext from '../../context/MainContext';
import wrapSection from '../../hoc/wrapSection';
import Row from '../../ui/Row/Row';
import "./Speakers.scss"
import { getThemeConfig } from '../../utils/functions';
import Spin from '../../ui/Spin/Spin';
import SpeakerBioModal from '../SpeakerBioModal/SpeakerBioModal';
import { CSSTransition } from 'react-transition-group';

export const SpeakerCard = (props) => {

    const [modalOpen, setModalOpen] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const [visible, setVisible] = useState(false)
    const { isGridLayout, textCentered, grid } = props

    useEffect(() => {
        window.setTimeout(() => {
            setVisible(true)
        }, props.timeout)
    }, [])

    const img = (
        <div style={{
            backgroundImage: `url(${props.media_url || "https://via.placeholder.com/200"})`,
        }} className="speaker-card__picture" />
    )
    let content = null

    if (isGridLayout) {

        const openPopupButton = (
            <div
                className="speaker-card__open-popup"
                onClick={() => setModalOpen(true)}
            >
                +
            </div>
        )
        content = (
            <div className="card speaker-card" style={{ border: 0 }}>
                {openPopupButton}
                {img}
                <div className={classNames("speaker-card__body", {
                    "text-center": textCentered
                })}>
                    <h5 className="card-title  speaker-card__title">{props.firstname} {props.lastname}</h5>
                    <p className="card-text speaker-card__description"
                        dangerouslySetInnerHTML={{ __html: props.role }} />
                    {/* <p className="card-text speaker-card__description">{props.description}</p> */}
                    {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                </div>
            </div>
        )
        const colRules = grid ? {
            [`col-xs-${grid.mobile}`]: grid.mobile,
            [`col-md-${grid.tablet}`]: grid.tablet,
            [`col-lg-${grid.desktop}`]: grid.desktop && !props.fullWidth,
        } : null

        return (
            <>
                <CSSTransition
                    // key={`speaker - ${item.id} `}
                    classNames="transition"
                    unmountOnExit
                    in={visible}
                    timeout={props.timeout}
                >
                    <div className={classNames("speaker-wrapper", colRules, {
                        [`col-lg-12`]: props.fullWidth,
                    })}>
                        {content}
                    </div>
                </CSSTransition>
                <SpeakerBioModal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    {...props}
                />
            </>
        )

    }
    const toggle = () => {
        setExpanded(expanded => {
            return !expanded
        })
    }

    const openAccordionButton = props.description ? (
        <div
            className="speaker-card__open-accordion"
            onClick={toggle}
        >
            {expanded ? "-" : "+"}
        </div>
    ) : null

    content = (
        <div className="align-self-end position-relative">
            {openAccordionButton}
            <div className={classNames("mt-0 pr-3 speaker-card__title")}>
                {props.firstname} {props.lastname}
            </div>
            <div className={classNames("mt-2 speaker-card__role")}
                dangerouslySetInnerHTML={{ __html: props.role }}
            />
            <div className={classNames("text-justified speaker-card__bio speaker-card__bio--expandable", {
                expanded: expanded
            })}>
                <p
                    dangerouslySetInnerHTML={{ __html: props.description }}
                />
            </div>
        </div>
    )

    return (
        <div className="row mb-3">
            <div className={classNames("col-3")}>
                <div className="media">
                    {img}
                </div>
            </div>
            <div className={classNames("col-9")}>
                {content}
            </div>
        </div>
    )
}

const Speakers = ({ hiddenItems = [], ...props }) => {

    // let lastConfig = []
    const context = useContext(MainContext)

    const layout = getThemeConfig(context, "speakers")

    const isGridLayout = (!window._.isEmpty(layout)) ? layout.theme === "grid" : false
    // const thumbsRounded = (!window._.isEmpty(layout)) ? layout['thumbs-shape'] === "round" : false
    const textCentered = (!window._.isEmpty(layout)) ? layout["text-align"] === "center" : false
    const grid = (!window._.isEmpty(layout)) ? layout["grid"] : null
    // const thumbBordered = (!window._.isEmpty(layout)) ? layout["thumbs-border"] : false

    const speakers = (
        props.items === undefined ||
        props.items === null ||
        props.items?.length === 0
    ) ? (context.speakers || []) : props.items

    let cards = <div><Spin /></div>
    if (!context.loadingContents) {
        cards = speakers?.filter(item => !hiddenItems?.includes(item.id))?.map((item, index) => {
            const pair = index % 2
            const timeout = (200 * index)
            return (
                <SpeakerCard
                    {...item}
                    fullWidth={props.fullWidth}
                    isGridLayout={isGridLayout}
                    // thumbsRounded={thumbsRounded}
                    textCentered={textCentered}
                    // thumbBordered={thumbBordered}
                    layout={layout}
                    pair={pair}
                    grid={grid}
                    timeout={timeout}
                    key={`speaker - ${item.id} `}
                />
            )
        })
    }

    return (isGridLayout) ? (
        <Row>
            {cards}
        </Row>
    ) : cards

}

Speakers.className = "speakers"
export default wrapSection(Speakers)