/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import MainContext from '../../context/MainContext';
import useFaqModal from '../../hooks/useFaqModal';
import Button from '../../ui/Button/Button';
import LogoutButton from '../../ui/LogoutButton/LogoutButton';
import Spin from '../../ui/Spin/Spin';
import EvaluationFormModal from '../EvaluationFormModal/EvaluationFormModal';
import Logo from '../Logo/Logo';
import "./Navbar.scss"
import ForceEvaluationFormModal from '../ForceEvaluationFormModal/ForceEvaluationFormModal';
import { getAuthToken, utc } from '../../utils/functions';
import classNames from 'classnames';

const Navbar = React.forwardRef((props, ref) => {

    const context = useContext(MainContext)
    const location = useLocation();
    const themeContext = useContext(ThemeContext);
    const [logged, setLogged] = useState(false)

    const [evaluationFormAnswered, setEvaluationFormAnswered] = useState(false)
    const [evaluartionFormModalOpen, setEvaluartionFormModalOpen] = useState(false)
    const [forceEvaluationFormModalOpen, setForceEvaluationFormModalOpen] = useState(false)
    const navbar = useRef()
    const {
        _faqMOdal,
        _openFaqModal,
    } = useFaqModal()

    const { episode, user, contents, evaluationFormEnabled } = props

    //     forceEfStartAt={episode.force_ef_start_at}
    // forceEfFinishAt={episode.force_ef_finish_at}

    useEffect(() => {
        setNavHeight()
        checkScrolled()
        document.addEventListener("scroll", (e) => {
            checkScrolled()
        })
        // if (props.hasUnansweredQuestions) {
        //     setEvaluartionFormModalOpen(true)
        // }
    }, [])

    useEffect(() => {
        if (props.hasUnansweredQuestions &&
            props.isVirtualWorld &&
            !props.isCtio2022 &&
            location.pathname !== "/evaluation"
        ) {
            setEvaluartionFormModalOpen(true)
        }
    }, [props.hasUnansweredQuestions, props.isVirtualWorld])

    useEffect(() => {
        if (!window._.isEmpty(context.user) && !logged) {
            setLogged(true)
        }
        else if (window._.isEmpty(context.user) && logged) {
            setLogged(false)
        }

    }, [context.user])

    const checkScrolled = () => {
        if (navbar.current) {
            if (window.scrollY > 50) {
                navbar.current.classList.add("scrolled")
            }
            else {
                navbar.current.classList.remove("scrolled")
            }
        }
    }

    const setNavHeight = (newHeight) => {
        if (newHeight === undefined || newHeight === null) {
            newHeight = navbar.current.offsetHeight
        }
        context.setNavHeight(newHeight)
    }

    const openEvaluartionFormModal = () => {
        setEvaluartionFormModalOpen(true)
    }

    const closeEvaluartionFormModal = () => {
        setEvaluartionFormModalOpen(false)
        props.onEvaluationFormModalClose()
    }

    const execLogout = () => {
        if (props.onLogoutClicked) {
            props.onLogoutClicked()
        }
    }

    const handleLogoutButtonClicked = () => {
        if (!episode) {
            return false
        }
        const now = utc()
        // console.log("now", now.format(), utc(episode.force_ef_start_at).format(), utc(episode.force_ef_finish_at).format())
        // console.log("now", window._.get(user, `answers.${episode.id}.answered`))//window._.get(props.answered, episode.id)
        // console.log("now", now.isSameOrBefore(utc(episode.force_ef_finish_at)))//window._.get(props.answered, episode.id)
        // console.log("now", !(!episode.force_ef_start_at && !episode.force_ef_finish_at))//window._.get(props.answered, episode.id)
        // console.log("now", episode.force_ef_start_at, episode.force_ef_finish_at)//window._.get(props.answered, episode.id)
        // console.log("now", episode)//window._.get(props.answered, episode.id)
        if (!episode.force_ef_start_at && !episode.force_ef_finish_at) {
            execLogout()
            return
        }

        if (
            evaluationFormEnabled &&
            !window._.get(user, `answers.${episode.id}.answered`) &&
            !evaluationFormAnswered &&
            (!episode.force_ef_start_at || now.isSameOrAfter(utc(episode.force_ef_start_at))) &&
            (!episode.force_ef_finish_at || now.isSameOrBefore(utc(episode.force_ef_finish_at)))
        ) {
            // open  ForceEvaluationFormModal
            setForceEvaluationFormModalOpen(true)
        }
        else {
            execLogout()
        }
    }

    // ForceEvaluationFormModal click ok button
    const handleConfirmOpenEvaluationForm = () => {
        setForceEvaluationFormModalOpen(false)
        setEvaluartionFormModalOpen(true)
    }

    // ForceEvaluationFormModal click cancel button
    const handleDenyOpenEvaluationForm = () => {
        setForceEvaluationFormModalOpen(false)
        execLogout()
    }

    const Nav = styled.nav`
        background-color: ${themeContext.navbarBackgroundColor || "transparent"};
        z-index: 801;
        &.scrolled {
            background-color: ${themeContext.navbarBackgroundColor || themeContext.defaultBackgroundColor};
            ${themeContext.shadowColor ? `box-shadow:0 0.25rem 1rem -0.25rem ${themeContext.shadowColor};` : ""}
        }
        @media (max-width: 991px) { 
            background-color:${themeContext.navbarBackgroundColor || themeContext.defaultBackgroundColor};
            ${themeContext.shadowColor ? `box-shadow:0 0.25rem 1rem -0.25rem ${themeContext.shadowColor};` : ""}
        }   
        `;

    const logo = (
        <Logo sectionName={"navbar"} />
    )

    const logXButton = (logged) ? (
        <li className="nav-item dropdown" style={{ position: "relative" }}>
            <LogoutButton
                onLogoutClicked={handleLogoutButtonClicked}
                mainButtonContent={`${context.user?.firstname} ${context.user?.lastname}`}
                logoutButtonContent={context.loggingOut ? <Spin /> : window._.get(context, "contents.logout")}
            />
            {/* <Button
                size={"sm"}
                onClick={props.onLogoutClicked}
                className="my-2 my-sm-0"
            >{context.loggingOut ? <Spin /> : window._.get(context, "contents.logout")}</Button> */}
        </li>
    ) : (
        <li className="nav-item active">
            <Button
                size={"sm"}
                onClick={() => context.setLoginModalOpen(true)}
                className="my-2 my-sm-0 btn-header-login"
            >{window._.get(context, "contents.login")}</Button>
        </li>
    )

    const changeEpisodeButton = (window._.get(context, "user") && window._.get(context, "user.episodes")) && Object.values(window._.get(context, "user.episodes"))?.filter(item => item.has_future_events)?.length > 1 ? (
        <li className="nav-item">
            <Button
                size={"sm"}
                onClick={() => context.setSelectEpisodeModalOpen(true)}
                className="btn btn-info my-2 mr-2 my-sm-0"
            >{window._.get(context, "contents.changeEpisode") || "Change Room"}</Button>
        </li>
    ) : null

    const faqButton = (context.faqs && !window._.isEmpty(context.faqs)) ? (
        <li className="nav-item active">
            <Button
                size={"sm"}
                onClick={() => onFaqButtonClicked()}
                className="btn btn-info my-2 mr-2 my-sm-0 btn-faq"
            >{window._.get(context, "contents.faqTitle") || "F.A.Q."}</Button>
        </li>
    ) : null

    const languagesList = window._.get(context, "event.languages") || []
    const languagesButton = languagesList?.length > 1 ? (
        <li className="nav-item dropdown" style={{ position: "relative" }}>
            <Button
                size={"sm"}
                className="my-2 my-sm-0  mr-2 dropdown-toggle"
                data-toggle="dropdown"
            >
                {context.language.toUpperCase()}
            </Button>
            <div className="dropdown-menu dropdown-menu-right">
                {
                    languagesList.map(lang => (
                        <a
                            className="dropdown-item"
                            onClick={() => context.setLanguage(lang.id)}
                        >
                            {lang.label}
                        </a>
                    ))
                }
            </div>
        </li>
    ) : null

    const onFaqButtonClicked = () => {
        // setFaqsModalOpen(true)
        _openFaqModal()
    }

    const getEvaluationQuestionsCount = () => {
        const categories = window._.get(episode, `evaluation_questions`)
        let questions = []
        if (categories) {
            categories.forEach(element => {
                questions = questions.concat(element.evaluationquestions?.filter(q => q.lang_id === context.language))
            })
        }
        return questions.length
    }
    const evaluationButton = (
        evaluationFormEnabled &&
        getEvaluationQuestionsCount() &&
        location.pathname !== "/evaluation"
    ) ? (
        <li className="nav-item active">
            <Button
                size={"sm"}
                onClick={() => openEvaluartionFormModal()}
                className="btn btn-info my-2 mr-2 my-sm-0"
            >{window._.get(context, "contents.evaluationFormTitle") || "Evaluation"}</Button>
        </li>
    ) : null

    const faqMOdal = getEvaluationQuestionsCount() ? (
        <>
            {/* <FaqsModal
                open={faqsModalOpen}
                setFaqsModalOpen={(val) => setFaqsModalOpen(val)} /> */}
            {
                evaluationFormEnabled ? (
                    <>
                        <EvaluationFormModal
                            user={user}
                            closeEvaluartionFormModal={closeEvaluartionFormModal}
                            setEvaluationFormAnswered={setEvaluationFormAnswered}
                            answered={
                                window._.get(user, `answers`)
                                // window._.get(user, `answers.${episodeId}.answered`)
                                // ||
                                // evaluationFormAnswered
                            }
                            alreadyAnsweredMessage={contents.alreadyAnsweredMessage}
                            questionsCategories={window._.get(episode, `evaluation_questions`)}
                            open={evaluartionFormModalOpen}
                        />
                        <ForceEvaluationFormModal
                            open={forceEvaluationFormModalOpen}
                            onClose={handleDenyOpenEvaluationForm}
                            onConfirm={handleConfirmOpenEvaluationForm}
                            content={window._.get(context, "contents.forceEfPopupContent")}
                            okButtonText={window._.get(context, "contents.forceEfPopupConfirmButtonText")}
                            koButtonText={window._.get(context, "contents.forceEfPopupDenyButtonText")}
                        />
                    </>
                ) : null
            }
        </>
    ) : null

    let home = "/"

    if (context.isPreview) {
        home = `${home}?preview=${context.isPreview}`
    }

    let buttonSlots = {
        0: [],
        10: [logXButton],
        20: [],
        30: [languagesButton],
        40: [],
        50: [changeEpisodeButton],
        60: [],
        70: [faqButton],
        80: [],
        90: [evaluationButton],
        100: []
    }

    const buttonSlotsKeys = Object.keys(buttonSlots)

    if (props.episode?.navbar_custom_buttons_configuration?.length) {
        props.episode.navbar_custom_buttons_configuration.forEach(item => {

            if (!item.alwaysVisible && !logged) {
                return;
            }

            const label = item.label[props.language] || item.label[context.event.default_lang] || item.label["en"]

            var mapObj = {
                "{EVENT_CODE}": context.event?.id,
                "{USER_TOKEN}": getAuthToken()
            };

            const href = item.href.replace(/\{EVENT_CODE\}|\{USER_TOKEN\}/gi, function (matched) {
                return mapObj[matched];
            });

            const btnElement = (
                <li className="nav-item active">
                    <a
                        role="button"
                        href={href}
                        target={item.target}
                        style={item.style}
                        className={classNames("btn btn-sm my-2 mr-2 my-sm-0", item.class)}
                    >{label}</a>
                </li>
            )

            buttonSlots[item.position || 100].push(btnElement)
        })
    }


    let buttons = []
    buttonSlotsKeys.reverse().forEach(k => {
        buttons.push(buttonSlots[k])
    })
    return (
        <>
            <Nav className="navbar fixed-top navbar-expand-lg  navbar-light" ref={navbar}>

                <Link to={home} className="navbar-brand">{logo}</Link>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="nav navbar-nav ml-auto" style={{
                        flexDirection: "row"
                    }}>
                        {buttons}
                        {/* {evaluationButton}
                        {faqButton}
                        {changeEpisodeButton}
                        {languagesButton}
                        {logXButton} */}
                    </ul>
                </div>
            </Nav>
            {faqMOdal}
            {faqButton ? _faqMOdal : null}
        </>
    )
})

export default Navbar