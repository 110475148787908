import classNames from 'classnames';
import React, { useContext } from 'react';
import MainContext from '../../context/MainContext';
import Row from '../../ui/Row/Row';
import { localTime } from '../../utils/functions';
import "./EpisodeTitle.scss"

const NestedAgendaTitle = (props) => {

    const { agenda, showDate } = props
    const context = useContext(MainContext)
    // const speakersLayoutCols = getThemeConfig(context, "speakers.cols")
    // const agendaLayoutCols = getThemeConfig(context, "agenda.cols")

    // let mobile = 12
    // let tablet = 12
    // let desktop = 12

    // try {
    //     mobile = speakersLayoutCols.mobile + agendaLayoutCols.mobile
    //     tablet = speakersLayoutCols.tablet + agendaLayoutCols.tablet
    //     desktop = speakersLayoutCols.desktop + agendaLayoutCols.desktop
    // }
    // catch (e) { }

    const title = (
        <span className='agenda-parent-title'>
            <span className='agenda-parent-title__timing'>
                {
                    showDate ?
                        localTime(agenda.start_at, context.event.timezone).format("HH:mm") :
                        `${agenda.duration} ${window._.get(context, "contents.minutes") || 'minutes'}`
                }
            </span>
            <span className='agenda-parent-title__title'>
                {agenda?.title}
            </span>
        </span>
    )
    const intro = title ? (
        <div className={classNames("episode-header col-12", props.className, {
            popup: props.popup
        })}>
            <Row className="episode-header__dates">
                {
                    props.hideDates ? null : (
                        <div className={classNames("col-12")}>
                            {
                                title ? (
                                    <>
                                        {/* <div>{liveStartDate ? localTime(liveStartDate, context.event.timezone).format("dddd") : null}</div> */}
                                        <div>{title}</div>
                                    </>
                                ) : null
                            }
                            {/* {
                                vodStartDateFormatted && !liveStartDateFormatted ? (
                                    // vodStartDateFormatted && (liveStartDateFormatted !== vodStartDateFormatted) ? (
                                    <>
                                        <div>{vodStartDate ? localTime(vodStartDate, context.event.timezone).format("dddd") : null}</div>
                                        <div>{vodStartDateFormatted}</div>
                                    </>
                                ) : null
                            } */}
                        </div>
                    )
                }
            </Row>
        </div>
    ) : null
    return intro
}

NestedAgendaTitle.className = "nested-agenda-title"
NestedAgendaTitle.fluid = true
export default NestedAgendaTitle