import classNames from "classnames";
import moment from "moment-timezone";
import React, { useEffect, useState, useContext } from "react"
import { getPlayerSources } from "../../api/functions";
import { LIVE, VOD } from "../../config/channelStatus";
import MainContext from "../../context/MainContext";
import ChannelStatusIcon from "../../ui/ChannelStatusIcon/ChannelStatusIcon";
import { utc } from "../../utils/functions";
import TheoPlayer from "../TheoPlayer/TheoPlayer";
import "./SelectEpisodeCard.scss"

let liveLocked = true

const SelectEpisodeCard = ({ data, onEpisodeSelected, borderColor, count = 0 }) => {
    const context = useContext(MainContext)
    const [playerSource, setPlayerSource] = useState()
    const [playerStatus, setPlayerStatus] = useState()
    const [playerChannel, setPlayerChannel] = useState()
    const getPlaylist = async (episodeId) => {
        const res = await getPlayerSources({
            episode_id: episodeId,
            lang_id: context.language
        })
        updateSource(res)
    }

    const updateSource = async (getPlaylistResponse) => {
        let source = {}
        if (getPlaylistResponse?.sources?.length > 1) {
            source = {
                sources: getPlaylistResponse.sources[0]
            }
        }
        else {
            source = {
                sources: getPlaylistResponse?.playlist[getPlaylistResponse.status]
            }
        }
        setPlayerSource(source);
        setPlayerChannel(getPlaylistResponse.channel);
        setPlayerStatus(getPlaylistResponse.status)
    }

    useEffect(() => {
        getPlaylist(data.id)
    }, [data])

    const onReadyStateChange = async (player) => {
        if ([LIVE].includes(playerStatus) && playerChannel?.is_fake_live) {
            const diff = moment().diff(utc(context.episode.live_started_at), "seconds")
            player.currentTime = diff;
            liveLocked = false
        }
        else {
            liveLocked = true
        }
    }

    const className = classNames("col-12 mb-3", {
        "col-lg-4": count <= 2,
        "col-lg-3": count > 2
    })

    return (
        <div className={className}>
            <div className="select-episode__card"
                style={{
                    borderColor
                }}
                onClick={() => onEpisodeSelected(data.id)}
            >
                {
                    [LIVE, VOD].includes(playerStatus) && <ChannelStatusIcon playerStatus={playerStatus} />
                }
                <div className="select-episode__card--body">
                    <div className="select-episode__card--player">
                        <TheoPlayer
                            source={playerSource}
                            hideControls={true}
                            hidePip={true}
                            onReadyStateChange={(e, player) => { onReadyStateChange(player) }}
                        />
                    </div>
                    <div className="select-episode__card--footer px-3 py-1">
                        <div className="select-episode__card--title">
                            {data.title}
                        </div>
                        {
                            data.has_future_live_events ? (
                                <div className="select-episode__card--date">
                                    <div
                                        className="select-episode__card--description"
                                        dangerouslySetInnerHTML={{ __html: window._.get(context, "contents.liveDatetimeTitle") }}
                                    />
                                    <div className="select-episode__card--date">
                                        {window._.get(data, "live_scheduling.formatted_start_at")}
                                    </div>
                                </div>
                            ) : data.has_future_vod_events ? (
                                <div className="select-episode__card--date">
                                    <div
                                        className="select-episode__card--description"
                                        dangerouslySetInnerHTML={{ __html: window._.get(context, "contents.vodDatetimeTitle") }}
                                    />
                                    <div className="select-episode__card--date">
                                        {window._.get(data, "vod_scheduling.formatted_start_at")}
                                    </div>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectEpisodeCard