import React from 'react';
import wrapSection from '../../hoc/wrapSection';
import useFaqModal from '../../hooks/useFaqModal';
import { cleanFaqPopup } from '../../utils/functions';

const Contacts = (props) => {

    const content = cleanFaqPopup(props.content)
    const {
        _faqMOdal,
        _openFaqModal,
    } = useFaqModal()

    return content ? (
        <div className="contacts-content">
            {
                content.map((row, index) => {

                    return (
                        <p key={`contacts-row-${index}`}>
                            {row.before ? (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: row.before
                                    }}
                                />
                            ) : null}
                            {
                                row.linkContent ? (
                                    <a
                                        className="faqs-popup-btn"
                                        onClick={() => _openFaqModal()}>
                                        {row.linkContent}
                                    </a>
                                ) : null
                            }
                            {row.after ? (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: row.after
                                    }}
                                />
                            ) : null}

                        </p>
                    )
                })
            }
            {
                _faqMOdal
            }
        </div>
    ) : null
}

Contacts.className = "contacts"
Contacts.hideIfEmpty = true
export default wrapSection(Contacts)