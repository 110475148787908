import React, { useEffect, useState, useContext } from "react"
import AdamModal from "../AdamModal/AdamModal";
import Input from "../Input/Input";
import MainContext from "../../context/MainContext";
import Button from "../../ui/Button/Button";
import classNames from "classnames";
import Spin from "../../ui/Spin/Spin";

const LoginModal = ({ hideChairmanLoginButton, ...props }) => {
    const [modalOpen, setModalOpen] = useState(false)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [isChairman, setIsChairman] = useState(false)
    const context = useContext(MainContext)

    useEffect(() => {
        setModalOpen(props.open)
    }, [props.open])

    const toggleLoginAsChairman = () => {
        setIsChairman(isChairman => !isChairman)
    }

    const loginForm = (
        <div className="form">
            <div>
                <Input
                    id="login_id"
                    name="login_id"
                    elementType={"email"}
                    label={"email"}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder={window._.get(context, "contents.emailBoxLoginPlaceholder")}
                />
            </div>
            {isChairman ? (
                <>
                    <div>
                        <Input
                            id="password"
                            name="password"
                            elementType={"password"}
                            label={"Password"}
                            onChange={(e) => setPassword(e.target.value)}
                            // value={password}
                            placeholder="Password"
                        />
                    </div>
                </>
            ) : null}
            {
                !hideChairmanLoginButton && (
                    <div
                        style={{ marginTop: "2rem" }}
                        onClick={toggleLoginAsChairman}
                        dangerouslySetInnerHTML={{
                            __html: isChairman ? window._.get(context, "contents.iamNotAChairman") : window._.get(context, "contents.loginAsChairman")
                        }} />
                )
            }

            {
                context.loginErrors ? (
                    <div
                        className="text-danger"
                        style={{ marginTop: "1rem" }}>
                        {context.loginErrors.join(", ")}
                    </div>
                ) : null
            }
            <div style={{ marginTop: "1rem" }}>
                {
                    context.alreadyLoggedError ? (
                        <>
                            <div>
                                {window._.get(context, "contents.closeEveryOpenSessionMessage")}
                            </div>
                            <Button
                                disabled={!email}
                                className="btn-form-submit btn-login-modal"
                                role="button"
                                onClick={() => props.onForceLogoutClicked()}>
                                {
                                    context.loggingOut ? (
                                        <Spin />
                                        // <i className="fas fa-circle-notch fa-spin"></i>
                                    ) : window._.get(context, "contents.closeEveryOpenSessionButtonText")
                                }
                            </Button>
                        </>
                    ) : (

                        <Button
                            disabled={!email}
                            className="btn-form-submit btn-login-modal"
                            role="button"
                            onClick={() => props.onLoginClicked({
                                email: email,
                                password: isChairman ? password : ""
                            })}>
                            {
                                context.loginWaiting ? (
                                    <Spin />
                                    // <i className="fas fa-circle-notch fa-spin"></i>
                                ) : window._.get(context, "contents.login")
                            }
                        </Button>

                    )
                }
            </div>
        </div>
    )

    // const logo = !window._.isEmpty(themeContext.mediaHeaderLogoDesktop) ? (
    //     <img
    //         src={themeContext.mediaHeaderLogoDesktop.url}
    //         // width="30"
    //         // height="30"
    //         className="d-inline-block align-center navbar-logo mb-4"
    //         alt={context.siteTitle} />
    // ) : context.siteTitle

    const sectionMainTitle = props.formTitle ? (
        <div className={classNames("website-section__title")}>
            {props.formTitle}
        </div>
    ) : null

    const grid = (
        <div className="modal-login__wrapper">
            <div className="modal-login__event-data d-none d-sm-block">
                <div className="modal-login__event-content">
                    {/* {logo} */}
                    <div
                        className="event-header__subtitle"
                    >{props.eventSubTitle}</div>
                    <div
                        className="event-header__title"
                    >{props.eventTitle}</div>
                </div>
            </div>
            <div className="modal-login__form">
                {sectionMainTitle}
                <div className="modal-login__form--wrapper">
                    {loginForm}
                </div>
            </div>
        </div>
    )

    const modal = (
        <AdamModal
            content={grid}
            open={modalOpen}
            fluidHeader={true}
            headerClass="d-none d-sm-block"
            transparentHeader={true}
            onClose={() => context.setLoginModalOpen(false)}
        />
    )

    return modal
}

export default LoginModal