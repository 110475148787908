import { useState } from 'react';

function useThreeStatesInternal() {

    const [initial, setInitial] = useState(true);

    const onTouched = (e) => {
        const keys = Object.keys(e)
        console.log("setTouched", e, keys)
        if (keys.includes("is_internal")) {
            setInitial(false)
        }
    }

    const isInternalField = ({ context, getValue }) => {
        return {
            elementType: "select",
            id: "is_internal",
            name: "is_internal",
            required: true,
            // invalidText: "Invalid error message.",
            placeholder: initial ? window._.get(context, "contents.internal") || "Internal" : null,
            label: window._.get(context, "contents.internal") || "Internal",
            value: getValue("is_internal"),
            options: [{
                value: 0,//internal_false_value_label
                label: window._.get(context, "contents.internalFalseValueLabel") || "External",
            }, {
                value: 1,//internal_false_value_label
                label: window._.get(context, "contents.internalTrueValueLabel") || "Internal",
            }]
        }
    }

    return {
        initial: initial,
        onTouched: (e) => onTouched(e),
        isInternalField: (e) => isInternalField(e)
    };
}

export default useThreeStatesInternal