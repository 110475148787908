import React, { useEffect, useState, useContext, useRef } from "react"
import AdamModal from "../AdamModal/AdamModal"
import MainContext from "../../context/MainContext"
import Container from "../../ui/Container/Container"
import Row from "../../ui/Row/Row"
import Button from "../../ui/Button/Button"

const WaitingRoomModal = (props) => {
    const [modalOpen, setModalOpen] = useState(false)
    const [playing, setPlaying] = useState(false)
    const audio = useRef()
    const context = useContext(MainContext)

    useEffect(() => {
        setModalOpen(props.open)
        // if (props.open && !playing) {
        //     setTimeout(() => {
        //         togglePlaying()
        //     }, 2000)
        // }
    }, [props.open])

    const togglePlaying = () => {
        if (playing) {
            audio.current.pause()
            setPlaying(false)
        }
        else {
            audio.current.play().then(() => {
                setPlaying(true)
            }).catch(error => {
                console.log('Unable to play the video, User has not interacted yet.');
            })
        }
    }

    const grid = (
        <Container fluid={true} className="text-center virtual-world-modal">

            <Row className="my-3">
                <div
                    className="col virtual-world-modal__countdown my-3">
                    {
                        props.episode && (
                            <>
                                <div
                                    className="my-3"
                                    dangerouslySetInnerHTML={{
                                        __html: window._.get(context, "contents.waitingRoomPopupContent", "")
                                    }} />
                                {
                                    props.episode.waitingRoom?.jingle_media && (
                                        <>
                                            <div>
                                                <Button onClick={togglePlaying}>
                                                    {
                                                        playing ?
                                                            window._.get(context, "contents.waitingRoomPopupPauseButtonText", "Pause") :
                                                            window._.get(context, "contents.waitingRoomPopupPlayButtonText", "Play")
                                                    }
                                                </Button>
                                            </div>
                                            <audio loop ref={audio}>
                                                <>
                                                    <source src={props.episode.waitingRoom.jingle_media.url} type={props.episode.waitingRoom.jingle_media.mime} />
                                                    Your browser does not support the audio element.</>
                                            </audio>
                                        </>
                                    )
                                }
                            </>
                        )
                    }
                </div>
            </Row>
        </Container >
    )

    const modal = (
        <AdamModal
            content={grid}
            open={modalOpen}
            // fluidHeader={true}
            // headerClass="d-none d-sm-block"
            // transparentHeader={true}
            onClose={() => context.closeWaitingRoomModal()}
        />
    )

    return modal
}

export default WaitingRoomModal