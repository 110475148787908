/* eslint-disable jsx-a11y/alt-text */
import classNames from 'classnames';
import React, { useContext, useRef, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import MainContext from '../../context/MainContext';
import Row from '../../ui/Row/Row';
import { getThemeConfig } from '../../utils/functions';

const EventTitle = (props) => {

    const context = useContext(MainContext)
    const speakersLayoutCols = getThemeConfig(context, "speakers.cols")
    const titleLayoutCols = getThemeConfig(context, "title.cols")
    const agendaLayoutCols = getThemeConfig(context, "agenda.cols")
    const subSubTitleRemoveMargin = getThemeConfig(context, `subsubtitle.remove-margin`)
    const themeContext = useContext(ThemeContext);

    const [touched, setTouched] = useState(false)

    const headerTitle = useRef()

    useEffect(() => {
        if (headerTitle.current) {
            context.setHeaderTitleHeight(headerTitle.current.offsetHeight)
        }
    }, [])

    // useEffect(() => {
    //     if (props.episodes.length > 0 && !props.episodes.includes(props.episode)) {
    //         props.onEpisodeChange(props.episodes[0].id, true)
    //     }
    // }, [props.episodes])

    const isCtio2022 = () => {
        return props.event === "ctio-2022"
    }

    const onEpisodeChange = (e) => {
        setTouched(true)
        props.onEpisodeChange(e)
    }

    let mobile = 12
    let tablet = 12
    let desktop = 12

    try {
        if (titleLayoutCols) {
            mobile = titleLayoutCols.mobile
            tablet = titleLayoutCols.tablet
            desktop = titleLayoutCols.desktop
        }
        else {
            mobile = speakersLayoutCols.mobile + agendaLayoutCols.mobile
            tablet = speakersLayoutCols.tablet + agendaLayoutCols.tablet
            desktop = speakersLayoutCols.desktop + agendaLayoutCols.desktop
        }
    }
    catch (e) { }

    let rightColLogo = null
    if (window._.get(themeContext, "mediaRightColLogo.url") && props.showRightColLogo) {
        rightColLogo = (
            <div className='col-right__logo mb-3'>
                <img src={window._.get(themeContext, "mediaRightColLogo.url")} />
            </div>
        )
    }

    let multiepisode = false
    let episodesDropdown = null
    let eventHeaderWidthLg = 12
    let forceDisable = false

    let dropDownEpisodes = props.episodes
    // allEpisodes
    if (props.episodes.length === 0) {
        dropDownEpisodes = props.allEpisodes
        forceDisable = true
    }

    let selectedEpisode = null
    try {
        if (isCtio2022()) {
            const selectable = dropDownEpisodes.filter(item => {
                return !(
                    !item.has_future_events ||
                    (!item.is_subscriptions_open && isCtio2022())
                    || forceDisable
                )
            })
            selectedEpisode = touched ? props.episode : selectable.length ? selectable[0] : dropDownEpisodes[dropDownEpisodes.length - 1]
        }
        else {
            selectedEpisode = props.episode || props.episodes[0].id
        }
    }
    catch (e) { }

    if (dropDownEpisodes.length > 1 && !props.hideDropDown && (context.episode || isCtio2022())) {
        multiepisode = true
        eventHeaderWidthLg = 9
        episodesDropdown = (
            <div className={classNames({
                "col-lg-3": !isCtio2022(),
                "ctio-2022 col-lg-4 offset-lg-4": isCtio2022()
            })}>
                <select
                    value={selectedEpisode ? selectedEpisode.id : null}
                    className="form-control"
                    onChange={(e) => onEpisodeChange(e)}
                >
                    {dropDownEpisodes.map(item => {
                        const disabled = (
                            !item.has_future_events ||
                            (!item.is_subscriptions_open && isCtio2022())
                            || forceDisable
                        )
                        return (
                            <option
                                disabled={disabled}
                                value={item.id}
                            >
                                {item.dropdown_label || item.title}
                            </option>
                        )
                    })}
                </select>
            </div>
        )
    }

    const formattedEpisodeCategories = (isCtio2022() && window._.get(selectedEpisode, "episode_categories")) ? window._.get(selectedEpisode, "episode_categories").map(item => item.title_default).join(",") : null

    const titleContent = (isCtio2022() && window._.get(selectedEpisode, "title")) ? (
        <>
            <div
                ref={headerTitle}
                className="event-header__title text-center"
            >{window._.get(selectedEpisode, "title")}</div>
            {
                formattedEpisodeCategories ? (
                    <div
                        className="event-header__title--categories text-center mt-3"
                    >{formattedEpisodeCategories}</div>
                ) : null
            }
        </>
    ) : (isCtio2022() && props.episodes.length === 0) ? null : (
        <>
            {
                rightColLogo
            }
            {
                props.eventSubSubTitle ? (
                    <div
                        className={classNames("event-header__sub-subtitle", {
                            "mb-3": !subSubTitleRemoveMargin
                        })}
                    >{props.eventSubSubTitle}</div>
                ) : null
            }
            {
                props.eventTitle ? (
                    <div
                        ref={headerTitle}
                        className="event-header__title"
                    >{props.eventTitle}</div>
                ) : null
            }
            {
                props.eventSubTitle ? (
                    <div
                        className="event-header__subtitle my-2"
                    >{props.eventSubTitle}</div>
                ) : null
            }
        </>
    )

    const title = (isCtio2022() && (props.episodes.length === 0 || !context.episode)) ? null : (
        <div className={classNames("event-header col-12", {
            [`col-lg-${eventHeaderWidthLg}`]: multiepisode && !isCtio2022(),
            "mt-4 ctio-2022": isCtio2022()
        })}>
            {titleContent}
        </div>
    )

    const eventLive = isCtio2022() ? window._.get(selectedEpisode, "live_scheduling.formatted_start_at") : props.eventLive
    const eventVod = isCtio2022() ? window._.get(selectedEpisode, "vod_scheduling.formatted_start_at") : props.eventVod

    const maxChannelDurationInHours = window._.get(selectedEpisode, "max_channel_duration")
    const maxChannelDuration = themeContext.showVodDurationInDays ? parseInt(maxChannelDurationInHours / 24) : maxChannelDurationInHours

    const intro = props.eventTitle || props.eventSubSubTitle || props.eventSubTitle ? (
        <div className={classNames("event-header col-12", props.className, {
            [`col-xs-${mobile > 12 ? 12 : mobile}`]: mobile,
            [`col-md-${tablet > 12 ? 12 : tablet}`]: tablet,
            [`col-lg-${desktop > 12 ? 12 : desktop}`]: desktop,
            "ctio-2022 mb-5": isCtio2022()
        })}>
            {
                props.eventIntro && isCtio2022() ? (
                    <div className={classNames({
                        "text-center my-5 event-header__intro": isCtio2022()
                    })}
                        dangerouslySetInnerHTML={{ __html: props.eventIntro }}
                    />
                ) : null
            }
            <Row>
                {!isCtio2022() ? title : null}
                {episodesDropdown}
                {isCtio2022() ? title : null}
            </Row>
            {
                props.hideDropDown || (isCtio2022() && (props.episodes.length === 0 || !context.episode || !context.episode?.is_subscriptions_open)) ? null : (
                    <Row className={classNames("event-header__dates", {
                        "ctio-2022": isCtio2022()
                    })}>
                        {eventLive ? (
                            <div className={classNames("mt-4", {
                                "col-xs-12 col-md-12 col-lg-6": !isCtio2022(),
                                "col-12 text-center": isCtio2022()
                            })}>
                                {props.eventLiveTitle ? (
                                    <div
                                        dangerouslySetInnerHTML={{ __html: props.eventLiveTitle + " " }} />
                                ) : null}
                                <div
                                    className={classNames("mt-2", "episode-dates", {
                                        "bullet": 0//!isCtio2022()
                                    })}>
                                    {eventLive}
                                </div>
                            </div>
                        ) : null}
                        {eventVod ? (
                            <div className={classNames("mt-4", {
                                "col-xs-12 col-md-12 col-lg-6": !isCtio2022(),
                                "col-12 text-center": isCtio2022()
                            })}>
                                {props.eventVodTitle ? (
                                    <div
                                        dangerouslySetInnerHTML={{ __html: props.eventVodTitle }} />
                                ) : null}
                                <div
                                    className={classNames("mt-2", "episode-dates", {
                                        "bullet": 0//!isCtio2022()
                                    })}>
                                    {eventVod}
                                    {
                                        window._.get(selectedEpisode, "vod_channels_duration") ? (
                                            <span> {props.upTo || "up to"} {maxChannelDuration} {props.hours || "hours"}</span>
                                        ) : null
                                    }
                                </div>
                            </div>
                        ) : null}
                        {
                            props.eventNotes && isCtio2022() ? (
                                <div className={classNames("col-12 mt-4", {
                                    "mt-4": !isCtio2022(),
                                    "text-center mt-5": isCtio2022()
                                })}
                                    dangerouslySetInnerHTML={{ __html: props.eventNotes }}
                                />
                            ) : null
                        }
                        {
                            props.eventIntro && !isCtio2022() ? (
                                <div className={classNames("col-12 mt-4", {
                                    "mt-4": !isCtio2022(),
                                    "text-center mt-5": isCtio2022()
                                })}
                                    dangerouslySetInnerHTML={{ __html: props.eventIntro }}
                                />
                            ) : null
                        }
                    </Row>
                )
            }
        </div>
    ) : null

    return intro
}

EventTitle.className = "eventtitle"
EventTitle.fluid = true
export default EventTitle