import React, { useEffect, useState } from "react"
import AdamModal from "../AdamModal/AdamModal";
import Container from "../../ui/Container/Container";
import Row from "../../ui/Row/Row";
import classNames from "classnames";
import AspectRatio from "../../ui/AspectRatio/AspectRatio";

const SpeakerBioModal = (props) => {
    const [modalOpen, setModalOpen] = useState(false)

    useEffect(() => {
        setModalOpen(props.open)
    }, [props.open])

    const { textCentered } = props

    const img = (
        <AspectRatio style={{
            backgroundImage: `url(${props.media_url || "https://via.placeholder.com/200"})`,
        }} className="speaker-card__picture mr-3" />
    )

    const content = (
        <Container
            className="py-4 px-4"
            fluid={true}
        >
            <Row
                className="modal__event-title">
                <div className="col-12">
                    <div className="card" style={{ border: 0 }}>
                        {img}
                        <div className={classNames("speaker-card__body", {
                            "text-center": textCentered
                        })}>
                            <h5 className="card-title  speaker-card__title">{props.firstname} {props.lastname}</h5>
                            <p className="card-text speaker-card__role"
                                dangerouslySetInnerHTML={{ __html: props.role }}
                            />
                            <p className="card-text speaker-card__bio mt-2"
                                dangerouslySetInnerHTML={{ __html: props.description }}
                            />
                            {/* <p className="card-text speaker-card__description">{props.description}</p> */}
                            {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                        </div>
                    </div>
                </div>
            </Row>
        </Container >
    )

    const modal = (
        <AdamModal
            small={true}
            content={content}
            open={modalOpen}
            // fluidHeader={true}
            onClose={() => props.onClose()}
        />
    )

    return modal
}

export default SpeakerBioModal