import classNames from "classnames"
import { useContext, useEffect, useState } from "react"
import MainContext from "../../context/MainContext"
import AgendaAccordion from "../../ui/AgendaAccordion/AgendaAccordion"
import Row from "../../ui/Row/Row"
import { consoleLog, getThemeConfig } from "../../utils/functions"
import Agenda from "../Agenda/Agenda"
import NestedAgendaTitle from "../EpisodeTitle/NestedAgendaTitle"
import Speakers from "../Speakers/Speakers"
import "./EpisodeDetails.scss"
import { LIVE, PRE_LIVE } from "../../config/channelStatus"

const NestedAgendaDetails = (props) => {

    const [open, setOpen] = useState(false)
    const [liveMode, setLiveMode] = useState(false)
    const context = useContext(MainContext)
    const {
        agenda,
        language,
        country,
        internal,
        episode,
        // expandable,
        className,
        groupedSeakers
        // fullWidth
    } = props

    useEffect(() => {
        let newLiveMode = false
        episode?.channels.forEach(element => {
            if ([LIVE, PRE_LIVE].includes(element.status)) {
                newLiveMode = true
            }
        });
        setLiveMode(newLiveMode)
    }, [episode])

    const toggle = () => {
        consoleLog("toggle")
        setOpen(open => {
            return !open
        })
    }

    const getChildren = () => {
        return agenda.children || []
    }

    const hasChildren = () => {
        return getChildren().length > 0
    }

    const openAccordionButton = hasChildren() ? (
        <div
            className="episode-header__open-accordion"
            onClick={toggle}
        >
            {open ? (
                <i className="agenda-explode-button fas fa-angle-up" />
            ) : <i className="agenda-explode-button fas fa-angle-down" />
            }
        </div>
    ) : null

    const accordion = hasChildren() ? (
        <AgendaAccordion
            removeMargin={getThemeConfig(context, "agenda.accordion.remove-margin")}
            removeShadow={getThemeConfig(context, "agenda.accordion.remove-shadow")}
            open={open}
        >
            {
                getChildren().map((child, index) => {
                    return (
                        <Row key={`agenda-row-${index}`}>
                            <Agenda
                                language={language}
                                country={country}
                                // episode={episode}
                                // episodeId={episode.id}
                                internal={internal}
                                showDate={liveMode}
                                items={[child]}
                                groupedSeakers={groupedSeakers}
                                fullWidth={groupedSeakers}
                            />
                            {
                                groupedSeakers ? null : (
                                    <Speakers
                                        language={language}
                                        country={country}
                                        // episodeId={episode.id}
                                        internal={internal}
                                        items={child.speakers}
                                        hiddenItems={child.hidden_speakers_cards}
                                    />
                                )
                            }
                        </Row>
                    )
                })
            }
        </AgendaAccordion>
    ) : null

    return (
        <div
            style={{ width: "100%", position: "relative" }}
            className={classNames(className)}
        >
            {openAccordionButton}
            <NestedAgendaTitle
                className={classNames(
                    props.detailsClassname,
                    "agenda-accordion-title", {
                    "expandable": hasChildren()
                }
                )}
                id={agenda.id}
                onToggleAccordionClicked={toggle}
                agenda={agenda}
                showDate={liveMode}
                // eventLive={window._.get(episode, "live_scheduling.formatted_start_at")}
                // eventLiveTitle={window._.get(contents, "liveDatetimeTitle")}
                // eventVod={window._.get(episode, "vod_scheduling.formatted_start_at")}
                // eventVodTitle={window._.get(contents, "vodDatetimeTitle")}
                // eventIntro={window._.get(contents, "websiteIntro")}
                // upTo={window._.get(contents, "upTo")}
                // hours={window._.get(contents, "hours")}
                open={open}
            // episode={episode}
            // fullWidth={props.fullWidth}
            // hideDates={props.hideDates}
            // hideSubtitle={props.hideDates}
            />
            {accordion}
        </div>
    )
}

export default NestedAgendaDetails