/* eslint-disable default-case */
import { useContext, useState, } from 'react';

import MainContext from '../../context/MainContext';
import useRegistrtionForm from '../../hooks/useRegistrtionForm';
import FormIsNotValidModal from '../FormIsNotValidModal/FormIsNotValidModal';

/**
 * MSD CTIO 2022
 * @param {object} props 
 * @returns 
 */
const MSDCtioRegistrationForm = (props) => {
    const context = useContext(MainContext)

    const [modalOpen, setModalOpen] = useState(false)

    const {
        output,
        countries,
        allCountries,
        getValue,
        getLegals
    } = useRegistrtionForm({
        forceEnableLoginButton: true,
        onSubmitDisabled: () => setModalOpen(true)
    })

    let fields = [];


    const languagesList = window._.get(context, "event.languages") || []
    const languageField = {
        elementType: "hidden",
        id: "lang_id",
        name: "lang_id",
        value: window._.get(languagesList[0], "value"),
    }

    fields.push(languageField)

    const getSpecialtyOptions = () => {
        const values = [
            "Endocrinology",
            "Gastroenterology",
            "Hematology",
            "Hepatology",
            "Internal Medicine",
            "Medical Oncology",
            "Pathology",
            "Pulmonology",
            "Radiology",
            "Surgeon",
            "Urology",
            "Others"
        ]

        const specialtiesTranslations = {
            "en": {
                "Endocrinology": "Endocrinology",
                "Gastroenterology": "Gastroenterology",
                "Hematology": "Hematology",
                "Hepatology": "Hepatology",
                "Internal Medicine": "Internal Medicine",
                "Medical Oncology": "Medical Oncology",
                "Pathology": "Pathology",
                "Pulmonology": "Pulmonology",
                "Radiology": "Radiology",
                "Surgeon": "Surgeon",
                "Urology": "Urology",
                "Others": "Others (Please specify)"
            }
        }

        return values.map(item => {
            return {
                id: item,
                value: item,
                label: window._.get(specialtiesTranslations, `${getValue("lang_id")}.${item}`) || window._.get(specialtiesTranslations, `en.${item}`)
            }
        })
    }

    if (context.event.has_internal) {
        const is_internal_options = [{
            value: "ext",//internal_false_value_label
            label: window._.get(context, "contents.internalFalseValueLabel") || "External",
        }, {
            value: "int",//internal_false_value_label
            label: window._.get(context, "contents.internalTrueValueLabel") || "Internal",
        }]
        fields.push({
            elementType: "singleselect",
            id: "is_internal",
            name: "is_internal",
            required: true,
            placeholder: window._.get(context, "contents.internal") || "Internal",
            value: getValue("is_internal") ? is_internal_options.find(a => getValue("is_internal") == a.value) : null,
            options: is_internal_options
        })
    }

    switch (context.event.registration_flow) {
        case 1:
            const registration_mode_options = [
                {
                    key: 101,
                    value: 101,//internal_false_value_label
                    label: window._.get(context, "contents.registrationModeTitleA") || "A",
                }, {
                    key: 102,
                    value: 102,//internal_false_value_label
                    label: window._.get(context, "contents.registrationModeTitleB") || "B",
                }, {
                    key: 100,
                    value: 100,//internal_false_value_label
                    label: window._.get(context, "contents.registrationModeTitleC") || "C",
                }]
            fields = fields.concat({
                elementType: "singleselect",
                id: "registration_mode",
                name: "registration_mode",
                required: true,
                placeholder: window._.get(context, "contents.registrationMode") || "Mode",
                value: getValue("registration_mode") ? registration_mode_options.find(a => getValue("registration_mode") == a.value) : null,
                options: registration_mode_options
            })
            break
    }

    switch (parseInt(getValue("registration_mode"))) {
        case 100:
            fields = fields.concat({
                elementType: "multiselect",
                id: "episodes_id",
                name: "episodes_id",
                required: true,
                closeOnSelect: false,
                placeholder: window._.get(context, "contents.selectEpisodes") || "Mode",
                // label: window._.get(context, "contents.selectEpisodes") || "Mode",
                value: getValue("episodes_id") || "",
                displayValue: "name",
                showCheckbox: true,
                options: window._.get(context, "event.episodes", []).filter(item => {
                    return item.has_future_events && item.is_subscriptions_open
                }).map(item => {
                    // options: window._.get(context, "allEpisodes", []).map(item => {
                    return {
                        name: [item.dropdown_label, item.title].join(" - "),
                        id: item.id,
                        key: item.id
                    }
                })
            })
            break
        case 102:
            fields = fields.concat({
                elementType: "multiselect",
                id: "episode_categories_id",
                name: "episode_categories_id",
                required: true,
                closeOnSelect: false,
                placeholder: window._.get(context, "contents.episodeCategories") || "Categories",
                // label: window._.get(context, "contents.episodeCategories") || "Categories",
                value: getValue("episode_categories_id") || "",
                displayValue: "name",
                showCheckbox: true,
                options: window._.get(context, "episodeCategories", []).map(item => {
                    return {
                        name: item.title_default,
                        id: item.id,
                        key: item.id
                    }
                })
            })
            break
        // case "101":            ALL WEBCAST
        // case "102":            EPISODE CATEGORIES
    }

    if (!context.internal) {
        fields.push({
            elementType: "singleselect",
            id: "country_id",
            name: "country_id",
            required: true,
            // invalidText: "Invalid error message.",
            // label: window._.get(context, "contents.country") || "Country",
            placeholder: window._.get(context, "contents.country") || "Country",
            // value: getValue("country_id") || context.country,
            value: getValue("country_id") ? countries.find(a => getValue("country_id") == a.value) : null,
            disabled: parseInt(context.internal),
            options: countries || []
        })
    }
    else {
        fields.push({
            elementType: "singleselect",
            id: "country_id_of_work",
            name: "country_id_of_work",
            required: true,
            // invalidText: "Invalid error message.",
            // label: window._.get(context, "contents.countryOfWork") || "Country of work",
            placeholder: window._.get(context, "contents.countryOfWork") || "Country of work",
            // value: getValue("country_id_of_work") || context.country,
            value: getValue("country_id_of_work") ? allCountries.find(a => getValue("country_id_of_work") == a.value) : null,
            disabled: !parseInt(context.internal),
            options: allCountries || []
        })
    }

    // if (context.country || context.internal) {
    fields = fields.concat([
        {
            elementType: "text",
            id: "firstname",
            name: "firstname",
            required: true,
            // invalidText: "Invalid error message.",
            // label: window._.get(context, "contents.firstname") || "firstname",
            placeholder: window._.get(context, "contents.firstname") || "firstname",
            value: getValue("firstname"),
        },
        {
            elementType: "text",
            id: "lastname",
            name: "lastname",
            required: true,
            // invalidText: "Invalid error message.",
            // label: window._.get(context, "contents.lastname") || "lastname",
            placeholder: window._.get(context, "contents.lastname") || "lastname",
            value: getValue("lastname"),
        },
        {
            elementType: "singleselect",
            id: "specialty",
            name: "specialty",
            required: true,
            // invalidText: "Invalid error message.",
            // label: window._.get(context, "contents.specialty") || "Specialty",
            placeholder: window._.get(context, "contents.specialty") || "Specialty",
            // value: getValue("specialty") || "",
            value: getValue("specialty") ? getSpecialtyOptions().find(a => getValue("specialty") == a.value) : null,
            // disabled: parseInt(context.internal),
            options: getSpecialtyOptions()
        },
    ])
    if (getValue("specialty") === "Others") {
        fields.push({
            elementType: "text",
            id: "other_specialty",
            name: "other_specialty",
            required: true,
            // invalidText: "Invalid error message.",
            // label: window._.get(context, "contents.otherSpecialty") || "Other specialty",
            placeholder: window._.get(context, "contents.otherSpecialty") || "Other specialty",
            value: getValue("other_specialty"),
        })
    }
    fields = fields.concat([
        {
            elementType: "text",
            id: "affiliation",
            name: "affiliation",
            required: true,
            // invalidText: "Invalid error message.",
            // label: window._.get(context, "contents.affiliation") || "Affiliation (Place of work)",
            placeholder: window._.get(context, "contents.affiliation") || "Affiliation (Place of work)",
            value: getValue("affiliation"),
        },
        {
            elementType: "email",
            id: "email",
            name: "email",
            required: true,
            // invalidText: "Invalid error message.",
            // label: window._.get(context, "contents.email") || "email",
            placeholder: window._.get(context, "contents.email") || "email",
            value: getValue("email"),
        }
        // ,
        // {
        //     elementType: "text",
        //     id: "phone",
        //     name: "phone",
        //     // label: window._.get(context, "contents.phone") || "Telephone No./Work No",
        //     placeholder: window._.get(context, "contents.phone") || "Telephone No./Work No",
        //     value: getValue("phone"),
        // }
    ])

    if (context.legals) {
        fields = fields.concat(getLegals())
    }
    // }

    return (
        <>
            {output(fields)}
            <FormIsNotValidModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
            />
        </>
    )
}

export default MSDCtioRegistrationForm