import moment from "moment-timezone"
import TagManager from "react-gtm-module";
import Pusher from 'pusher-js';
import * as channelStatus from "../config/channelStatus"

export const getAuthToken = () => {
    return sessionStorage.getItem(process.env.REACT_APP_AUTH_TOKEN_KEY);
    // return localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN_KEY);
}

export const setAuthToken = (token) => {
    return sessionStorage.setItem(process.env.REACT_APP_AUTH_TOKEN_KEY, token);
    // return localStorage.setItem(process.env.REACT_APP_AUTH_TOKEN_KEY, token);
}

export const utc = (dateTime) => {
    return moment.tz(dateTime, process.env.REACT_APP_UTC)
}

export const localTime = (time, timezone) => {
    let _time = utc(time)
    if (timezone) {
        _time = _time.tz(timezone)
    }
    return _time
}

export const tzAbbr = (time, timezone) => {
    return moment.tz.zone(timezone).abbr(utc(time).format("x")); // PDT
}

export const getContents = (context, key) => {
    return window._.get(context, `contents.${key}`)
}

export const getThemeConfig = (context, key) => {
    return window._.get(context, `event.theme.config.${key}`)
}

export const formatRegistrationFooter = (original, context) => {

    if (!original) {
        return ""
    }
    let value = original
    const supportEmail = window._.get(context, "event.support_email")
    const faqTitle = window._.get(context, "contents.faqTitle")

    if (value) {
        value = value.replaceAll('{{FAQ_LINK}}', faqTitle)
        value = value.replaceAll('{{SUPPORT_EMAIL}}', supportEmail)
    }

    return value
}

export const translateChannelStatus = (statusCode) => {

    switch (statusCode) {
        // // UNKNOWN
        case channelStatus.UNKNOWN:
            return "UNKNOWN";
        case channelStatus.PRE_LIVE:
            return "PRE_LIVE";
        case channelStatus.LIVE:
            return "LIVE";
        case channelStatus.BUFFER:
            return "BUFFER";
        case channelStatus.BYE:
            return "BYE";
        case channelStatus.PRE_VOD:
            return "PRE_VOD";
        case channelStatus.VOD:
            return "VOD";
        case channelStatus.POST_VOD:
            return "POST_VOD";
        default:
            return false

        // // LIVE ONLY
        // const PRE_LIVE = 101;
        // const LIVE = 121;

        // // LIVE & VOD
        // const BUFFER = 202;
        // const BYE = 262;

        // // VOD ONLY
        // const PRE_VOD = 303;
        // const VOD = 323;
        // const POST_VOD = 343;
    }
}

export const cleanCheckboxLabel = (string) => {
    if (!string) {
        return string
    }
    const cleaned = string.replace(/^<p[^>]*>|<\/p>$/g, '');
    const test = cleaned.match(/\[TOS_POPUP\].*?\[\/TOS_POPUP\]/g); // ["<h1>A</h1>", "<h2>B</h2>", "<h3>C</h3>"]
    const temp = cleaned.replace(/\[TOS_POPUP\].*?\[\/TOS_POPUP\]/g, "++--TEMP_LINK--++"); // ["<h1>A</h1>", "<h2>B</h2>", "<h3>C</h3>"]

    const chunks = temp.split("++--TEMP_LINK--++")

    const linkContent = test ? test[0].replace(/^\[TOS_POPUP\]|\[\/TOS_POPUP\]$/g, '') : ""
    return {
        "before": chunks[0] ? chunks[0].replace(/&nbsp;$/, "") : null,
        "after": chunks[1] ? chunks[1].replace(/&nbsp;$/, "") : null,
        "linkContent": linkContent
    }
}

export const cleanFaqPopup = (string) => {

    if (!string) {
        return string
    }
    const res = string.split("\n").map((i, key) => {
        const cleaned = i.replace(/^<p[^>]*>|<\/p>$/g, '');
        const test = cleaned.match(/\[FAQ_POPUP\].*?\[\/FAQ_POPUP\]/g); // ["<h1>A</h1>", "<h2>B</h2>", "<h3>C</h3>"]
        const temp = cleaned.replace(/\[FAQ_POPUP\].*?\[\/FAQ_POPUP\]/g, "++--TEMP_LINK--++"); // ["<h1>A</h1>", "<h2>B</h2>", "<h3>C</h3>"]

        const chunks = temp.split("++--TEMP_LINK--++")

        const linkContent = test ? test[0].replace(/^\[FAQ_POPUP\]|\[\/FAQ_POPUP\]$/g, '') : ""

        return {
            "before": chunks[0],
            "after": chunks[1],
            "linkContent": linkContent
        }
    })
    return res
}

export const initializeGTM = (gtmId) => {
    const tagManagerArgs = {
        gtmId: gtmId,
    }
    TagManager.initialize(tagManagerArgs)
}

export const capitalize = (text) => {
    return text ? text.charAt(0).toUpperCase() + text.slice(1) : ""
}

export const consoleLog = (...string) => {
    if (process.env.NODE_ENV === "development") {
        console.log(string)
    }
}

export const initPusher = () => {
    return new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
        // key: process.env.REACT_APP_PUSHER_APP_KEY,
        cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
        forceTLS: true,
        authEndpoint: process.env.REACT_APP_API_BASEURL + 'broadcasting/auth',
        pongTimeout: 6000,
        activityTimeout: 30000,
        auth: {
            headers: {
                'Authorization': "Bearer " + getAuthToken()
            }
        }
    });
}

export const getLocalStorageOffsetFieldName = (postFix) => {
    return `vod-offset-${process.env.NODE_ENV}-${postFix}`
}

export const getLocalStorageLastPauseFieldName = (postFix) => {
    return `last-pause-offset-${process.env.NODE_ENV}-${postFix}`
}

export const getLocalStorageDurationFieldName = (postFix) => {
    return `last-pause-duration-${process.env.NODE_ENV}-${postFix}`
}

export const getLocalStorageQaAEnabled = () => {
    return `qaa-enabled-${process.env.NODE_ENV}`
}

export const setQaAEnabled = (value) => {
    consoleLog("setQaAEnabled", value)
    sessionStorage.setItem(getLocalStorageQaAEnabled(), value);
}

export const getQaAEnabled = () => {
    return sessionStorage.getItem(getLocalStorageQaAEnabled());
}

// Deprecated
export const getVttFileUrl = (m3u8FileUrl) => {
    return m3u8FileUrl.replace(/\.m3u8/, ".vtt")
}

export const getChaptersVttFileUrl = (m3u8FileUrl) => {
    return m3u8FileUrl.replace(/index\.m3u8/, "chapters.vtt")
}

export const getCloseCaptionsVttFileUrl = (m3u8FileUrl, langId = null) => {
    const replace = langId ? `closedcaptions_${langId}.vtt` : "closedcaptions.vtt"
    return m3u8FileUrl.replace(/index\.m3u8/, replace)
}

export const getThumbnailsVttFileUrl = (m3u8FileUrl) => {
    return m3u8FileUrl.replace(/index\.m3u8/, "thumbnails.vtt")
}

export const lightenDarkenColor = (col, amt) => {

    var usePound = false;

    if (col[0] === "#") {
        col = col.slice(1);
        usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00FF) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000FF) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);

}