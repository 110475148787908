import { useContext } from 'react';

import useRegistrtionForm from '../../../hooks/useRegistrtionForm';
import MainContext from '../../../context/MainContext';

/**
 * Eurogin KIND
 * @param {object} props 
 * @returns 
 */
const MSDIdForum2021RegistrationForm = (props) => {
    const context = useContext(MainContext)

    const {
        output,
        countries,
        allCountries,
        getValue,
        getLegals
    } = useRegistrtionForm()

    let fields = 1
        ? [
            {
                elementType: "hidden",
                id: "episodes",
                name: "episodes",
                value: window._.map(props.openEpisodes, (item => item.id))//[window._.map(props.openEpisodes, (item => item.id))],
            },
        ]
        : [];


    const languagesList = window._.get(context, "event.languages") || []
    const languageField = languagesList.length > 1 ? {
        elementType: "select",
        id: "lang_id",
        name: "lang_id",
        required: true,
        // invalidText: "Invalid error message.",
        label: window._.get(context, "contents.language") || "Language",
        placeholder: window._.get(context, "contents.language") || "Language",
        value: getValue("lang_id") || context.language,
        options: window._.get(context, "event.languages") || []
    } : {
        elementType: "hidden",
        id: "lang_id",
        name: "episodes",
        value: window._.get(languagesList[0], "value"),
    }

    fields.push(languageField)

    const getSpecialtyOptions = () => {
        const values = [
            // "Microbiologist",
            // "Critical Care / Intensivist",
            // "Infectious Disease",
            // "Internal / Pulmonologist",
            // "Hospital Pharmacist",
            // "MSD",
            // "Other"
            "Critical Care / Intensive Care",
            "Clinical Pharmacologists",
            "Hematologists",
            "Hospital Pharmacists",
            "Infectious Disease",
            "Microbiology",
            "Pulmonology / Respiratory",
            "Transplant Physicians",
            "Other",
            "MSD",
        ]

        const specialtiesTranslations = {
            "en": {
                "Critical Care / Intensive Care": "Critical Care / Intensive Care",
                "Clinical Pharmacologists": "Clinical Pharmacologists",
                "Hematologists": "Hematologists",
                "Hospital Pharmacists": "Hospital Pharmacists",
                "Infectious Disease": "Infectious Disease",
                "Microbiology": "Microbiology",
                "Pulmonology / Respiratory": "Pulmonology / Respiratory",
                "Transplant Physicians": "Transplant Physicians",
                "Other": "Other",
                "MSD": "MSD",
            },
            "es": {
                "Critical Care / Intensive Care": "Critical Care / Intensive Care",
                "Clinical Pharmacologists": "Clinical Pharmacologists",
                "Hematologists": "Hematologists",
                "Hospital Pharmacists": "Hospital Pharmacists",
                "Infectious Disease": "Infectious Disease",
                "Microbiology": "Microbiology",
                "Pulmonology / Respiratory": "Pulmonology / Respiratory",
                "Transplant Physicians": "Transplant Physicians",
                "Other": "Other",
                "MSD": "MSD",
            },
            "pt": {
                "Critical Care / Intensive Care": "Critical Care / Intensive Care",
                "Clinical Pharmacologists": "Clinical Pharmacologists",
                "Hematologists": "Hematologists",
                "Hospital Pharmacists": "Hospital Pharmacists",
                "Infectious Disease": "Infectious Disease",
                "Microbiology": "Microbiology",
                "Pulmonology / Respiratory": "Pulmonology / Respiratory",
                "Transplant Physicians": "Transplant Physicians",
                "Other": "Other",
                "MSD": "MSD",
            }
            // "en": {
            //     "Microbiologist": "Microbiologist",
            //     "Critical Care / Intensivist": "Critical Care / Intensivist",
            //     "Infectious Disease": "Infectious Disease",
            //     "Internal / Pulmonologist": "Internal / Pulmonologist",
            //     "Hospital Pharmacist": "Hospital Pharmacist",
            //     "MSD": "MSD",
            //     "Other": "Other"
            // },
            // "es": {
            //     "Microbiologist": "Microbiólogo",
            //     "Critical Care / Intensivist": "Cuidados Intensivos/ Intensivista",
            //     "Infectious Disease": "Enfermedades infecciosas",
            //     "Internal / Pulmonologist": "Internista / Neumólogo",
            //     "Hospital Pharmacist": "Farmacéutico hospitalario",
            //     "MSD": "MSD",
            //     "Other": "Otros"
            // },
            // "pt": {
            //     "Microbiologist": "Microbiologist",
            //     "Critical Care / Intensivist": "Terapia intensiva/ Intensivista",
            //     "Infectious Disease": "Doença infecciosa",
            //     "Internal / Pulmonologist": "Medicina interna/Pneumologista",
            //     "Hospital Pharmacist": "Farmacêutico hospitalar",
            //     "MSD": "MSD",
            //     "Other": "Outros"
            // },
            // "fr": {
            //     "Microbiologist": "Microbiologiste",
            //     "Critical Care / Intensivist": "Soins critiques / Intensiviste",
            //     "Infectious Disease": "Infectiologue",
            //     "Internal / Pulmonologist": "Interniste / Pneumologue",
            //     "Hospital Pharmacist": "Pharmacien hospitalier",
            //     "MSD": "MSD",
            //     "Other": "Autre"
            // },
            // "ru": {
            //     "Microbiologist": "Микробиолог",
            //     "Critical Care / Intensivist": "Специалист по интенсивной терапии / Реаниматолог",
            //     "Infectious Disease": "Инфекционист",
            //     "Internal / Pulmonologist": "Терапевт / Пульмонолог",
            //     "Hospital Pharmacist": "Клинический фармацевт",
            //     "MSD": "MSD",
            //     "Other": "Другие"
            // },
            // "zh": {
            //     "Microbiologist": "微生物学家",
            //     "Critical Care / Intensivist": "重症监护/重症医师",
            //     "Infectious Disease": "传染病",
            //     "Internal / Pulmonologist": "内科/肺科医生",
            //     "Hospital Pharmacist": "医院药剂师",
            //     "MSD": "默沙东 (MSD)",
            //     "Other": "其他"
            // }
        }

        return values.map(item => {
            return {
                value: item,
                label: window._.get(specialtiesTranslations, `${getValue("lang_id")}.${item}`) || window._.get(specialtiesTranslations, `en.${item}`)
            }
        })
    }

    if (context.event.has_internal) {
        fields.push({
            elementType: "select",
            id: "is_internal",
            name: "is_internal",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.internal") || "Internal",
            value: getValue("is_internal") || "0",
            options: [{
                value: 0,//internal_false_value_label
                label: window._.get(context, "contents.internalFalseValueLabel") || "External",
            }, {
                value: 1,//internal_false_value_label
                label: window._.get(context, "contents.internalTrueValueLabel") || "Internal",
            }]
        })
    }

    if (!parseInt(context.internal)) {
        fields.push({
            elementType: "select",
            id: "country_id",
            name: "country_id",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.country") || "Country",
            placeholder: window._.get(context, "contents.country") || "Country",
            value: getValue("country_id"),
            disabled: parseInt(context.internal),
            options: countries || []
        })
    }
    else {
        fields.push({
            elementType: "select",
            id: "country_id_of_work",
            name: "country_id_of_work",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.countryOfWork") || "Country of work",
            placeholder: window._.get(context, "contents.countryOfWork") || "Country of work",
            value: getValue("country_id_of_work"),
            disabled: !parseInt(context.internal),
            options: allCountries || []
        })
    }

    // if (context.country || context.internal) {
    fields = fields.concat([
        {
            elementType: "text",
            id: "firstname",
            name: "firstname",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.firstname") || "firstname",
            placeholder: window._.get(context, "contents.firstname") || "firstname",
            value: getValue("firstname"),
        },
        {
            elementType: "text",
            id: "lastname",
            name: "lastname",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.lastname") || "lastname",
            placeholder: window._.get(context, "contents.lastname") || "lastname",
            value: getValue("lastname"),
        },
        {
            elementType: "select",
            id: "specialty",
            name: "specialty",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.specialty") || "Specialty",
            placeholder: window._.get(context, "contents.specialty") || "Specialty",
            value: getValue("specialty"),
            // disabled: parseInt(context.internal),
            options: getSpecialtyOptions()
        },
        {
            elementType: "text",
            id: "affiliation",
            name: "affiliation",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.affiliation") || "Affiliation (Place of work)",
            placeholder: window._.get(context, "contents.affiliation") || "Affiliation (Place of work)",
            value: getValue("affiliation"),
        },
        {
            elementType: "email",
            id: "email",
            name: "email",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.email") || "email",
            placeholder: window._.get(context, "contents.email") || "email",
            value: getValue("email"),
        },
        {
            elementType: "text",
            id: "phone",
            name: "phone",
            label: window._.get(context, "contents.phone") || "Telephone No./Work No",
            placeholder: window._.get(context, "contents.phone") || "Telephone No./Work No",
            value: getValue("phone"),
        }
    ])

    if (context.legals) {
        fields = fields.concat(getLegals())
    }
    // }

    return output(fields)
}

export default MSDIdForum2021RegistrationForm