import classNames from "classnames"
import React, { useContext, useEffect, useState } from "react"
import MainContext from "../../context/MainContext"
import QaAUserForm from "../../forms/QaAUserForm"
import { deleteQuestion, loadQuestions } from "../../api/functions"
import EditQaAForm from "../../forms/EditQaAForm"
import { initPusher, localTime } from "../../utils/functions"
import _ from "lodash"

const QaACardSidebar = (props) => {

    const context = useContext(MainContext)
    const postFix = props.sent ? "sent" : "sidebar"
    const country = window._.get(props, "country.title")

    const senderIsAttendant = !window._.get(props, "subscriber.subscription.is_chairman") && !window._.get(props, "subscriber.subscription.is_internal")

    const originalContent = window._.get(props, "original_content")
    const editedContent = window._.get(props, "edited_content")
    const edited = (editedContent !== null) && (editedContent !== originalContent)
    const senderName = `${window._.get(props, "subscriber.firstname")} ${window._.get(props, "subscriber.lastname")}`
    const editedLabel = edited ? ` (edited)` : null

    return (
        <div onClick={props.onClick}
            className={classNames(`qaa-card__${postFix} mb-3 p-3`, {
                "edited": edited
            })}>
            <div
                className={`qaa-card__${postFix}--name`}>
                {senderName}{editedLabel} {
                    country && senderIsAttendant ? ` (${country})` : null
                }
            </div>
            <div
                className={classNames(`qaa-card__${postFix}--time`)}
            >{localTime(window._.get(props, "created_at"), context.event.timezone).format("D-M-Y H:m")}</div>
            {
                props.sent ? (
                    <div
                        className={`qaa-card__${postFix}--content mb-3`}>
                        {editedContent || originalContent}
                    </div>
                ) : (
                    <div
                        className={`qaa-card__${postFix}--preview mb-3`}>
                        {editedContent || originalContent}
                    </div>
                )
            }
        </div>
    )
}


const QaACardEditable = (props) => {

    const context = useContext(MainContext)
    const country = window._.get(props, "country.title")
    const senderIsAttendant = !window._.get(props, "subscriber.subscription.is_chairman") && !window._.get(props, "subscriber.subscription.is_internal")
    return (
        <div
            className={classNames("qaa-card__editable p-3 mb-4")}
            onAbort={props.onAbort}>
            <div
                className={classNames("qaa-card__editable--time")}
            >{localTime(window._.get(props, "created_at"), context.event.timezone).format("D-M-Y H:m")}</div>
            <div
                className={classNames("qaa-card__editable--user")}
            >{window._.get(props, "subscriber.firstname")} {window._.get(props, "subscriber.lastname")} {
                    country && senderIsAttendant ? ` (${country})` : null
                }</div>
            <div
                className={classNames("qaa-card__editable--original py-2")}
            >{window._.get(props, "original_content")}</div>
            <EditQaAForm
                {...props}
                chairMan={true}
                onSubmit={props.onSubmit}
                onDelete={props.onDelete}
                onUpdate={props.onUpdate}
            />
        </div>
    )
}

const QandAManager = (props) => {
    const context = useContext(MainContext)
    const [loadedQuestions, setLoadedQuestions] = useState([])
    const [pushedQuestions, setPushedQuestions] = useState([])
    const [sentQuestions, setSentQuestions] = useState([])
    const [editableQuestions, setEditableQuestions] = useState([])
    const [editableQuestionsId, setEditableQuestionsId] = useState([])
    const [currentChannelId, setCurrentChannelId] = useState();
    const [currentChannelTag, setCurrentChannelTag] = useState();
    const [loading, setLoading] = useState(false)

    // useEffect(() => {
    //     _loadQuestions()
    // }, [])

    useEffect(() => {
        if (pushedQuestions.length > 0) {
            let newLoadedQuestions = [...pushedQuestions]
            newLoadedQuestions = newLoadedQuestions.concat(loadedQuestions)
            setLoadedQuestions(newLoadedQuestions)
            setPushedQuestions([])
        }
    }, [pushedQuestions])

    // useEffect(() => {
    //     window.Pusher = initPusher()

    //     if (context.episodeId && !window._.isEmpty(context.user)) {
    //         const channel = window.Pusher.subscribe(`private-qaa-moderator-${props.eventId}.${context.episodeId}`)
    //         channel.bind_global(globalCallback);
    //     }
    //     return function cleanup() {
    //         if (window.Pusher) {
    //             window.Pusher.unsubscribe(`private-qaa-moderator-${props.eventId}.${context.episodeId}`)
    //         }
    //     };
    // }, [context.episodeId])

    useEffect(() => {
        if (currentChannelId) {
            _loadQuestions(context.episodeId, currentChannelTag)

            window.Pusher = initPusher()

            if (context.episodeId && !window._.isEmpty(context.user)) {
                const channel = window.Pusher.subscribe(`private-qaa-moderator-${props.eventId}.${context.episodeId}`)
                channel.bind_global(globalCallback);
            }
            return function cleanup() {
                if (window.Pusher) {
                    window.Pusher.unsubscribe(`private-qaa-moderator-${props.eventId}.${context.episodeId}`)
                }
            };
        }
    }, [currentChannelTag, context.episodeId])

    const _loadQuestions = async (episodeId, tag) => {
        let params = {
            episode_id: episodeId
        }
        if (tag) {
            params = {
                ...params,
                stream_id: tag
            }
        }
        const res = await loadQuestions(params)
        setLoadedQuestions(res)
        const res2 = await loadQuestions({
            ...params,
            forwarded: true
        })
        setSentQuestions(res2)
    }

    const globalCallback = (eventName, data) => {
        switch (eventName) {
            case "App\\Events\\NewQuestionToModerate":
                addNewQuestionToModerate(data.question, currentChannelTag)
                break
        }
    }

    const addNewQuestionToModerate = (question, tag) => {
        console.log("currentChannelTag", currentChannelTag, tag)
        if (!currentChannelTag || parseInt(currentChannelTag) === parseInt(question.stream_id)) {
            let newLoadedQuestions = [...pushedQuestions]
            newLoadedQuestions.unshift(question)
            setPushedQuestions(newLoadedQuestions)
        }
    }

    const addToEditableList = (item) => {
        let newList = [...editableQuestions]
        let newListId = [...editableQuestionsId]
        newList.unshift(item)
        newListId.unshift(item.id)
        setEditableQuestions(newList)
        setEditableQuestionsId(newListId)

        // setLoadedQuestions(loadedQuestions => [...loadedQuestions].filter(q => q.id !== item.id))
    }

    const removeFromEditableList = (itemId) => {
        let newList = [...editableQuestions]
        let newListId = [...editableQuestionsId]
        newList = newList.filter(item => item.id !== itemId)
        newListId = newListId.filter(id => id !== itemId)
        setEditableQuestions(newList)
        setEditableQuestionsId(newListId)
    }

    const removeFromPage = (question) => {
        removeFromEditableList(question.id)
        // if (!question.deleted) {
        if (question.confirmed) {
            addToSentList(question)
        }
        // else {
        if (question.defaultValues) {
            question = {
                ...question,
                edited_content: question.defaultValues.edited_content
            }
        }
        // const newList = [...loadedQuestions].filter(item => item.id !== question.id)
        // console.log("newList", newList)
        // if (!question.deleted) {
        //     newList.unshift(question)
        // }
        // console.log("newList", newList)
        // setLoadedQuestions(newList)
        setLoadedQuestions(a => {
            let newList = [...a].filter(item => item.id !== question.id)
            if (!question.deleted && !question.confirmed) {
                newList.unshift(question)
                // newList = [question].concat(newList)
            }
            return newList
        })
        // addNewQuestionToModerate(question)
        // }
        // }
    }

    const addToSentList = (question) => {
        let newSentList = [...sentQuestions]
        newSentList.unshift(question)
        setSentQuestions(newSentList)
    }

    const deleteSelectedQuestion = async (item) => {
        setLoading(item.id)
        try {
            await deleteQuestion(item.id)
            removeFromPage({
                ...item,
                deleted: true
            })
        }
        catch (e) {
            alert("Something went wrong")
        }
        finally {
            setLoading(false)
        }
    }

    const openConfirm = (item) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Are you sure you want to delete permanently this question?")) {
            deleteSelectedQuestion(item)
        }
    }

    const openSaveForLater = (item) => {
    }
    let loadedQuestionsFiltered = loadedQuestions.filter((item, index) => !editableQuestionsId.includes(item.id))
    loadedQuestionsFiltered = _.uniqBy(loadedQuestionsFiltered, 'id')
    // const loadedQuestionsFiltered = loadedQuestions
    return (
        <>
            <div
                className={classNames("qaa-sidebar__left col-lg-3 col-md-3 col-sm-3")}>
                <h3 className="mb-4">Questions from users {`${loadedQuestionsFiltered?.length > 0 ? ` (${loadedQuestionsFiltered.length})` : ""}`}</h3>
                {
                    loadedQuestionsFiltered && loadedQuestionsFiltered.length > 0 ? loadedQuestionsFiltered.map((item, index) => {
                        return (
                            <QaACardSidebar
                                key={`qaa-card-sidebar-${index}`}
                                {...item}
                                onClick={() => addToEditableList(item)}
                            />
                        )
                    }) : (
                        <div>Questions list is empty</div>
                    )
                }
            </div>
            <div
                className={classNames("qaa-list col-lg-6 col-md-6 col-sm-6 pb-3")}>
                <h3 className="mb-4">Selected questions</h3>
                <div className="pb-3">
                    {
                        editableQuestions && editableQuestions.length > 0 ? editableQuestions.map((item, index) => {
                            return (
                                <QaACardEditable
                                    key={`qaa-card-editable-${index}`}
                                    {...item}
                                    // episode={context.episode}
                                    // onAbort={() => removeFromEditableList(item.id)}
                                    onAbort={() => removeFromPage(item)}
                                    onSubmit={(newValues) => removeFromPage(newValues)}
                                    onDelete={() => openConfirm(item)}
                                    onUpdate={() => openSaveForLater(item)}
                                />
                            )
                        }) : (
                            <div className="mb-4">Select questions to edit</div>
                        )
                    }
                </div>
                <h3 className="my-4">Forwarded to the chairman</h3>
                {
                    sentQuestions && sentQuestions.length > 0 ? sentQuestions.map((item, index) => {
                        return (
                            <QaACardSidebar
                                key={`qaa-card-sidebar-${index}`}
                                sent={true}
                                {...item}
                            />
                        )
                    }) : null
                }
            </div>
            <div
                className={classNames("qaa-sidebar__right col-lg-3 col-md-3 col-sm-3")}>
                <h3 className="mb-4">Add questions</h3>
                <QaAUserForm
                    episodeId={props.episodeId}
                    chairMan={true}
                    onSubmit={(e) => addToSentList(e)}
                    handleCurrentChannel={(e) => setCurrentChannelId(e)}
                    handleCurrentChannelTag={(e) => setCurrentChannelTag(e)}
                />
            </div>
        </>
    )
}

export default QandAManager