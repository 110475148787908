
import React, { useState } from "react";
import classNames from "classnames";
import { cleanCheckboxLabel } from "../../utils/functions";
import AdamModal from "../AdamModal/AdamModal";
import Multiselect from 'multiselect-react-dropdown';
import Select from 'react-select';

const Input = (props) => {

    const [modalOpen, setModalOpen] = useState(false)
    // const [originalContent, setOriginalContent] = useState()
    const [currentContent, setCurrentContent] = useState("")

    const openModal = (content) => {
        // setOriginalContent(oc)
        setCurrentContent(content)
        setModalOpen(true)
    }

    const onModalRequestClose = () => {
        // setOriginalContent()
        setCurrentContent("")
        setModalOpen(false)
    }

    const pluckIds = (selectedList) => {
        return selectedList.map(item => item.id)
    }

    let inputElement = null;
    const name = props.id
    const cleanProps = () => {
        let {
            key,
            labelHtml,
            invalidText,
            elementType,
            register,
            control,
            labelOn,
            labelOff,
            selectedItem,
            popupContent,
            original,
            nowrap,
            ...cleanProps
        } = props;

        let defaultClassName = ""

        switch (props.elementType) {
            case "checkbox":
                defaultClassName = "form-check-input"
                break;
            case "singleselect":
                defaultClassName = "form-singleselect"
                break;
            default:
                defaultClassName = "form-control form-control-lg"
        }

        let placeholder = props.placeholder
        if (props.required && !props.hide_asterisk) {
            placeholder += `*`
        }
        return {
            ...cleanProps,
            id: props.id,
            className: props.className ? props.className + ` ${defaultClassName}` : defaultClassName,
            className: classNames(
                props.className,
                defaultClassName,
                { "is-invalid": props.invalid }
            ),
            placeholder: placeholder,
            onChange: (e) => props.onChange(e),
            name: name
            // value: (value === null) ? undefined : value
        };
    };
    switch (props.elementType) {
        case "text":
        case "email":
        case "password":
            inputElement = (
                <input
                    type={props.elementType}
                    // DO NOT REMOVE
                    {...cleanProps()}
                />
            );
            break;
        case "textarea":
            inputElement = (
                <textarea
                    {...cleanProps()}
                >{props.value}</textarea>
            );
            break;
        case "checkbox":
            inputElement = (
                <input
                    type={props.elementType}
                    {
                    ...cleanProps()
                    }
                    checked={props.value}
                />
            );
            break;
        // case "radio-buttons":
        //     inputElement = (
        //         <ContentSwitcher
        //             aria-label={cleanProps.id}
        //             id={props.id}
        //             labelText={props.labelText}
        //             onChange={(e) => props.onChange(e)}
        //             selectedIndex={props.defaultValue}
        //             items={props.items}
        //             firstIndex={1}
        //         />
        //     );
        //     break;
        case "hidden":
            inputElement = (
                <input
                    {...cleanProps()}
                    type={"hidden"}
                />
            );
            break;
        case "select":
            inputElement = (
                <select
                    {...cleanProps()}
                >
                    {
                        props.placeholder ? (
                            <option
                                value={""}
                                className="placeholder"
                            // disabled
                            >{props.placeholder}</option>
                        ) : null
                    }
                    {props.options.map((item, index) => {
                        return (
                            <option
                                value={item.value}
                                key={`option-${props.id}-${index}`}
                            >{item.label}</option>
                        )
                    })}
                </select>
            );
            break;
        case "singleselect":
            inputElement = (
                <Select
                    {...cleanProps()}
                    classNamePrefix="form-singleselect"
                    // onSelect={cleanProps().onChange}
                    onSelect={(e) => { cleanProps().onChange(e.value) }}
                    options={props.options}
                />
            );
            break;

        case "multiselect":
            inputElement = (
                <Multiselect
                    {...cleanProps()}
                    // onSelect={cleanProps().onChange}
                    onSelect={(selectedList, selectedItem) => { cleanProps().onChange(pluckIds(selectedList)) }}
                    // onRemove={cleanProps().onChange}
                    onRemove={(selectedList, removedItem) => { cleanProps().onChange(pluckIds(selectedList)) }}
                    options={props.options}
                />
            );
            break;

        default:
            inputElement = null;
    }
    let label = props.label
    let asterisk = ""
    if (props.required && !props.hide_asterisk) {
        asterisk = `*`
    }

    if (props.nowrap) {
        return inputElement
    }
    let modal = null
    if (props.labelHtml) {
        const cleaned = cleanCheckboxLabel(props.labelHtml)
        label = (
            <span>
                {
                    cleaned.before ? (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: cleaned.before
                            }} />
                    ) : null
                }
                {
                    cleaned.linkContent ? props.popupContent ? (
                        <a
                            className="tos-link"
                            onClick={() => openModal(props.popupContent)}
                            dangerouslySetInnerHTML={{
                                __html: cleaned.linkContent
                            }} />
                    ) : (
                        <span dangerouslySetInnerHTML={{
                            __html: cleaned.linkContent
                        }} />
                    ) : null
                }
                {
                    cleaned.after ? (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: cleaned.after
                            }} />
                    ) : null
                }
                {asterisk}
            </span>
        )
        // const customStyles = {
        //     content: {
        //         top: '50%',
        //         left: '50%',
        //         right: 'auto',
        //         bottom: 'auto',
        //         marginRight: '-50%',
        //         width: '50%',
        //         transform: 'translate(-50%, -50%)',
        //         zIndex: "900000",
        //         padding: 0,
        //         position: "relative",
        //         overflow: "hidden"
        //     }
        // };
        const contentStyles = {
            // maxHeight: "75vh",
            overflow: "auto",
            fontSize: "0.75rem",
            lineHeight: "1.2rem"
        };

        const modalContents = (
            <div
                className="modal__content my-4 mx-4"
                style={contentStyles}
                dangerouslySetInnerHTML={{
                    __html: currentContent
                }} />
        )
        modal = (
            <AdamModal
                content={modalContents}
                open={modalOpen}
                // fluidHeader={true}
                onClose={onModalRequestClose}
            />
        )
    }

    const invalidFeedback = (props.invalid && props.invalidText) ? (
        <div class="invalid-feedback">
            {props.invalidText}
        </div>
    ) : null
    return (props.elementType !== "checkbox") ? (
        <div className="form-group">
            {
                props.label ? (
                    <label htmlFor={props.id}>{label}{asterisk}</label>
                ) : null
            }
            {inputElement}
            {invalidFeedback}
        </div>
    ) : (
        <div className="form-check">
            {inputElement}
            {
                props.label ? (
                    <label
                        className="form-check-label"
                        htmlFor={props.id}>
                        {label}{asterisk}
                    </label>
                ) : null
            }
            {
                props.labelHtml ? (
                    <label
                        className="form-check-label"
                        htmlFor={props.id}
                    >{label}</label>
                ) : null
            }
            {modal}
            {invalidFeedback}
        </div>
    )
};

export default Input;
