import classNames from "classnames"
import "./InitialModalBanner.scss"

const InitialModalBanner = (props) => {

    const { icon } = props

    // switch (icon) {
    //     case "calendar":
    //         iconUrl = ""
    //         break
    //     case "play":
    //         iconUrl = ""
    //         break
    //     case "camera":
    //         iconUrl = ""
    //         break
    //     case "flag":
    //         iconUrl = ""
    //         break
    // }

    // const icon = iconUrl ? (
    //     <div className={classNames(props.className, "initial-modal__banner--icon")}>
    //         <img src={iconUrl} />
    //     </div>
    // ) : null
    const style = icon ? {
        backgroundImage: `url(/img/icons/${icon}.png)`
    } : {}
    return (
        <div
            className={classNames(props.className, "initial-modal__banner col mx-1")}
            style={style}
        >
            <div className={classNames(props.className, "initial-modal__banner--content")}>
                {props.children}
            </div>
        </div>
    )
}

export default InitialModalBanner