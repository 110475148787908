import React, { useEffect, useState, useContext } from "react"
import AdamModal from "../AdamModal/AdamModal";
import MainContext from "../../context/MainContext";
import Container from "../../ui/Container/Container";
import { localTime } from "../../utils/functions";
import Row from "../../ui/Row/Row";
import CountDown from "../../ui/CountDown/CountDown";
import "./VirtualWorldModal.scss"

const VirtualWorldModal = (props) => {
    const [modalOpen, setModalOpen] = useState(false)
    const context = useContext(MainContext)

    useEffect(() => {
        setModalOpen(props.open)
    }, [props.open])

    const goToVw = () => {
        if (props.virtual_world_url && context.user) {
            let vwUrl = props.virtual_world_url + `?iam=${context.user.email}`
            if (context.isPreview) {
                vwUrl += `&preview=${context.isPreview}`
            }
            window.location.assign(vwUrl)
        }
    }

    const { virtual_world_start_at } = props

    const grid = (
        <Container fluid={true} className="text-center virtual-world-modal">
            <Row className="mt-3">
                <div className="col virtual-world-modal__event-title">
                    {props.eventTitle}
                </div>
            </Row>
            <Row className="my-3">
                <div
                    className="col"
                    dangerouslySetInnerHTML={{
                        __html: `${props.openMessage || `Virtual World will open its doors on ${localTime(virtual_world_start_at, context.event.timezone).format("D-M-Y HH:mm")}`}`
                    }} />
            </Row>
            <Row className="my-3">
                <div
                    className="col virtual-world-modal__countdown">
                    <CountDown
                        dateTime={virtual_world_start_at}
                        mode="splitted"
                        onZero={goToVw} />
                </div>
            </Row>
        </Container >
    )

    const now = new Date()
    const footerContent = context.event.client_name ? `Copyright © ${now.getFullYear()} ${context.event.client_name}` : null

    const modal = (
        <AdamModal
            content={grid}
            open={modalOpen}
            // fluidHeader={true}
            headerClass="d-none d-sm-block"
            footerContent={footerContent}
            onClose={() => props.onClose(false)}
        />
    )

    return modal
}

export default VirtualWorldModal