import React, { useEffect, useState, useContext } from "react"
import AdamModal from "../AdamModal/AdamModal";
import { ThemeContext } from "styled-components";
import MainContext from "../../context/MainContext";
import Container from "../../ui/Container/Container";
import Row from "../../ui/Row/Row";
import InitialModalBanner from "../../ui/InitialModalBanner/InitialModalBanner";
import EventTitle from "../EventTitle/EventTitle";
import EpisodeDetails from "../EpisodeDetails/EpisodeDetails";

const InitialModal = (props) => {
    const [modalOpen, setModalOpen] = useState(false)
    const context = useContext(MainContext)
    const themeContext = useContext(ThemeContext);

    const { contents } = props
    const { is2ColsView, episode, event, language, internal, country } = context

    useEffect(() => {
        setModalOpen(props.open)
    }, [props.open])

    // hide_logo_initial_popup hideLogoInitialPopup
    // hide_title_initial_popup hideTitleInitialPopup
    const logo = !window._.isEmpty(themeContext.mediaHeaderLogoDesktop) && themeContext.showMainlogoInitialPopup ? (
        <img
            src={themeContext.mediaHeaderLogoDesktop.url}
            // width="30"
            // height="30"
            className="d-inline-block align-center navbar-logo navbar-logo__modal mt-4"
            alt={context.siteTitle} />
    ) : context.siteTitle

    const bannersContents = {
        "calendar": contents.initialPopupBox1Title,
        "youtube": contents.initialPopupBox2Title,
        "video": contents.initialPopupBox3Title,
        "flag": contents.initialPopupBox4Title,
    }
    let banners = []
    if (themeContext.showBannersInitialPopup) {
        Object.keys(bannersContents).forEach(key => {
            if (bannersContents[key]) {
                banners.push(<InitialModalBanner key={`im-banner-${key}`} icon={key}>{bannersContents[key]}</InitialModalBanner>)
            }
        })
    }

    const eventTitle = themeContext.showTitleInitialPopup && episode ? (
        <Row className="mt-3">
            <EventTitle
                className="mt-3"
                eventTitle={is2ColsView ? contents.eventTitle : episode.title}
                eventSubTitle={is2ColsView ? null : contents.eventTitle}
                eventSubSubTitle={contents.subSubTitle}
                upTo={window._.get(contents, "upTo")}
                hours={window._.get(contents, "hours")}
                // eventLive={window._.get(episode, "live_scheduling.formatted_start_at")}
                // eventLiveTitle={window._.get(contents, "liveDatetimeTitle")}
                // eventVod={window._.get(episode, "vod_scheduling.formatted_start_at")}
                // eventVodTitle={window._.get(contents, "vodDatetimeTitle")}
                eventIntro={window._.get(contents, "websiteIntro")}
                episodes={event.episodes || []}
                episode={context.episode}
                // onEpisodeChange={(e) => onEpisodeChange(e)}
                hideDropDown={true}
                showRightColLogo={themeContext.showLogoInitialPopup}
            />
        </Row>
    ) : null

    const grid = event ? (
        <Container fluid={true}>
            {logo}
            {eventTitle}
            {
                banners?.length > 0 && (
                    <Row className="my-3 mx-0">
                        {banners}
                    </Row>
                )
            }
            <Row>
                {
                    // form theme configuration
                    themeContext.showEpisodesInitialPopup ? event.episodes.map((item, index) => {
                        return (
                            <EpisodeDetails
                                key={`episode-detail-${index}`}
                                episode={item}
                                contents={contents}
                                language={language}
                                internal={internal}
                                country={country}
                                className={"col-lg-6"}
                                detailsClassname="p-0 py-3"
                                fullWidth={true}
                                hideDates={true}
                            />
                        )
                    }) : null
                }
            </Row>
            {
                props.content ?
                    (
                        <Row
                            className="my-2 py-4 px-2">
                            <div className="col-12">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: props.content
                                    }} />
                            </div>
                        </Row>
                    ) : null
            }
            {
                contents.initialPopupFooter ? (
                    <div className="initial-modal__footer my-4"
                        dangerouslySetInnerHTML={{
                            __html: contents.initialPopupFooter
                        }} />
                ) : null
            }
        </Container >
    ) : null

    const modal = (
        <AdamModal
            content={grid}
            open={modalOpen}
            fluidHeader={!themeContext.showDefaultheaderInitialPopup}
            className="initial-modal "
            hideHeader={!themeContext.showDefaultheaderInitialPopup}
            onClose={() => props.onClose()}
        />
    )

    return modal
}

export default InitialModal