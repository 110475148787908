import classNames from 'classnames';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import MainContext from '../../context/MainContext';
import Row from '../../ui/Row/Row';
import { getThemeConfig, localTime } from '../../utils/functions';
import "./EpisodeTitle.scss"

const EpisodeTitleWithNestedAgenda = (props) => {

    const { episode, agenda, fullWidth } = props
    const context = useContext(MainContext)
    const themeContext = useContext(ThemeContext);
    const speakersLayoutCols = getThemeConfig(context, "speakers.cols")
    const agendaLayoutCols = getThemeConfig(context, "agenda.cols")

    let mobile = 12
    let tablet = 12
    let desktop = 12

    try {
        mobile = speakersLayoutCols.mobile + agendaLayoutCols.mobile
        tablet = speakersLayoutCols.tablet + agendaLayoutCols.tablet
        desktop = speakersLayoutCols.desktop + agendaLayoutCols.desktop
    }
    catch (e) { }

    const title = agenda?.title || props.eventTitle

    // const interanlDetailssBoxClassName = fullWidth ? "col-12" : "col-xs-12 col-md-12 col-lg-9"
    console.log("liveStartDateFormatted", props.eventTitle, props.id)
    const intro = props.eventTitle ? (
        <div className={classNames("episode-header col-12", props.className, {
            popup: props.popup
        })}>
            <Row className="episode-header__title">
                {
                    props.hideDates ? props.eventSubTitle ? (
                        <div className="episode-header__subtitle col-12 mb-2">
                            {props.eventSubTitle}
                        </div>
                    ) : null : (
                        <div className={classNames("col-12")}>
                            {
                                props.eventTitle ? (
                                    <>
                                        {/* <div>{liveStartDate ? localTime(liveStartDate, context.event.timezone).format("dddd") : null}</div> */}
                                        <div>{title}</div>
                                    </>
                                ) : null
                            }
                            {/* {
                                vodStartDateFormatted && !liveStartDateFormatted ? (
                                    // vodStartDateFormatted && (liveStartDateFormatted !== vodStartDateFormatted) ? (
                                    <>
                                        <div>{vodStartDate ? localTime(vodStartDate, context.event.timezone).format("dddd") : null}</div>
                                        <div>{vodStartDateFormatted}</div>
                                    </>
                                ) : null
                            } */}
                        </div>
                    )
                }
            </Row>
        </div>
    ) : null
    return intro
}

EpisodeTitleWithNestedAgenda.className = "parent-agenda-title"
EpisodeTitleWithNestedAgenda.fluid = true
export default EpisodeTitleWithNestedAgenda