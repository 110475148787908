import classNames from 'classnames';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import MainContext from '../../context/MainContext';
import Row from '../../ui/Row/Row';
import { getThemeConfig } from '../../utils/functions';
import "./EpisodeTitle.scss"

const EpisodeTitle = ({ linesOrder, ...props }) => {

    const { episode, fullWidth } = props
    const context = useContext(MainContext)
    const themeContext = useContext(ThemeContext);
    const speakersLayoutCols = getThemeConfig(context, "speakers.cols")
    const agendaLayoutCols = getThemeConfig(context, "agenda.cols")

    let mobile = 12
    let tablet = 12
    let desktop = 12

    try {
        mobile = speakersLayoutCols.mobile + agendaLayoutCols.mobile
        tablet = speakersLayoutCols.tablet + agendaLayoutCols.tablet
        desktop = speakersLayoutCols.desktop + agendaLayoutCols.desktop
    }
    catch (e) { }

    // const liveStartDate = window._.get(episode, "live_scheduling.start_at")
    const liveStartDateDayName = window._.get(episode, "live_scheduling.dayname_start_at")
    // const vodStartDate = window._.get(episode, "vod_scheduling.start_at")
    const vodStartDateDayName = window._.get(episode, "vod_scheduling.dayname_start_at")

    // const liveStartDateTimeFormatted = liveStartDate ? localTime(liveStartDate, context.event.timezone).format("MMM D, YYYY") : null
    const liveStartDateTimeFormatted = window._.get(episode, "live_scheduling.formatted_start_at")
    const liveStartDateFormatted = window._.get(episode, "live_scheduling.formatted_date_start_at")
    // const vodStartDateTimeFormatted = vodStartDate ? localTime(vodStartDate, context.event.timezone).format("MMM D, YYYY") : null
    // const vodStartDateTimeFormatted = window._.get(episode, "vod_scheduling.formatted_finish_at")
    const vodStartDateFormatted = window._.get(episode, "vod_scheduling.formatted_date_start_at")

    // const openAccordionButton = (
    //     <div
    //         className="episode-header__open-accordion"
    //         onClick={() => props.onToggleAccordionClicked()}
    //     >
    //         {props.open ? "-" : "+"}
    //     </div>
    // )

    const interanlDatesBoxClassName = fullWidth ? "col-12" : "col-xs-12 col-md-12 col-lg-3"
    const interanlDetailssBoxClassName = fullWidth ? "col-12" : "col-xs-12 col-md-12 col-lg-9"

    const maxChannelDurationInHours = window._.get(props, "episode.max_channel_duration")
    const maxChannelDuration = themeContext.showVodDurationInDays ? parseInt(maxChannelDurationInHours / 24) : maxChannelDurationInHours

    const episodeDate = (
        <>
            {props.eventLive ? (
                <div>
                    {props.eventLiveTitle ? (
                        <em
                            dangerouslySetInnerHTML={{ __html: props.eventLiveTitle + " " }} />
                    ) : null}
                    {
                        themeContext.dateOnNewLine ? <br /> : <span />
                    }
                    <em
                        className="">
                        {/* {localTime(props.eventLive, context.event.timezone).format("HH:mm")} */}
                        {props.eventLive}
                    </em>
                </div>
            ) : null}
            {props.eventVod ? (
                <div>
                    {props.eventVodTitle ? (
                        <em
                            dangerouslySetInnerHTML={{ __html: props.eventVodTitle }} />
                    ) : null}
                    {
                        themeContext.dateOnNewLine ? <br /> : <span />
                    }
                    <em>
                        {/* {localTime(props.eventVod, context.event.timezone).format("HH:mm")} */}
                        {props.eventVod}
                        {
                            window._.get(props, "episode.vod_channels_duration") ? (
                                <span> {props.upTo || "up to"} {maxChannelDuration} {props.hours || "hours"}</span>
                                // <span> {window._.get(props, "episode.vod_channels_duration").join("/")} hours</span>
                            ) : null
                        }
                    </em>
                </div>
            ) : null}
        </>
    );

    const episodeTitle = (
        <>
            {props.eventTitle}
            {
                props.eventSubTitle ? (
                    <div className="episode-header__subtitle mb-2">
                        <small>{props.eventSubTitle}</small>
                    </div>
                ) : null
            }
        </>
    )
    // linesOrder

    let firstRow = null;
    let secondRow = null;

    switch (linesOrder) {
        case 1:
            firstRow = (
                <strong>{episodeTitle}</strong>
            );;
            secondRow = episodeDate;
            break;
        default:
            firstRow = episodeDate;
            secondRow = episodeTitle;
    }

    const intro = props.eventTitle ? (
        <div className={classNames("episode-header col-12", props.className, {
            [`col-xs-${mobile > 12 ? 12 : mobile}`]: mobile,
            [`col-md-${tablet > 12 ? 12 : tablet}`]: tablet,
            [`col-lg-${desktop > 12 ? 12 : desktop}`]: desktop,
        }, {
            popup: props.popup
        })}>
            <Row className="episode-header__dates">
                {
                    props.hideDates ? null : (
                        <div className={classNames(interanlDatesBoxClassName)}>
                            {
                                liveStartDateFormatted ? (
                                    <>
                                        <div>{liveStartDateDayName}</div>
                                        <div>{liveStartDateFormatted}</div>
                                    </>
                                ) : null
                            }
                            {
                                vodStartDateFormatted && !liveStartDateTimeFormatted ? (
                                    // vodStartDateTimeFormatted && (liveStartDateTimeFormatted !== vodStartDateTimeFormatted) ? (
                                    <>
                                        <div>{vodStartDateDayName}</div>
                                        <div>{vodStartDateFormatted}</div>
                                    </>
                                ) : null
                            }
                        </div>
                    )
                }
                <div className={classNames(interanlDetailssBoxClassName)}>
                    {<div className="episode-header__dates--details">
                        {firstRow}
                    </div>}
                    <div className="episode-header__title mt-2 pt-2">
                        {secondRow}
                    </div>
                </div>
            </Row>
        </div>
    ) : null
    return intro
}

EpisodeTitle.className = "episodetitle"
EpisodeTitle.fluid = true
export default EpisodeTitle