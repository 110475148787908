import React, { useContext } from "react"
import classnames from "classnames"
import MainContext from "../../context/MainContext"
import Row from "../Row/Row"

const PageBody = (props) => {

    const context = useContext(MainContext)
    const className = classnames(props.className, {
        "container-fluid": !props.isCol && !props.narrow,
        "container": !props.isCol && props.narrow,
        "col": props.isCol,
    })

    const content = (context.isAppView || context.is2ColsView) ? (
        <Row relative={"relative"}>
            {props.children}
        </Row>
    ) : props.children

    const style = 0 ? {
        paddingTop: `${context.navHeight * 1.2}px`
    } : {}

    return (
        <div className={className} style={style}>
            {content}
        </div>
    )
}
export default PageBody