export const client = window.axios

export const buildQuery = (queryParams) => {
    const query = Object.keys(queryParams).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key])
    }).join('&');
    return query
}

export const get = async (url) => {
    const res = await client.get(url)
    return res
}

export const login = async (params) => {
    const res = await client.post(`login`, params)
    return res.data.data
}

export const logout = async (params) => {
    const res = await client.post(`logout`, params)
    return res.data.data
}

export const forceLogout = async (params) => {
    const res = await client.post(`force-logout`, params)
    return res.data.data
}

export const me = async (params) => {
    const query = window._.isEmpty(params) ? "" : `?${buildQuery(params)}`
    // const query = window._.isEmpty(params) ? "" : `?${buildQuery(params)}`
    const res = await client.get(`me${query}`)
    return res.data.data
}

export const detectEvent = async (params) => {
    const query = window._.isEmpty(params) ? "" : `?${buildQuery(params)}`
    const res = await client.get(`detect${query}`)
    return res.data.data
}

export const eventPreview = async (params) => {
    const query = window._.isEmpty(params) ? "" : `?${buildQuery(params)}`
    const res = await client.get(`preview${query}`)
    return res.data.data
}

export const getEvent = async (params) => {
    const query = window._.isEmpty(params) ? "" : `?${buildQuery(params)}`
    const res = await client.get(`${query}`)
    return res.data.data
}

export const getSpeakers = async (params) => {

    const { country, language, episodeId, internal } = params

    let queryParams = {
        lang_id: language,
        episode_id: episodeId,
        is_internal: internal,
    }

    if (country) {
        queryParams = {
            ...queryParams,
            country_id: country
        }
    }

    const res = await client.get(`speakers?${buildQuery(queryParams)}`)
    return res.data.data
}

export const getAgenda = async (params) => {

    const { country,
        language,
        episodeId,
        internal
    } = params

    let queryParams = {
        lang_id: language,
        episode_id: episodeId,
        is_internal: internal
    }

    if (country) {
        queryParams = {
            ...queryParams,
            country_id: country
        }
    }

    const res = await client.get(`agendas?${buildQuery(queryParams)}`)
    return res.data.data
}

export const getEligibleEpisodes = async (params) => {

    const {
        lang_id,
        country_id,
        registration_mode,
        internal,
        selectedEpisodes,
        selectedEpisodeCategories,
        all
    } = params

    let query = {
        country_id: country_id,
        lang_id: lang_id,
        is_internal: internal,
        episodes: selectedEpisodes,
        episode_categories_id: selectedEpisodeCategories,
        all: all
    }

    if (registration_mode) {
        query = {
            ...query,
            registration_mode: registration_mode
        }
    }

    const res = await client.get(`eligible-episodes?${buildQuery(query)}`)
    return res.data.data
}

export const getStyles = async (params) => {

    const res = await client.get(`layouts`)
    return res.data.data
}

export const getMedia = async (params) => {

    const res = await client.get(`media`)
    return res.data.data
}

export const getContents = async (params) => {
    const query = (params) ? `?${buildQuery(params)}` : ''
    const res = await client.get(`contents${query}`)
    return res.data.data
}

export const getLegals = async (params) => {
    const { country, language, internal } = params
    let queryParams = {}

    if (country) {
        queryParams = {
            ...queryParams,
            country_id: country
        }
    }
    if (language) {
        queryParams = {
            ...queryParams,
            lang_id: language
        }
    }
    if (internal) {
        queryParams = {
            ...queryParams,
            is_internal: internal
        }
    }
    const res = await client.get(`legals?${buildQuery(queryParams)}`)
    return res.data.data
}

export const getPlayerSources = async (params) => {
    const res = await client.get(`sources?${buildQuery(params)}`)
    return res.data.data
}

export const loadQuestions = async (params) => {
    const res = await client.get(`questions?${buildQuery(params)}`)
    return res.data.data
}

export const markQuestionAsRead = async (questionId) => {
    const res = await client.post(`questions/${questionId}/mark-as-read`)
    return res.data.data
}

export const deleteQuestion = async (questionId) => {
    const res = await client.delete(`questions/${questionId}`)
    return res.data.data
}

// Evaluation form
export const sendEvaluationFormAnswers = async (params) => {
    const res = await client.post(`evaluationform/answer`, params)
    return res.data.data
}

export const playerWhebHook = async (params) => {
    // player-event
    const res = await client.post(`webhooks/player-event`, params)
    return res.data.data
}

export const playerInsight = async (params) => {
    // player-event
    const res = await client.post(`webhooks/player-insight`, params)
    return res.data.data
}

export const selectEpisodeWebHook = async ({ episodeId }) => {
    // player-event
    const url = [
        "select-episode",
        episodeId
    ].join("/")
    const res = await client.post(url)
    return res.data.data
}

export const setSubscriberChannelPresence = async ({ channelId }) => {
    // player-event
    const url = [
        "subscriber-channel-presence"
    ].join("/")
    const res = await client.post(url, { channel_id: channelId })
    return res.data.data
}

export const resetSubscriberChannelPresence = async () => {
    // player-event
    const url = [
        "reset-subscriber-channel-presence"
    ].join("/")
    const res = await client.post(url)
    return res.data.data
}