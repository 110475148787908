import React, { useState, useEffect, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import classNames from "classnames"
import MainContext from '../../context/MainContext';
import wrapSection from '../../hoc/wrapSection';
import Row from '../../ui/Row/Row';
import "./Agenda.scss"
import { getThemeConfig, localTime, tzAbbr } from '../../utils/functions';
import Spin from '../../ui/Spin/Spin';
import { LIVE, PRE_LIVE } from '../../config/channelStatus'
import _ from 'lodash';
import { CSSTransition } from 'react-transition-group';
import { SpeakerCard } from '../Speakers/Speakers';

const AgendaRow = ({ allowNested, groupedSeakers, hideAgendaDatetime, sidebar, ...props }) => {

    const context = useContext(MainContext)
    const [showDate, setShowDate] = useState(false)
    const [visible, setVisible] = useState(false)
    const [expanded, setExpanded] = useState(false)
    useEffect(() => {
        window.setTimeout(() => {
            setVisible(true)
        }, props.timeout)
    }, [])

    useEffect(() => {
        // consoleLog("context.channelStatus", context.channelStatus)
        const canShowDate = (
            [
                LIVE,
                PRE_LIVE
            ].includes(context.channelStatus) ||
            !context.channelStatus
        )
        // consoleLog("context.channelStatus", canShowDate)
        setShowDate(canShowDate)

    }, [context.channelStatus])

    const { grid, isAppView } = props
    let { episode } = props
    // const Speakers = styled.div`
    //     color: ${props.style.tertiaryColor};
    // `;

    const nestedAgenda = props.children
    const showNestedAgenda = allowNested && nestedAgenda?.length
    // console.log("showNestedAgenda", showNestedAgenda)
    const vertical = props.vertical

    const toggle = () => {
        setExpanded(expanded => {
            return !expanded
        })
    }

    const openAccordionButton = showNestedAgenda ? (
        <div
            className="speaker-card__open-accordion"
            onClick={toggle}
        >
            {expanded ? "-" : "+"}
        </div>
    ) : null

    const title = (
        <>
            <div className={classNames("agenda-row__title col", {
                "col-10": !isAppView,
                "col-12": isAppView,
            })}>
                {openAccordionButton}
                <div dangerouslySetInnerHTML={{
                    __html: props.title
                }}
                />
            </div>
        </>
    )

    const subtitle = (
        <>
            <div className={classNames("agenda-row__subtitle col mt-1", {
                "col-10": !isAppView,
                "col-12": isAppView,
            })}>
                {openAccordionButton}
                <div dangerouslySetInnerHTML={{
                    __html: props.description
                }}
                />
            </div>
        </>
    )

    if (!episode) {
        episode = window._.get(context, "episode")
    }

    const speechTime = ((
        episode.has_future_live_events &&
        showDate
    ) || props.showDate) &&
        !hideAgendaDatetime ?
        `${localTime(props.start_at, props.timezone).format("HH:mm")} - ${localTime(props.start_at, props.timezone).add(props.duration, "minutes").format("HH:mm")} (${props.duration} ${window._.get(context, "contents.minutes") || 'minutes'})` : // ${props.timezone}
        `${props.duration} ${window._.get(context, "contents.minutes") || 'minutes'}`

    const time = (
        <div className={classNames("agenda-row__time col", {
            "col-2": !isAppView,
            "col-12": isAppView,
            "sidebar": sidebar
        }, {
            "text-right": props.isZigZagTheme
        })}>
            {speechTime}
        </div>
    )

    // const duration = (!props.isZigZagTheme) ? (
    //     <div className="col-2 agenda-row__duration">
    //         {props.duration} minutes
    //     </div>
    // ) : null

    const gridStyle = grid ? {
        [`col-xs-${grid.mobile}`]: grid.mobile,
        [`col-md-${grid.tablet}`]: grid.tablet,
        [`col-lg-${grid.desktop}`]: grid.desktop,
    } : null

    return (
        <CSSTransition
            // key={`speaker - ${item.id} `}
            classNames="transition"
            unmountOnExit
            in={visible}
            timeout={props.timeout}
        >
            <div className={classNames("agenda-row", {
                "sidebar": sidebar
            })}>
                {
                    groupedSeakers ? (
                        <Row>
                            <div className='col-xs-12 col-md-6 col-lg-6 agenda-row__agenda-card'>
                                {time}
                                {title}
                                {subtitle}
                            </div>
                            <div className='col-xs-12 col-md-6 col-lg-6 agenda-row__speaker-card'>
                                {
                                    props.speakers.map((speaker, speakerIndex) => (
                                        <SpeakerCard
                                            {...speaker}
                                            fullWidth={props.fullWidth}
                                            // isGridLayout={isGridLayout}
                                            // thumbsRounded={thumbsRounded}
                                            // textCentered={textCentered}
                                            // thumbBordered={thumbBordered}
                                            layout={"grid"}
                                            // pair={pair}
                                            grid={grid}
                                            // timeout={timeout}
                                            key={`speaker-name-${speakerIndex}`}
                                        />
                                    ))}
                            </div>
                        </Row>
                    ) : (
                        props.isZigZagTheme ? (
                            <Row>
                                {title}
                                {subtitle}
                                {time}
                            </Row>
                        ) : (
                            <Row>
                                {time}
                                {title}
                                {subtitle}
                            </Row>
                        )
                    )

                }
                {
                    showNestedAgenda ? (
                        <div className={classNames("agenda-nested__container", {
                            "expanded": expanded
                        })}>
                            <Agenda
                                // title={contents.agendaTitle}
                                // language={language}
                                // country={country}
                                // episodeId={episodeId}
                                // internal={internal}
                                fullWidth={true}
                                items={props.children}
                                groupedSeakers={groupedSeakers}
                            // allowNested={true}
                            />
                        </div>
                    ) : groupedSeakers ? null : (
                        <Row>
                            <div className={classNames({
                                "col-10": !props.isZigZagTheme,
                                "col-12": props.isZigZagTheme,
                            }, gridStyle)}>
                                <div className="agenda-row__speakers">
                                    {
                                        vertical ?
                                            props.speakers.map((speaker, speakerIndex) => (
                                                <div key={`speaker-name-${speakerIndex}`}>
                                                    {speaker.firstname} {speaker.lastname}
                                                </div>
                                            )) :
                                            props.speakers.map(speaker => {
                                                return `${speaker.firstname} ${speaker.lastname}`
                                            }).join(", ")
                                    }
                                </div>
                            </div>
                        </Row>
                    )
                }
            </div >
        </CSSTransition>
    )
}

const AgendaDay = ({ allowNested, groupedSeakers, hideAgendaDatetime, sidebar, ...props }) => {

    const context = useContext(MainContext)
    const inverted = getThemeConfig(context, "agenda.date") === "inverted"

    const Day = styled.h3`
        display: inline-block;
        color: ${inverted ? props.style.backgroundColor : props.style.primaryColor};
        background-color: ${inverted ? props.style.primaryColor : "transparent"};
        padding: ${inverted ? ".5rem 1rem" : "0"}
    `;

    const agendaTheme = getThemeConfig(context, "agenda.theme")
    const hideDay = getThemeConfig(context, "agenda.hide-day")
    const isZigZagTheme = agendaTheme === "zigzag"
    const className = (props.isAppView) ? "" : classNames({
        "col-12": !isZigZagTheme,
        "col-9": isZigZagTheme
    }, {
        "offset-3": (props.indexDay % 2) && isZigZagTheme
    })
    const vertical = getThemeConfig(context, "speakers.list-direction") === "vertical"

    const eventDayCOntent = (
        <Day className="event-day">
            {localTime(props.day, props.timezone).format("D-M-Y")} {tzAbbr(props.day, props.timezone)}
            {/* {moment(props.day).tz(props.timezone).format("D-M-Y")} */}
        </Day>
    )
    const eventDay = (props.isAppView) ? (
        eventDayCOntent
    ) : (
        <Row>
            <div className="col-12">
                {eventDayCOntent}
            </div>
        </Row>
    )

    return (
        <div className={className}
            key={`agenda-day-${props.indexDay}`}
        >
            {hideDay ? null : eventDay}
            {/* <div> */}
            {props.items[props.day].map((item, index) => {
                const timeout = (100 * index)
                return (
                    <AgendaRow
                        {...item}
                        isZigZagTheme={isZigZagTheme}
                        vertical={vertical}
                        isAppView={props.isAppView}
                        timezone={props.timezone}
                        episode={props.episode}
                        style={props.style}
                        grid={props.grid}
                        showDate={props.showDate}
                        hideAgendaDatetime={hideAgendaDatetime}
                        timeout={timeout}
                        allowNested={allowNested}
                        groupedSeakers={groupedSeakers}
                        sidebar={sidebar}
                        key={`agenda-row-${props.indexDay}-${item.id}`} />
                )
            })}
            {/* </div> */}
        </div>
    )
}

const Agenda = ({ allowNested, groupedSeakers, sidebar, ...props }) => {

    // const [items, setItems] = useState([])
    // const [country, setCountry] = useState()
    // const [language, setLanguage] = useState()
    // const [episodeId, setepisodeId] = useState()
    // const [loadingContents, setLoadingContents] = useState(false)
    // const [lastConfig, setLastConfig] = useState()
    // const [loading, setLoading] = useState(false)

    const context = useContext(MainContext)
    const themeContext = useContext(ThemeContext);

    const { episode } = props
    // const agendaTheme = getThemeConfig(context, "agenda")

    // useEffect(() => {
    //     fetchData(loadingContents)
    //     return () => { setItems([]) }
    // }, [props.country, props.language, props.episodeId, loadingContents])

    // useEffect(() => {
    //     setLoadingContents(context.loadingContents)
    // }, [context.loadingContents])

    // useEffect(() => {
    //     // const grouped = window._.groupBy(context.agenda, (item) => {
    //     //     return item.day
    //     // })

    //     const agenda = (
    //         props.items === undefined &&
    //         !window._.isEmpty(props.items)
    //     ) ? context.agenda : props.items
    //     consoleLog("agenda", agenda)
    //     setItems(agenda)
    // }, [context.agenda, props.items])

    // useEffect(() => {
    //     if (props.country) {
    //         setCountry(props.country)
    //     }
    // }, [props.country])

    // useEffect(() => {

    //     if (props.language) {
    //         setLanguage(props.language)
    //     }
    // }, [props.language])

    // useEffect(() => {
    //     if (props.episodeId) {
    //         setepisodeId(props.episodeId)
    //     }
    // }, [props.episodeId])

    // const fetchData = async (disabled) => {
    //     const { country, language, episodeId, internal } = props
    //     console.log("fetchData", country, language, episodeId, !loadingContents)
    //     if (country && language && episodeId && !disabled && lastConfig !== [country, language, episodeId, internal]) {
    //         setLoading(true)
    //         setLastConfig([country, language, episodeId, internal])
    //         try {
    //             const res = await getAgenda({ country, language, episodeId, internal })
    //             const grouped = window._.groupBy(res, (item) => {
    //                 return item.day
    //             })


    //             setItems(grouped)
    //         }
    //         catch (e) { }
    //         finally {
    //             setLoading(false)
    //         }
    //     }
    // }
    const agenda = (
        props.items === undefined ||
        props.items === null ||
        props.items?.length === 0
    ) ? (context.agenda || []) : props.items

    const grouped = window._.groupBy(agenda, (item) => {
        return item.day
    })
    let agendaDays = []
    const layout = getThemeConfig(context, "agenda")
    const grid = (!window._.isEmpty(layout)) ? layout["grid"] : null

    const isAppView = (
        context.isAppView ||
        context.is2ColsView
    )

    if (context.loadingContents) {
        agendaDays = <div><Spin /></div>
    }
    else {
        Object.keys(grouped).forEach((day, indexDay) => {
            {
                const pair = indexDay % 2
                agendaDays.push(
                    <AgendaDay
                        episode={episode}
                        pair={pair}
                        indexDay={indexDay}
                        timezone={context.event.timezone}
                        style={themeContext}
                        day={day}
                        key={`agenda-day-${indexDay}`}
                        items={grouped}
                        grid={grid}
                        allowNested={allowNested}
                        showDate={props.showDate}
                        hideAgendaDatetime={props.hideAgendaDatetime}
                        groupedSeakers={groupedSeakers}
                        sidebar={sidebar}
                        isAppView={isAppView} />
                )
            }
        })
    }
    return (isAppView) ? (

        agendaDays

    ) : (
        <Row>
            {agendaDays}
        </Row>
    )
}
Agenda.className = "agenda"
export default wrapSection(Agenda)