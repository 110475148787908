import classNames from "classnames"
import React, { useEffect, useState, useContext } from "react"
import MainContext from "../../context/MainContext"
import QaAUserForm from "../../forms/QaAUserForm"
import Row from "../../ui/Row/Row"
import { consoleLog, getQaAEnabled, setQaAEnabled } from "../../utils/functions"

const QandA = (props) => {

    const [formEnabled, setFormEnabled] = useState(false)

    const context = useContext(MainContext)

    useEffect(() => {
        consoleLog("window.Pusher", window.Pusher)
        window.Pusher.bind("App\\Events\\ChangeQaaStatus", (data) => {
            consoleLog(`QandA ${JSON.stringify(data)}`);
            _setQaAEnabled(parseInt(data.status) === 1)
        })

    }, [context.channelId])

    useEffect(() => {

        let checkValue = _getQaAEnabled()
        consoleLog("checkValue1", checkValue)
        if (checkValue === undefined) {
            checkValue = parseInt(context.episode.qaa_enabled)
        }
        consoleLog("checkValue2", checkValue)
        if (checkValue !== formEnabled) {
            _setQaAEnabled(checkValue === 1)
        }
    }, [context.episode])

    const _setQaAEnabled = (value) => {
        setQaAEnabled(value)
        setFormEnabled(value)
    }

    const _getQaAEnabled = () => {
        getQaAEnabled()
    }

    return (
        <>
            <div
                className={classNames("qaa-subscriber", {
                    "mb-4": !props.isIframe,
                    "px-3": props.isIframe,
                    "p-0": props.isIframe,
                })}
            >
                <Row>
                    <div
                        className={classNames("col", {
                            "offset-lg-2 col-lg-8": !props.isIframe,
                            "col-lg-12": props.isIframe,
                        })}>
                        <QaAUserForm enabled={formEnabled} inline />
                    </div>
                </Row>
            </div>
        </>
    )
}

export default QandA