import "./FakePlayer.scss"

const FakePlayer = (props) => {

    return (
        <div className="fake-player">
            <div className="fake-player__content">
                {props.children}
            </div>
        </div>
    )
}

export default FakePlayer