import './bootstrap'
import {
    BrowserRouter as Router
} from "react-router-dom";
import Adam from "./components/App/Adam"

// Sentry.init({
//     dsn: "https://653294ccb89f4276a2a09ec37a106c6f@o546848.ingest.sentry.io/5700364",
//     integrations: [new Integrations.BrowserTracing()],

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
// });

// window.Pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
//     // key: process.env.REACT_APP_PUSHER_APP_KEY,
//     cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
//     forceTLS: true,
//     authEndpoint: process.env.REACT_APP_API_BASEURL + 'broadcasting/auth',
//     pongTimeout: 6000,
//     activityTimeout: 30000,
//     auth: {
//         headers: {
//             'Authorization': "Bearer " + getAuthToken()
//         }
//     }
// });


const MainApp = () => {
    return (
        <Router>
            <Adam />
        </Router>
    )
}
export default MainApp