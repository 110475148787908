import React from 'react';
const SlidoIframe = (props) => {

    const minHeight = props.minHeight || "560px"

    return (
        <div dangerouslySetInnerHTML={{
            __html: `<iframe src="${props.url}" height="100%" width="100%" frameBorder="0" style="min-height: ${minHeight};" title="Slido"></iframe>`
        }} />
    )
}

SlidoIframe.className = "slido"
export default SlidoIframe