import React, { useEffect, useState, useContext } from "react"
import AdamModal from "../AdamModal/AdamModal";
import MainContext from "../../context/MainContext";
import Container from "../../ui/Container/Container";
import Row from "../../ui/Row/Row";

const FormIsNotValidModal = (props) => {
    const [modalOpen, setModalOpen] = useState(false)
    const context = useContext(MainContext)

    useEffect(() => {
        setModalOpen(props.open)
    }, [props.open])



    const grid = (
        <Container fluid={true} className="text-center virtual-world-modal">
            <Row className="mt-3 text-center">
                {
                    context.loginErrors ? (
                        <div
                            className="pb-4 col-12"
                            style={{ marginTop: "1rem" }}>
                            {"Please complete all the required fields"}
                        </div>
                    ) : null
                }
            </Row>
        </Container >
    )

    const modal = (
        <AdamModal
            content={grid}
            open={modalOpen}
            // fluidHeader={true}
            headerClass="d-none d-sm-block"
            // transparentHeader={true}
            onClose={() => props.onClose()}
        />
    )

    return modal
}

export default FormIsNotValidModal