import { useContext } from 'react';

import MainContext from '../../../context/MainContext';
import useRegistrtionForm from '../../../hooks/useRegistrtionForm';
import { capitalize } from '../../../utils/functions';

const ApLungCancer2023Form = (props) => {
    const context = useContext(MainContext)

    const {
        output,
        countries,
        allCountries,
        getValue,
        getLegals,
    } = useRegistrtionForm()

    let fields = 1
        ? [
            {
                elementType: "hidden",
                id: "episodes",
                name: "episodes",
                value: window._.map(props.openEpisodes, (item => item.id))//[window._.map(props.openEpisodes, (item => item.id))],
            },
        ]
        : [];


    const languagesList = window._.get(context, "event.languages") || []
    const languageField = languagesList.length > 1 ? {
        elementType: "select",
        id: "lang_id",
        name: "lang_id",
        required: true,
        // invalidText: "Invalid error message.",
        label: window._.get(context, "contents.language") || "Language",
        placeholder: window._.get(context, "contents.language") || "Language",
        value: getValue("lang_id") || context.language,
        options: window._.get(context, "event.languages") || []
    } : {
        elementType: "hidden",
        id: "lang_id",
        name: "episodes",
        value: window._.get(languagesList[0], "value"),
    }

    fields.push(languageField)

    const getSpecialtyOptions = () => {
        const values = [
            "Clinical Oncologist",
            "Medical Oncologist",
            "Pulmonologist",
            "Radiation Oncologist",
            "Toracic Surgeon",
            "Others"
        ]

        const specialtiesTranslations = {
            "en": {
                "Clinical Oncologist": "Clinical Oncologist",
                "Medical Oncologist": "Medical Oncologist",
                "Pulmonologist": "Pulmonologist",
                "Radiation Oncologist": "Radiation Oncologist",
                "Toracic Surgeon": "Toracic Surgeon",
                "Others": "Others"
            }
        }

        return values.map(item => {
            return {
                value: item,
                label: window._.get(specialtiesTranslations, `${getValue("lang_id")}.${item}`) || window._.get(specialtiesTranslations, `en.${item}`)
            }
        })
    }

    if (context.event.has_internal) {
        fields.push({
            elementType: "select",
            id: "is_internal",
            name: "is_internal",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.internal") || "Internal",
            value: getValue("is_internal") || "0",
            options: [{
                value: 0,//internal_false_value_label
                label: window._.get(context, "contents.internalFalseValueLabel") || "External",
            }, {
                value: 1,//internal_false_value_label
                label: window._.get(context, "contents.internalTrueValueLabel") || "Internal",
            }]
        })
    }


    // if (context.country || context.internal) {
    fields = fields.concat([
        {
            elementType: "text",
            id: "firstname",
            name: "firstname",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.firstname") || "firstname",
            placeholder: window._.get(context, "contents.firstname") || "firstname",
            value: getValue("firstname"),
        },
        {
            elementType: "text",
            id: "lastname",
            name: "lastname",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.lastname") || "lastname",
            placeholder: window._.get(context, "contents.lastname") || "lastname",
            value: getValue("lastname"),
        },
    ])

    if (!parseInt(context.internal)) {
        fields = fields.concat([
            {
                elementType: "select",
                id: "country_id",
                name: "country_id",
                required: true,
                // invalidText: "Invalid error message.",
                label: window._.get(context, "contents.country") || "Country",
                placeholder: window._.get(context, "contents.country") || "Country",
                value: getValue("country_id") || context.country,
                disabled: parseInt(context.internal),
                options: countries || []
            },
            {
                elementType: "text",
                id: "istitution",
                name: "istitution",
                required: true,
                label: window._.get(context, "contents.istitution") || "Istitution",
                placeholder: capitalize(window._.get(context, "contents.istitution")) || "Istitution",
                value: getValue("istitution"),
            },
            {
                elementType: "select",
                id: "specialty",
                name: "specialty",
                required: true,
                // invalidText: "Invalid error message.",
                label: window._.get(context, "contents.specialty") || "Specialty",
                placeholder: window._.get(context, "contents.specialty") || "Specialty",
                value: getValue("specialty") || "Hematologist",
                // disabled: parseInt(context.internal),
                options: getSpecialtyOptions()
            }]
        )
        if (getValue("specialty") === "Others") {
            fields.push({
                elementType: "text",
                id: "other_specialty",
                name: "other_specialty",
                required: true,
                // invalidText: "Invalid error message.",
                label: window._.get(context, "contents.otherSpecialty") || "Other specialty",
                placeholder: window._.get(context, "contents.otherSpecialty") || "Other specialty",
                value: getValue("other_specialty"),
            })
        }
    }
    else {
        fields = fields.concat([
            {
                elementType: "select",
                id: "country_id_of_work",
                name: "country_id_of_work",
                required: true,
                // invalidText: "Invalid error message.",
                label: window._.get(context, "contents.countryOfWork") || "Country of work",
                placeholder: window._.get(context, "contents.countryOfWork") || "Country of work",
                value: getValue("country_id_of_work") || context.country,
                disabled: !parseInt(context.internal),
                options: allCountries || []
            },
            {
                elementType: "select",
                id: "department",
                name: "department",
                required: true,
                // invalidText: "Invalid error message.",
                label: window._.get(context, "contents.department") || "Department",
                placeholder: window._.get(context, "contents.department") || "Department",
                value: getValue("department") || "MRL/GMSA",
                options: [{
                    value: "MRL/GMSA",
                    label: "MRL/GMSA"
                }, {
                    value: null,
                    label: "Other"
                }]
            }]
        )
        if (getValue("department") === "Other") {
            fields.push({
                elementType: "text",
                id: "other_department",
                name: "other_department",
                required: true,
                label: window._.get(context, "contents.otherDepartment") || "Other department",
                placeholder: window._.get(context, "contents.otherDepartment") || "Other department",
                value: getValue("other_department"),
            })
        }
    }

    fields.push(
        {
            elementType: "email",
            id: "email",
            name: "email",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.email") || "email",
            placeholder: window._.get(context, "contents.email") || "email",
            value: getValue("email"),
        }
    )

    if (context.legals) {
        fields = fields.concat(getLegals())
    }
    // }

    return output(fields)
}

export default ApLungCancer2023Form