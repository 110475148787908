/* eslint-disable default-case */
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { buildQuery } from '../api/functions';
import Form from '../components/Form/Form';
import MainContext from '../context/MainContext';
import { consoleLog, getContents, getThemeConfig } from '../utils/functions';
import useRegistrtionFlow from './useRegistrtionFlow';

function useRegistrtionForm(props = {}) {

    const context = useContext(MainContext)
    const [version, setVersion] = useState(1);
    const [previousValues, setPreviousValues] = useState({});
    const [defaultValues, setDefaultValues] = useState({});
    const [touched, setTouched] = useState([]);
    const themeContext = useContext(ThemeContext);

    const { forceEnableLoginButton, onSubmitDisabled, multiselectFields } = props

    const {
        _isMsdCtio2022
    } = useRegistrtionFlow()

    useEffect(() => {
        if (_isMsdCtio2022()) {
            context.setRegistrationMode(getValue("registration_mode"))
        }
    }, [])

    let newRequiredTos = []
    const getEndpoint = () => {
        const query = buildQuery({});
        return [
            "register"
        ].join("/") + `?${query}`;
    }

    const getValue = (name, type) => {
        const value = !window._.isEmpty(defaultValues) ? defaultValues[name] : null;

        switch (type) {
            case "bool":
                return value !== 0;
            default:
                return value || "";
        }
    }

    const onChange = (key, value) => {
        // console.log("onChange", key, value);
        setPreviousValues(defaultValues)
        switch (key) {
            case "lang_id":
                context.setLanguage(value)
                break
            case "country_id":
                context.setCountry(value)
                break
            case "country_id_of_work":
                context.setCountryOfWork(value)
                break
            case "is_internal":
                context.setInternal(value)
                break
            case "registration_mode":
                context.setRegistrationMode(value)
                break
            case "episode_categories_id":
                context.setSelectedEpisodeCategories(value)
                break
            case "episodes_id":
                context.setSelectedEpisodes(value)
                break
            case "episodes":
                context.setSelectedEpisodes(value)
                break
            case "stream_id":
                context.setSelectedStreamId(value)
                break
        }
        setDefaultValues({
            ...defaultValues,
            [key]: value
        });
    }

    const onSubmit = (params) => {
        context.onRegistred(true)
    }

    const countries = window._.get(context, "event.countries")
    // const countries = [{
    //     id: null,
    //     label: window._.get(context, "contents.country") || "Country",
    //     code: null
    // }].concat(window._.get(context, "event.countries"))

    const allCountries = window._.get(context, "event.all_countries")
    // const allCountries = [{
    //     id: null,
    //     label: window._.get(context, "contents.countryOfWork") || "Country of work",
    //     code: null
    // }].concat(window._.get(context, "event.all_countries"))

    const logo = (getThemeConfig(context, "registration-form.logo")) ?
        !window._.isEmpty(themeContext.mediaRegistrationFormLogoDesktop) ? (
            <div className="regstration-form__logo">
                <img
                    src={themeContext.mediaRegistrationFormLogoDesktop.url}
                    className="d-inline-block align-center"
                    alt={context.siteTitle} />
            </div>
        ) : null : null

    const disableSubmit = (reqs) => {
        let disable = false
        if (reqs) {
            reqs.forEach(item => {
                if (!getValue(item)) {
                    disable = true
                }
            })
        }
        else {
            disable = true
        }
        return disable
    }

    const getLegals = () => {
        let _fields = []
        if (context.legals) {
            context.legals.forEach(item => {
                consoleLog("context.legals", item.field_name)
                if (!item.field_name) {
                    return false
                }
                _fields.push({
                    elementType: "checkbox",
                    id: item.field_name,
                    name: item.field_name,
                    required: item.is_required,
                    hide_asterisk: item.hide_asterisk,
                    // invalidText: "Invalid error message.",
                    // label: `${getContents(context, "iAgree")} ${getContents(context, "cookiePolicyLinkText")}`,
                    labelHtml: item.checkbox_label,
                    popupContent: item.content,
                    value: getValue(item.field_name),
                    section: "footer"
                })
                if (item.is_required) {
                    newRequiredTos.push(item.field_name)
                }
            })
        }

        return _fields
    }

    const cleanValues = (values) => {
        let cleaned = {}
        Object.keys(values).forEach(k => {

            let value = (values[k].value !== undefined && values[k].value !== null) ? values[k].value : values[k]
            if (multiselectFields?.indexOf(k) >= 0) {
                value = value?.map(item => item.value)?.join(", ")
            }
            cleaned = {
                ...cleaned,
                [k]: value
            }
        })

        return cleaned
    }

    const getSpecialtyOptions = (langId) => {
        let options = []
        if (!langId) {
            langId = context.event?.default_language
        }
        const specialties = context.event?.registration_form_specialties[langId] || {}
        Object.keys(specialties).forEach(k => {
            options.push({
                value: k,
                label: specialties[k]
            })
        })

        return options
    }

    const onTouched = (e) => {
        setTouched(e)
    }

    const updateValues = (values) => {
        setDefaultValues(values)
        setVersion(version => ++version)
    }

    const output = (fields, extraProps) => {
        return (
            <div className="regstration-form regstration-from__wrapper">
                {logo}
                <Form
                    key={`registration-form-version-${version}`}
                    fields={fields}
                    endpoint={getEndpoint()}
                    method={"post"}
                    confirmBeforeSubmit={true}
                    onSubmit={(e) => onSubmit(e)}
                    onChange={(key, val) => onChange(key, val)}
                    submitButtonLabel={getContents(context, "register")}
                    disableSubmit={disableSubmit(newRequiredTos)}
                    cleanValues={(values) => cleanValues(values)}
                    isRegistration={true}
                    forceEnableLoginButton={forceEnableLoginButton}
                    onSubmitDisabled={onSubmitDisabled}
                    onTouched={onTouched}
                    {...extraProps}
                // modalOnEmptyEpisodesList={_isMsdCtio2022()}
                />
            </div>
        )
    }
    return {
        output: (fields, extraProps) => output(fields, extraProps),
        countries: countries,
        allCountries: allCountries,
        touched: touched,
        formValues: defaultValues,
        previousFormValues: previousValues,
        getLegals: getLegals,
        getEndpoint: getEndpoint,
        getSpecialtyOptions: getSpecialtyOptions,
        // setFields: (value) => setFields(value),
        getValue: (name, type) => getValue(name, type),
        onChange: (key, value) => onChange(key, value),
        onSubmit: (params) => onSubmit(params),
        updateValues: (values) => updateValues(values)
    };
}

export default useRegistrtionForm