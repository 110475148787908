import React, { useContext } from 'react';
import MainContext from '../../context/MainContext';
import wrapSection from '../../hoc/wrapSection';
import useRegistrtionFlow from '../../hooks/useRegistrtionFlow';
import Button from '../../ui/Button/Button';
import { getContents } from '../../utils/functions';
import ApIdForumCovid19Form from '../ApIdForumCovid19Form/ApIdForumCovid19Form';
import ApidGnWebinarRegistrationForm from '../ApidGnWebinarRegistrationForm/ApidGnWebinarRegistrationForm';
import ApIdGyn2023Form from '../ApIdGyn2023Form/ApIdGyn2023Form';
import ApidRegistrationForm from '../ApidRegistrationForm/ApidRegistrationForm';
import Eurogin2021RegistrationForm from '../Eurogin2021RegistrationForm/Eurogin2021RegistrationForm';
import EdizioniDmRegistrationForm from '../Form/EdizioniDmRegistrationForm/EdizioniDmRegistrationForm';
import MSDApNiasBRegistrationForm from '../Form/MSDApNiasRegistrationForm/MSDApNiasBRegistrationForm';
import MSDApNiasRegistrationForm from '../Form/MSDApNiasRegistrationForm/MSDApNiasRegistrationForm';
import MSDApNmbUpdates2021RegistrationForm from '../Form/MSDApNmbUpdates2021RegistrationForm/MSDApNmbUpdates2021RegistrationForm';
import MSDAPSmartMeetingRegistrationForm from '../Form/MSDAPSmartMeetingRegistrationForm/MSDAPSmartMeetingRegistrationForm';
import MSDCovidSassRegistrationForm from '../Form/MSDCovidSassRegistrationForm/MSDCovidSassRegistrationForm';
import MSDCtioRegistrationForm from '../Form/MSDCtioRegistrationForm';
import MSDGlobalCmvRegistrationForm from '../Form/MSDGlobalCmvRegistrationForm/MSDGlobalCmvRegistrationForm';
import MSDGlobalHpv2021RegistrationForm from '../Form/MSDGlobalHpv2021RegistrationForm/MSDGlobalHpv2021RegistrationForm';
import MSDHncrResearchForum2022RegistrationForm from '../Form/MSDHncrResearchForum2022RegistrationForm/MSDHncrResearchForum2022RegistrationForm';
import MSDIdForum2021RegistrationForm from '../Form/MSDIdForum2021RegistrationForm/MSDIdForum2021RegistrationForm';
import MSDJcapMatchRegistrationForm from '../Form/MSDJcapMatchRegistrationForm/MSDJcapMatchRegistrationForm';
import MSDJCAPRegistrationForm from '../Form/MSDJCAPRegistrationForm/MSDJCAPRegistrationForm';
import OrganonVirtualSassRegistrationForm from '../Form/OrganonVirtualSassRegistrationForm/OrganonVirtualSassRegistrationForm';
import MsdApidForumCovid19 from '../MsdApidForumCovid19/MsdApidForumCovid19';
import MSDHPVRegistrationForm from '../MSDHPVRegistrationForm/MSDHPVRegistrationForm';
import MSDVaccinesAcademyRegistrationForm from '../MSDVaccinesAcademyRegistrationForm/MSDVaccinesAcademyRegistrationForm';
import MSDVaccinesAcademyRegistrationForm2022 from '../MSDVaccinesAcademyRegistrationForm2022/MSDVaccinesAcademyRegistrationForm2022';
import RegistrationForm from '../RegistrationForm/RegistrationForm';
import SpecialRegistrationForm from '../SpecialRegistrationForm/SpecialRegistrationForm';
import ApLungCancer2023Form from '../Form/ApLungCancer2023Form/ApLungCancer2023Form';
import ApLungCancer2023FormVer2 from '../Form/ApLungCancer2023Form/ApLungCancer2023FormVer2';
import MsdPedVaccines2023 from '../MsdPedVaccines2023/MsdPedVaccines2023';
import IsploraWebinarRegistrationForm from '../Form/IsploraWebinarRegistrationForm/IsploraWebinarRegistrationForm';
import GamRegistrationForm from '../Form/GamRegistrationForm/GamRegistrationForm';

const Registration = (props) => {
    const context = useContext(MainContext)
    const {
        _isMsdCtio2022
    } = useRegistrtionFlow()
    let output = null

    const episodes = window._.get(context, "event.episodes")
    const allEpisodes = window._.get(context, "allEpisodes", [])

    const openEpisodes = episodes.filter(item => {
        return item.has_future_events && item.is_subscriptions_open
    })
    const allOpenEpisodes = allEpisodes.filter(item => {
        // console.log("emptyList", item.id, (item.has_future_events && item.is_subscriptions_open))
        return item.has_future_events && item.is_subscriptions_open
    })

    const formProps = {
        ...props,
        openEpisodes: openEpisodes
    }

    const emptyList = (
        (openEpisodes?.length < 1 && (!_isMsdCtio2022() || context.event.force_hide_registration_form))
        //  ||
        // (allOpenEpisodes?.length < 1 && _isMsdCtio2022())
    )
    if (emptyList) {
        const goToLoginForm = (
            <div
                onClick={() => context.setLoginModalOpen(true)}
                className="registration-form__gotologin"
                dangerouslySetInnerHTML={{ __html: getContents(context, "goToLoginForm") }}>
            </div>
        )
        output = (
            <>
                <div dangerouslySetInnerHTML={{
                    __html: getContents(context, "registrationClosed")
                }} />
                {goToLoginForm}
            </>
        )
    }
    else if (context.registered) {
        output = (
            <>
                <div dangerouslySetInnerHTML={{
                    __html: getContents(context, "thankyouMessage")
                }} />
                <Button
                    size={"sm"}
                    onClick={() => context.setLoginModalOpen(true)}
                    className="btn btn-outline-success my-2 my-sm-0"
                >{window._.get(context, "contents.login")}</Button>
            </>
        )
    }
    else {
        switch (
        parseInt(window._.get(context, "event.registration_form"))
        ) {
            case 1:
                output = (
                    <SpecialRegistrationForm {...formProps} />
                )
                break
            case 2:
                output = (
                    <ApidRegistrationForm {...formProps} />
                )
                break
            case 3:
                output = (
                    <Eurogin2021RegistrationForm {...formProps} />
                )
                break
            case 4:
                output = (
                    <MSDHPVRegistrationForm {...formProps} />
                )
                break
            case 5:
                output = (
                    <MSDIdForum2021RegistrationForm {...formProps} />
                )
                break
            case 6: //MSD_VACCINES_ACADEMY
                output = (
                    <MSDVaccinesAcademyRegistrationForm {...formProps} />
                )
                break
            case 7: //MSD_VACCINES_ACADEMY
                output = (
                    <MSDApNmbUpdates2021RegistrationForm {...formProps} />
                )
                break
            case 8: //MSD_VACCINES_ACADEMY
                output = (
                    <MSDJCAPRegistrationForm {...formProps} />
                )
                break
            case 9: //MSD_VACCINES_ACADEMY
                output = (
                    <MSDAPSmartMeetingRegistrationForm {...formProps} />
                )
                break
            case 10: //MSD_VACCINES_ACADEMY
                output = (
                    <MSDCovidSassRegistrationForm {...formProps} />
                )
                break
            case 11: //MSD_VACCINES_ACADEMY
                output = (
                    <MSDGlobalHpv2021RegistrationForm {...formProps} />
                )
                break
            case 12: //MSD_VACCINES_ACADEMY
                output = (
                    <MSDApNiasRegistrationForm {...formProps} />
                )
                break
            case 13: //MSD_VACCINES_ACADEMY
                output = (
                    <MSDJcapMatchRegistrationForm {...formProps} />
                )
                break
            case 14: //MSD_VACCINES_ACADEMY
                output = (
                    <MSDApNiasBRegistrationForm {...formProps} />
                )
                break
            case 15: //AP ID Forum - COVID-19 Greta
                output = (
                    <ApIdForumCovid19Form {...formProps} /> // ApIdForumCovid19 
                )
                break
            case 16: //AP ID Forum - GN Webinar Greta
                output = (
                    <ApidGnWebinarRegistrationForm {...formProps} /> // ApIdForumCovid19 
                )
                break
            case 17: // MSD_VACCINES_ACADEMY 2022
                output = (
                    <MSDVaccinesAcademyRegistrationForm2022 {...formProps} /> // ApIdForumCovid19 
                )
                break
            case 18:
                output = (
                    <MsdApidForumCovid19 {...formProps} />
                )
                break
            case 19:
                output = (
                    <MSDGlobalCmvRegistrationForm {...formProps} />
                )
                break
            case 20:
                output = (
                    <OrganonVirtualSassRegistrationForm {...formProps} />
                )
                break
            case 21:
                output = (
                    <MSDHncrResearchForum2022RegistrationForm {...formProps} />
                )
                break
            case 22: //APID_GYN_2023_Reg
                output = (
                    <ApIdGyn2023Form {...formProps} />
                )
                break
            case 23:
                output = (
                    <ApLungCancer2023Form {...formProps} />
                )
                break
            case 24:
                output = (
                    <MsdPedVaccines2023 {...formProps} />
                )
                break
            case 25:
                output = (
                    <GamRegistrationForm     {...formProps} />
                )
                break
            case 3023:
                output = (
                    <ApLungCancer2023FormVer2 {...formProps} />
                )
                break
            case 1001: // MSD_CTIO
                output = (
                    <MSDCtioRegistrationForm {...formProps} />
                )
                break
            case 2001: // Edizioni DM
                output = (
                    <EdizioniDmRegistrationForm {...formProps} />
                )
                break
            case 2002: // Isplora Webinar
                output = (
                    <IsploraWebinarRegistrationForm {...formProps} />
                )
                break
            default:
                output = (
                    <RegistrationForm {...formProps} />
                )
        }
    }

    return (
        <div>
            {output}
        </div>
    )
}

Registration.className = "registration"
export default wrapSection(Registration)