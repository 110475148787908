import React, { useEffect, useRef, useState } from "react"

const AspectRatio = (props) => {

    const [height, setHeight] = useState()

    const wrapper = useRef()

    useEffect(() => {
        setNewHeight()
        window.addEventListener("resize", () => {
            setNewHeight()
        })
    })

    const setNewHeight = () => {
        if (window._.isEmpty(wrapper) ||
            window._.isEmpty(window._.get(wrapper, "current")) ||
            window._.get(wrapper, "current") === null) {
            return false
        }
        //srrr
        const _ratio = (props.ratio) ? props.ratio.split("x") : [1, 1]
        const ratio = _ratio[0] / _ratio[1]
        setHeight(wrapper.current.offsetWidth * ratio)
    }

    return (
        <div {...props} ref={wrapper} style={{
            ...props.style,
            height: `${height}px`
        }}>
            {props.children}
        </div>
    )
}

export default AspectRatio