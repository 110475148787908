import React, { useContext, useEffect, useState, } from 'react';

import { buildQuery } from '../api/functions';
import Form from '../components/Form/Form';
import MainContext from '../context/MainContext';

import { getContents } from '../utils/functions';

const QaAUserForm = ({ handleCurrentChannel, handleCurrentChannelTag, ...props }) => {
    const [defaultValues, setDefaultValues] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [currentChannel, setCurrentChannel] = useState();
    const [currentChannelTag, setCurrentChannelTag] = useState();

    const context = useContext(MainContext)

    useEffect(() => {
        if (!context.channelId) {
            setCurrentChannel()
        }
        else {
            setCurrentChannel(context.user?.channels?.find(item => parseInt(item.id) === parseInt(context.channelId)))
        }
    }, [context.channelId, context.user])

    useEffect(() => {
        setCurrentChannelTag(currentChannel?.stream_id)
        if (handleCurrentChannel) {
            handleCurrentChannel(currentChannel?.id)
        }
    }, [currentChannel])

    useEffect(() => {
        if (handleCurrentChannelTag) {
            handleCurrentChannelTag(currentChannelTag)
        }
    }, [currentChannelTag])

    const getEndpoint = () => {
        const query = buildQuery({});
        // const endpoint = `events/${event}/${CONTENTS}?${query}`;
        return [
            "questions"
        ].join("/") + `?${query}`;
    }

    const getValue = (name, type) => {
        const value = !window._.isEmpty(defaultValues) ? defaultValues[name] : null;

        switch (type) {
            case "bool":
                return value !== 0;
            default:
                return value || "";
        }
    };

    const onChange = (key, value) => {
        setDefaultValues({
            ...defaultValues,
            [key]: value
        });
    }


    const contantFieldName = props.chairMan ?
        "edited_content" :
        "original_content"

    const onSubmit = (e) => {
        setDefaultValues({
            ...defaultValues,
            [contantFieldName]: ""
        });

        setSubmitted(true)

        window.setTimeout(() => {
            setSubmitted(false)
        }, 3000)

        if (getValue("send_immediately")) {
            props.onSubmit(e)
        }
    }

    let fields = 1
        ? [
            {
                elementType: "hidden",
                id: "episode_id",
                name: "episode_id",
                value: window._.get(context, "episode.id"),
            },
            {
                elementType: "hidden",
                id: "stream_id",
                name: "stream_id",
                value: currentChannelTag,
            },
        ]
        : [];

    fields.push({
        elementType: "textarea",
        id: contantFieldName,
        name: contantFieldName,
        required: true,
        label: window._.get(context, "contents.qnaFormTextareaLabel") || "Please type your question here",
        placeholder: window._.get(context, "contents.qnaFormTextareaPlaceholder") || "Ask a question",
        value: getValue(contantFieldName),
        disabled: !props.enabled && !props.chairMan
    })

    if (props.chairMan) {
        fields.push({
            elementType: "hidden",
            id: "confirmed",
            name: "confirmed",
            value: getValue("send_immediately"),
        })
        fields.push({
            elementType: "hidden",
            id: "original_content",
            name: "original_content",
            value: getValue("edited_content")
        })
        const episodeQaaCategories = window._.get(context, "episode.qaa_categories")
        // const episodeQaaCategories = null

        if (episodeQaaCategories) {
            let categorySelectOptions = [
                {
                    value: null,
                    label: window._.get(context, "contents.qaa_category") || "Select a category",
                }
            ]
            categorySelectOptions = categorySelectOptions.concat(episodeQaaCategories.map(item => {
                return {
                    value: item.toLowerCase(),//internal_false_value_label
                    label: item,
                }
            }))
            fields.push({
                elementType: "select",
                id: "category",
                name: "category",
                placeholder: window._.get(context, "contents.qaa_category") || "Category",
                value: getValue("category"),
                options: categorySelectOptions
            })
        }
        fields.push({
            elementType: "checkbox",
            id: "send_immediately",
            name: "send_immediately",
            labelHtml: "Send immediately",
            value: getValue("send_immediately"),
        })
    }

    const disableSubmit = () => {
        // let disable = false
        // context.event.episodes.forEach(item => {
        //     if (item.id === window._.get(context, "episode.id")) {
        //         disable = !item.is_subscriptions_open
        //     }
        // })
        return submitted || (!props.enabled && !props.chairMan)
        // return submitted || (!props.enabled && !props.chairMan)
    }

    const submitButtonLabel = (submitted) ? getContents(context, "questionSubmitted") || "Thank you" : getContents(context, "submitQuestion") || "Submit question"

    return (
        <div className="regstration-form regstration-from__wrapper">
            <Form
                // key={`form-website-contents-${key}`}
                fields={fields}
                endpoint={getEndpoint()}
                method={"post"}
                // confirmBeforeSubmit={true}
                onSubmit={(e) => onSubmit(e)}
                onChange={(key, val) => onChange(key, val)}
                submitButtonLabel={submitButtonLabel}
                disableSubmit={disableSubmit()}
                disableRequiredHelper={true}
                disableValidation={true}
                inline={props.inline}
            />
        </div>
    );
}

export default QaAUserForm