/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import Button from "../Button/Button"
import "./LogoutButton.scss"

const LogoutButton = (props) => {

    return (
        <>
            <Button
                size={"sm"}
                className="my-2 my-sm-0 dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                {props.mainButtonContent}
            </Button>
            <div className="dropdown-menu dropdown-menu-right">
                <a
                    className="dropdown-item"
                    onClick={props.onLogoutClicked}
                >
                    {props.logoutButtonContent}
                </a>
            </div>
        </>
    )
}

export default LogoutButton