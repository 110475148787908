import classNames from "classnames"
import { useState } from "react"
import AgendaAccordion from "../../ui/AgendaAccordion/AgendaAccordion"
import Row from "../../ui/Row/Row"
import { consoleLog } from "../../utils/functions"
import Agenda from "../Agenda/Agenda"
import EpisodeTitle from "../EpisodeTitle/EpisodeTitle"
import Speakers from "../Speakers/Speakers"
import "./EpisodeDetails.scss"

const EpisodeDetails = (props) => {

    const [open, setOpen] = useState(false)
    const {
        episode,
        language,
        country,
        internal,
        contents,
        expandable,
        className,
        groupedSeakers,
        hideAgendaDatetime,
        accordionTitleIcon,
        fullWidth
    } = props

    const toggle = () => {
        consoleLog("toggle")
        setOpen(open => {
            return !open
        })
    }

    let openIcon = null;
    let closeIcon = null;

    switch (accordionTitleIcon) {
        case 1:
            openIcon = (
                <i className="fas fa-angle-down" />
            );
            closeIcon = (
                <i className="fas fa-angle-up" />
            )
            break;
        default:
            openIcon = "+";
            closeIcon = "-";
    }

    const openAccordionButton = expandable ? (
        <div
            className="episode-header__open-accordion"
            onClick={toggle}
        >
            {open ? closeIcon : openIcon}
        </div>
    ) : null

    const accordion = expandable ? (
        <AgendaAccordion open={open}>
            <Row>
                <Agenda
                    language={language}
                    country={country}
                    episode={episode}
                    episodeId={episode.id}
                    internal={internal}
                    items={episode.agenda}
                    groupedSeakers={groupedSeakers}
                    fullWidth={groupedSeakers}
                    hideAgendaDatetime={!!hideAgendaDatetime}
                    accordionTitleIcon={accordionTitleIcon}
                />
                {
                    groupedSeakers ? null : (
                        <Speakers
                            language={language}
                            country={country}
                            episodeId={episode.id}
                            internal={internal}
                            items={episode.speakers}
                            hiddenItems={episode.hidden_speakers_cards}
                        />
                    )
                }

            </Row>
        </AgendaAccordion>
    ) : null

    return (
        <div
            style={{ width: "100%", position: "relative" }}
            className={classNames(className)}
        >
            {openAccordionButton}
            <EpisodeTitle
                className={classNames(
                    props.detailsClassname,
                    "website-section"
                )}
                eventTitle={episode.title}
                eventSubTitle={episode.subtitle}
                onToggleAccordionClicked={toggle}
                eventLive={window._.get(episode, "live_scheduling.formatted_start_at")}
                eventLiveTitle={window._.get(contents, "liveDatetimeTitle")}
                eventVod={window._.get(episode, "vod_scheduling.formatted_start_at")}
                eventVodTitle={window._.get(contents, "vodDatetimeTitle")}
                // eventIntro={window._.get(contents, "websiteIntro")}
                upTo={window._.get(contents, "upTo")}
                hours={window._.get(contents, "hours")}
                open={open}
                episode={episode}
                fullWidth={props.fullWidth}
                hideDates={props.hideDates}
                linesOrder={props.titleLinesOrder}
            // hideSubtitle={props.hideDates}
            />
            {accordion}
        </div>
    )
}

export default EpisodeDetails