import React, { useState } from 'react';
import CookieConsent from "react-cookie-consent";
import AdamModal from '../AdamModal/AdamModal';
import { cleanCheckboxLabel } from '../../utils/functions';
import Container from '../../ui/Container/Container';
import Row from '../../ui/Row/Row';
// import "./PlayerBox.scss"
const AdamCookieConsent = (props) => {

    const debug = true
    const { theme } = props
    const [modalOpen, setModalOpen] = useState(false)

    console.log("checkbox_label", process.env.NODE_ENV)
    const openModal = (content) => {

        setModalOpen(true)
    }

    const onModalRequestClose = () => {


        setModalOpen(false)
    }
    const grid = props.ccContent ? (
        <Container fluid={true}>
            {
                <Row
                    className="my-2 py-4 px-2">
                    <div className="col-12">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: props.ccContent
                            }} />
                    </div>
                </Row>
            }
        </Container >
    ) : null

    const modal = grid ? (
        <AdamModal
            content={grid}
            open={modalOpen}
            // fluidHeader={true}
            onClose={onModalRequestClose}
        />
    ) : null

    const cleaned = cleanCheckboxLabel(props.ccCheckboxLabel)
    const label = (
        <span>
            {
                cleaned.before ? (
                    <span
                        className="tos-text"
                        dangerouslySetInnerHTML={{
                            __html: cleaned.before
                        }} />
                ) : null
            }
            {
                cleaned.linkContent ? props.ccContent ? (
                    <a
                        style={{
                            color: theme.secondaryColor,
                            textDecoration: "underline"
                        }}
                        className="tos-link"
                        onClick={() => openModal(props.ccContent)}
                        dangerouslySetInnerHTML={{
                            __html: cleaned.linkContent
                        }} />
                ) : (
                    <span
                        className="tos-text"
                        dangerouslySetInnerHTML={{
                            __html: cleaned.linkContent
                        }} />
                ) : null
            }
            {
                cleaned.after ? (
                    <span
                        dangerouslySetInnerHTML={{
                            __html: cleaned.after
                        }} />
                ) : null
            }
        </span>
    )
    return (
        <>
            <CookieConsent
                location="bottom"
                buttonText={props.ccButtonLabel || "Ok"}
                buttonClasses="btn-cookie-consent"
                cookieName="myAwesomeCookieName2"
                debug={("development" === process.env.NODE_ENV) && debug}
                style={{
                    bottom: "2rem",
                    right: "2rem",
                    left: "2rem",
                    width: "auto",
                    backgroundColor: theme.primaryColor,
                    color: theme.secondaryColor
                }}
                buttonStyle={{
                    color: props.cookieConsentButtonTextColor,
                    border: `1px solid ${props.cookieConsentButtonBorderColor}`,
                    backgroundColor: props.cookieConsentButtonBackgroundColor,
                }}
                expires={150}
                overlay={true}
                containerClasses={"cookie-consent"}
            >
                {label}
            </CookieConsent>
            {modal}
        </>
    )
}

export default AdamCookieConsent