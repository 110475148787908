import React from 'react';
import classNames from "classnames"

const LightText = (props) => {

    const El = props.as || "div"

    const className = classNames(props.className, "light-text")

    return (
        <El {...props} className={className}>
            {props.children}
        </El>
    )
}

export default LightText