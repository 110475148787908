import React, { useEffect, useState } from "react"
import { utc } from "../../utils/functions"


const CountDown = (props) => {

    const [timestamp, setTimestamp] = useState(0)

    useEffect(() => {
        const diff = utc().subtract(utc(props.dateTime))
        setTimestamp(diff.unix())
        window.setInterval(() => {
            const diff = utc().subtract(utc(props.dateTime))
            if (diff.unix() === 0 && props.onZero) {
                props.onZero()
            }
            setTimestamp(diff.unix())
        }, 1000)
    }, [])

    const seconds = Math.abs(parseInt(timestamp % 60))
    const minutes = Math.abs(parseInt(((timestamp % 3600)) / 60))
    const hrs = Math.abs(parseInt(timestamp % 86400 / 3600))
    const days = Math.abs(parseInt(timestamp / 86400))

    let output = null

    switch (props.mode) {
        case "splitted":
            output = (
                <>
                    {days || ""}{days ? "d " : null}{hrs || ""}{hrs ? "h " : null}{minutes < 10 ? "0" : null}{minutes}m {seconds < 10 ? "0" : null}{seconds}s
                </>
            )
            break
        default:
            output = (
                <>
                    {hrs || ""}{hrs ? ":" : null}{minutes < 10 ? "0" : null}{minutes}:{seconds < 10 ? "0" : null}{seconds}
                </>
            )
    }

    return output
}

export default CountDown