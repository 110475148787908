import classNames from "classnames";
import React, { useEffect, useState } from "react"
import Logo from "../../components/Logo/Logo";
import Button from "../Button/Button";
import "./AdamOverlay.scss"

const AdamOverlay = ({ children, onClose, hideCloseButton, title, onLogoutClicked, ...props }) => {
    const [overlayOpen, setOverlayOpen] = useState(false)

    useEffect(() => {
        setOverlayOpen(props.open)
    }, [props.open])

    const close = () => {
        setOverlayOpen(false)
        if (onClose) {
            onClose()
        }
    }

    const overlayClassName = classNames("adam-overlay", {
        "adam-overlay__withclosebutton": !hideCloseButton
    })

    const modal = overlayOpen ? (
        <div className={overlayClassName}>
            <div className="adam-overlay__navbar navbar fixed-top navbar-expand-lg">
                <div className="navbar-brand"><Logo /></div>
                <ul className="nav navbar-nav ml-auto" style={{
                    flexDirection: "row"
                }}>
                    {
                        !hideCloseButton && (
                            <li className="nav-item mr-2">
                                <Button onClick={close}>Close</Button>
                            </li>
                        )
                    }
                    {
                        onLogoutClicked ? (
                            <li className="nav-item">
                                <Button onClick={onLogoutClicked}>Logout</Button>
                            </li>
                        ) : null
                    }

                </ul>
            </div>
            {
                title ? (
                    <h3 className="adam-overlay__title p-3">{title}</h3>
                ) : null
            }
            {children}
        </div>
    ) : null

    return modal
}

export default AdamOverlay