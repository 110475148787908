import React, { useEffect, useState, useContext } from "react"
import AdamModal from "../AdamModal/AdamModal";
import MainContext from "../../context/MainContext";
import Button from "../../ui/Button/Button";
import Spin from "../../ui/Spin/Spin";
import Container from "../../ui/Container/Container";
import Row from "../../ui/Row/Row";

const AlreadyLoggedInModal = (props) => {
    const [modalOpen, setModalOpen] = useState(false)
    const context = useContext(MainContext)

    useEffect(() => {
        setModalOpen(props.open)
    }, [props.open])



    const grid = (
        <Container fluid={true} className="text-center virtual-world-modal">
            <Row className="mt-3 text-center">
                {
                    context.loginErrors ? (
                        <div
                            className="text-danger col-12"
                            style={{ marginTop: "1rem" }}>
                            {context.loginErrors.join(", ")}
                        </div>
                    ) : null
                }
            </Row>

            <Row className="my-3">
                <div
                    className="col virtual-world-modal__countdown">
                    {
                        context.alreadyLoggedError ? (
                            <>
                                <div>
                                    {window._.get(context, "contents.closeEveryOpenSessionMessage")}
                                </div>
                                <Button
                                    className="btn-form-submit btn-login-modal"
                                    role="button"
                                    onClick={() => props.onForceLogoutClicked()}>
                                    {
                                        context.loggingOut ? (
                                            <Spin />
                                            // <i className="fas fa-circle-notch fa-spin"></i>
                                        ) : window._.get(context, "contents.closeEveryOpenSessionButtonText")
                                    }
                                </Button>
                            </>
                        ) : null
                    }
                </div>
            </Row>
        </Container >
    )

    const modal = (
        <AdamModal
            content={grid}
            open={modalOpen}
            fluidHeader={true}
            headerClass="d-none d-sm-block"
            transparentHeader={true}
            onClose={() => context.closeAlreadyLoggedInModal()}
        />
    )

    return modal
}

export default AlreadyLoggedInModal