import React, { useEffect, useState, useContext } from "react"
import AdamModal from "../AdamModal/AdamModal";
import MainContext from "../../context/MainContext";
import Container from "../../ui/Container/Container";
import Row from "../../ui/Row/Row";
import Button from "../../ui/Button/Button";

const PreRegistrationModal = (props) => {
    const [modalOpen, setModalOpen] = useState(false)
    const context = useContext(MainContext)

    useEffect(() => {
        setModalOpen(props.open)
    }, [props.open])

    const siteTitle = context.contents.eventTitle
    const okButtonText = context.contents.yesCorrect
    const koButtonText = context.contents.noTakeMeBack

    const grid = (
        <Container
            className="py-4 px-4"
            fluid={true}
        >
            <Row
                className="modal__event-title">
                <div className="col-12">
                    {siteTitle}
                </div>
            </Row>
            {
                props.content && props.emailAddress ?
                    (
                        <Row
                            className="my-2 py-2">
                            <div className="col-12">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: props.content
                                    }} />
                                <div
                                    className="alert alert-secondary text-center mt-4 py-2 px-2"
                                >
                                    {props.emailAddress}
                                </div>
                                <div
                                    className="text-center mt-4 py-2 px-2"
                                >
                                    <Button
                                        onClick={props.onConfirm}
                                        size="sm"
                                        className="mx-2 btn-email-confirmation"
                                    >
                                        {okButtonText}
                                    </Button>
                                    <Button
                                        onClick={props.onCancel}
                                        size="sm"
                                        className="mx-2 btn-email-confirmation"
                                    >
                                        {koButtonText}
                                    </Button>
                                </div>
                            </div>
                        </Row>
                    ) : null
            }
        </Container >
    )

    const modal = (
        <AdamModal
            small={true}
            content={grid}
            open={modalOpen}
            // fluidHeader={true}
            onClose={() => props.onClose()}
        />
    )

    return modal
}

export default PreRegistrationModal