import { useContext, useState, } from 'react';

import useRegistrtionForm from '../../../hooks/useRegistrtionForm';
import MainContext from '../../../context/MainContext';

/**
 * Eurogin KIND
 * @param {object} props 
 * @returns 
 */
const MSDGlobalHpv2021RegistrationForm = (props) => {
    const context = useContext(MainContext)

    const {
        output,
        countries,
        allCountries,
        getValue,
        getLegals
    } = useRegistrtionForm()

    let fields = 1
        ? [
            {
                elementType: "hidden",
                id: "episodes",
                name: "episodes",
                value: window._.map(props.openEpisodes, (item => item.id))//[window._.map(props.openEpisodes, (item => item.id))],
            },
        ]
        : [];


    const languagesList = window._.get(context, "event.languages") || []
    const languageField = languagesList.length > 1 ? {
        elementType: "select",
        id: "lang_id",
        name: "lang_id",
        required: true,
        // invalidText: "Invalid error message.",
        label: window._.get(context, "contents.language") || "Language",
        placeholder: window._.get(context, "contents.language") || "Language",
        value: getValue("lang_id") || context.language,
        options: window._.get(context, "event.languages") || []
    } : {
        elementType: "hidden",
        id: "lang_id",
        name: "episodes",
        value: window._.get(languagesList[0], "value"),
    }

    fields.push(languageField)

    const getSpecialtyOptions = () => {
        const values = [
            "Infectious Disease Specialists",
            "Public Health Specialists",
            "Oncologists",
            "Gynecologists",
            "Urologists",
            "Virologists",
            "Pediatricians",
            "General/Family Practice",
            "Other"
        ]

        const specialtiesTranslations = {
            "en": {
                "Infectious Disease Specialists": "Infectious Disease Specialists",
                "Public Health Specialists": "Public Health Specialists",
                "Oncologists": "Oncologists",
                "Gynecologists": "Gynecologists",
                "Urologists": "Urologists",
                "Virologists": "Virologists",
                "Pediatricians": "Pediatricians",
                "General/Family Practice": "General/Family Practice",
                "Other": "Other (Please specify)"
            }
        }

        return values.map(item => {
            return {
                value: item,
                label: window._.get(specialtiesTranslations, `${getValue("lang_id")}.${item}`) || window._.get(specialtiesTranslations, `en.${item}`)
            }
        })
    }

    if (context.event.has_internal) {
        fields.push({
            elementType: "select",
            id: "is_internal",
            name: "is_internal",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.internal") || "Internal",
            value: getValue("is_internal") || "0",
            options: [{
                value: 0,//internal_false_value_label
                label: window._.get(context, "contents.internalFalseValueLabel") || "External",
            }, {
                value: 1,//internal_false_value_label
                label: window._.get(context, "contents.internalTrueValueLabel") || "Internal",
            }]
        })
    }

    if (!parseInt(context.internal)) {
        fields.push({
            elementType: "select",
            id: "country_id",
            name: "country_id",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.country") || "Country",
            placeholder: window._.get(context, "contents.country") || "Country",
            value: getValue("country_id") || context.country,
            disabled: parseInt(context.internal),
            options: countries || []
        })
    }
    else {
        fields.push({
            elementType: "select",
            id: "country_id_of_work",
            name: "country_id_of_work",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.countryOfWork") || "Country of work",
            placeholder: window._.get(context, "contents.countryOfWork") || "Country of work",
            value: getValue("country_id_of_work") || context.country,
            disabled: !parseInt(context.internal),
            options: allCountries || []
        })
    }

    // if (context.country || context.internal) {
    fields = fields.concat([
        {
            elementType: "text",
            id: "firstname",
            name: "firstname",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.firstname") || "firstname",
            placeholder: window._.get(context, "contents.firstname") || "firstname",
            value: getValue("firstname"),
        },
        {
            elementType: "text",
            id: "lastname",
            name: "lastname",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.lastname") || "lastname",
            placeholder: window._.get(context, "contents.lastname") || "lastname",
            value: getValue("lastname"),
        },
        {
            elementType: "select",
            id: "specialty",
            name: "specialty",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.specialty") || "Specialty",
            placeholder: window._.get(context, "contents.specialty") || "Specialty",
            value: getValue("specialty") || "Hematologist",
            // disabled: parseInt(context.internal),
            options: getSpecialtyOptions()
        },
    ])
    if (getValue("specialty") === "Other") {
        fields.push({
            elementType: "text",
            id: "other_specialty",
            name: "other_specialty",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.otherSpecialty") || "Other specialty",
            placeholder: window._.get(context, "contents.otherSpecialty") || "Other specialty",
            value: getValue("other_specialty"),
        })
    }
    fields = fields.concat([
        {
            elementType: "text",
            id: "affiliation",
            name: "affiliation",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.affiliation") || "Affiliation (Place of work)",
            placeholder: window._.get(context, "contents.affiliation") || "Affiliation (Place of work)",
            value: getValue("affiliation"),
        },
        {
            elementType: "email",
            id: "email",
            name: "email",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.email") || "email",
            placeholder: window._.get(context, "contents.email") || "email",
            value: getValue("email"),
        },
        {
            elementType: "text",
            id: "phone",
            name: "phone",
            label: window._.get(context, "contents.phone") || "Telephone No./Work No",
            placeholder: window._.get(context, "contents.phone") || "Telephone No./Work No",
            value: getValue("phone"),
        }
    ])

    if (context.legals) {
        fields = fields.concat(getLegals())
    }
    // }

    return output(fields)
}

export default MSDGlobalHpv2021RegistrationForm