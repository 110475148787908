// UNKNOWN
export const UNKNOWN = 99;

// LIVE ONLY
export const PRE_LIVE = 101;
export const LIVE = 121;

// LIVE & VOD
export const BUFFER = 202;
export const BYE = 262;

// VOD ONLY
export const PRE_VOD = 303;
export const VOD = 323;
export const POST_VOD = 343;