import classNames from "classnames"
import React, { useContext, useEffect, useState } from "react"
import MainContext from "../../context/MainContext"
import { loadQuestions, markQuestionAsRead } from "../../api/functions"
import { getContents, initPusher, localTime } from "../../utils/functions"
import Button from "../../ui/Button/Button"
import Input from "../Input/Input"

const QaACardSidebar = (props) => {

    const context = useContext(MainContext)
    const country = window._.get(props, "country.title")
    const senderIsAttendant = !window._.get(props, "subscriber.subscription.is_chairman") && !window._.get(props, "subscriber.subscription.is_internal")
    return (
        <div onClick={props.onClick}
            className={classNames("qaa-card__sidebar mb-3 p-3")}>
            <div
                className="qaa-card__sidebar--name">
                {window._.get(props, "subscriber.firstname")} {window._.get(props, "subscriber.lastname")} {
                    country && senderIsAttendant ? ` (${country})` : null
                }
            </div>
            <div
                className={classNames("qaa-card__sidebar--time")}
            >{localTime(window._.get(props, "created_at"), context.event.timezone).format("D-M-Y H:m")}</div>
            {
                props.category && (
                    <div>[{props.category}]</div>
                )
            }
            <div
                className="qaa-card__sidebar--preview mb-3">
                {window._.get(props, "edited_content")}
            </div>
        </div>
    )
}

const QaACardEditable = (props) => {

    const context = useContext(MainContext)
    const [loading, setLoading] = useState(false)
    const country = window._.get(props, "country.title")
    const senderIsAttendant = !window._.get(props, "subscriber.subscription.is_chairman") && !window._.get(props, "subscriber.subscription.is_internal")
    const markAsRead = async () => {
        setLoading(true)
        try {
            const res = await markQuestionAsRead(props.id)
            if (window._.get(res, "id")) {
                props.onSubmit()
            }
        }
        catch (e) {
            alert("Something went wrong")
        }
        finally {
            setLoading(false)
        }
    }

    return (
        <div
            className={classNames("qaa-card__editable p-3 mb-4")}
        >
            <div
                className={classNames("qaa-card__editable--time")}
            >{localTime(window._.get(props, "created_at"), context.event.timezone).format("D-M-Y H:m")}</div>
            <div
                className={classNames("qaa-card__editable--user")}
            >{window._.get(props, "subscriber.firstname")} {window._.get(props, "subscriber.lastname")} {
                    country && senderIsAttendant ? ` (${country})` : null
                }</div>
            {
                props.category && (
                    <div>
                        [{props.category}]
                    </div>
                )
            }
            <div
                className={classNames("qaa-card__editable--big py-4")}
            >{window._.get(props, "edited_content")}</div>
            <Button
                disabled={loading}
                size="sm"
                onClick={markAsRead}>
                {
                    loading ? "Please wait" : getContents(context, "markAsRead") || "Delete"
                }
            </Button>
            <Button
                disabled={loading}
                size="sm"
                className="ml-2"
                onClick={props.onAbort}>
                {
                    getContents(context, "unselectQaaQuestion") || "Unselect"
                }
            </Button>
        </div>
    )
}

const QandASpeaker = (props) => {
    const context = useContext(MainContext)
    const [loadedQuestions, setLoadedQuestions] = useState([])
    const [pushedQuestions, setPushedQuestions] = useState([])
    const [editableQuestions, setEditableQuestions] = useState([])
    const [editableQuestionsId, setEditableQuestionsId] = useState([])
    const [categoryFilter, setCategoryFilter] = useState()
    const [currentChannel, setCurrentChannel] = useState();
    const [currentChannelTag, setCurrentChannelTag] = useState();
    // useEffect(() => {
    //     _loadQuestions()
    // }, [])

    useEffect(() => {
        if (pushedQuestions.length > 0) {
            let newLoadedQuestions = [...pushedQuestions]
            newLoadedQuestions = newLoadedQuestions.concat(loadedQuestions)
            setLoadedQuestions(newLoadedQuestions)
            setPushedQuestions([])
        }
    }, [pushedQuestions])

    useEffect(() => {
        if (!context.channelId) {
            setCurrentChannel()
        }
        else {
            setCurrentChannel(context.user?.channels?.find(item => parseInt(item.id) === parseInt(context.channelId)))
        }
    }, [context.channelId, context.user])

    useEffect(() => {
        setCurrentChannelTag(currentChannel?.stream_id)
    }, [currentChannel])

    useEffect(() => {
        if (currentChannel) {
            _loadQuestions(context.episodeId, currentChannelTag)
        }
        window.Pusher = initPusher()
        if (context.episodeId && !window._.isEmpty(context.user)) {
            // _loadQuestions(context.episodeId)
            const channel = window.Pusher.subscribe(`private-qaa-speaker-${props.eventId}.${context.episodeId}`)
            channel.bind_global(globalCallback);
        }
        return function cleanup() {
            if (window.Pusher) {
                window.Pusher.unsubscribe(`private-qaa-speaker-${props.eventId}.${context.episodeId}`)
            }
        };
    }, [currentChannelTag, context.episodeId])

    // useEffect(() => {
    //     window.Pusher = initPusher()
    //     if (context.episodeId && !window._.isEmpty(context.user)) {
    //         // _loadQuestions(context.episodeId)
    //         const channel = window.Pusher.subscribe(`private-qaa-speaker-${props.eventId}.${context.episodeId}`)
    //         channel.bind_global(globalCallback);
    //     }
    //     return function cleanup() {
    //         if (window.Pusher) {
    //             window.Pusher.unsubscribe(`private-qaa-speaker-${props.eventId}.${context.episodeId}`)
    //         }
    //     };
    // }, [context.episodeId])

    const _loadQuestions = async (episodeId, tag) => {
        let params = {
            episode_id: episodeId
        }
        if (tag) {
            params = {
                ...params,
                stream_id: tag
            }
        }
        const res = await loadQuestions(params)
        setLoadedQuestions(res)
    }

    const globalCallback = (eventName, data) => {
        switch (eventName) {
            case "App\\Events\\NewQuestionToAnswer":
                addNewQuestionToAnswer(data.question)
                break
        }
    }

    const addNewQuestionToAnswer = (question) => {
        if (!currentChannelTag || parseInt(currentChannelTag) === parseInt(question.stream_id)) {
            let newLoadedQuestions = [...pushedQuestions]
            newLoadedQuestions.unshift(question)
            setPushedQuestions(newLoadedQuestions)
        }
    }

    const addToEditableList = (item) => {
        let newList = [...editableQuestions]
        let newListId = [...editableQuestionsId]
        newList.unshift(item)
        newListId.unshift(item.id)
        setEditableQuestions(newList)
        setEditableQuestionsId(newListId)
    }

    const removeFromEditableList = (itemId) => {
        let newList = [...editableQuestions]
        let newListId = [...editableQuestionsId]
        newList = newList.filter(item => item.id !== itemId)
        newListId = newListId.filter(id => id !== itemId)
        setEditableQuestions(newList)
        setEditableQuestionsId(newListId)
    }

    const removeFromPage = (id) => {
        let newList = [...loadedQuestions]
        newList = newList.filter(item => item.id !== id)
        removeFromEditableList(id)
        setLoadedQuestions(newList)
    }

    let loadedQuestionsFiltered = loadedQuestions.filter(item => !editableQuestionsId.includes(item.id))
    if (categoryFilter && categoryFilter !== "Filter by category") {
        loadedQuestionsFiltered = loadedQuestionsFiltered.filter(item => categoryFilter === item.category)
    }
    return (
        <>
            <div
                className={classNames("qaa-list col-lg-4 col-md-6 col-sm-6 pb-3")}>
                <h3 className="mb-4">Questions from users</h3>
                {
                    window._.get(context, "episode.qaa_categories") ? (
                        <div className="py-4">
                            <Input
                                elementType="select"
                                onChange={(e) => setCategoryFilter(e.target.value)}
                                options={[
                                    {
                                        value: null,
                                        label: window._.get(context, "contents.qaa_category") || "Filter by category",
                                    },
                                    ...window._.get(context, "episode.qaa_categories").map(item => {
                                        return {
                                            value: item.toLowerCase(),//internal_false_value_label
                                            label: item
                                        }
                                    })]}
                            />
                        </div>
                    ) : "No"
                }
                {
                    loadedQuestionsFiltered && loadedQuestionsFiltered.length > 0 ? loadedQuestionsFiltered.map(item => {
                        return (
                            <QaACardSidebar
                                {...item}
                                onClick={() => addToEditableList(item)}
                            />
                        )
                    }) : (
                        <div>The list is empty</div>
                    )
                }
            </div>
            <div
                className={classNames("qaa-list col-lg-8 col-md-6 col-sm-6 pb-3")}>
                <h3 className="mb-4">Select questions to read</h3>
                {
                    editableQuestions && editableQuestions.length > 0 ? editableQuestions.map(item => {
                        return (
                            <QaACardEditable
                                {...item}
                                // episode={context.episode}
                                onAbort={() => removeFromEditableList(item.id)}
                                onSubmit={() => removeFromPage(item.id)}
                            />
                        )
                    }) : (
                        <div>The list is empty</div>
                    )
                }
            </div>
        </>
    )
}

export default QandASpeaker