import { useContext } from 'react';

import useRegistrtionForm from '../../../hooks/useRegistrtionForm';
import MainContext from '../../../context/MainContext';

const OrganonVirtualSassRegistrationForm = (props) => {
    const context = useContext(MainContext)

    const {
        output,
        countries,
        allCountries,
        getValue,
        getLegals
    } = useRegistrtionForm({
        multiselectFields: ["specialty"]
    })

    let fields = 1
        ? [
            {
                elementType: "hidden",
                id: "episodes",
                name: "episodes",
                value: window._.map(props.openEpisodes, (item => item.id))//[window._.map(props.openEpisodes, (item => item.id))],
            },
        ]
        : [];


    const languagesList = window._.get(context, "event.languages") || []
    const languageField = languagesList.length > 1 ? {
        elementType: "select",
        id: "lang_id",
        name: "lang_id",
        required: true,
        // invalidText: "Invalid error message.",
        label: window._.get(context, "contents.language") || "Language",
        placeholder: window._.get(context, "contents.language") || "Language",
        value: getValue("lang_id") || context.language,
        options: window._.get(context, "event.languages") || []
    } : {
        elementType: "hidden",
        id: "lang_id",
        name: "episodes",
        value: window._.get(languagesList[0], "value"),
    }

    fields.push(languageField)

    const getSpecialtyOptions = () => {
        const values = [
            "Cardiology",
            "Endocrinology",
            "Family Medicine / General Practice",
            "Family Planning",
            "Obstetrics / Gynecology",
            "Other",
            "Not applicable",
        ]

        const specialtiesTranslations = {
            "en": {
                "Cardiology": "Cardiology",
                "Endocrinology": "Endocrinology",
                "Family Medicine / General Practice": "Family Medicine / General Practice",
                "Family Planning": "Family Planning",
                "Obstetrics / Gynecology": "Obstetrics / Gynecology",
                "Not applicable": "Not applicable",
                "Transplant Physician": "Transplant Physician",
                "Other": "Other",
            }
        }

        return values.map((item, index) => {
            return {
                value: item,
                label: window._.get(specialtiesTranslations, `${getValue("lang_id")}.${item}`) || window._.get(specialtiesTranslations, `en.${item}`)
            }
        })
    }

    if (context.event.has_internal) {
        fields.push({
            elementType: "select",
            id: "is_internal",
            name: "is_internal",
            required: true,
            label: window._.get(context, "contents.internal") || "Internal",
            value: getValue("is_internal") || "0",
            options: [{
                value: 0,//internal_false_value_label
                label: window._.get(context, "contents.internalFalseValueLabel") || "External",
            }, {
                value: 1,//internal_false_value_label
                label: window._.get(context, "contents.internalTrueValueLabel") || "Internal",
            }]
        })
    }

    if (!parseInt(context.internal)) {
        fields.push({
            elementType: "select",
            id: "country_id",
            name: "country_id",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.country") || "Country",
            placeholder: window._.get(context, "contents.country") || "Country",
            value: getValue("country_id"),
            disabled: parseInt(context.internal),
            options: countries || []
        })
    }
    else {
        fields.push({
            elementType: "select",
            id: "country_id_of_work",
            name: "country_id_of_work",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.countryOfWork") || "Country of work",
            placeholder: window._.get(context, "contents.countryOfWork") || "Country of work",
            value: getValue("country_id_of_work"),
            disabled: !parseInt(context.internal),
            options: allCountries || []
        })
    }

    // if (context.country || context.internal) {
    fields = fields.concat([
        {
            elementType: "text",
            id: "firstname",
            name: "firstname",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.firstname") || "firstname",
            placeholder: window._.get(context, "contents.firstname") || "firstname",
            value: getValue("firstname"),
        },
        {
            elementType: "text",
            id: "lastname",
            name: "lastname",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.lastname") || "lastname",
            placeholder: window._.get(context, "contents.lastname") || "lastname",
            value: getValue("lastname"),
        },
        {
            elementType: "singleselect",
            isMulti: true,
            id: "specialty",
            name: "specialty",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.specialty") || "Specialty",
            placeholder: window._.get(context, "contents.specialty") || "Specialty",
            value: getValue("specialty", "miltiselect"),
            // disabled: parseInt(context.internal),
            options: getSpecialtyOptions()
        },
        {
            elementType: "text",
            id: "affiliation",
            name: "affiliation",
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.affiliation") || "Affiliation (Place of work)",
            placeholder: window._.get(context, "contents.affiliation") || "Affiliation (Place of work)",
            value: getValue("affiliation"),
        },
        {
            elementType: "email",
            id: "email",
            name: "email",
            required: true,
            // invalidText: "Invalid error message.",
            label: window._.get(context, "contents.email") || "email",
            placeholder: window._.get(context, "contents.email") || "email",
            value: getValue("email"),
        },
        {
            elementType: "text",
            id: "phone",
            name: "phone",
            label: window._.get(context, "contents.phone") || "Telephone No./Work No",
            placeholder: window._.get(context, "contents.phone") || "Telephone No./Work No",
            value: getValue("phone"),
        }
    ])

    if (context.legals) {
        fields = fields.concat(getLegals())
    }
    // }

    return output(fields)
}

export default OrganonVirtualSassRegistrationForm