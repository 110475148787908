import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import wrapSection from '../../hoc/wrapSection';
import "./Footer.scss"

const Footer = (props) => {

    const themeContext = useContext(ThemeContext);
    const footerContentColor = themeContext.footerTextColor || themeContext.tertiaryColor
    const poweredByColor = themeContext.poweredByColor || footerContentColor

    const logo = !window._.isEmpty(themeContext.mediaFooterLogoDesktop) ? (
        <img
            src={themeContext.mediaFooterLogoDesktop.url}
            // width="30"
            // height="30"
            className="d-inline-block align-center footer-logo"
            alt="Powered By ADM Media brand solutions" />
    ) : "ADM Media brand solutions"

    const footerDynamicContent = props.content ? (
        <div
            style={{ color: footerContentColor }}
            className="footer-content"
            dangerouslySetInnerHTML={{ __html: props.content }}
        >
        </div>
    ) : null

    return (
        <footer>
            {footerDynamicContent}
            <div style={{ color: poweredByColor }}>
                Powered By {logo}
            </div>
        </footer>
    )
}
Footer.className = "footer"
export default wrapSection(Footer)