import classNames from "classnames"
import React, { useContext, useEffect, useRef, useState } from "react"
import { LIVE } from "../../config/channelStatus"
import MainContext from "../../context/MainContext"
import Row from "../../ui/Row/Row"
import Agenda from "../Agenda/Agenda"
import Input from "../Input/Input"
import SlidoIframe from "../SlidoIframe/SlidoIframe"
import Speakers from "../Speakers/Speakers"
import "./PlayerSidebar.scss"

const PlayerSidebar = ({ hideAgendaDatetime, ...props }) => {
    const context = useContext(MainContext)
    const mainRow = useRef()
    const [selectedSidebarTab, setSelectedSidebarTab] = useState("info")
    const [offsetTop, setOffsetTop] = useState(0)
    // const [offsetRight, setOffsetRight] = useState(0)
    const [activeTabs, setActiveTabs] = useState([])
    const [touched, setTouched] = useState(false)

    const {
        contents,
        language,
        country,
        episodeId,
        internal,
        episode
    } = props

    const sidebarTabChanged = (e) => {
        setTouched(true)
        setSelectedSidebarTab(e.target.value)
    }
    const tabsDropdown = useRef()

    useEffect(() => {
        changePaddingTop()
        changePaddingRight()
        window.addEventListener("resize", () => {
            changePaddingTop()
            changePaddingRight()
        })
    }, [])

    useEffect(() => {
        const newactiveTabs = episode?.playertabs?.filter(tab => {
            return tab.channel_status?.includes(context.channelStatus)
        })
        setActiveTabs(newactiveTabs)
    }, [episode, context.channelStatus])

    const changePaddingTop = () => {
        if (tabsDropdown.current) {
            setOffsetTop(tabsDropdown.current.offsetHeight)
        }
    }

    const changePaddingRight = () => {
        // if (mainRow.current) {
        //     setOffsetRight(mainRow.current.style.paddingRight)
        // }
    }

    const slidoUrl = episode ? episode.slido_url : window._.get(context, "episode.slido_url")

    let options = [{
        value: "",
        label: selectedSidebarTab ? "Close" : "Open"
    }]

    if (!touched) {
        options.push({
            value: "info",
            label: contents.infoTabTitle || "INFO"
        })
    }

    options = options.concat([{
        value: "agenda",
        label: contents.agendaTitle
    }, {
        value: "speakers",
        label: contents.speakersTitle
    }])

    let tabsContent = {
        "info": (
            <div className="my-5 generic-info-tab" dangerouslySetInnerHTML={{
                __html: contents.infoTabContent || `Click "INFO" for more contents`
            }} />
        ),
        "agenda": (
            <Agenda
                title={contents.agendaTitle}
                language={language}
                country={country}
                episodeId={episodeId}
                internal={internal}
                fullWidth={true}
                items={episode.agenda}
                allowNested={true}
                hideAgendaDatetime={!!hideAgendaDatetime}
                sidebar={true}
                className="sidebar"
            />
        ),
        "speakers": (<Speakers
            title={contents.speakersTitle}
            language={language}
            country={country}
            episodeId={episodeId}
            internal={internal}
            fullWidth={true}
            items={episode.speakers}
            hiddenItems={episode.hidden_speakers_cards}
        />
        ),
    }

    if (slidoUrl) {
        tabsContent = {
            ...tabsContent,
            "slido": (
                <SlidoIframe url={slidoUrl} />
            )
        }
    }

    if ([LIVE].includes(context.channelStatus) && slidoUrl) {
        // if (context.channelStatus === LIVE && slidoUrl) {
        options.push({
            value: "slido",
            label: contents.pollingTitle || "Polling"
        })
    }
    if (activeTabs) {
        activeTabs.forEach(tab => {
            options.push({
                value: tab.id,
                label: tab.dropdown_title || tab.title
            })
            tabsContent = {
                ...tabsContent,
                [tab.id]: (
                    <div dangerouslySetInnerHTML={{
                        __html: tab.content
                    }} />
                )
            }
        })
    }

    const select = (
        <Input
            elementType="select"
            value={selectedSidebarTab}
            options={options}
            onChange={(e) => sidebarTabChanged(e)}
        />
    )

    return (
        <>
            {/* {context.channelStatus} */}
            <div
                className={
                    classNames("player-sidebar col-md-12", {
                        "col-12 col-lg-3 ": selectedSidebarTab,
                        "d-lg-none  ": !selectedSidebarTab
                    })}
                style={{ marginTop: `${offsetTop}px` }}
            >
                <Row>
                    <div
                        className={classNames("player-sidebar__menu d-lg-none col-md-3 col-sm-3")}>
                        {select}
                    </div>
                    <div
                        ref={mainRow}
                        className={classNames("col-lg-12 col-md-9 col-sm-9")}>
                        {
                            tabsContent[selectedSidebarTab]
                        }
                    </div>
                </Row>
            </div>
            <div
                ref={tabsDropdown}
                className={classNames("player-sidebar__menu d-none d-lg-block", {
                    "closed": !selectedSidebarTab
                })}>
                {select}
            </div>
        </>
    )
}

export default PlayerSidebar