import { useState } from 'react';
import FaqsModal from "../components/FaqsModal/FaqsModal";

function useFaqModal() {

    const [faqsModalOpen, setFaqsModalOpen] = useState(false)

    const open = () => {
        setFaqsModalOpen(true)
    }

    const close = () => {
        setFaqsModalOpen(false)
    }

    const faqMOdal = (
        <>
            <FaqsModal
                open={faqsModalOpen}
                setFaqsModalOpen={(val) => setFaqsModalOpen(val)} />
        </>
    )


    return {
        _faqMOdal: faqMOdal,
        _openFaqModal: open,
        _closeFaqModal: close
    };
}

export default useFaqModal