import React, { useContext } from "react"
import classNames from "classnames";
import PlayerBox from "../PlayerBox/PlayerBox";
import QandA from "../QandA/QandA";
import Row from "../../ui/Row/Row";
import { LIVE, PRE_LIVE, PRE_VOD, UNKNOWN, VOD } from "../../config/channelStatus";
import SlidoIframe from "../SlidoIframe/SlidoIframe";
import "./IframeContent.scss"
import FakePlayer from "../FakePlayer/FakePlayer";
import MainContext from "../../context/MainContext";

const IframeContent = (props) => {

    const { slido, eventId, episode, channelStatus, goToPlayer, hideQaAIframe } = props
    const slidoUrl = episode ? episode.slido_url : ""

    const context = useContext(MainContext)
    let content = null

    const showSlido = (slido && context.channelStatus && context.channelStatus !== UNKNOWN)

    if (!episode) {
        content = (
            <div className={
                classNames("col")
            }>
                <FakePlayer>
                    {window._.get(context, "contents.notEligible")}
                </FakePlayer>
            </div>
        )
    }
    else if (goToPlayer && episode) {
        content = (
            <>
                <div className={
                    classNames("col", "p-0", {
                        "col-sm-8": showSlido
                    })
                }>
                    <PlayerBox
                        eventId={eventId}
                        episodeId={episode.id}
                    />
                    {
                        ([LIVE, PRE_LIVE, VOD, PRE_VOD].includes(channelStatus)) && !hideQaAIframe ? (
                            <QandA isIframe />
                        ) : null
                    }
                </div>
                {
                    (showSlido) && slidoUrl ? (
                        <div className={
                            classNames("col", "col-4", "p-0", "d-none", "d-sm-block")
                        }>
                            <SlidoIframe
                                minHeight="100vh"
                                url={slidoUrl} />
                        </div>
                    ) : null
                }
            </>
        )
    }
    return (
        <div id="iframe-container" className="iframe-container">
            <Row className="m-0">
                {content}
            </Row>
        </div>
    )
}

export default IframeContent