import classNames from 'classnames';
import React, { useContext, useRef, useState } from 'react';
import { ThemeContext } from 'styled-components';
import Input from '../components/Input/Input';
import Logo from '../components/Logo/Logo';
import MainContext from '../context/MainContext';
import Button from '../ui/Button/Button';
import Container from '../ui/Container/Container';
import Row from '../ui/Row/Row';
import Spin from '../ui/Spin/Spin';
import { getThemeConfig } from '../utils/functions';

function wrapSection(WrappedComponent) {
    return function (props) {
        const [email, setEmail] = useState("")
        if (WrappedComponent.hideIfEmpty && !props.content) {
            return null
        }
        const themeContext = useContext(ThemeContext);
        const context = useContext(MainContext)
        const mediaBackgroundDesktop = window._.get(themeContext, `media${window._.upperFirst(WrappedComponent.className)}BackgroundDesktop`)
        const backgroundRef = useRef(null)
        // let VideoDesktop = null;
        // let video = null
        let videoSource = null

        const defaultStyle = {
            display: "block",
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
            maxWidth: "100%",
            backgroundColor: window._.get(themeContext, `${WrappedComponent.className}BackgroundColor`) || window._.get(themeContext, "backgroundColor"),
            backgroundImage: (mediaBackgroundDesktop && mediaBackgroundDesktop.type === "image") ? `url(${mediaBackgroundDesktop.url})` : "none"
        }

        const hasDesktopVideo = (mediaBackgroundDesktop && mediaBackgroundDesktop.type === "video")
        // const Background = hasDesktopVideo ? "video" : "div";
        let background = (
            <div
                style={defaultStyle}
                className="section-background image-background" />
        )
        if (hasDesktopVideo) {
            videoSource = (
                <source src={mediaBackgroundDesktop.url} type={mediaBackgroundDesktop.mime} />
            )
            background = (
                <video
                    ref={backgroundRef}
                    style={defaultStyle}
                    className="section-background image-background"
                    autoplay muted loop
                >
                    {videoSource}
                </video>)
        }

        // const Section = styled.section`
        //     position: relative;
        //     padding: 3rem 0;
        //     // border-top: 1px solid #cccccc;
        // `;
        // const Title = styled.h2`
        //     line-height: 2.85rem;

        // `;

        const isAppView = window._.get(context, "isAppView")
        const is2ColsView = window._.get(context, "is2ColsView")

        const sectionMainTitle = props.mainTitle ? (
            <div className={classNames("website-section__maintitle")}>
                {props.mainTitle}
            </div>
        ) : null

        const sectionIntro = props.intro ? (
            <div className={classNames("website-section__intro")}
                dangerouslySetInnerHTML={{
                    __html: props.intro
                }}
            />
        ) : null

        const sectionTitle = props.hideTitle ? props.hideSectionIntro ? null : sectionIntro : props.title ? (isAppView || is2ColsView) ? (
            <>
                {sectionMainTitle}
                {sectionIntro}
                <h2 className={classNames("website-section__title")}>
                    {props.title}
                </h2>
            </>
        ) : (
            <Row>
                <div className="col-12">
                    <h2 className="website-section__title">
                        {props.title}
                    </h2>
                </div>
            </Row>
        ) : null

        const logo = props.hasLogo ? (
            <Logo sectionName={WrappedComponent.className} />
        ) : null

        let loginForm = null

        const loginFormTitle = window._.get(context, "contents.embeddedLoginTitle")

        loginForm = (
            WrappedComponent.className === "registration" &&
            props.hasLoginForm
        ) ? (
            <>
                <Row className="py-4 mt-4">
                    {
                        loginFormTitle ? (
                            <div className="col-12 pb-3 embedded-login-title">
                                {loginFormTitle}
                            </div>
                        ) : null
                    }
                    <div className="col-8 pr-0">
                        <Input
                            id="login_id"
                            name="login_id"
                            elementType={"email"}
                            onChange={(e) => setEmail(e.target.value)}
                            nowrap={true}
                            role="button"
                            value={email}
                            className=" w-100"
                            placeholder={window._.get(context, "contents.emailBoxLoginPlaceholder")}
                            style={{
                                display: "inline-block",
                            }}
                        />
                    </div>
                    <div className="col-4">
                        <Button
                            className="btn-login w-100"
                            onClick={() => props.onLoginClicked({
                                email: email
                            })}>
                            {
                                props.loginWaiting ? (
                                    <Spin />
                                    // <i className="fas fa-circle-notch fa-spin"></i>
                                ) : window._.get(context, "contents.login")
                            }
                        </Button>
                    </div>

                    {
                        props.loginErrors ? (
                            <div className="col-12 mt-2 text-danger">
                                {props.loginErrors.join(", ")}
                            </div>
                        ) : null
                    }
                </Row>
            </>
        ) : null
        // }        

        const sectionContent = (
            <>
                {logo}
                {loginForm}
                {sectionTitle}
                <>
                    <WrappedComponent {...props} />
                </>
            </>
        )

        const sectionTheme = getThemeConfig(context, WrappedComponent.className)
        const cols = window._.get(sectionTheme, "cols") || {}
        // console.log("getThemeConfig", WrappedComponent.className, context)
        let sectionClass = classNames(
            "website-section",
            WrappedComponent.className,
            props.className
        )
        const colRules = (props.fullWidth) ? "col-12" : {
            [`col-xs-${cols.mobile}`]: cols.mobile,
            [`col-md-${cols.tablet}`]: cols.tablet,
            [`col-lg-${cols.desktop}`]: cols.desktop,

        }
        sectionClass = sectionTheme ? classNames(
            sectionClass, colRules) : ""
        return (isAppView || is2ColsView) && !props.forceContainer ? (
            <div className={sectionClass} style={props.style}>
                {sectionContent}
            </div>
        ) : (
            <section className={sectionClass} style={{
                position: "relative",
                padding: "3rem 0"
            }}>
                {background}
                <Container
                    fluid={WrappedComponent.fluid || props.fluidContainer ? 1 : 0}
                    style={{ position: "relative", zIndex: 100 }}
                >
                    {sectionContent}
                </Container>
            </section>
        )
    }
}

export default wrapSection